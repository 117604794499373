module.exports = {
    headline: 'Aquarea Service + En lugn stund',
    text: 'Vi tar hand om din värmepump så att du kan slappna av och njuta av ett mysigt och varmt hem.',
    btn: {
        href: '/sv_SE/home/service_package',
        text: 'SE VÅRA SERVICEPAKET'
    },
    campaign: {
        img: {
            url: '/image/home/hero/PaSo-GW.svg',
            alt: 'hero-img-alt-text',
        },
        text: 'Beställ en Aquarea Service+-prenumeration under Gröna veckor mellan den 8-30 november 2021 och få installationspaketet gratis till ett värde av 1 495 euro. För varje abonnemang som säljs under denna period kommer vi också att plantera ett träd tillsammans med Treedom i ett behövande område.'
    }
};
