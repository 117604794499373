import React, {useEffect, useState} from "react";
import {FormGroup} from "../layout/form";

const SimpleCheckbox = ({checkboxes, setCheckboxValues, hint, images} = this.props) => {
    const [state, setState] = useState({checkboxes: checkboxes})

    useEffect(() => {
        setState({checkboxes: checkboxes})
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const _onChange = (event, id, _label) => {
        let checkboxesTemp = state.checkboxes
        
        checkboxesTemp.forEach((checkboxe) => {
            checkboxe.defaultValue = false
        })
        checkboxesTemp[id].defaultValue = true;

        setState({checkboxes: checkboxesTemp})
        setCheckboxValues(id === 0, _label)
    }

    return (
        <>
            {
                state.checkboxes.map((item, index) =>
                    <FormGroup key={index} className="col-4">
                        <div className="form-check">
                            <input className="form-check-input"
                                   id={item.key + "" + index} type="radio"
                                   value={item.value}
                                   checked={item.defaultValue}
                                   onChange={(event) => _onChange(event, index, item.key)}/>
                            <label className="form-check-label" htmlFor={item.key + "" + index}>
                                {images[index] && images[index].type === "label" ?
                                    <img src={images[index].path} alt=""/> : item.label}
                            </label>
                        </div>

                    </FormGroup>
                )
            }
        </>
    )
}

export default SimpleCheckbox;
