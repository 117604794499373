module.exports = {
    inputs: [
        {
            label: "New Password",
            id: "newPassword",
            name: "newPassword",
            placeholder: "New Password",
            type: "password"
        },
        {
            label: "Confirm Password",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Confirm Password",
            type: "password"
        }
    ],
    success: "Password reset has been requested, please check your inbox!",
    subheadline: "Reset Password",
    headline: "Reset Password",
    resetBtn: "Reset Password"
}