module.exports = {
    headline: "Service Packages comparison table",
    activePlanHint: "active plan",
    buttonTextUpgrade: "upgrade plan",
    buttonTextActive: "active plan",
    startingAt: " ",
    plusFee: "plus installation service package for",
    premiumText: "When purchasing an \"Aquarea+ Premium\" service package, you will receive \"Aquarea+ Smart\" until a Panasonic technician checks the installation and approves that the key technical requirements are fulfilled. In the case of not achieving these technical requirements, you will remain an \"Aquarea+ Smart\" customer and can enjoy the benefits of this package. You will be charged the \"Aquarea+ Smart\" price until the time your installation has been approved for \"Aquarea+ Premium\".",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_comfort.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exclusive"
            }
        ]
    },
    feature: {
        list: [{
            label: "On site annual maintenance",
            included: [true, true, true]
        },
            {
                label: "Priority support hotline",
                included: [true, true, true]
            },
            {
                label: "24/7 IoT remote monitoring",
                included: [false, true, true]
            },
            {
                label: "Aquarea periodical report",
                included: [false, true, true]
            },
            {
                label: "Daily diagnosis check",
                included: [false, true, true]
            },
            {
                label: "Priority support hotline 24/7",
                included: [false, true, true]
            },
            {
                label: "Service support within 24h including weekends",
                included: [false, true, true]
            },
            {
                label: "Panasonic Spare parts",
                included: [false, "20% Discount", "Included*"]
            },
            {
                label: "Labour & travel costs included",
                included: [false, "One breakdown repair visit included per year", true]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Included in Package"
        }
    },
    advantages: "Benefits of a subscription",
    explanation: {
        text: "Pricing incl. VAT (month)",
        layout: " text plan-comparison_table_feature_name"
    },
    planAbo: [
        // TODO: hardcoded annual prices! see CBDWA-329
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>per month or €258 annually</strong>",
            quarterly:"<strong>quarterly or €258 annually</strong>",
            annually:"<strong></strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>per month or €348 annually</strong>",
            quarterly:"<strong>per quarter or €348 annually</strong>",
            annually:"<strong></strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>per month or €468 annually</strong>",
            quarterly:"<strong>per month or €468 annually</strong>",
            annually:"<strong></strong>"
        }
    ],
    productCode: [
        {
            code: "Product Code",
            layout: " text plan-comparison_table_feature_name"
        },
        {
            code: "SVC-A2W-WARR-YR-S",
            layout: "_footer code text"
        },
        {
            code: "SVC-A2W-WARR-YR-A",
            layout: "_footer code text"
        },
        {
            code: "SVC-A2W-WARR-YR-P",
            layout: "_footer code text"
        },
    ],
    annotation: "*Terms & conditions apply"
};
