import React from "react";
import classNames from "classnames";
import Loader from "react-loader-spinner";

export const BtnGroup = ({children, className}) => {
  return (
    <div className={classNames("btn-group", className)}>
      {children}
    </div>
  );
}

export const StandardButton = ({isEnabled, onClick, text, classNames}) => {
  return (
    <button
      type="button"
      className={classNames}
      disabled={!isEnabled}
      onClick={onClick}
    >
      {text}
    </button>
  );
}

export const LoadingSpinnerButton = ({isEnabled, isLoading, onClick, text, classNames}) => {
  return (
    <button
      type="button"
      className={classNames}
      disabled={!isEnabled || isLoading}
      onClick={onClick}
    >
      {isLoading && <span className="loading-spinner-button-loading-spinner">
        <Loader
          type="TailSpin"
          color="#FFFFFF"
          height={20}
          width={20}
        />
      </span>}
      <span className="loading-spinner-button-text">{text}</span>
    </button>
  );
}