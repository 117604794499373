module.exports = {
  success: {
    title: "Thank you!",
    headline: "Your payment has been successful!",
    subheadline: "Thank you very much, ",
    btn: "GO TO MY ACCOUNT",
  },
  failure: "Your payment was rejected at our payment partner system.",
  cancel: "Your payment has been cancelled.",
  error: "An error occurred during payment at our payment partner system.",
  expired: "Your payment session expired at our payment partner system.",
  pending: "Your payment is still in progress at our payment partner system. This can take up to 10 minutes.",
  link: "GO TO MY ACCOUNT",
  link_href: "/en_IE/home/my_account",
};
