import React, { Component } from "react";
import i18n from "../../lib/i18n";
import { Link } from "react-router-dom";
import {
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
} from "../../lib/sessionStorage";
import { DetailList, DetailListRow, DetailEntry } from "../layout/detailEntry";

const t = i18n.namespace("MyAccountAccountInformation");
const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

class AccountInformation extends Component {
  render() {
    return (
      <>
        <DetailList>
          <DetailListRow>
            <DetailEntry label={t("details.name")}>
              {`${this.props.account.firstName} ${this.props.account.lastName}`}
            </DetailEntry>
            <DetailEntry label={t("details.address")}>
              {this.props.account.addresses[0].street}
              <br />
              {`${this.props.account.addresses[0].zipCode} ${this.props.account.addresses[0].city}`}
            </DetailEntry>
          </DetailListRow>
          <DetailListRow>
            <DetailEntry label={t("details.email")}>
              {this.props.account.email}
            </DetailEntry>
            <DetailEntry label={t("details.phone")}>
              {this.props.account.addresses[0].phoneNumber}
            </DetailEntry>
          </DetailListRow>
        </DetailList>

        <div className="mb-3 mb-sm-0 text-center">
          <Link className="btn-secondary" to={"/"+ countryCode +"/home/my_account/edit"}>
            {t("btn.edit")}
          </Link>
          <Link className="btn-primary mx-2" to={"/"+ countryCode +"/home/password_reset"}>
            {t("btn.reset")}
          </Link>
        </div>
      </>
    );
  }
}

export default AccountInformation;
