module.exports = {
    headline: "Welcome back",
    subheadline: "Welcome back",
    inputs: [
        {
            label: "Email",
            id: "email",
            name: "email",
            placeholder: "Email",
            type: "email"
        },
        {
            label: "Password",
            id: "password",
            name: "password",
            password_placeholder: "Password",
            type: "password"
        }
    ],
    btn: "Sign in",
    link: "forgot password?",
    link_href: "/en_ES/home/password_forgot",
    newProtectionPlan: {
        label: "Need a Service Package?",
        link: "Find a Service Package",
        link_href: "/en_ES/home/service_package",
    }

}