import React, {useContext, useEffect, useState} from "react";
import {useHistory, withRouter} from "react-router-dom";
import StepButtons from "../stepButtons";
import {
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem
} from "../../../lib/sessionStorage";
import {submitOrderLanguage, submitOrderRequest} from "../../../lib/dataAccess/checkout/orderRestEndpoint";
import {DetailContainer, DetailEntry, DetailHeadline, DetailList} from "../../layout/detailEntry";
import i18n from "../../../lib/i18n";
import {CartContext} from "../../../lib/contexts/cartContext";
import {exceptedPaymentMethods} from "./types/paymentMethods";
import {ErrorResponseHandling} from "../../../lib/dataAccess/errorResponseHandling";
import {AuthContext} from "../../../lib/contexts/authContext";
import {getCountryForCountryCode, getLanguageForCountryCode} from "../../../lib/helper/localizationHelper";
import { sendGTMDataLayer } from "../../../lib/tracking/initGTM";

const t = i18n.namespace("SummaryList");

export function ConfirmationStep(props) {
    const {cartContext} = useContext(CartContext);
    const { authContext, setAuthContext } = useContext(AuthContext);
    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
    //TODO remove quickfix for selecting paypal without activating it
    const paypal = {
        id: 'paypal',
        label: 'PayPal',
        image: '/image/icons/payment/paypal.svg'
    }
    const usedPaymentMethod = window.location.href.indexOf("devHiddenSePayPal=yes") > 0 ? paypal : exceptedPaymentMethods(countryCode).find(paymentMethod => paymentMethod.id === cartContext.paymentMethod);

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        window.scrollTo({
            top: 100,
            left: 100,
            behavior: 'smooth'
        });
        sendGTMDataLayer({
          event: "pageview",
          category: "Checkout-5Confirmation",
          eventModel: {
            event_category: "Checkout-5Confirmation",
          },
        });
    }, []);

    async function handleNextStepButtonClick(event) {
        event.preventDefault();
        setIsLoading(true);
        sendGTMDataLayer({
          event: "go to external payment",
          category: "Checkout-5Confirmation-NextStep",
          eventModel: {
            event_category: "Checkout-5Confirmation-NextStep",
          },
        });
        try {
            let userLanguage = await submitOrderLanguage(getLanguageForCountryCode(countryCode),getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME))
            if (userLanguage) {
                let res = await submitOrderRequest(getSessionStorageItem(CART_ID_SESSION_STORAGE_NAME), getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME));
                const windowOrigin = encodeURIComponent(window.location.origin);
                const paymentRedirectUrl = res.data.redirectUrl + '&'
                    + 'country=' + getCountryForCountryCode(countryCode) + '&'
                    + 'language=' + getLanguageForCountryCode(countryCode) + '&'
                    + 'successURL=' + windowOrigin + '/success&'
                    + 'cancelURL=' + windowOrigin + '/' + countryCode + '/home/checkout/payment&'
                    + 'failureURL=' + windowOrigin + '/payfailure&'
                    + 'errorURL=' + windowOrigin + '/payerror&'
                    + 'expiryURL=' + windowOrigin + '/payexpiry&'
                    + 'pendingURL=' + windowOrigin + '/paypending'
                console.log('paymentRedirectUrl: ', paymentRedirectUrl);
                window.location.href = paymentRedirectUrl;
            }    
        } catch (e) {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        }
    }

    return (
        <div className="registration_step_content">
            <DetailContainer>
                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("connectionCheck")}/>

                    {<DetailEntry label={t("details.internetConnected") + ":"}>
                        {props?.session?.getValuesByStep(0)?.find(value => value.key === 'internetConnected')?.value ?
                            t("yes") : t("no")}
                    </DetailEntry>}
                    {<DetailEntry label={t("details.internetReady") + ":"}>
                        { // value not set in checkout per default cause of preselection -> defaults to true
                        props?.session?.getValuesByStep(0)?.find(value => value.key === 'internetReady')?.value === null
                         ||props?.session?.getValuesByStep(0)?.find(value => value.key === 'internetReady')?.value ?
                            t("yes") : t("no")}
                    </DetailEntry>}
                </DetailList>

                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("accountInformation")}>
                        <button className="detail_headline_action_btn" type="button"
                                onClick={() => props.navigation.setStep(1)}>{t("editBtn")}</button>
                    </DetailHeadline>

                    <DetailEntry label={t("details.name") + ":"}>
                        {
                            // props.session.getValuesByStep(2).find(value => value.key === 'title').value + " "
                            props.session.getValuesByStep(1).find(value => value.key === 'firstName').value + " "
                            + props.session.getValuesByStep(1).find(value => value.key === 'lastName').value
                        }
                    </DetailEntry>
                    <DetailEntry label={t("details.email") + ":"}>
                        {props.session.getValuesByStep(1).find(value => value.key === 'email').value}
                    </DetailEntry>
                    <DetailEntry label={t("details.address") + ":"}>
                        {props.session.getValuesByStep(1).find(value => value.key === 'street').value}
                    </DetailEntry>
                    <DetailEntry label={t("details.zipCode") + ":"}>
                        {props.session.getValuesByStep(1).find(value => value.key === 'zipCode').value}
                    </DetailEntry>
                    <DetailEntry label={t("details.city") + ":"}>
                        {props.session.getValuesByStep(1).find(value => value.key === 'city').value}
                    </DetailEntry>
                    <DetailEntry label={t("details.phone") + ":"}>
                        {props.session.getValuesByStep(1).find(value => value.key === 'phoneNumber').value}
                    </DetailEntry>
                </DetailList>

                <DetailList className="detail_list--condenced">
                    <DetailHeadline headline={t("paymentDetails")}>
                        <button className="detail_headline_action_btn" type="button"
                                onClick={() => props.navigation.setStep(2)}>{t("editBtn")}</button>
                    </DetailHeadline>
                    <DetailEntry label={t("details.paymentMethod") + ":"}>
                        <img src={usedPaymentMethod.image} alt={usedPaymentMethod.label}/> {usedPaymentMethod.label}
                    </DetailEntry>
                </DetailList>

            </DetailContainer>

            <StepButtons
                showBackButton={false}
                backButtonHref={props.currentStepValues.href}
                backButtonLabel={props.currentStepValues.href_label}
                nextButtonEnabled={true}
                nextButtonOnClick={handleNextStepButtonClick}
                nextButtonText={t("confirmBtn")}
                btnAlignment="justify-content-center"
                isLoading={isLoading}
            />
        </div>
    )
}

export default withRouter(ConfirmationStep);


