module.exports = {
    editBtn: "Rediger",
    confirmBtn: "Køb servicepakke",
    connectionCheck: "Tjek forbindelse",
    accountInformation: "Konto Information",
    paymentDetails: "Betalingsmetode",
    installedBase: "Oplysninger om enheden",
    monthly:"om måneden",
    quarterly:"pr. kvartal",
    annually:"årligt",
    yes: "Ja",
    no: "Nej",
    details: {
        monthlyPrice:"Månedlige omkostninger",
        connectionFeePrice:"Engangsudgifter til installationsservice",
        internetConnected: "Har du en Cloud Adapter installeret?",
        internetReady: "Er din enhed klar til forbindelse?",
        modelName: "Model",
        serialNumber: "Serienummer",
        installationDate: "Installationsdato",
        gatewayId: "Gateway ID",
        title: "titel",
        firstName: "Fornavn",
        lastName: "Efternavn",
        name: "Navn",
        email: "Email",
        address : "Adresse",
        zipCode : "Postnummer",
        city: "By",
        phoneNumber: "Telefonnummer",
        phone : "Telefonnummer",
        paymentMethod: "Betalingsmetode"
    },
    confirm: "bekræfte"
}
