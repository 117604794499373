module.exports = {
    image: "",
    text: "Un sistema de calefacción requiere un mantenimiento regular para que funcione bien y prolongar su vida útil al máximo, al igual que mi coche. Con un plan Aquarea Service+, puedo relajarme mientras Panasonic se encarga del mantenimiento.",
    author: "Cecilie M. Thomson",
    quotes:{
        image: "/image/home/quotes/logo.png",
        alt: "aquarea",
        headline: "Explore nuestra amplia gama de equipos para nuestras bombas de calor Aquarea",
        link: "Lea más sobre las bombas de calor Aquarea aquí",
        link_href: "https://www.aircon.panasonic.eu/ES_es/"
    }
};
