module.exports = {
    headline: 'Resta aggiornato',
    icons: [
        {
            icon: "facebook",
            link: "https://www.facebook.com/PanasonicHeatingCoolingEU/"
        },
        {
            icon: "youtube",
            link: "https://www.youtube.com/c/PanasonicHeatingCoolingSolutionsEurope"
        },
        {
            icon: "linkedin",
            link: "https://www.linkedin.com/company/panasonic-heating-and-cooling-solutions-europe/"
        },
    ],
    links: [
        {
            href: "/it_IT/home/terms_of_use_of_the_website",
            label: "Condizioni di utilizzo" // del sito web
        },
        {
            href: "/it_IT/home/right_of_withdrawal",
            label: "Diritto di recesso"
        },
        {
            href: "/it_IT/home/cookies/",
            target: "_blank",
            label: "Politica dei cookie"
        },
        {
            href: "/it_IT/home/privacy_policy",
            label: "Politica sulla privacy"
        },
        {
            href: "/it_IT/home/terms_and_conditions",
            label: "Termini e condizioni"
        }
    ],
    copyright: "Copyright 2021 Panasonic Marketing Europe GmbH, tutti i diritti riservati",
};
