module.exports = {
    details: {
        title: "Titel",
        firstName: "Förnamn",
        lastName: "Efternamn",
        name: "Namn",
        email: "E-post",
        address : "Adress",
        zipCode : "Postnummer",
        city: "Ort",
        phoneNumber: "Telefonnummer",
        phone : "Telefonnummer",
    },
    btn: {
        edit: "Redigera personlig information",
        back: "Tillbaka till mitt konto",
        update: "Uppdatera konto",
        reset: "Återställning av kod"
    },
    editAccount: {
        headline: "Redigera personlig information"
    },
    loading: "Laddar ..."
}
