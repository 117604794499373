module.exports = {
    image: "",
    text: "Ett värmesystem kräver regelbundet underhåll för att fungera bra och förlänga dess livslängd så mycket som möjligt - precis som min bil. Med en Aquarea Service+-plan kan jag slappna av medan Panasonic tar hand om underhållet.",
    author: "Cecilie M. Thomson",
    quotes:{
        image: "/image/home/quotes/logo.png",
        alt: "aquarea",
        headline: "Utforska vårt breda utbud av utrustning för våra Aquarea-värmepumpar",
        link: "Läs mer om Aquarea-värmepumpar här",
        link_href: "https://www.aircon.panasonic.eu/SE_sv/"
    }
};
