import React, {useContext, useEffect, useState} from 'react'
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Breadcrumbs from "../lib/breadcrumbs";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";
import StepButtons from "../components/stepForm/stepButtons";
import {COUNTRY_CODE_NAME, getSessionStorageItem} from "../lib/sessionStorage";
import {putRegisterUnitData} from "../lib/dataAccess/customer/accountRestEndpoint";
import {AuthContext} from "../lib/contexts/authContext";
import {scrollToPos} from "../lib/scrollToPos";
import InputMask from "react-input-mask";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("RegisterUnit");

function RegisterUnit(props) {
  useEffect(() => {
    scrollToPos(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const billingAddress = props.location.baseUnit?.address
  const [showPopup, setShowPopup] = useState(false);
  const [popupImg, setPopupImg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modelName, setModelName] = useState("");
  const [installationDate, setInstallationDate] = useState(null);
  const [serialNumber, setSerialNumber] = useState("");
  const [gatewayId, setGatewayId] = useState("");
  const [street, setStreet] = useState(billingAddress?.street);
  const [zipCode, setZipCode] = useState(billingAddress?.zipCode);
  const [city, setCity] = useState(billingAddress?.city);
  const [serialNumberError, setSerialNumberError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [gatewayIdError, setGatewayIdError] = useState("");
  const [installationDateError, setInstallationDateError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const {authContext} = useContext(AuthContext);

  const ERROR_DATE_IS_IN_FUTURE = t("errors.futureDate")
  const ERROR_DATE_IS_TOO_FAR_IN_PAST = t("errors.dateTooFarInPast")
  const ERROR_ONLY_DIGITS = t("errors.onlyDigits")
  const ERROR_LENGTH_IS_11 = t("errors.lengthIs11")
  const ERROR_ZIPCODE_INVALID = t("errors.zipCode")
  const ERROR_ZIPCODE_SHORT = t("errors.zipCodeMin")
  const ERROR_ZIPCODE_LONG = t("errors.zipCodeMax")
  const ERROR_REQUIRED_FIELD = t("errors.requiredField")
  const ERROR_ADDRESS_TOO_LONG = t("errors.addressTooLong")
  const ERROR_CITY_TOO_LONG = t("errors.cityTooLong")
  const ERROR_SERIAL_SHORT = t("errors.serialMin")
  const ERROR_SERIAL_LONG = t("errors.serialMax")

  const today = new Date();
  const minDate = new Date();
  minDate.setFullYear(today.getFullYear() - 10);

  function togglePopup(event, imgPath) {
    setShowPopup(!showPopup)
    setPopupImg(imgPath)
  }

  function stopPropagation(e) {
    e.stopPropagation();
  }

  function getShippingAddress() {
    const shippingAddress = props.location.baseUnit?.address
    shippingAddress.street = street
    shippingAddress.zipCode = zipCode
    shippingAddress.city = city
    return shippingAddress;
  }

  async function onConfirmButtonClick() {
    let shippingAddress = getShippingAddress()
    if (window.confirm(getBaseUnitInstallationAddressLabel() + "\n" +
        shippingAddress.street + "\n" +
        shippingAddress.city + "   " + shippingAddress.zipCode + "\n" +
        getBaseUnitCanNotBeChangedLabel())) {
      setIsLoading(true)

      try {
        const body = {
          "deviceType": modelName,
          "gatewayId": gatewayId,
          "installationDate": installationDate.toISOString().split("T")[0],
          "serialNumber": serialNumber,
          "internetReady": props.location.baseUnit?.internetReady,
          "internetConnected": props.location.baseUnit?.internetConnected,
          "address": getShippingAddress()
        }
        await putRegisterUnitData(props.location.baseUnit?.id, body, authContext.authToken)
        window.location.href = "/" + countryCode + "/home/my_account"
      } catch (error) {
        console.error(error)
        window.location.href = "/" + countryCode + "/404"
      }
    }
  }

  function getBaseUnitInstallationAddressLabel() {
    switch (countryCode.substring(countryCode.length - 2)) {
      case "DK":
        return "Enhedens installationsadresse:"
      case "ES":
        return "Dirección de la instalación de su aerotermia:"
      case "IE":
        return "Base Unit Installation Address:"
      case "IT":
        return "Indirizzo di installazione dell'unità di base:"
      case "SE":
        return "Värmepumpens installationsadress:"
      default:
        return "Base Unit Installation Address:"
    }
  }

  function getBaseUnitCanNotBeChangedLabel() {
    switch (countryCode.substring(countryCode.length - 2)) {
      case "DK":
        return "Enhedens installationsadresse kan ikke ændres efter registrering, er du sikker på, at du har indtastet de korrekte oplysninger?"
      case "ES":
        return "La dirección de instalación de su aerotermia no se puede modificar una vez registrada, ¿está seguro de que ha introducido la información correcta?"
      case "IE":
        return "The Base Unit Installation Address can not be changed after registration, are you sure you have entered the correct information?"
      case "IT":
        return "L'indirizzo di installazione dell'unità di base non può essere modificato dopo la registrazione, sei sicuro di aver inserito le informazioni corrette?"
      case "SE":
        return "Basenhetens installationsadress kan inte ändras efter registrering, är du säker på att du har angett rätt information?"
      default:
        return "The Base Unit Installation Address can not be changed after registration, are you sure you have entered the correct information?"
    }
  }

  function renderPopupLink(imgData) {
    return <div className={"hover-img-link form-label"}>
      <button onClick={(e) => togglePopup(e, imgData.path)}>{imgData.linkText}</button>
    </div>
  }

  function renderOptions(options) {
    return Object.values(options).map(
      function(item) {
        return <option key={item} value={item}>{item}</option>
      }
    )
  }

  function validateInstallationDate(event) {
    const dateString = event.currentTarget.value
    const chosenDate = new Date(dateString)

    if (chosenDate > today) {
      setInstallationDateError(ERROR_DATE_IS_IN_FUTURE)
      return
    }
    if (chosenDate < minDate) {
      setInstallationDateError(ERROR_DATE_IS_TOO_FAR_IN_PAST)
      return
    }

    setInstallationDate(chosenDate)
    setInstallationDateError("")
  }

  function validateSerialNumber(event) {
    const value = event.currentTarget.value
    let error = ''
    if (!value) {
      error = '';
    } else if (!/^\d+$/.test(value)) {
      error = ERROR_ONLY_DIGITS;
    } else if (value.length > 20) {
      error = ERROR_SERIAL_LONG;
    } else if (value.length < 8) {
      error = ERROR_SERIAL_SHORT;
    }
    setSerialNumberError(error);
  
    if (!error) {
      setSerialNumber(value);
    }
  }

  function validateGatewayId(event) {
    const value = event.currentTarget.value
    if (value !== undefined && value !== "" && !(/^B[0-9]{4}-[0-9]{5}$/.test(value))) {
      setGatewayIdError(ERROR_LENGTH_IS_11)
    } else {
      setGatewayId(value)
      setGatewayIdError("")
    }
  }

  function isNextButtonEnabled() {
    return !(!installationDate || !modelName || !!serialNumberError || !street || !zipCode || !city ||
        !!installationDateError || !!serialNumberError || !!gatewayIdError || !!zipCodeError ||
        modelName === t("model.emptyOption"));
  }

  function setStreetValue(event) {
    const value = event.currentTarget.value
    if (value.length < 1) {
      setAddressError(ERROR_REQUIRED_FIELD);
    } else if (value.length > 30) {
      setAddressError(ERROR_ADDRESS_TOO_LONG);
    } else {
      setAddressError("");
    }
    setStreet(value);

  }

  function setCityValue(event) {
    const value = event.currentTarget.value
    if (value.length < 1) {
      setCityError(ERROR_REQUIRED_FIELD);
    } else if (value.length > 35) {
      setCityError(ERROR_CITY_TOO_LONG);
    } else {
      setCityError("")
    }
    setCity(value)
  }
  function setZipCodeValue(event) {
    const zipCodeValue = event?.currentTarget?.value;
    const regExpDK = /^[0-9]{4}$/;
    // const regExpIE = /^[0-9A-Z]{7}[0-9A-Z]*$/;
    const regExpES = /^[0-9]{5}$/;
    const regExpIT = /^[0-9]{5}$/;
    const regExpSV = /^[0-9]{3} [0-9]{2}$/;
    switch (countryCode.substring(countryCode.length - 2)) {
      case "DK":
        if (!regExpDK.test(zipCodeValue)) {
          setZipCodeError(ERROR_ZIPCODE_INVALID)
          setZipCode(zipCodeValue)
        } else {
          setZipCode(zipCodeValue)
          setZipCodeError("");
        }
        break;
      case "ES":
        if (!regExpES.test(zipCodeValue)) {
          setZipCodeError(ERROR_ZIPCODE_INVALID)
          setZipCode(zipCodeValue)
        } else {
          setZipCode(zipCodeValue)
          setZipCodeError("");
        }
        break;
      case "IE":       
        setZipCode(zipCodeValue)
        if(zipCodeValue.length === 0){
          setZipCodeError(ERROR_REQUIRED_FIELD)
          return
        }
        if(zipCodeValue.length < 7){
          setZipCodeError(ERROR_ZIPCODE_SHORT)
        }
        else if(zipCodeValue.length > 8){
          setZipCodeError(ERROR_ZIPCODE_LONG)
        }
        else {
          setZipCodeError("");
        }
      break;
      case "IT":
        if (!regExpIT.test(zipCodeValue)) {
          setZipCodeError(ERROR_ZIPCODE_INVALID)
          setZipCode(zipCodeValue)
        } else {
          setZipCode(zipCodeValue)
          setZipCodeError("");
        }
        break;
      case "SE":
        if (!regExpSV.test(zipCodeValue)) {
          setZipCodeError(ERROR_ZIPCODE_INVALID)
          setZipCode(zipCodeValue)
        } else {
          setZipCode(zipCodeValue)
          setZipCodeError("");
        }
        break;
      default :
        setZipCodeError("unknown Country code")
        return;
    }
  }

  return <>
    <div className="registration-heatpump page">
      {showPopup ?
          <div className={"popup-outer"} onClick={togglePopup}>
            <div className={"popup-inner"} onClick={stopPropagation}>
              <img className={"popup-img"} src={popupImg} alt={"popup img alt text"}/>
            </div>
          </div>
          : null
      }

      <Menu />

      <div className="container">
        <div className="page_header">
          <section className="breadcrumbs">
            <Breadcrumbs />
          </section>

          <h1 className="registration_headline">{t("headline")}</h1>
        </div>

        <div className={"container checkout_page"}>
          <div className={"form-section checkout_page_content"}>
            <div className={"form-container"}>
              <div className={"registration_step_content"}>
                <fieldset className={"form-fieldset"}>
                  <h2 className={"form-headline"}>{t("date.title")}</h2>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("date.name")}</label>
                      <input id="installationDate" className={"form-control is-invalid"} type={"date"}
                             min={minDate}
                             max={today}
                             name={t("date.name")}
                             onChange={(event) => validateInstallationDate(event)}>
                      </input>
                      <div className={"error-text"}>
                        {!!installationDateError ? installationDateError : ""}
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset className={"form-fieldset"}>
                  <h2 className={"form-headline"}>{t("model.title")}</h2>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("model.name")}</label>
                      {renderPopupLink(t("model.img"))}
                      <select className={"form-control"} name={"model"} id={"model"} value={modelName}
                              onChange={(event) =>
                                  setModelName(event.currentTarget.value)}>
                        <option key={t("model.emptyOption")}>{t("model.emptyOption")}</option>
                        {renderOptions(t("model.options"))}
                      </select>
                    </div>
                  </div>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("serialNumber.name")}</label>
                      {renderPopupLink(t("serialNumber.img"))}
                      <input className={"form-control"} type="text" id={"serialNumber"} maxLength="40"
                             name={"serialNumber"}
                             onChange={(event) => validateSerialNumber(event)}>
                      </input>
                      <div className={"error-text"}>
                        {!!serialNumberError ? serialNumberError : ""}
                      </div>
                    </div>
                  </div>
                </fieldset>
                <fieldset className={"form-fieldset"}>
                  <h2 className={"form-headline"}>{t("gatewayId.title")}</h2>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"} htmlFor={"gatewayId"}>{t("gatewayId.name")}</label>
                      {renderPopupLink(t("gatewayId.img"))}
                      <InputMask mask="B9999-99999" className={"form-control"} id={"gatewayId"}
                                 name={"gatewayId"} onChange={(event) => validateGatewayId(event)}/>
                      <div className={"error-text"}>
                        {!!gatewayIdError ? gatewayIdError : ""}
                      </div>
                    </div>
                  </div>
                </fieldset>

                <fieldset className={"form-fieldset"}>
                  <h2 className={"form-headline"}>{t("shippingAddress.title")}</h2>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("shippingAddress.street.label")}</label>
                      <input className={"form-control"} type="text" id={"shippingAddress.street"}
                             name={"shippingAddress.street"}
                             value={street}
                             onChange={(event) => setStreetValue(event)}>
                      </input>
                      <div className={"error-text"}>
                        {!!addressError ? addressError : ""}
                      </div>
                    </div>
                  </div>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("shippingAddress.zipcode.label")}</label>
                      <input className={"form-control"} type="text" id={"shippingAddress.zipcode"}
                             name={"shippingAddress.zipcode"}
                             value={zipCode}
                             onChange={(event) => setZipCodeValue(event)}>
                      </input>
                      <div className={"error-text"}>
                        {!!zipCodeError ? zipCodeError : ""}
                      </div>
                    </div>
                  </div>
                  <div className={"form-row"}>
                    <div className={"form-group col"}>
                      <label className={"form-label"}>{t("shippingAddress.city.label")}</label>
                      <input className={"form-control"} type="text" id={"shippingAddress.city"}
                             name={"shippingAddress.city"}
                             value={city}
                             onChange={(event) => setCityValue(event)}>
                      </input>
                      <div className={"error-text"}>
                        {!!cityError ? cityError : ""}
                      </div>
                    </div>
                  </div>
                </fieldset>

                <StepButtons
                    showBackButton={true}
                    backButtonLabel={t("btn.back")}
                    backButtonOnClick={props.history.goBack}
                    nextButtonEnabled={isNextButtonEnabled()}
                    nextButtonOnClick={onConfirmButtonClick}
                    nextButtonText={t("btn.next")}
                    isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Newsletter />
    <Footer />
  </>
}

export default RegisterUnit;
