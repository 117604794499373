module.exports = {
    reports: "Rapporter",
    headline: "Aquarea Service+ Driftsrapport",
    table: {
        headers: {
            date: "dato",
            report: "driftsrapport",
        },
        showAll: "vis alle rapporter",
        hideOlder: "skjul gamle rapporter",
    },
    download: "Download rapporten her"
}
