let locale;

function init(l) {
  locale = l;
}

const cache = {};

function flatten(obj) {
  const result = {};
  for (const k in obj) {
    if (!obj.hasOwnProperty(k)) continue;
    if (typeof obj[k] === 'object') {
      result[k] = obj[k];
      const flat = flatten(obj[k]);
      for (const flatK in flat) {
        if (!flat.hasOwnProperty(flatK)) continue;
        result[`${k}.${flatK}`] = flat[flatK];
      }
    } else {
      result[k] = obj[k];
    }
  }
  return result;
}

function load(namespace) {
  let values = require(`../locales/${locale}/${namespace}`);
  cache[namespace] = flatten(values);
}

function interpolate(translation, params) {
  return translation.replace(/{{(.*?)}}/g, function (_, match) {
    match = match.trim();
    return params[match] ? params[match] : '';
  });
}

function get(namespace, key, params) {
  if (!cache[namespace]) {
    load(namespace);
  }

  const value = cache[namespace][key];

  if (!value) {
    return `${namespace}.${key}`;
  }

  if (typeof value !== "string") {
    return value;
  }

  return params ? interpolate(value, params) : value;
}

function namespace(ns) {
  return (key, params) => get(ns, key, params);
}
const i18n = { init, namespace, interpolate }
export default i18n;
