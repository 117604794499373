module.exports = {
    headline: "Cassa",
    list: [
        {
            label: "Controllo delle connessioni",
            href: "/it_IT/home/service_package",
            href_label: "TORNA AI PIANI DI PROTEZIONE",
            next_btn: "PASSO AVANTI",
            add_installation_items: {
                add_installation_btn: "Aggiungi il servizio di installazione per 1.495,00DKK",
                add_installation_label: "Informazioni importanti:",
                add_installation_text: "Per poterti offrire il servizio completo dei nostri Piani di Protezione, è necessario che il tuo dispositivo sia collegato a Internet. <br><br>Per assicurarti che il tuo dispositivo sia connesso a Internet, ti consigliamo di prenotare il nostro pacchetto di servizi di installazione Internet. Questo servizio ti costerà un costo una tantum di 49€. <br><br>Aggiungeremo automaticamente il nostro servizio di installazione al processo di acquisto e ti chiameremo per fissare un orario per l'installazione.",
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "PASSO AVANTI",
                change_plan_btn: "Cambia la mia selezione in Aquarea Plan*",
                change_plan_link: "Per saperne di più su Aquare Plan *",
                change_plan_label: "Informazioni importanti:",
                change_plan_text: "In questo momento non possiamo offrirle tutti i nostri piani di protezione perché il suo dispositivo non può essere collegato a Internet. Per fornirle il miglior servizio possibile, cambieremo il suo Protection Plan selezionato in Aquarea Bronze in questo processo di acquisto. <br><br>Il tuo piano di protezione selezionato: Aquarea Plan *** <br><br>Cambieremo in: Aquarea Plan* per 25€ mese.",
                change_plan_dropdowntext: "Con il nostro contratto di manutenzione Aquarea Guard ha una preoccupazione in meno. La sua pompa di calore è costantemente monitorata a distanza. E se si verifica un errore, siamo i primi a saperlo. La contatteremo immediatamente e cercheremo di risolvere il problema o di coordinare un appuntamento di assistenza.<br><br>Non deve fare altro che prendere una tazza di tè nel frattempo.",
                change_plan_dropdowntext_close_btn: true,
                change_plan_dropdowntext_close_btn_label: "CHIUDERE LE INFORMAZIONI",
            },
            inputList: [
                {
                    text: "Per offrirti un servizio ottimale, dobbiamo assicurarci che i tuoi dispositivi siano connessi a Internet.",
                    label: "La vostra unità è collegata a Internet?",
                    width: "completo",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "non è sicuro?",
                        text: "prova",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sì",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "La sua unità è pronta per internet?",
                    width: "completo",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "non è sicuro?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sì",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
            ],
        },
        {
            label: "Base installata",
            href: "/service_package",
            href_label: "TORNA AI PIANI DI PROTEZIONE",
            next_btn: "PASSO AVANTI",
            inputList: [
                {
                    headline: "Quale modello è installato?",
                    inputs: [
                        {
                            label: "NOME MODELLO",
                            name: "model",
                            placeholder: "KIT-ADC05JEB5",
                            type: "selezionare",
                            width: "completo",
                            options: [
                                "KIT-ADC05JEB5",
                                "KIT-WQC09H3E8",
                                "KIT-WQC12H9E8",
                                "WH-MXC09H3E5",
                                "WH-MXC12H6E5",
                                "WH-MXC09H3E8",
                            ]
                        },
                        {
                            label: "NUMERO DI SERIE",
                            name: "numero di serie",
                            placeholder: "X03YWWXXX",
                            type: "text",
                            width: "completo"
                        }
                    ]
                },
                {
                    headline: "Se si dispone già dell'adattatore di rete, inserire l'ID del dispositivo",
                    inputs: [
                        {
                            label: "DEVICE ID",
                            name: "gatewayid",
                            placeholder: "54321-12345",
                            type: "text",
                            width: "completo"
                        },
                    ]
                },
                {
                    headline: "Quando è stata installata l'unità?",
                    inputs: [
                        {
                            label: "DATA DI INSTALLAZIONE",
                            name: "installationDate",
                            placeholder: "12/22/2020",
                            type: "data",
                            width: "completo",
                        },
                    ]
                },
            ],
        },
        {
            label: "Informazioni sul conto",
            href: "/service_package",
            href_label: "TORNA AI PIANI DI PROTEZIONE",
            next_btn: "PASSO AVANTI",
            inputList: [
                {
                    headline: "Creare un nuovo account",
                    login: "ISCRIVITI",
                    login_path: "",
                    login_label: "Sei già un utente?",
                    inputs: [
                        {
                            label_first: "EMAIL",
                            label_last: "RIPETERE L'E-MAIL",
                            name: "e-mail",
                            placeholder: "Email",
                            type: "matching_email_values",
                            width: "completo",
                            checkId: "e-mail",
                            inputs:{
                                type: "e-mail",
                                validation: "matching_email"
                            }
                        },
                        {
                            label_first: "PASSWORD",
                            label_last: "RIPETI LA PASSWORD",
                            name: "password",
                            placeholder: "Password",
                            type: "password",
                            width: "completo",
                            checkId: "password",
                            inputs:{
                                type: "password",
                                validation: "matching_password"
                            }
                        },
                    ]
                },
                {
                    headline: "Indirizzo del conto",
                    inputs: [
                        {
                            label: "TITOLO",
                            name: "titolo",
                            placeholder: "Mrs.",
                            type: "selezionare",
                            width: "piccolo",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            type: "white_space",
                            width: "piccolo",
                        },
                        {
                            label: "NOME",
                            name: "firstName",
                            placeholder: "Nome",
                            type: "text",
                            width: "piccolo",
                            validation: "richiesto"
                        },
                        {
                            label: "Cognome",
                            name: "lastName",
                            placeholder: "cognome",
                            type: "text",
                            width: "piccolo",
                            validation: "richiesto"
                        },
                        {
                            label: "STREET",
                            name: "strada",
                            placeholder: "strada",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "C.A.P.",
                            name: "zipCode",
                            placeholder: "1820",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "CITTÀ",
                            name: "città",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "NUMERO DI TELEFONO",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "completo"
                        }
                    ]
                },
                {
                    headline: "Dove si trova l'unità?",
                    inputs: [
                        {
                            labels: [
                                {
                                    id: "same_address",
                                    label: 'Same as account</br>address',
                                    name: "same_acc",
                                    value: "",
                                    width: "piccolo"
                                },
                                {
                                    id: "diff_address",
                                    label: 'Enter different</br>address',
                                    name: "diff_address",
                                    value: "",
                                    width: "piccolo"
                                }
                            ],
                            type: "radio",
                            width: "completo",
                        },
                    ]
                }
            ],
        },
        {
            label: "Dettagli di pagamento",
            href: "/service_package",
            href_label: "TORNA INDIETRO",
            text: "Si prega di selezionare il metodo di pagamento Il pagamento sarà completato dopo il checkout",
            next_btn: "PASSO AVANTI",
            inputList: [
                {
                    headline: "Inserisci i dettagli del pagamento",
                    inputs: [
                        {
                            label: "Metodo di pagamento",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Metodo di pagamento",
                                    key: "paymentOpt",
                                    value: "credito",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Metodo di pagamento",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "completo"
                        },
                        {
                            label: "NUMERO DI CARTA",
                            name: "card_number",
                            placeholder: "Numero di carta",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "DATA DI SCADENZA",
                            name: "expiration_date",
                            placeholder: "Data di scadenza",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "CVV",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                                    name: "datarights",
                                    value: "",
                                    width: "completo"
                                },
                            ],
                            type: "radio",
                            width: "completo",
                        }
                    ]
                },
            ],
        },
        {
            label: "Conferma",
            href: "/service_package",
            href_label: "TORNA AI PIANI DI PROTEZIONE",
            next_btn: "PASSO AVANTI",
            inputList: [
                {
                    headline: "Creare un nuovo account",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "Email",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "PASSWORD",
                            placeholder: "Password",
                            type: "text",
                            width: "piccolo"
                        }
                    ]
                },
                {
                    headline: "Indirizzo del conto",
                    inputs: [
                        {
                            label: "TIPO DI CLIENTE",
                            name: "cliente",
                            placeholder: "Privato",
                            type: "selezionare",
                            width: "piccolo",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "TITOLO",
                            name: "titolo",
                            placeholder: "Mrs.",
                            type: "selezionare",
                            width: "piccolo",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "NOME",
                            name: "first_name",
                            placeholder: "Nome",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "Cognome",
                            name: "last_name",
                            placeholder: "cognome",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "STREET",
                            name: "strada",
                            placeholder: "indirizzo",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "C.A.P.",
                            name: "zipCode",
                            placeholder: "1820",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "CITTÀ",
                            name: "città",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "piccolo"
                        },
                        {
                            label: "NUMERO DI TELEFONO",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "completo"
                        }
                    ]
                }
            ],
        }
    ],
    summary: {
        conformation: {
            headline: "CONFERMA",
            subheadline: "Piano selezionato"
        },
    },
};
