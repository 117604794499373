module.exports = {
    inputs: {
        label: "Email",
        id: "email",
        name: "email",
        placeholder: "Your Email",
        type: "email"
    },
    success: "Der er anmodet om nulstilling af adgangskode, se venligst i din e-mailindbakke!",
    subheadline:"Adgangskode glemt",
    headline:"Adgangskode glemt",
    submit: "Indsend"
}