module.exports = {
    headline: "Why choose our Aquarea Service +?",
    text: "By choosing Aquarea Service +, you get the best from your Aquarea heat pump, without any surprises. Thanks to remote monitoring, your heating specialist can take care of everything without bothering you and interrupting your life.",
    list: [
        {
            icon: "/image/icons/services/monitoring.png",
            text: "Our service technicians are experts in Aquarea heat pumps",
        },
        {
            icon: "/image/icons/services/24h_response_time.png",
            text: "We diagnose your problem remotely and avoid any unnecessary visits",
        },
        {
            icon: "/image/icons/services/247.png",
            text: "Application of IoT technology to your heat pump",
        }
    ],
    btn_label: "SEE OUR SERVICE PACKAGES",
    btn_href: "/en_IE/home/service_package"
};
