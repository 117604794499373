module.exports = {
    common: {
        REQUIRED: "This field is required",
        MAX_LENGTH: "Max {{maxLength}} characters",
        MIN_LENGTH: "Min {{minLength}} characters",
        MAX_LENGTH_DIGIT: "Max {{maxLength}} digits",
        MIN_LENGTH_DIGIT: "Min {{minLength}} digits",
        COMPARE: "The repeat value should be the same",
        DATE: "No valid date",
        EXACTLY_LENGTH: "Exact {{exactlyLength}} characters",
        EMAIL: "No valid email address",
        BACKEND_VALIDATION_ERROR: "There is a server error",
        ZIPCODE: "{{length}} and only numbers",
        SWEDISH_ZIPCODE: "{{length}} and only numbers",
        PASSWORD: "Min 8 characters, numbers and letters",
        EXACT_TAX_CODE_LENGTH: "Please enter {{exactlyLength}} character tax code"
    },
    USER_ALREADY_REGISTERED: "We cannot proceed, as your e-mail address is already registered",
    REGISTRATION_FAILED: "An technical error occurred during the registration. Please try again later.",
    validationMethods: {
        "required": {
            message: "This field is required.",
        },
        "email": {
            message: "Invalid email address",
        },
        "matching_email_values": {
            message_mail: "Invalid email address",
            message_not_same: "The email addresses must be the same."
        },
        "password": {
            message: "Invalid password",
            message_password: "Invalid password.",
            message_password_lengths: "Min 8 characters, numbers and letters",
            message_not_same: "The passwords must be the same."
        },
        "loginError": {
            message: "Login or Password are incorrect"
        },
        "missingEmail": {
            message: "Email is missing"
        },
    },
    optionalValue: "optional"
}
