module.exports = {
    headline: "Varför välja Aquarea Service+?",
    text: "Välj Aquarea Service+ och få ut det bästa av din Aquarea-värmepump - utan onödiga överraskningar. Tack vare fjärrövervakning kan en professionell tekniker ta hand om värmepumpens funktion utan att behöva störa dig i din vardag.",
    list: [
        {
            icon: "/image/icons/services/monitoring.png",
            text: "Våra servicetekniker är experter på Aquarea-värmepumpar.",
        },
        {
            icon: "/image/icons/services/24h_response_time.png",
            text: "Vi diagnostiserar eventuella fel externt och undviker onödiga servicebesök.",
        },
        {
            icon: "/image/icons/services/247.png",
            text: "Vi ansluter IoT-teknik till din värmepump",
        }
    ],
    btn_label: "SE VÅRA SERVICEPAKET",
    btn_href: "/sv_SE/home/service_package"
};