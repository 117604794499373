import React, {useContext, useEffect, useState, useRef} from "react";
import Menu from "../components/menu";
import Breadcrumbs from "../lib/breadcrumbs";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";

import {TokenContext} from "../lib/contexts/tokenContext";
import {Link, withRouter} from "react-router-dom";
import {emailValidation, requiredInput} from "../lib/validation";
import {AUTH_TOKEN_SESSION_STORAGE_NAME, COUNTRY_CODE_NAME, getSessionStorageItem, setSessionStorageItem} from "../lib/sessionStorage";
import {AuthContext} from "../lib/contexts/authContext";
import {getAccount} from "../lib/dataAccess/accountService";
import {logInUser} from "../lib/dataAccess/customer/customerRestEndpoint";
import { LoadingSpinnerButton } from "../components/layout/buttons";
import { getFormattedCountryCode } from "../lib/helper/localizationHelper";


import i18 from "../lib/i18n";
const t = i18.namespace("LoginPage");
const messages = i18.namespace("Validation");
let countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

//TODO: create hook for profil data (token).
const LoginPage = (props) => {
    const [setAccountInformation] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { authContext, setAuthContext } = useContext(AuthContext);
    const { setTokenContext } = useContext(TokenContext);
    const [state, setState] = useState({
        email: { error: false, value: "" },
        password: { error: false, value: "" },
        login: { error: false, value: "" },
        missingEmail: { error: false, value: "" },
    });

    const inputEmailRef = useRef(null);
    const inputPasswordRef = useRef(null);
    const refs = [inputEmailRef, inputPasswordRef]

    useEffect(() => {
        if (!!authContext.authToken) {
            setAuthContext({ authToken: null, accountData: null });
        }
        const authToken = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);

        if (authToken) {
            getAccount(authToken)
                .then(res => setAccountInformation(res.data))
                .catch(e => {
                    //console.log(e);
                })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const _onBlur = (event, type) => {
        let value = event.target.value;
        let error = state[type].error;
        if (type === "password") {
            error = requiredInput(value);
            updateState(type, value, error);
        }

        if (type === "email") {
            error = emailValidation(value);
            updateState(type, value, error);
        }
    };

    const updateState = (type, value, error) => {
        let temp = state[type];
        temp.error = error;
        temp.value = value;

        setState((prevState) => {
            return {...prevState, [type]: temp};
        });
    };

    const storedCountryCode = localStorage.getItem(
      "lastSelectedLanguageForSpain"
    );

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!state.password.error && !state.email.error) {
            try {
                const loginResponse = await logInUser({password: inputPasswordRef.current.value, email: inputEmailRef.current.value});

                setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME, loginResponse.data.token);
                setTokenContext(loginResponse.data);

                const getAccountResponse = await getAccount(loginResponse.data.token);
                setAuthContext({
                    ...authContext,
                    authToken: loginResponse?.data.token,
                    accountData: getAccountResponse?.data
                });
                if (storedCountryCode && ["en_ES", "es_ES"].includes(countryCode)) {
                    countryCode = storedCountryCode
                    setSessionStorageItem(COUNTRY_CODE_NAME, getFormattedCountryCode(countryCode));
                }
                props.history.push("/" + countryCode + "/home/my_account");
                window.location.reload();
            } catch (error) {
                if (error.response?.data.message === "[EMAIL_MISSING, PASSWORD_MISSING]" ||
                    error.response?.data.message === "Invalid Username and/or Password" ||
                    error.response?.data.message === "Unauthorized: not logged in"
                ) {
                    updateState("login", true, true);
                    setIsLoading(false);
                } else if (error.response?.data.message === "[EMAIL_MISSING]") {
                    updateState("missingEmail", true, true);
                    setIsLoading(false);
                } else if (error.response?.data.message === "[PASSWORD_MISSING]") {
                    updateState("password", true, true);
                    setIsLoading(false);
                } else {
                    props.history.push("/404");
                }
            }
        }

        setIsLoading(false);
    };

    const showErrorMessage = (type) => {
        return (
            <p className="error_message error_message--red">
                {messages("validationMethods")[type].message}
            </p>
        );
    };

    return (
        <>
            <div className="login-page">
                <Menu/>.
                <div className="page_header container">
                    <section className="breadcrumbs">
                        <Breadcrumbs/>
                    </section>

                    <h1 className="login-page_headline">{t("headline")}</h1>
                </div>
                <div className="login-page_wrapper container">
                    <div className="login-page_wrapper">
                        <div className="login-page_content">
                            <h3 className="login-page_subheadline">{t("subheadline")}</h3>
                            {t("inputs").map((item, i) => (
                                <div className="login-page_inputs" key={i}>
                                    <p className="login-page_inputs_label">{item.label}</p>
                                    <input
                                        ref={refs[i]}
                                        className={
                                            state[item.type].error
                                                ? "login-page_inputs_input error"
                                                : "login-page_inputs_input"
                                        }
                                        type={item.type}
                                        name={item.name}
                                        id={item.id}
                                        onBlur={(event) => _onBlur(event, item.type)}
                                    />
                                    {state[item.type].error && showErrorMessage(item.type)}
                                </div>
                            ))}
                            {state.login.error && showErrorMessage("loginError")}
                            {state.missingEmail.error && showErrorMessage("missingEmail")}
                            <LoadingSpinnerButton
                                onClick={handleSubmit}
                                text={t("btn")}
                                classNames="btn-primary order-1 order-md-2 loading-spinner-button btn"
                                isEnabled={true}
                                isLoading={isLoading}
                            />
                            <a className="btn btn_line" href={t("link_href")}>{t("link")}</a>
                            <div className="login-page_protection-plan">
                                <h3 className="login-page_protection-plan_subheadline">
                                    {t("newProtectionPlan").label}
                                </h3>
                                <Link
                                    className="btn btn_transparent"
                                    to={t("newProtectionPlan").link_href}
                                >
                                    {t("newProtectionPlan").link}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter/>
            <Footer/>
        </>
    );
};

export default withRouter(LoginPage);
