module.exports = {
    headline: "Servicepaket",
    icon: '/image/icons/cart/cart.png',
    iconCount: '/image/icons/cart/cart2.png',
    href: '/cart',
    titel: "kundvagn",
    overlayLabel:"Ditt val:",
    btn: "skyddsplan för inköp",
    btnPath: "/sv_SE/home/service_package",
    edit: "redigera",
    editPath: "/sv_SE/home/service_package"
};
