module.exports = {
    details: {
        title: "Titel",
        firstName: "Fornavn",
        lastName: "Efternavn",
        name: "Navn",
        email: "Email",
        address : "Adresse",
        zipCode : "Postnummer",
        city: "By",
        phoneNumber: "Telefonnummer",
        phone : "Telefonnummer",
    },
    btn: {
        edit: "Rediger personlige informationer",
        back: "Tilbage til min konto",
        update: "Opdater konto",
        reset: "Nulstil kode"
    },
    editAccount: {
        headline: "Rediger personlige informationer"
    },
    loading: "Indlæsning ..."
}
