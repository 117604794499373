module.exports = {
    headline: "Aquarea Service+ kontrakt",
    baseUnit: "Installerad anordning",
    contract: "Kontrakt",
    month:"månad",
    monthly:"månad",
    quarterly:"kvartal",
    annually:"år",
    disclaimerHeadline :"Aquarea+ Premium Service: teknisk hälsokontroll och driftskontroll i väntan på att den ska genomföras.",
    disclaimer:"Når du køber servicepakke \"Aquarea + Premium\", får du \"Aquarea + Smart\", indtil Panasonics tekniker kontrollerer installationen og godkender, at vigtige tekniske krav er opfyldt. Hvis du ikke opfylder disse tekniske krav, vil du forblive og nyde fordelene ved \"Aquarea + Smart\"-pakken. Indtil din installation er godkendt til \"Aquarea + Premium\", bliver du opkrævet for \"Aquarea + Smart\"-pris.\n",
    contract_complete: "Detta kontrakt har redan uppfyllts.",
    contract_cancelled: "Detta kontrakt har upphävts.",
    waitingForDateUpdateStatus:"Väntar på svar från betaltjänstsystemet",
    updateExpiryDate:'Utgångsdatum behöver förnyas',
    updateFailed:'Uppdatering av utgångsdatum misslyckades - försök igen senare',
    updateSuccess:'Exp.Date updated',
    details: {
        model: "modell",
        orderNumber: "Beställningsnummer",
        installationDate: "Installationsdatum",
        protectionPlan: "servicepaket",
        location: "plats",
        serialNumber: "serienummer",
        contractValidTill: "Avtalet är giltigt till och med",
        costs: "kostnader",
        creditCardNumber:"kreditkortnummer",
        paymentMethod:"Betalningsmetod",
        expiryDate:'Utgångsdatum',
        expiryValue:'GILTIG TILL OCH MED'
    },
    btn:{
        upgrade: "uppgradering av servicepaketet",
        cancel: "avbryta servicepaketet",
        payment_update: "ändra betalningsmetod",
        registerHeatpump: "registrera värmepump",
        registerYourDevice: "registrera din enhet",
        expiry_update:'UPPDATERA UTGÅNGSDATUM',
        updateAllContracts:'JA UPPDATERA ALLA',
        cancelUpdate:'NEJ',
    },
    modal:{
        updateContractsTitle:"Uppdatera alla kontrakt",
        updateContractsBody:"Det finns flera kontrakt med samma utgångsdatum - vill du uppdatera alla?",
    },
    accountPage:'/sv_SE/home/my_account'
}
