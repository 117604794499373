import React, { useContext, useEffect, useState } from 'react'
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Newsletter from "../components/newsletter";
import { Link } from "react-router-dom";
import { AuthContext } from "../lib/contexts/authContext";
import { scrollToPos } from '../lib/scrollToPos';
import { ANONYMOUS_ID_SESSION_STORAGE_NAME, AUTH_TOKEN_SESSION_STORAGE_NAME, CART_ID_SESSION_STORAGE_NAME, COUNTRY_CODE_NAME, getSessionStorageItem, removeSessionStorageByName } from '../lib/sessionStorage';
import { getAccount } from '../lib/dataAccess/customer/accountRestEndpoint';
import Loader from "react-loader-spinner";
import { useInterval } from "../lib/hooks/useInterval";
import { getCartFromServer } from '../lib/dataAccess/checkout/cartRestEndpoint';
import {ErrorResponseHandling} from "../lib/dataAccess/errorResponseHandling";
import { sendGTMDataLayer } from '../lib/tracking/initGTM';

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("success");

const Success = () => {
    const POLLING_TIMEOUT = 1000;
    const { authContext, setAuthContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [pollingTimeout, setPollingTimeout] = useState(POLLING_TIMEOUT);
    const history = useHistory();

    useEffect(() => {
        sendGTMDataLayer({
          event: "pageview",
          category: "Checkout-6Success",
          eventModel: {
            event_category: "Checkout-6Success",
          },
        });
        removeSessionStorageByName(CART_ID_SESSION_STORAGE_NAME);
        removeSessionStorageByName(ANONYMOUS_ID_SESSION_STORAGE_NAME);
        scrollToPos(0, 0);
        const authToken = authContext?.authToken || getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);

        if (authToken) {
            getAccount(authToken)
                .then(res => setAuthContext({
                    ...authContext,
                    accountData: res.data
                })).catch(error => {
                    ErrorResponseHandling(error, authContext, setAuthContext, history);
                })
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    function getName() {
        if (!authContext.accountData) {
            return "!";
        }
        return `${authContext.accountData.firstName} ${authContext.accountData.lastName}!`;
    }

    useInterval(async() =>{
        getCartFromServer(null, getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
            if(result.data.lineItems.length <= 0){
                setIsLoading(false);
            }
            setPollingTimeout(pollingTimeout + POLLING_TIMEOUT);
        })
        .catch(e => {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        });
    }, isLoading ? pollingTimeout : null);

    return (
        <>
            {isLoading && <span className="success_loading_spinner">
            <Loader
            type="TailSpin"
            color="#7099AD"
            height={200}
            width={200}
            className="success_loading_spinner_loader"
            />
            </span>}
            <div>
                <Menu />
                <div className="homepage_body">
                    <div className="success">
                        <div className="container">
                            <p className="success_title">{t("title")}</p>
                        </div>
                        <div className="success_wrapper container">
                            <div className="success_icon">

                            </div>
                            <div className="success_container">
                                <p className="success_headline">
                                    {t("headline")}
                                </p>
                                <p className="success_subheadline">
                                    {t("subheadline")}{getName()}
                                </p>
                            </div>
                            <Link
                                className="success_btn btn"
                                to={"/"+ countryCode +"/home/my_account"}>
                                {t("btn")}
                            </Link>
                        </div>
                    </div>
                </div>
                <Newsletter />
                <Footer />
            </div>
 
        </>
    )
}

export default Success;
