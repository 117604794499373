export function formatMoneyByCountryCode(money, countryCode) {
    const locale = countryCode.replace("_", "-")

    const currencies = new Map();
    currencies.set("sv-SE", ["SEK", "code"])
    currencies.set("da-DK", ["DKK", "code"])
    currencies.set("it-IT", ["EUR", "symbol"])
    currencies.set("es-ES", ["EUR", "symbol"])
    currencies.set("en-IE", ["EUR", "symbol"])
    currencies.set("en-ES", ["EUR", "symbol"])

    if (!!countryCode) {
        let formatter = Intl.NumberFormat(locale,
            {
                style: 'currency',
                currency: currencies.get(locale)[0],
                currencyDisplay: currencies.get(locale)[1]
            })
        return formatter.format(money)
    }

    return money
}