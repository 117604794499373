import React, {useContext, useEffect,  useState} from 'react';
import { Link, useParams,useHistory } from "react-router-dom";
import i18 from "../../lib/i18n";
import { DetailEntry, DetailList, DetailListRow, OptionalValue } from "../layout/detailEntry";
import { BtnGroup, LoadingSpinnerButton } from "../layout/buttons";
import { SectionHeadline, SectionSubheadline } from "../layout/section";
import {FormText} from "../layout/form";
import {
    getSessionStorageItem,
    COUNTRY_CODE_NAME,
} from "../../lib/sessionStorage";
import {checkMultipleContracts, deletePaymetToken, getIsCampaignOrdered, setUpdateInfoPending } from "../../lib/dataAccess/checkout/orderRestEndpoint";
import {CartContext} from "../../lib/contexts/cartContext";
import {AuthContext} from "../../lib/contexts/authContext"; 
import { formatMoneyByCountryCode } from "../../lib/helper/moneyHelper";
import Modal from '../modal';
import { getCountryForCountryCode, getLanguageForCountryCode } from '../../lib/helper/localizationHelper';

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'en_IE';

const t = i18.namespace("MyAccountContract");
const redirectUrl = "/"+ countryCode +"/home/my_account/cancel_protection_plans";

const Contract = ({baseUnit}) => {
    const {orderData:{infoStatus, orderId}} = baseUnit
    const [askConfirmation, setAskConfirmation] = useState(false)
    const [allowExpiryUpdate, setAllowExpiryUpdate] = useState(infoStatus?.renew ?? false)
    const [tokenDeletionPending, setTokenDeletionPending] = useState(false)
    const [multiContractCheckPending, setMultiContractCheckPending] = useState(false)
    const [tokenDeletionMessage, setTokenDeletionMessage] = useState('')
    const { authContext } = useContext(AuthContext);
    const { target } = useParams();
    const history = useHistory();

  useEffect(() => {
    const { pending, renew, failed } = infoStatus;
    if (!pending && !renew && !failed) {
      history.replace("/" + countryCode + "/home/my_account");
      return;
    }
    if (orderId === target) {
      setUpdateInfoPending(
        {
          orderId: baseUnit.orderData.orderId,
          pendingFlag: true,
        },
        authContext?.authToken
      ).then(history.replace("/" + countryCode + "/home/my_account"));
      setTokenDeletionMessage(t("waitingForDateUpdateStatus"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, orderId]);

    const isContract = () => {
        return baseUnit.orderData !== undefined;
    }

    const isContractConfirmed = () => {
        return baseUnit.orderData !== undefined && baseUnit.orderData.status === "CONFIRMED";
    }

    const isContractCompleted = () => {
        return baseUnit.orderData !== undefined && baseUnit.orderData.status === "COMPLETE";
    }

    const isContractCancelled = () => {
        return baseUnit.orderData !== undefined && baseUnit.orderData.status === "CANCELLED";
    }

    const getContractLineItem = () => {
        return baseUnit.orderData?.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"));
    }

    // TODO replace baseUnit.orderData.lineItems[0] with getContractLineItem ???
    // Assumption: baseUnit.orderData.lineItems[0] and alike refer to Contract lineItem?

    // This method is sometimes undefined ...
    /*
    const getContractLineItem = () => {
        return baseUnit.orderData.lineItems.filter(
            lineItem => lineItem.sku.startsWith("contract-")
        );
    }
    */

    const getServicePackageName = () => {
        return getContractLineItem() ? getContractLineItem().name : "";
    }
    const getPaymentPeriod = () => {
        return getContractLineItem()?.sku.split('-')[3] || "monthly";
    }
    const getServicePackageType = () => {
        return getContractLineItem()?.sku.split('-')[2];
    }

    const isIncomplete = () => {
        return baseUnit?.serialNumber.includes("DUMMY") || baseUnit?.deviceType === ""
    }

    // helper function for Cancel url
    const cancelUrlHelper = () => {
        let url = new URL(`${process.env.REACT_APP_API_ENDPOINT_BASE_URL}${redirectUrl}`);
        url.searchParams.set('sku',`${getContractLineItem()?.sku}`)
        url.searchParams.set('orderId',`${baseUnit.orderData?.orderId}`)
        return url;
    }

    const [isCampaignOrdered, setIsCampaignOrdered] = useState(false);
    useEffect(() => {
        if(baseUnit.orderData && CartContext?.authToken) {
            getIsCampaignOrdered(baseUnit.orderData?.orderId, CartContext.authToken).then(response => {
                setIsCampaignOrdered(response.data.isCampaign)
            })
        }
    });

    function drawCampaign() {
        if (isCampaignOrdered) {
            return <div className={"your-protection-plan_img"}>
                <img src={t("campaign.img")} alt={t("campaign.alt")} />
            </div>;
        }
    }

    function printRegisterLink() {
        return <Link
            className="btn-alt-link"
            to={{
                pathname: '/'+ countryCode +'/home/register_unit',
                baseUnit: baseUnit
            }}
        >
            {t("btn.registerYourDevice")}
        </Link>
    }

    function isDummyBaseUnit() {
        return baseUnit?.serialNumber.substring(0,5) === "DUMMY"
    }

    function formatDate(date) {
        const year = date.substring(0, 4)
        const month = date.substring(5, 7)
        const day = date.substring(8)
        const separator = "."

        return day + separator + month + separator + year
    }

    const handleChangeExpiry = async () => {
        setMultiContractCheckPending(true)
        try {
            const {data:isMultiContracted} = await checkMultipleContracts(baseUnit.orderData.orderId,CartContext.authToken)
            setAskConfirmation(isMultiContracted)
            if(!isMultiContracted){
                handleDeleteToken()
            }
        } catch{
            console.log('error');
            setTokenDeletionMessage(t("updateFailed"))
        } finally{
            setMultiContractCheckPending(false)
        }

    }
    const handleDeleteToken = async () => {
        setTokenDeletionPending(true)
        setAllowExpiryUpdate(false)
        try {
            let {data: {redirectUrl}} = await deletePaymetToken(baseUnit.orderData.orderId, authContext?.authToken)
            const windowOrigin = encodeURIComponent(window.location.origin);
            const paymentRedirectUrl = redirectUrl + '&'
                + 'country='+ getCountryForCountryCode(countryCode) + '&'
                + 'language=' + getLanguageForCountryCode(countryCode) + '&'
                + 'successURL=' + windowOrigin + '/' + countryCode + '/home/my_account/updateOrder/' + baseUnit.orderData.orderId + '&'
                + 'cancelURL=' + windowOrigin + '/update_cancel&'
                + 'failureURL=' + windowOrigin + '/' + countryCode + '/update_failure/' + baseUnit.orderData.orderId + '&'
                + 'errorURL=' + windowOrigin + '/update_error&'
                + 'expiryURL=' + windowOrigin + '/update_expired&'
                + 'pendingURL=' + windowOrigin + '/update_pending';
                if(paymentRedirectUrl){
                    window.location.replace(paymentRedirectUrl);
                }
        } catch (e) {
            if(e.response.status === 500){
                setTokenDeletionMessage(t("updateFailed"))
                setTokenDeletionPending(false)
                setAllowExpiryUpdate(true)
            }
        }
    }

    const renderExpiryDateMessage = () => {
        const{ renew, pending, failed} = infoStatus
        if(tokenDeletionMessage){
            return(
                <div className='detail_entry_helper level_1'>
                    {tokenDeletionMessage}
                </div>
            )
        }
        let message = ''
        if(renew){
            message = t("updateExpiryDate")
        }
        if(failed){
            message = t("updateFailed")
        }
        if(pending){
            message = t("waitingForDateUpdateStatus")
        }
        return(
            <div className="detail_entry_helper level_1">
                {message}
            </div>
        )
    }

    const modalContent ={
        title:t("modal.updateContractsTitle"),
        body:t("modal.updateContractsBody"),
    }
    const handleCancelUpdate = () => {
        setAskConfirmation(false)
        setAllowExpiryUpdate(true)
        setMultiContractCheckPending(false)
        setTokenDeletionPending(false)
    }
    const renderModal= () => {
        return(
            <Modal
            id={baseUnit.orderData.orderId}
            content={modalContent}
            isOpen={askConfirmation}
            handleClose={handleCancelUpdate}
            primaryAction={{
                component:<LoadingSpinnerButton
                    isEnabled
                    isLoading={tokenDeletionPending}
                    classNames="btn-primary order-1 order-md-2 loading-spinner-button"
                    onClick={handleDeleteToken}
                    text={t("btn.updateAllContracts")}
                    />,
            }}
            secondaryAction={{
                component:<LoadingSpinnerButton
                    isEnabled={!tokenDeletionPending}
                    classNames="btn-secondary order-1 order-md-2"
                    onClick={handleCancelUpdate}
                    text={t("btn.cancelUpdate")}
                    />,
            }}
            />
        )
    }

    return (
        <>
            {renderModal()}
            {drawCampaign()}
            <OptionalValue condition={getServicePackageType() !== 'gold' && getContractLineItem()?.approvalStatus === 'PENDING'} emptyValue="">
                <h3>{t("disclaimerHeadline")}</h3>
                <div className="infoText_narrow">
                    {t("disclaimer")}
                </div>
            </OptionalValue>
            <SectionSubheadline><OptionalValue condition={isContract()} emptyValue={t("baseUnit")}>{t("contract")}</OptionalValue></SectionSubheadline>
            <SectionHeadline><OptionalValue condition={isContract()} emptyValue="">{t("headline")}</OptionalValue></SectionHeadline>
            <div className={"contract-data-overview"}>
                <OptionalValue condition={isIncomplete()} emptyValue="">
                    <BtnGroup className="detail_entry">
                        <Link
                            className="btn-quarternary"
                            to={{
                                pathname: '/'+ countryCode +'/home/register_unit',
                                baseUnit: baseUnit
                            }}
                        >
                            {t("btn.registerHeatpump")}
                        </Link>
                    </BtnGroup>
                </OptionalValue>

                <DetailList>
                    <DetailListRow>
                        {!!baseUnit.deviceType ?
                            <DetailEntry label={t("details.model")}>
                                {baseUnit.deviceType}
                            </DetailEntry>
                            : isDummyBaseUnit() ?
                                <DetailEntry label={t("details.model")}>
                                    {printRegisterLink()}
                                </DetailEntry>
                            : ""
                        }
                        <DetailEntry label={t("details.orderNumber")}>
                            <OptionalValue condition={baseUnit.orderData?.orderNumber}>
                                {baseUnit.orderData?.orderNumber}
                            </OptionalValue>
                        </DetailEntry>
                    </DetailListRow>
                    <DetailListRow>
                        {!!baseUnit.installationDate ?
                            <DetailEntry label={t("details.installationDate")}>
                                {formatDate(baseUnit.installationDate)}
                            </DetailEntry>
                            : isDummyBaseUnit() ?
                                <DetailEntry label={t("details.installationDate")}>
                                    {printRegisterLink(baseUnit)}
                                </DetailEntry>
                                : ""}

                        <DetailEntry label={t("details.protectionPlan")}>
                            <OptionalValue condition={isContract()} >
                                {getServicePackageName()}
                            </OptionalValue>
                        </DetailEntry>
                    </DetailListRow>
                    <DetailListRow>
                        <DetailEntry label={t("details.serialNumber")}>
                            <OptionalValue condition={baseUnit.serialNumber && !isDummyBaseUnit()}
                                           emptyValue={printRegisterLink()}>
                                {baseUnit.serialNumber}
                            </OptionalValue>
                        </DetailEntry>
                        <DetailEntry label={t("details.location")}>
                            <OptionalValue
                                condition={baseUnit?.address.street}
                                emptyValue=''>
                                {baseUnit.address.street}
                                <br />
                            </OptionalValue>
                            <OptionalValue condition={baseUnit.address.zipCode} >
                                {`${baseUnit.address.zipCode} ${baseUnit.address.city}`}
                            </OptionalValue>
                        </DetailEntry>
                    </DetailListRow>
                    <OptionalValue condition={isContract()} emptyValue="">
                        <DetailListRow>
                            <DetailEntry label={t("details.costs")}>
                                <OptionalValue condition={baseUnit.orderData?.lineItems.length} >
                                    {formatMoneyByCountryCode(getContractLineItem()?.totalGross.amount, countryCode)} / {t(getPaymentPeriod())}
                                </OptionalValue>
                            </DetailEntry>
                            <DetailEntry label={t("details.contractValidTill")}>
                                {baseUnit && baseUnit.orderData && baseUnit.orderData.lineItems && baseUnit.orderData.lineItems.length > 0 && getContractLineItem()?.contractEnd != null ? `${getContractLineItem()?.contractEnd.split(" ")[0]}` : <>&mdash;</>}
                            </DetailEntry>
                        </DetailListRow>
                    </OptionalValue>
                    <OptionalValue condition={isContract()} emptyValue="">
                        <DetailListRow>
                            <DetailEntry label={t("details.paymentMethod")}>
                                {baseUnit.orderData?.paymentMethod.toUpperCase()}
                            </DetailEntry>
                            <OptionalValue condition={baseUnit.orderData?.creditCardNumber} emptyValue="">
                                <DetailEntry label={t("details.creditCardNumber")}>
                                    {baseUnit.orderData?.creditCardNumber}
                                </DetailEntry>
                            </OptionalValue>
                            <OptionalValue condition={!baseUnit.orderData?.creditCardNumber} emptyValue="">
                                <DetailEntry className="detail_entry--empty"/>
                            </OptionalValue>
                        </DetailListRow>
                        <OptionalValue condition={baseUnit.orderData?.creditCardExpiryDate} emptyValue="">
                            <DetailListRow>
                                <DetailEntry label={t("details.expiryDate")}>
                                    <div>
                                        {t("details.expiryValue")}{' '}
                                        {baseUnit.orderData?.creditCardExpiryDate}
                                    </div>
                                    {renderExpiryDateMessage()}
                                </DetailEntry>
                            </DetailListRow>
                        </OptionalValue>
                    </OptionalValue>
                </DetailList>
            </div>
            <OptionalValue condition={isContract()}  emptyValue="">
                <OptionalValue condition={isContractConfirmed()} emptyValue="">
                    <BtnGroup>
                        <OptionalValue condition={getServicePackageType() !== 'gold' && getContractLineItem()?.approvalStatus !== 'PENDING'} emptyValue="">
                            <Link
                                className="btn-primary"
                                to={'/'+ countryCode +'/home/upgrade/' + baseUnit.orderData?.orderId}
                            >
                                {t("btn.upgrade")}
                            </Link>
                        </OptionalValue>
                        <Link
                            className="btn-secondary"
                            to={`${cancelUrlHelper().pathname}${cancelUrlHelper().search}`}
                        >
                            {t("btn.cancel")}
                        </Link>
                    </BtnGroup>
                    <br/>
                </OptionalValue>
                <OptionalValue condition={isContractConfirmed() || isContractCancelled()} emptyValue="">
                    <BtnGroup className="detail_entry">
                        <Link
                            className="btn-primary"
                            to={'/'+ countryCode +'/home/updatepayment/' + baseUnit.orderData?.orderId}
                        >
                            {t("btn.payment_update")}
                        </Link>
                        <OptionalValue condition={infoStatus?.renew} emptyValue="">
                            <LoadingSpinnerButton
                                isEnabled={allowExpiryUpdate}
                                isLoading={multiContractCheckPending || tokenDeletionPending}
                                classNames="btn-primary order-1 order-md-2 loading-spinner-button"
                                onClick={!tokenDeletionPending ? handleChangeExpiry : ()=>{}}
                                text={t("btn.expiry_update")}
                                
                                />
                        </OptionalValue>
                    </BtnGroup>
                </OptionalValue>
                <OptionalValue condition={isContractCompleted()} emptyValue="">
                    <FormText>{t("contract_complete")}</FormText>
                </OptionalValue>
                <OptionalValue condition={isContractCancelled()} emptyValue="">
                    <FormText>{t("contract_cancelled")}</FormText>
                </OptionalValue>
            </OptionalValue>
        </>
    );
}

export default Contract;
