module.exports = {
    title: "Terminazione",
    headline: "Ci dispiace che tu voglia cancellare il tuo abbonamento.",
    btnReturnToProfile: "Torna al mio account",
    cancelSectionTitle: "Annullare l'iscrizione",
    cancelText:"È sicuro di voler cancellare il suo abbonamento e quindi il suo pacchetto Aquarea Service+?",
    btnCancelSubscription: "Sì, cancella il mio abbonamento",
    downgradeSectionTitle: "Esegui il downgrade del tuo abbonamento",
    downgradeText: "Sono disponibili le seguenti opzioni di downgrade:",
    btnDowngradeOrder: "Esegui il downgrade del mio abbonamento",
    downgradeOptions: {
        silver: "Esegui il downgrade al pacchetto Aquarea Service+ SMART",
        bronze: "Esegui il downgrade al pacchetto Aquarea Service+ COMFORT"
    }
}
