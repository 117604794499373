import React, { useContext, useEffect } from 'react'
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Newsletter from "../components/newsletter";
import { Link } from "react-router-dom";
import { AuthContext } from "../lib/contexts/authContext";
import { scrollToPos } from '../lib/scrollToPos';
import { ANONYMOUS_ID_SESSION_STORAGE_NAME, AUTH_TOKEN_SESSION_STORAGE_NAME, CART_ID_SESSION_STORAGE_NAME, COUNTRY_CODE_NAME, getSessionStorageItem, removeSessionStorageByName } from '../lib/sessionStorage';
import { getAccount } from '../lib/dataAccess/customer/accountRestEndpoint';
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("paymentChangeSuccess");

const PaymentChangeSuccess = () => {
    const { authContext, setAuthContext } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        removeSessionStorageByName(CART_ID_SESSION_STORAGE_NAME);
        removeSessionStorageByName(ANONYMOUS_ID_SESSION_STORAGE_NAME);
        scrollToPos(0, 0);

        const authToken = authContext?.authToken || getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);

        if (authToken) {
            getAccount(authToken)
                .then(res => setAuthContext({
                    ...authContext,
                    accountData: res.data
                })).catch(error => {
                    ErrorResponseHandling(error, authContext, setAuthContext, history);
                })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Menu />
            <div className="homepage_body">
                <div className="success">
                    <div className="container">
                        <p className="success_title">{t("title")}</p>
                    </div>
                    <div className="success_wrapper container">
                        <div className="success_icon">

                        </div>
                        <div className="success_container">
                            <p className="success_headline">
                                {t("headline")}
                            </p>
                        </div>
                        <Link
                            className="success_btn btn"
                            to={"/"+ countryCode +"/home/my_account"}>
                            {t("btn")}
                        </Link>
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </div>
    )
}

export default PaymentChangeSuccess;
