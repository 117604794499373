module.exports = {
    headline: "Gamma di pacchetti di servizi",
    desc_label: "Descrizione",
    terms_label: "Termini e condizioni",
    annually: "all'anno (IVA inclusa)",
    quarterly: 'per trimestre (IVA inclusa)',
    monthly: 'al mese (IVA inclusa)',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'al mese',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "Con un abbonamento Aquarea Service+ Premium, hai un pensiero in meno. La tua pompa di calore viene monitorata a distanza 24 ore su 24. E se dovesse verificarsi un'anomalia, sarai avvisati immediatamente e ti aiuteremo a risolvere nel più breve tempo possibile il problema. Se ciò non fosse possibile, un tecnico interverrà entro le 24 ore, sia nei giorni feriali che nella mattinata del Sabato.",
            disclaimer: "Per attivare questo pacchetto, è necessario una linea internet attiva e il collegamento dell’impianto al servizio gratuito \"Aquarea Service Cloud\". \n" +
                "In fase di sottoscrizione del contratto, viene acquistata inizialmente la versione \"Aquarea+ Smart\" al corrispondente valore di vendita.\n" +
                "Dopo aver verificato e controllato che i requisiti tecnici richiesti per la tipologia di abbonamento sono soddisfatti, Panasonic sposterà l'abbonamento in \"Premium\" addebitando la restante quota a copertura del valore di vendita del pacchetto di servizi  \"Aquarea+ Premium\".\n",
            info: "Devi solo goderti il comfort della tua casa.",
            good_to_know_title: "Notizie utili",
            good_to_know_list: [
                {
                    label: "Contratto su base annuale"
                },
            ],
            link: "Scarica tutti i termini",
            linkHref: "/it_IT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Vai al confronto"
            },
            btn_book: {
                href: "",
                label: "Ordine Premium"
            },
            iconList: [
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Monitoraggio 24/7 via Cloud",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Manutenzione annuale",
                },
                {
                    icon: "/image/icons/services/remotely_diagnose.svg",
                    alt: "",
                    label: "Diagnosi predittiva dei mal-\nfunzionamenti",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Supporto tecnico a distanza",
                },
                {
                    icon: "/image/icons/services/maintenance_report.svg",
                    alt: "",
                    label: "Rapporto annuale sul funzionamento",
                },
                {
                    icon: "/image/icons/services/priority.svg",
                    alt: "",
                    label: "Consulenza personalizzata",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'al mese',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "Con un abbonamento Aquarea Service+ Smart, hai un pensiero in meno. La tua pompa di calore viene monitorata a distanza 24 ore su 24.  E se dovesse verificarsi una anomalia, sarai avvisati immediatamente e ti aiuteremo a risolvere il problema da remoto nel più breve tempo possibile. Se ciò non fosse possibile, un tecnico interverrà entro le 48 ore, sia nei giorni feriali che nella mattina del Sabato.",
            disclaimer: "Per attivare questo pacchetto, è necessario una linea internet attiva e il collegamento dell’impianto al servizio gratuito \"Aquarea Service Cloud\".\n",
            info: "Devi solo goderti il comfort della tua casa.",
            good_to_know_title: "Notizie utili",
            good_to_know_list: [
                {
                    label: "Contratto su base annuale"
                },
            ],
            link: "Scarica tutti i termini",
            linkHref: "/it_IT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Vai al confronto"
            },
            btn_book: {
                href: "",
                label: "Ordine Smart"
            },
            iconList: [
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "Monitoraggio 24/7 via Cloud",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Manutenzione annuale",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "Hotline per segnalazione guasti 24/7 (call back)",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Supporto tecnico a distanza",
                },

            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_comfort.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_comfort.svg",
            subheadline: 'al mese',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "Con un abbonamento Aquarea Service+ Comfort, hai un pacchetto di servizi che include una hotline per segnalazione guasti 24/7, interventi prioritari entro le 48 ore (sia nei giorni feriali che nella mattina del Sabato) e una visita annuale di manutenzione da parte di uno dei nostri tecnici.",
            info: "Devi solo goderti il comfort della tua casa.",
            good_to_know_title: "Notizie utili",
            good_to_know_list: [
                {
                    label: "Contratto su base annuale"
                },
            ],
            link: "Scarica tutti i termini",
            linkHref: "/it_IT/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Vai al confronto"
            },
            btn_book: {
                href: "",
                label: "Ordine Comfort"
            },
            iconList: [
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Interventi entro 48 ore (nei giorni feriali e nella mattina del Sabato)",
                },
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Manutenzione annuale",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "Hotline per segnalazione guasti 24/7 (call back)",
                }
            ]
        },

    ],
};
