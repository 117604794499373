module.exports = {
    payments: {
        label: "Metodi di pagamento:",
        visa: "/image/icons/checkout/visa.svg",
        visa_alt: "visa",
        mastercard: "/image/icons/checkout/mastercard.svg",
        mastercard_alt: "masterCard",
        paypal: "/image/icons/checkout/paypal.svg",
        paypal_alt: "paypal",
        visa_secure: "/image/icons/checkout/visaSecure.png",
        visa_secure_alt: "visa secure",
        mastercard_id_check: "/image/icons/checkout/mastercardIdCheckWhite.png",
        mastercard_id_check_alt: "mastercard id check"
    }
};