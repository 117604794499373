import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import FormItem from "../../registration/formItem";
import StepButtons from "../stepButtons";
import SignIn from "./signIn";
import {FormFieldset, FormHeadline, FormRow} from "../../layout/form";
import {getCountryForCountryCode} from "../../../lib/helper/localizationHelper";

import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem
} from "../../../lib/sessionStorage";

import {logInUser, registerUser} from "../../../lib/dataAccess/customer/customerRestEndpoint";
import {updateInstalledBaseUnits} from "../../../lib/dataAccess/checkout/cartRestEndpoint";
import {getAccount} from "../../../lib/dataAccess/customer/accountRestEndpoint";
import {editAccount} from '../../../lib/dataAccess/accountService';
import {ErrorResponseHandling} from "../../../lib/dataAccess/errorResponseHandling";
import {scrollToFirstInvalidControl} from "../../registration/validators";
import {scrollToPos} from "../../../lib/scrollToPos";

import {AuthContext} from "../../../lib/contexts/authContext";
import {checkoutSteps} from "./types/checkoutStepsMapping";

import i18 from "../../../lib/i18n";
import ToggleSwitch from "../../toggleSwitch";
import {CartContext} from "../../../lib/contexts/cartContext";

const messages = i18.namespace("Validation");

function AccountInformationStep(props) {
    const {authContext, setAuthContext} = useContext(AuthContext);
    const {setCartContext} = useContext(CartContext);
    const [compareEmailValues, setCompareEmailValues] = useState({
        email: null,
        repeatMail: null
    });
    const [comparePasswordValues, setComparePasswordValues] = useState({
        password: null,
        repeatPassword: null
    });
    const [showSomeMessages, setShowSomeMessages] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [backendValidation, setBackendValidation] = useState({hasError: false, errors: {}});
    const [signIn, setSignIn] = useState(false);
    const [entry, setEntry] = useState(null)
    const [addresses, setAddresses] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [btnEnabled, setBtnEnabled] = useState(true)
    const history = useHistory();

    useEffect(() => {
        // console.log("effect ---> authContext?.authToken", authContext, authContext?.authToken)
        // console.log(entry, addresses);
        if (authContext?.authToken && authContext?.accountData) {
            //console.log("effect2 ---> authContext?.authToken")
            setEntry(Object.entries(authContext.accountData));
            setAddresses(Object.entries(authContext.accountData.addresses[0]));
            setIsLoggedIn(true);
        }
    }, [authContext]);

    useEffect(() => {
        if (backendValidation.hasError) {
            scrollToFirstInvalidControl();
        }
    }, [backendValidation]);

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    function toggleSwitch(bool) {
        setSignIn(bool);
    }

    /*
    * set values by key for email
    *
    * @param value: any
    * @param key: first or last
    * */
    function handleFormEmailValueCompare(value, key) {
        setCompareEmailValues({
            ...compareEmailValues,
            [key]: value
        });
    }

    /*
    * set values by key for password
    *
    * @param value: any
    * @param key: first or last
    * */
    function handleFormPasswordValueCompare(value, key) {
        setComparePasswordValues({
            ...comparePasswordValues,
            [key]: value
        });
    }

    const getFirstAccountAddress = () => {
        return authContext.accountData.addresses[0];
    }

    async function handleNextStepButtonClick(event) {
        event.preventDefault();

        // console.log(isLoggedIn)

        if (isLoggedIn) {
            confirmAccountInformation();
        }else{
            setIsLoading(true);
            const requestData = generateRegistrationRequestBody();

            if (areAccountInformationInputsValid()) {
                try {
                    const verificationResponse = await registerUser(requestData).catch(e => {
                        if (e.response?.data.message === "[USER_ALREADY_REGISTERED]" || e.response?.data.message === "[REGISTRATION_FAILED]") {
                            setBackendValidation({
                                ...backendValidation,
                                hasError: true,
                                errors: {...backendValidation.errors, "email": messages(e.response?.data.message.replace('[','').replace(']',''))}
                            })
                            setIsLoading(false);
                            return;
                        }

                        throw e;
                    });

                    if (!verificationResponse) {
                        return;
                    }

                    const loginRequestData = {
                        email: requestData.email,
                        password: requestData.password,
                        anonymousId: '',
                        checkoutLogin: true
                    };
                    const loginResponse = await logInUser(loginRequestData);
                    setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME, loginResponse.data.token);

                    const getAccountResponse = await getAccount(loginResponse.data.token)
                        .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    });

                    // console.debug(getAccountResponse);

                    setAuthContext({
                        ...authContext,
                        accountData: getAccountResponse.data,
                        authToken: loginResponse.data.token
                    });

                    setEntry(Object.entries(getAccountResponse.data));
                    setAddresses(Object.entries(getAccountResponse.data.addresses[0]));

                    // console.log(entry, addresses);
                    setIsLoggedIn(true);

                    props.navigation.goNext();
                } catch (error) {
                    console.error('error at user registration', error);
                    props.navigation.setStep("error");
                }
            } else {
                setIsLoading(false);
                setShowSomeMessages(true);
                scrollToPos(100, 100);
            }
        }
    }

    const confirmAccountInformation = async () => {
        setBtnEnabled(false)
        updateAccount().then((res) => {
            if(res.status === 200){
                setAuthContext({...authContext, accountData: res.data});
                updateInstalledBaseUnits({...getInstalledBaseUnits()},authContext.authToken)
                .then((updateInstalledBaseUnitsResponse)=> {
                    if(updateInstalledBaseUnitsResponse.status !== 200) return
                    setCartContext(updateInstalledBaseUnitsResponse.data);
                    setSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME, updateInstalledBaseUnitsResponse.data.anonymousId);
                    setSessionStorageItem(CART_ID_SESSION_STORAGE_NAME, updateInstalledBaseUnitsResponse.data.id);
                    props.navigation.goNext();
                }).catch(e => {
                    ErrorResponseHandling(e, authContext, setAuthContext, history);
                })
                .finally(() => {
                    setBtnEnabled(true)
                })
            }
        })
        .catch((e) => {
            setBtnEnabled(true)
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        })
    }

    const updateAccount = () => {
        const accountInformationStepValues = [...props.session.getSomeValues()[1]];
        const updateData = {
            firstName: accountInformationStepValues.find(valueObject => valueObject.key === 'firstName')?.value,
            lastName: accountInformationStepValues.find(valueObject => valueObject.key === 'lastName')?.value,
            sendInvoice: accountInformationStepValues.find(valueObject => valueObject.key === 'sendInvoice')?.value,
            address: {
                id: getFirstAccountAddress().id,
                countryCode: getFirstAccountAddress().countryCode,
                city: accountInformationStepValues.find(valueObject => valueObject.key === 'city')?.value,
                street: accountInformationStepValues.find(valueObject => valueObject.key === 'street')?.value,
                zipCode: accountInformationStepValues.find(valueObject => valueObject.key === 'zipCode')?.value,
                phoneNumber: accountInformationStepValues.find(valueObject => valueObject.key === 'phoneNumber')?.value,
            }
        };
        return editAccount(authContext.authToken, updateData)
    }

    function saveValues(event, isValid) {
        const key = event.target.name;

        if (backendValidation.hasError && backendValidation.errors[key]) {
            const errors = {...backendValidation.errors};
            delete errors[key];

            setBackendValidation({...backendValidation, hasError: !!Object.keys(errors).length, errors});
        }

        props.session.setValueAndValidity(event, isValid, props.currentStep);
    }

    function updateValues(key, value, label) {
        // props.session.saveValuesByStep???
    }

    function generateRegistrationRequestBody() {
        const accountInformationStepValues = [...props.session.getSomeValues()[1]];
        return {
            email: accountInformationStepValues.find(valueObject => valueObject.key === 'email')?.value,
            password: accountInformationStepValues.find(valueObject => valueObject.key === 'password')?.value,
            firstName: accountInformationStepValues.find(valueObject => valueObject.key === 'firstName')?.value,
            lastName: accountInformationStepValues.find(valueObject => valueObject.key === 'lastName')?.value,
            title: accountInformationStepValues.find(valueObject => valueObject.key === 'title')?.value,
            anonymousId: getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME),
            installedBaseUnits: [getInstalledBaseUnits()],
            address: getAddress(),
            sendInvoice: getCheckboxStatus(accountInformationStepValues)
        };
    }

    function getInstalledBaseUnits() {
        const connectionCheckStepValues = [...props.session.getSomeValues()[checkoutSteps.connectionCheck]];
        let isInternetReady;

        if (connectionCheckStepValues.find(valueObject => valueObject.key === 'internetConnected')?.value) {
            isInternetReady = true;
        } else {
            isInternetReady = connectionCheckStepValues.find(valueObject => valueObject.key === 'internetReady')?.value || false;
        }
        let addedInstallationFee = connectionCheckStepValues.find(valueObject => valueObject.key === 'addConnectionService')?.value
        return {
//            serialNumber: installedBaseUnitStepValues.find(valueObject => valueObject.key === 'serialnumber')?.value,
            installationDate: connectionCheckStepValues.find(valueObject => valueObject.key === 'installationDate')?.value,
            isYoungerThan5Years: connectionCheckStepValues.find(valueObject => valueObject.key === 'baseUnitAge')?.value,
//            deviceType: installedBaseUnitStepValues.find(valueObject => valueObject.key === 'model')?.value,
            internetConnected: connectionCheckStepValues.find(valueObject => valueObject.key === 'internetConnected')?.value,
            internetReady: isInternetReady,
            addedInstallationFee: addedInstallationFee,
//            gatewayId: installedBaseUnitStepValues.find(valueObject => valueObject.key === 'gatewayid')?.value,
            address: getAddress(),
            lineItemId: "<id of cart line item>"
        }
    }

    function getCheckboxStatus(dataSource) {
        if (dataSource) {
            return dataSource.find(valueObject => valueObject.key === 'sendInvoice')?.value === "true" ||
                dataSource.find(valueObject => valueObject.key === 'sendInvoice')?.value === true;
        } else {
            return false;
        }

    }

    function getAddress() {
        const accountInformationStepValues = [...props.session.getSomeValues()[checkoutSteps.accountInformation]];

        return {
            countryCode: getCountryForCountryCode(getSessionStorageItem(COUNTRY_CODE_NAME)),
            city: accountInformationStepValues.find(valueObject => valueObject.key === 'city')?.value,
            zipCode: accountInformationStepValues.find(valueObject => valueObject.key === 'zipCode')?.value,
            street: accountInformationStepValues.find(valueObject => valueObject.key === 'street')?.value,
            phoneNumber: accountInformationStepValues.find(valueObject => valueObject.key === 'phoneNumber')?.value,
        }
    }

    function areAccountInformationInputsValid() {
        if (!props.currentSessionValues) {
            return false;
        }

        return !props.currentSessionValues.some(sessionValue => sessionValue.invalid);
    }

    function findValue(ItemKey) {
        if (entry && addresses) {
            for (const [key, value] of entry) {
                if (key === ItemKey) {
                    return value;
                }
            }

            for (const [key, value] of addresses) {
                if (key === ItemKey) {
                    return value;
                }
            }
        }

        return "";
    }
    return (
        <div className="registration_step_content">
            {!isLoggedIn && <ToggleSwitch checked={signIn} onClick={toggleSwitch} id="switch" name="switch"/>}
            {
                signIn ? (
                    <SignIn goNext={props.navigation.goNext} toggleSwitch={toggleSwitch}/>
                ) : <>
                    {props.currentStepValues.inputList.map((inputListItem, inputListItemIndex) =>
                        <FormFieldset key={inputListItemIndex}>
                            <FormHeadline headline={inputListItem.headline}/>
                            {
                                inputListItem.inputs.map((rows, rowIndex) =>
                                    <FormRow key={rowIndex}>
                                        {
                                            rows.map((input, inputIndex) =>
                                                <FormItem
                                                    key={inputIndex}
                                                    setComparePasswordValues={handleFormPasswordValueCompare}
                                                    setCompareEmailValues={handleFormEmailValueCompare}
                                                    compareEmailValues={compareEmailValues}
                                                    comparePasswordValues={comparePasswordValues}
                                                    item={input}
                                                    backendValidationError={backendValidation.errors[input.name]}
                                                    saveValueByStep={saveValues}
                                                    updateValuesByKey={updateValues}
                                                    currentStepValues={props.currentSessionValues || {}}
                                                    showSomeMessages={showSomeMessages}
                                                    currentValue={findValue(input.name)}
                                                    disabled={isLoggedIn && ['email'].includes(input.name)}
                                                    isLogin={isLoggedIn}
                                                    country={getCountryForCountryCode(getSessionStorageItem(COUNTRY_CODE_NAME))}
                                                    checked={getCheckboxStatus(props.session.getSomeValues()[1])}
                                                />
                                            )
                                        }
                                    </FormRow>
                                )
                            }
                        </FormFieldset>
                    )}
                </>
            }
            {!signIn && <StepButtons
                showBackButton={true}
                goBackToProtectionPlan={false}
                backButtonHref={null}
                backButtonOnClick={props.navigation.goPrev}
                backButtonLabel={props.currentStepValues.href_label}
                nextButtonEnabled={!isLoading && !backendValidation.hasError}
                nextButtonOnClick={handleNextStepButtonClick}
                showDisabled={isLoggedIn ? btnEnabled : areAccountInformationInputsValid()}
                nextButtonText={props.currentStepValues.next_btn}
                isLoading={isLoading}
            />

            }
        </div>
    )
}

export default AccountInformationStep;