import React from "react";
import {Route} from "react-router-dom";
import LoginPage from "../../pages/LoginPage";

const PrivateRoute = ({component: Component, auth, path}) => {
    // console.log("redirect to dahsboard", auth);
    return (
        <Route path={path}
            render={(props) => auth?.authToken !== null
                ? <Component {...props} />
                : <LoginPage {...props} />}
        />
    )
}

export default PrivateRoute;