module.exports = {
    headline: "How to choose your right service package?",
    subheadline: "Aquarea Service + offers a choice of 3 different service packages so you can select the one that best fits your needs.",
    imageLeft: {
        image: "/image/home/chooseplan/left.png",
        alt: "",
        text: "Find your ideal package with our interactive test",
        btn_label: "Start the test",
        btn_href: ""
    },
    imageRight: {
        image: "/image/home/chooseplan/right.png",
        alt: "",
        text: "Would you like personal advice on which package is most suitable for you?",
        btn_label: "Contact us",
        btn_href: ""
    }
};
