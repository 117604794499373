import React, {useContext, useState} from "react";
import {withRouter} from 'react-router-dom';
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import Menu from "../components/menu";
import {Page, PageBreadcrumb, PageFooter, PageHeader, PageHeadline} from "../components/layout/page";
import {BtnGroup, LoadingSpinnerButton} from "../components/layout/buttons";

import {sameValueValidation, passwordValidation} from "../lib/validation";

import {changePassword} from "../lib/dataAccess/customer/customerRestEndpoint";
import {changePasswordWithToken} from "../lib/dataAccess/customer/accountRestEndpoint";
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";

import {AuthContext} from "../lib/contexts/authContext";
import {TokenContext} from "../lib/contexts/tokenContext";
import {getAccount} from "../lib/dataAccess/accountService";

import i18n from "../lib/i18n";
import {
    getSessionStorageItem,
    setSessionStorageItem,
    COUNTRY_CODE_NAME,
    AUTH_TOKEN_SESSION_STORAGE_NAME,
} from "../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const messages = i18n.namespace("Validation");
const t = i18n.namespace("ResetPassword");

function PasswordReset(props) {
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const {authContext, setAuthContext} = useContext(AuthContext);
    const { setTokenContext } = useContext(TokenContext);
    const history = useHistory();

    const resetPassword = () => {
        if (!sameValueValidation(newPassword, confirmPassword)) {
            setIsLoading(true);

            if (props.match.params.target) {
                changePassword({
                    "target": props.match.params.target,
                    "newPassword": newPassword
                })
                    .then(res => {
                        // console.log(res);

                        setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME, res.data.token);
                        setTokenContext(res.data);

                        getAccount(res.data.token).then((getAccountResponse) =>{
                            setAuthContext({
                                ...authContext,
                                authToken: res?.data.token,
                                accountData: getAccountResponse?.data
                            });
                            setIsLoading(false);
                            props.history.push("/"+ countryCode +"/home/my_account");
                        }).catch(e => {
                          // console.error(e);
                          setIsLoading(false);
                          props.history.push("/"+ countryCode +"/404");
                      })
                    })
                    .catch(e => {
                        // console.error(e);
                        setIsLoading(false);
                        props.history.push("/"+ countryCode +"/404");
                    })
            }

            if (authContext.authToken) {
                // console.log(authContext.authToken, {"newPassword": newPassword})
                changePasswordWithToken({"newPassword": newPassword}, authContext.authToken)
                    .then(res => {
                        //console.log(res);
                        setIsLoading(false);
                        props.history.push("/"+ countryCode +"/home/my_account");
                    })
                    .catch(e => {
                        setIsLoading(false);
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    })
            }

            if (!authContext.authToken && !props.match.params.target) {
                setIsLoading(false);
                props.history.push("/"+ countryCode +"/404");
            }

        } else {
            setErrorMessage(messages("validationMethods")["password"].message_not_same);
            setError(true);
        }
    }

    const _onBlur = (event) => {
        const value = event.target.value;
        setError(false);
        if (passwordValidation(value)) {
            setErrorMessage(messages("validationMethods")["password"].message_password_lengths);
            setError(true);
        }
    }

    return (
        <Page>
            <Menu/>
            <div className="container">
                <PageHeader>
                    <PageBreadcrumb/>
                    <PageHeadline headline={t("headline")}/>
                </PageHeader>
                <div className="login-page_wrapper container">
                    <div className="login-page_wrapper">
                        <div className="login-page_content">
                            <h3 className="login-page_subheadline">{t("subheadline")}</h3>
                            <div className="login-page_inputs">
                                <p className="login-page_inputs_label">{t("inputs")[0].label}</p>
                                <input
                                    className="login-page_inputs_input"

                                    type={t("inputs")[0].type}
                                    name={t("inputs")[0].name}
                                    id={t("inputs")[0].id}
                                    value={newPassword}
                                    onBlur={(event) => _onBlur(event)}
                                    onChange={(event) => setNewPassword(event.target.value)}
                                />
                                <p className="login-page_inputs_label">{t("inputs")[1].label}</p>
                                <input
                                    className="login-page_inputs_input"

                                    type={t("inputs")[1].type}
                                    name={t("inputs")[1].name}
                                    id={t("inputs")[1].id}
                                    value={confirmPassword}
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    onBlur={(event) => _onBlur(event)}
                                />

                                <p className="error_message error_message--red">
                                    {error && errorMessage}
                                </p>
                            </div>


                            <BtnGroup className="justify-content-center">
                                <LoadingSpinnerButton
                                    onClick={resetPassword}
                                    classNames="btn-primary loading-spinner-button"
                                    isEnabled={true}
                                    text={t("resetBtn")}
                                    isLoading={isLoading}
                                />
                            </BtnGroup>
                        </div>
                    </div>

                </div>

            </div>
            <PageFooter/>
        </Page>
    )
}

export default withRouter(PasswordReset);