import axios from "axios";
import { getHeaderForApiRequest } from "../dataAccessHelper";

const BASE_URL = process.env.REACT_APP_API_PRODUCT_ENDPOINT_BASE_URL;

export function getProduct() {
    return axios.get(BASE_URL + '/product', {
        headers: getHeaderForApiRequest()
    });
}

export function getProductService(){
    return axios.get(BASE_URL + '/product/service', {
        headers: getHeaderForApiRequest()
    });
}