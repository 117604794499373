module.exports = {
    headline: "Quale pacchetto di servizi è giusto per te?",
    subheadline: "Scegli tra tre diversi pacchetti Aquarea Service+ e trova quello più adatto alle tue esigenze.",
    imageLeft: {
        image: "/image/home/chooseplan/left.png",
        alt: "",
        text: "Prova il nostro test interattivo e trova il pacchetto perfetto per le tue esigenze",
        btn_label: "Iniziare il test",
        btn_href: "/it_IT/home/service_package"
    },
    imageRight: {
        image: "/image/home/chooseplan/right.png",
        alt: "",
        text: "Vuoi un consiglio personale su quale pacchetto è meglio per te?",
        btn_label: "Contattateci",
        btn_href: "https://www.aircon.panasonic.eu/IT_it/my-project/"
    }
};
