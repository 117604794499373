import React from 'react';
import i18 from "../lib/i18n";

const t = i18.namespace("PlanServicePoints");

export default function PlanServicePoints() {
  return (
    <section className="plan-service-points">
      <div className="container">
        <div className="section-content section-content--indented">
          <h2 className="plan-service-points_headline">{t("headline")}</h2>
          <p className="plan-service-points_text">{t("text")}</p>
          <ul className="plan-service-points_list list-unstyled">
            {
              t("list").map((item, i) =>
                <li key={i} className="plan-service-points_list_item">
                  <div className="plan-service-points_list_item_content">
                    <img className="plan-service-points_list_item_icon" src={item.iconUrl} alt={item.iconAlt}/>
                    <strong className="plan-service-points_list_item_headline">{item.headline}</strong>
                    <p className="plan-service-points_list_item_text">{item.text}</p>
                  </div>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </section>
  )
}
