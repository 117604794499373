module.exports = {
    headline: 'ABONNEMENTSVILLKOR FÖR PANASONICS AQUAREA SERVICE+',
    link: "Gå tillbaka till hemsidan",
    link_href: "/home",
    reload: 'Något gick fel. Försök att ladda om sidan igen.',
    text: '<h3>1.Aquarea Service+</h3>' +
        '<p>1.1.Disse abonnementsvilkår regulerer abonnementet på Panasonics webbaserede tjeneste Aquarea Service+ (<strong>"Aquarea Service+”</strong> eller <strong>"Tjenesten"</strong>). </p> ' +
        '<p>Abonnementsvilkårene, herunder Panasonics privatlivspolitik (tilgængelig via link nedenfor) (under ét betegnet <strong>"Vilkårene"</strong>), kan ændres fra tid til anden i overensstemmelse med bestemmelserne i punkt 17. </p>' +
        '<p>Vilkårene udgør en del af aftalen mellem Panasonic og abonnenten (betegnet <strong>"Brugeren"</strong>) og gælder for enhver, der abonnerer på Aquarea Service+, som drives og ydes af Panasonic Marketing Europe GmbH, Hagenauer Str. 43, 65203 Wiesbaden, Tyskland, handelsregistret afdeling B nr. 13178, i Danmark telefon: +45 89 87 45 01, e-mail: <a href="mailto:panasonic.aircon.dk@h1.se">Panasonic.aircon.dk@h1.se</a> (<strong>"Panasonic"</strong>). </p>' +
        '<p>1.2.Ved at bruge Aquarea Service+ accepterer Brugeren at ville overholde Vilkårene. </p>' +
        '<p>1.3.Panasonic tilbyder følgende abonnementstyper:</p>' +
        '<ul><li>(i)\tBasic</li>(ii)\tSmart<li>(iii)\tPremium</li></ul> <p></p>' +
        '<p>Indholdet af abonnementerne er beskrevet i afsnit 3 nedenfor og på Aquarea Service+’ hjemmeside <a target="_blank" href="https://shop.aquarea.panasonic.eu"><strong>https://shop.aquarea.panasonic.eu</strong></a> (<strong>"Hjemmesiden"</strong>). Tjenesten ydes kun gennem et abonnement (<strong>"Abonnementet"</strong>) i overensstemmelse med Vilkårene.</p>' +
        '<p>1.4.\tFor at abonnere på Smart- og Premium-versionerne af Tjenesten er det påkrævet, at Brugeren også abonnerer på AQUAREA SERVICE CLOUD, som administreres af Panasonic Corporation, Japan, til brug for Panasonics Air to Water varmepumpeenheder. For flere oplysninger om AQUAREA SERVICE CLOUD henvises til handelsvilkårene for AQUAREA SERVICE CLOUD.  </p>' +
        '<p>1.5.\tFor at bestille Tjenesten fra Hjemmesiden skal Brugeren være slutbruger og forbruger, der ejer en Panasonic Aquarea Air-to-Water-enhed. Erhvervspartnere og andre ikke-forbrugere kan ikke bestille Aquarea Service+-produkter.\n </p>' +
        '<p>1.6.\tSe nedenfor og i servicebeskrivelserne på Hjemmesiden for flere oplysninger om Aquarea Service+-produkterne. </p>' +
        '<h3>2.\tAbonnement på Tjenesten</h3>' +
        '<p>2.1.\tFor at abonnere på Aquarea Service+ skal Brugeren afgive en ordre på et bestemt Aquarea Service+-produkt på Hjemmesiden. For at gøre dette kan Brugeren vælge produkter og lægge dem i sin indkøbskurv. Ved kassen skal Brugeren indtaste sine oplysninger (herunder navn, faktureringsadresse og e-mailadresse), oprette en Aquarea Service+-konto og vælge en af de mulige betalingsmetoder. Brugeren har pligt til at afgive aktuelle, komplette og korrekte oplysninger. Når alle oplysninger er indtastet, får Brugeren en oversigt over sin ordre og bliver bedt om at gennemføre ordren. </p>' +
        '<p>2.2.\tNår Brugeren har afgivet en ordre, sender Panasonic straks en bekræftelse på modtagelse af ordren via e-mail. Denne bekræftelse kan være automatisk genereret og udgør ikke Panasonics accept af ordren. <strong>Der er først indgået en aftale, når Panasonic har accepteret ordren.</strong></p>' +
        '<p>2.3.\tBrugerens afgivelse af en ordre skal betragtes som et tilbud til Panasonic om at abonnere på Tjenesten på nærværende vilkår. Panasonic kan efter eget skøn acceptere eller afvise ordren. </p>' +
        '<p>Enhver kontrakt er betinget og afhængig af en bekræftelse af tilgængelighed samt godkendelse af både Brugerens ordre og betalingsoplysninger. </p>' +
        '<p>Hvis Panasonic accepterer Brugerens ordre, giver Panasonic Brugeren meddelelse herom ved at bekræfte indgåelse af abonnementsaftalen inden for tre (3) arbejdsdage efter modtagelse af Brugerens ordre. Hvis Panasonic ikke kan acceptere Brugerens ordre, bliver Brugeren kontaktet. </p>' +
        '<p>Ved accept af Brugerens tilbud Brugeren kan tilgå Tjenesten på Hjemmesiden via sin Aquarea Service+-konto når Panasonic har accepteret ordren. Dette forudsætter dog, at Brugeren også har accepteret og givet samtykke til, at Panasonic uanset Brugerens fortrydelsesret påbegynder levering af Tjenesten straks, <strong>jf. hertil pkt. 17.5.</strong>&nbsp; I modsat fald kan Brugeren først tilgå Tjenesten, når fristen for Brugerens fortrydelsesret, jf. pkt. 17, er udløbet.</p>' +
        '<p>Brugeren betaler abonnementsgebyr fra det tidspunkt, Panasonic påbegynder levering af Tjenesten.</p>' +
        '<p>2.4.\tPanasonic er berettiget til at acceptere ordren eller at afvise ordren, for eksempel hvis Brugeren ikke har opfyldt de betingelser, der angives på ordretidspunktet, hvis Brugerens betaling ikke kan behandles, hvis de bestilte Aquarea Service+-produkter ikke er tilgængelige, eller hvis der er en åbenbare prisfejl eller andre fejl. I tilfælde af en sådan prisfejl eller andre fejl forbeholder Panasonic sig retten til enten at afvise ordren eller at kontakte Brugeren med alternative muligheder. I tilfælde af en sådan afvisning efter denne bestemmelse aktiveres Brugerens adgang til Tjenesten ikke, og eventuel modtaget betaling tilbagebetales af Panasonic til Brugeren.</p>' +
        '<p>2.5.\tBrugeren anerkender og accepterer, at når Brugeren bestiller Aquarea Service+ som abonnement, godkender Brugeren periodiske betalinger til Panasonic med den betalingsmetode og efter de intervaller, som Brugeren har valgt, indtil det relevante Abonnement ophører.</p>' +
        '<p>2.6.\tDe gældende Vilkår er også tilgængelige på Hjemmesiden.</p>' +
        '<p>2.7.\tPanasonic kan fra tid til anden tilpasse tilbuddene på Hjemmesiden.</p>' +
        '<p>2.8.\tTjenesten ydes i Danmark, dog ikke på Grønland og Færøerne.</p>' +
        '<h3>3.\tAbonnementstyper og beskrivelse af Tjenesten</h3>' +
        '<p>3.1.\tFor at Brugeren kan benytte alle fordelene ved Aquarea Service+ Smart og Premium-abonnementerne, skal Brugerens Air to Water-enhed kobles til Panasonics AQUAREA SERVICE CLOUD gennem en aktiv internetforbindelse.</p>' +
        '<p>Brugeren kan bestille en kobling af sin Panasonic Air-to-Water-enhed til AQUAREA SERVICE CLOUD gennem sin installatør eller direkte fra Panasonics Aquarea Service+- team, som tilbyder at koble Brugerens Air-to-Water-enhed til AQUAREA CLOUD SERVICE mod et engangsgebyr. </p>' +
        '<p>Bemærk, at den årlige vedligeholdelsesydelse og eventuelle andre reparations- eller vedligeholdelsesydelser omfattet af Brugerens Aquarea Service+-pakke kræver, at Brugerens Air-to-Water-enhed er installeret i overensstemmelse med Panasonics specifikationer, retningslinjer og gældende lovgivning af en kvalificeret installatør. Panasonic kan ikke yde årlige vedligeholdelsesydelser eller eventuelle andre reparations- eller vedligeholdelsesydelser på stedet, hvis Brugerens Air-to-Water-enhed ikke er installeret korrekt. Ændring af installationer, der ikke er udført i overensstemmelse med ovennævnte, er ikke inkluderet i nogen af abonnementerne.</p>' +
        '<p>3.2.\tAbonnementstyper og mulige betalingsmetoder</p>' +
        '<p>(1)\tGenerel bemærkning</p>' +
        '<p>Brugeren må ikke benytte en tredjepart til at udføre vedligeholdelsesydelser uden forudgående aftale med Aquarea Service+-teamet. Såfremt det sker, er Panasonic ikke forpligtet til at yde den årlige vedligeholdelsesydelse eller andre reparations- eller vedligeholdelsesydelser omfattet af Abonnementet.\n</p>' +
        '<p>(2)\tBestanddelene af vedligeholdelsesydelser på stedet (vedligeholdelsesdiagram)\n</p>' +
        '<p>For de abonnementer, der omfatter vedligeholdelsesydelser på stedet, omfatter dette følgende: </p>' +
        '<table><tr><th><strong>Generelle indstillinger</strong></th><th><strong>Kontrol af køle middelcyklus</strong></th><th><strong>Systemtjek på vandsiden</strong></th><th><strong>Elsystemet</strong></th></tr>' +
                '<tr><td><ul><li>•\tKontrol af korrekthed af den overordnede enhedsinstallation</li><li>•\tKontrol af enhedsindstillinger</li><li>•\tDriftsperioder</li><li>•\tKompressor start/stop-antal</li><li>•\tForbrug</li><li>•\tKorrosionstjek af udendørsenhed</li></ul></td><td><ul><li>•\tKontrol af rør inkl. isolering</li><li>•\tLækkontrol (visuel og detektor, spray)</li><li>•\tHøjtryk i opvarmningstilstand og beholdertilstand</li><li>•\tKontrol af blæser og blæsermotor</li><li>•\tFordamper ren</li><li>•\tAfrimningsfunktion</li></ul></td><td><ul><li>•\tAnodetjek</li><li>•\tSi-/filterrengøring</li><li>•\tKontrol af luftskylning</li><li>•\tKontrol af trykreduktionsventil centralvarme </li><li>•\tKontrol af intern Panasonic-sikkerhedsventil, hvis den bruges</li><li>•\tKontrol af ekspansionsbeholder internt</li><li>•\tVandgennemstrømningstjek min./maks.</li><li>•\tVandtryk</li><li>•\tOverløbsventil/bufferbeholder</li><li>•\tKalibrering af sensorer efter behov</li><li>•\tKontrol af trevejsventilfunktion (centralvarme til varmt brugsvand og omvendt)</li></ul></td><td><ul><li>•\tKontrol af alle elektriske forbindelser</li><li>•\tKontrol af faser, spænding og strømstyrke</li></ul></td></tr></table>' +
        '<p></p>' +
        '<p>(3)\tBasic-abonnement</p>' +
        '<p>a)\tFordele:</p>' +
        '<ul><li>•\tÅrlig vedligeholdelsesydelse på stedet, inklusive kontrol af kontrolpunkter, professionel rengøring og vedligeholdelsesrapport – se vedligeholdelsesdiagrammet.</li> <li>•\tPrioritetsadgang til Panasonics supporttelefon på +45 89 87 45 01 fra kl. 8 til 17 mandag til fredag.</li></ul>' +
        '<p></p>' +
        '<p>b)\tBemærkninger:</p>' +
        '<ul><li>•\tBrugeren kan til enhver tid opgradere sit abonnement til en Smart- eller Premium-pakke (hvis kravene er opfyldt, se under Smart- og Premium-pakker) med et varsel på én måned plus løbende måned. </li></ul>' +
        '<p></p>' +
        '<p>(4)\tSmart-abonnement</p>' +
        '<p>a)\tFordele:</p>' +
        '<ul><li>•\tÅrlig vedligeholdelsesydelse på stedet, inklusive kontrol af kontrolpunkter, professionel rengøring og vedligeholdelsesrapport – se vedligeholdelsesdiagrammet.</li>' +
        '<li>•\tAlarmnotifikation i tilfælde af fejl via AQUAREA SERVICE CLOUD </li>' +
        '<li>•\t24-7 IoT fjernovervågning af nøgleparametre via AQUAREA SERVICE CLOUD og daglige (mandag til fredag) fejltjek af Aquarea Service+-teamet.</li>' +
        '<li>•\tDaglig diagnosetjek af Aquarea Service+-teamet (mandag til fredag ekskl. helligdage)</li>' +
        '<li>•\t24-7-hotline med prioritetsadgang til Panasonics supporttelefon på +45 89 87 45 01, inklusive weekender og helligdage</li>' +
        '<li>•\t20 % rabat på listeprisen på alle reservedele til Brugerens Panasonic Air-to-Water-enhed </li>' +
        '<li>•\tSupport inden for 24 timer efter rapportering af fejl, inklusive weekender og helligdage, hvis fejlen ikke kan løses ved fjernstyring</li></ul>' +
        '<p></p>' +
        '<p>b)\tBemærkninger:</p>' +
        '<ul><li>•\tAir-to-Water-enheden skal være registreret på AQUAREA SERVICE CLOUDs hjemmeside</li><li>•\tAir-to-Water-enheden skal installeres med et Panasonic CZ-TAW1-modul (ikke inkluderet i abonnementet), som er en onlinekonnektor, og have en aktiv internetforbindelse.</li>' +
        '<li>•\tBrugeren kan til enhver tid opgradere sit abonnement til en Premium-pakke (hvis kravene er opfyldt, se under Premium-abonnement) med et varsel på én måned plus løbende måned.</li>' +
        '<li>•\tBrugeren kan nedgradere til en Smart-pakke med et varsel til udgangen af den måned, der følger efter den måned, hvor anmodningen om nedgradering fremsættes, , dog således at der ikke kan nedgraderes med virkning fra et tidspunkt før seks (6) måneder efter indgåelse af aftalen om Abonnementet.</li>' +
        '</ul>' +
        '<p></p>' +
        '<p>(5)\tPremium-abonnement</p>' +
        '<p>a)\tFordele:</p>' +
        '<ul>' +
        '<li>•\tÅrlig vedligeholdelsesydelse på stedet, inklusive kontrol af kontrolpunkter, professionel rengøring og vedligeholdelsesrapport – se vedligeholdelsesdiagrammet.</li>' +
        '<li>•\tAlarmnotifikation i tilfælde af fejl via AQUAREA SERVICE CLOUD </li>' +
        '<li>•\t24-7 IoT fjernovervågning af nøgleparametre via AQUAREA SERVICE CLOUD og daglige (mandag til fredag) fejltjek af Aquarea Service+-teamet.</li>' +
        '<li>•\tDaglig diagnosetjek af Service+-teamet (mandag til fredag ekskl. helligdage)</li>' +
        '<li>•\t24-7-hotline med prioritetsadgang til Panasonics supporttelefon på +45 89 87 45 01, inklusive weekender og helligdage</li>' +
        '<li>•\tSupport på stedet inden for 24 timer efter rapportering af fejl, inklusive weekender og helligdage, hvis fejlen ikke kan løses ved fjernstyring</li>' +
        '<li>•\tGratis reservedele i tilfælde af påviste fejl</li>' +
        '<li>•\tGratis reparationsarbejde i tilfælde af påviste fejl</li>' +
        '</ul>' +
        '<p></p>' +
        '<p>b)\tBemærkninger:</p>' +
        '<ul>' +
        '<li>•\tAir-to-Water-enheden skal være registreret på AQUAREA SERVICE CLOUDs hjemmeside</li>' +
        '<li>•\tAir-to-Water-enheden skal installeres med et Panasonic CZ-TAW1-modul (ikke inkluderet i abonnementet), som er en onlinekonnektor, og have en aktiv internetforbindelse. </li>' +
        '<li>•\tBrugeren kan nedgradere sit abonnement til en Basic- eller Smart-pakke med et varsel til udgangen af den måned, der følger efter den måned, hvor anmodningen om nedgradering fremsættes, dog således at der ikke kan nedgraderes med virkning fra et tidspunkt før seks (6) måneder efter indgåelse af aftalen om Abonnementet.</li>' +
        '</ul>' +
        '<p></p>' +
        '<h3>4.\tBetaling og yderligere omkostninger</h3>' +
        '<p>4.1.\tBrugeren kan betale for Abonnementet gennem følgende tilgængelige betalingsmetoder: </p>' +
        '<ul><li>•\tKreditkort: Visa, Mastercard</li><li>•\tPayPal</li></ul><p></p>' +
        '<p>4.2.\tBetalingstransaktionen sker gennem en tredjepart, Worldpay (UK) Ltd., Worldpay Limited, Worldpay AP Ltd. og Worldpay BV. Brugeren bliver omdirigeret til tredjepartsbetalingstjeneste for at autorisere betalingen. Bemærk venligst, at Worldpay gemmer dine betalingsoplysninger med henblik på tilbagevendende betaling af Tjenesten.</p>' +
        '<p>4.3.\tBetaling sker i danske kroner. ' +
        '<p>4.4.\tEventuelle betalingsgebyr til betalingstjenesteudbyderen, kortudstederen og tilsvarende kan forekomme og er Panasonic uvedkommende. </p>' +
        '<p>4.5.\tOmkostninger, herunder til levering, kan forekomme ved bestilling af reservedele til brug for reparation- eller vedligeholdelsesydelser, i det omfang dette ikke er omfattet af Abonnementet.</p>' +
        '<h3>5.\tServiceperiode og ophør</h3>' +
        '<p>5.1.\tBrugeren kan opsige Abonnementet med et varsel på én måned plus løbende måned, dog således at Abonnementet ikke kan ophøre tidligere end seks (6) måneder efter aftaleindgåelse.</p>' +
        '<p>5.2.\tPanasonic kan opsige Abonnementet med et varsel på seks (6) måneder plus løbende måned. </p>' +
        '<p>5.3.\tPanasonic er herudover berettiget til at opsige Abonnementet uden varsel af følgende årsager:  </p>' +
        '<ul><li>•\tBrugeren har foretaget en ikke-ubetydelig overtrædelse af nærværende Vilkår. </li><li>•\tBrugeren skylder to eller flere måneders servicegebyr.</li><li>•\tPanasonic har berettiget mistanke om, at Brugerens Air-to-Water-enhed ikke er installeret i overensstemmelse med de gældende regler og Panasonics specifikationer og retningslinjer af en kvalificeret installatør, eller at installationer eller enheder er blevet ændret på nogen måde. </li></ul>' +
        '<p>5.4.\tHvis en aftale er indgået på baggrund af urigtige forudsætninger, er aftalen ugyldig. Panasonic er i så fald ikke forpligtet til at levere Tjenesten. Bestemmelserne i punkt 17.4-17.5 finder da tilsvarende anvendelse. </p>' +
        '<p>5.5.\tFor Brugere med Smart- og Premium-pakken ophører Abonnementet automatisk ved ophør og/eller udløb af Brugerens AQUAREA CLOUD SERVICE-Abonnement.  </p>' +
        '<p>5.6.\tPanasonic refunderer eventuelle betalinger, som Brugeren har betalt for en periode, som ligger efter tidspunktet for ophør af Abonnementet. Bestemmelsen i pkt. 5.7 finder dog tilsvarende anvendelse, hvis ophøret sker inden for de første 6 måneder af abonnementsperioden, og det er Brugerens forhold, der gør, at Abonnementet ophører.</p>' +
        '<p>5.7.\t<strong>Hvis Brugeren opsiger kontrakten til ophør seks (6) måneder efter Abonnementets oprettelse, og Panasonic i serviceperioden har udført eller udfører reparations- og vedligeholdelsesydelser og/eller har bestilt eller leveret, eller bestiller og leverer, reservedele, hvor den samlede værdi af ydelsen og reservedelene overstiger abonnementsgebyret i serviceperioden, kan Panasonic  kræve betaling for  den del af værdien af de præsterede ydelser og/eller bestilte eller leverede reservedele, der overstiger værdien af abonnementsgebyret i serviceperioden. </strong></p>' +
        '<p>5.8.\tHvis Brugeren fraflytter den ejendom, hvor Air-to-Water-enheden er installeret, skal Brugeren informere Panasonic herom med et varsel på én måned plus løbende måned. Panasonic vil derpå med Brugeren drøfte mulighederne for flytning af Abonnementet eller for, at Abonnementet kan overtages af en efterfølgende bruger.</p>' +
        '<h3>6.\tAnmodning om reparations- eller vedligeholdelsesydelser</h3>' +
        '<p>6.1.\tOplysninger om reparations- og vedligeholdelsesydelser er tilgængelige på <a target="_blank" href="https://shop.aquarea.panasonic.eu"><strong>https://shop.aquarea.panasonic.eu</strong></a>. Der kan anmodes om ydelser ved opkald til +45 89 87 45 01 på arbejdsdage fra kl. 8 til 17 med et Basic-abonnement og 24-7, inklusive weekender og helligdage, for Smart- og Premium-abonnementer. Servicebesøg udføres inden for normal arbejdstid fra kl. 8 til 16 på hverdage.</p>' +
        '<h3>7.\tLevering af Tjenesten</h3>' +
        '<p>7.1.\tPanasonic kan benytte tredjeparter til at udføre reparations- og vedligeholdelsesydelser, som er omfattet af Abonnementet (<strong>"servicepartnere"</strong>). </p>' +
        '<p>7.2.\tVisse funktioner i Aquarea Service+ i Smart- og Premium-versionen kræver, at Brugerens Air-to-Water-enhed har internetforbindelse til Panasonics AQUAREA SERVICE CLOUD.</p>' +
        '<p>7.3.\tUdøvelse af reparations- og vedligeholdelsesydelser som omfattet af Abonnementet kræver, at Brugerens Air-to-Water-enhed er korrekt installeret i overensstemmelse med Panasonics retningslinjer af en person, der er kvalificeret og certificeret installatør (<strong>"kvalificeret installatør"</strong>). Panasonic er ikke forpligtet til at levere reparations- og vedligeholdelsesydelser under Aquarea Service+, hvis installationen af Brugerens Air-to-Water-enhed ikke overholder de gældende regler og Panasonics specifikationer og retningslinjer eller ikke er udført af en kvalificeret installatør. </p>' +
        '<p>7.4.\tReservedele til Air-to-Water-enheder produceres i ti (10) år efter ophør af produktionen af den relevante enhed. Panasonic er kun forpligtet til at levere reservedele til en enhed, der er taget ud af produktion, mens reservedelene stadig produceres, og Panasonic har ikke pligt til at udføre reparations- eller vedligeholdelsesservice på enheden, hvis dette kræver reservedele, der ikke længere produceres.  </p>' +
        '<h3>8.\tSuspendering af Tjenesten</h3>' +
        '<p>\tPanasonic <strong>kan midlertidigt suspendere Tjenesten (eller Brugerens anvendelse af Tjenesten)</strong>, hvis:</p>' +
        '<p>(1)\tsuspenderingen er nødvendig for at udføre regelmæssig eller uventet vedligeholdelse af Aquarea Service+ eller teknisk udstyr relateret til ydelsen af Tjenesten,</p>' +
        '<p>(2)\tydelse af Tjenesten forhindres af brand, afbrydelse, naturkatastrofe, pandemi eller en anden begivenhed uden for Panasonics kontrol som defineret i punkt 13,</p>' +
        '<p>(3)\tnetværkstjenesteudbyderens levering suspenderes af en hvilken som helst grund, </p>' +
        '<p>(4)\tPanasonic anser det for nødvendigt midlertidigt at suspendere Tjenesten af administrative, driftsmæssige eller tekniske grunde, eller</p>' +
        '<p>(5)\tPanasonic har grund til at antage, at Brugeren tilgår eller bruger Tjenesten til at begå en ulovlig handling,</p>' +
        '<p>Når det med rimelighed er muligt og er lovligt, vil Panasonic varsle Brugeren om en eventuel suspendering. Panasonic vil gøre sig rimelige bestræbelser på at genoptage levering af Tjenesten, når Panasonic har fastslået, at det anliggende, der forårsagede suspenderingen, er blevet løst. </p>' +
        '<h3>9.\tUdelukkelse fra Tjenesten</h3>' +
        '<p><strong>Panasonic er ikke forpligtet til at præstere Tjenesten til Brugeren og er berettiget til at opsige Abonnementet uden varsel i følgende tilfælde:</strong></p>' +
        '<p>(1)\t<strong>Hvis Brugeren benytter tredjeparter til at udføre vedligeholdelsesydelser uden forudgående aftale med Panasonics Aquarea Service+-team. </strong></p>' +
        '<p>(2)\t<strong>Hvis en fejl eller skade i Brugerens Air-to-Water-enhed er forårsaget af (i) forkert brug af enheden, (ii) tredjepartsprodukter, (iii) mangelfuld strømforsyning,(iv) forkert installation af Air-to-Water-enheden i strid med lovgivningen eller Panasonics specifikationer og retningslinjer, eller (v) at installationerne eller enhederne er blevet ændret på nogen måde. </strong></p>' +
        '<p>(3)\t<strong>Hvor installationen ikke er foretaget af en kvalificeret installatør,</strong></p>' +
        '<p>(4)\t<strong>Hvis Tjenesten er forbudt i henhold til lovgivningen.</strong></p>' +
        '<h3>10.\tBrugerens forpligtelse til at samarbejde</h3>' +
        '<p>10.1.\tBrugeren anerkender, at samarbejde mellem Panasonic og Brugeren er kritisk for Panasonics mulighed for at kunne levere Tjenesten effektivt. Brugeren skal bistå og samarbejde med Panasonic om alt, der er nødvendigt eller tilrådeligt for at udføre Tjenesten.</p>' +
        '<p>10.2.\tBrugerens pligt til samarbejde omfatter bl.a. pligt til at abonnere på AQUAREA SERVICE CLOUD (hvis det er nødvendigt for at bruge Tjenesten, hvilket aktuelt er tilfældet for Premium- og Smart-versionerne), korrekt dataindtastning (hvis der er nogen) og rimelig adgang til Air-to-Water-enheden, hvis brug af Tjenesten gør det nødvendigt at besøge og/eller arbejde hos Brugeren. Hvis det er nødvendigt at udføre arbejde hos Brugeren, er Panasonic ikke forpligtet hertil, hvis arbejdsmiljøet ikke er sikkert. </p>' +
        '<p>10.3.\tAfhængigt af Air-to-Water-enhedens tilstand kan det være nødvendigt for Brugeren at bestille reservedele, som sælges separat, for at gøre fuldstændig brug af Tjenesten. </p>' +
        '<p>' +
        '10.4.\tHvis aftaler om udførelse af servicearbejde, uanset om det er planlagt eller ikke, aflyses eller forhindres af Brugeren, vil et gebyr bestående af en procentdel af de samlede forventede arbejdsomkostninger, som nærmere anført herunder, blive opkrævet:\n' +
        '\n<strong>' +
        '<p><ul><li>0-24 timers varsel: 100 % af den gældende timetakst baseret på den anslåede indsats kan opkræves.</li>' +
        '<li>24-48 timers varsel: 75 % af den gældende timetakst baseret på den anslåede indsats kan opkræves.</li>' +
        '<li>48-72 timers varsel: 50 % af den gældende timetakst baseret på den anslåede indsats kan opkræves.</li>' +
        '<li>72 timer og derover: 0 % opkræves.</li></ul></p>' +
        '\n</strong>' +
        '10.5.\tPanasonic er ikke ansvarlig for nogen negativ virkning af Brugerens mangel på gensidigt samarbejde. \n' +
        '</p>' +
        '<h3>11.\tOverdragelse</h3>' +
        '<p>' +
        '11.1.\tPanasonic kan helt eller delvist overdrage sine rettigheder og forpligtelser i henhold til Abonnementet til en tredjepart efter forudgående varsel til Brugeren i henhold til punkt 18.2-18.3.\n' +
        '\n' +
        '<p>11.2\t<strong>Brugeren må ikke overdrage sine rettigheder til Tjenesten eller andre rettigheder og forpligtelser i henhold til Abonnementet uden forudgående skriftlig godkendelse af Panasonic. Denne godkendelse må ikke nægtes af Panasonic af uden rimelig grund.\n </strong></p>' +
        '</p>' +
        '<h3>12.\tImmaterielle rettigheder</h3>' +
        '<p>12.1.\tOphavsrettigheder, varemærkerettigheder og andre immaterielle rettigheder, der benyttes i Tjenesten og information stillet til rådighed af Panasonic i forbindelse dermed ejes af Panasonic eller andre rettighedshavere, som Panasonic har opnået licenser fra til at bruge denne information i forbindelse med Tjenesten.</p>' +
        '<p>12.2.\tBrugeren må ikke (a) ændre, lave udledte værker af, adskille, dekompilere, foretage reverse engineering, gengive, genudgive eller kopiere nogen del af Tjenesten, (b) tilgå eller bruge Tjenesten til direkte eller indirekte at bygge eller supportere produkter eller tjenester i konkurrence med Panasonic eller (c) licensere, sælge, overføre, overdrage, distribuere, outsource, tillade time-sharing eller servicebureaubrug, anvende Tjenesten kommercielt eller tilgængeliggøre Tjenesten til nogen tredjepart, og Brugeren må ikke få andre til eller tillade andre at gøre dette. </p>' +
        '<p>12.3.\tDet er nødvendigt for Panasonic at bruge indhold og oplysninger, som Brugeren uploader eller på anden måde indsender til Panasonic, med henblik på at levere Tjenesten. Brugeren giver hermed Panasonic en verdensomspændende, overdragelig, uigenkaldelig, vederlagsfri licens, som kan underlicenseres, til at bruge alt indhold og alle oplysninger givet af Brugeren og skabe og udnytte afledte værker med det formål at yde eller forbedre Tjenesten, alt sammen inden for grænserne af og i henhold til privatlivspolitikken, jf. punkt 16. </p>' +
        '<h3>13.\tForce majeure og begrænsning af ansvar</h3>' +
        '<p>13.1.<strong>\tPanasonic forbeholder sig ret til at udskyde en leveringsdato for reservedele og/eller Tjenesten eller midlertidigt reducere omfanget af Tjenesten, hvis Panasonic forhindres eller forsinkes i at udføre sine aktiviteter på grund af omstændigheder, der med rimelighed er uden for Panasonics kontrol, herunder for eksempel force majeure, myndighedshandlinger, krig, national undtagelsestilstand, terrorhandlinger, protester, uroligheder, civil opstandelse, brand, eksplosion, oversvømmelse, epidemi, pandemi, lockouter, strejker eller andre arbejdskonflikter (uanset om de vedrører Panasonics arbejdskraft), begrænsninger eller forsinkelser, der påvirker transportører, eller umulighed for eller forsinkelse i at få leverancer af egnede eller hensigtsmæssige materialer og kapacitet. Hvis omfanget af Tjenesten midlertidigt reduceres, vil abonnementsgebyret blive reduceret forholdsmæssigt. </strong></p>' +
        '<p>13.2.<strong>I det omfang det tillades af gældende lovgivning, kan Panasonics samlede erstatningsansvar i henhold til Abonnementet i enhver tolv måneders-periode ikke overstige 30.000 DKK, ligesom Panasonic ikke er ansvarlig for indirekte skader og følgeskader.</strong></p>' +
        '<h3>14.\tTredjepartslinks</h3>' +
        '<p>14.1.\tHjemmesiden kan indeholde links til tredjepartswebsteder. Disse linkede websteder er ikke under Panasonics kontrol, og Panasonic er ikke ansvarlig for indholdet af nogen af disse websteder eller noget link på et sådant websted. </p>' +
        '<h3>15.\tSpørgsmål og klager</h3>' +
        '<p>15.1.\tI tilfælde af spørgsmål om eller klager over Tjenesten eller Abonnementet kan Brugeren kontakte Panasonics kundeservice via telefon +45 89 87 45 01 eller e-mail Panasonic.aircon.dk@h1.se.</p>' +
        '<p>15.2.\tBrugeren kan også sende en klage til Konkurrence- og Forbrugerstyrelsens klagecenter, Carl Jacobsens Vej 35, 2500 Valby. Klagen kan indsendes gennem www.forbrug.dk. En klage kan også indsendes gennem Europa-Kommissionens onlineklageportal. Det er relevant for personer med bopæl i en anden medlemsstat. En sådan klage kan indsendes gennem https://ec.europa.eu/odr. Når Brugeren sender en klage, skal e-mailadressen Panasonic.aircon.dk@h1.se bruges. </p>' +
        '<p>15.3.\tKøbelovens mangelsregler kan finde anvendelse på leverede reservedele.</p>' +
        '<h3>16.\tDatabeskyttelse og datasikkerhed</h3>' +
        '<p>16.1.\tBrugerens oplysninger behandles i overensstemmelse med gældende lovgivning, herunder den generelle forordning om databeskyttelse (GDPR) og databeskyttelsesloven. Oplysninger indsamles bl.a. med det formål at gøre det muligt for Panasonic at udføre Tjenesten, forbedre Panasonics produktudbud og opkræve og behandle betaling for Tjenesten. For at få flere oplysninger om databeskyttelse og datasikkerhed bedes du se vores privatlivspolitik på Hjemmesiden via linket <a href="https://shop.aircon.panasonic.eu/sv_SE/home/privacy_policy" target="_blank">https://shop.aircon.panasonic.eu/sv_SE/home/privacy_policy</a>. </p>' +
        '<h3>17.\tFortrydelsesret</h3>' +
        '<p>17.1.\tBrugeren har ret til at fortryde enhver afgiven ordre i henhold til forbrugeraftaleloven. </p>' +
        '<p>17.2.\tBrugeren har ret til at fortryde Abonnementet og/eller køb af fysiske produkter efter de nedenfor angivne vilkår inden for fjorten (14) dage efter Panasonics accept af ordren og indgåelse af aftalen. </p>' +
        '<p>17.3.\tFor at gøre brug af fortrydelsesretten skal Brugeren informere Panasonic herom ved e-mail til <a href="mailto:Panasonic.aircon.dk@h1.se">Panasonic.aircon.dk@h1.se</a>. E-mailen skal være sendt til Panasonic inden udløbet af fortrydelsesperioden. E-mailen skal indeholde en tydelig erklæring om Brugerens beslutning om fortrydelse af Abonnementet. Brugeren kan bruge standardannulleringsformularen på <a target="_blank" href="https://shop.aquarea.panasonic.eu"><strong>https://shop.aquarea.panasonic.eu</strong></a>, men er ikke forpligtet hertil. Hvis Brugeren gør brug af sin fortrydelsesret, sender Panasonic uden unødigt ophold Brugeren en e-mail med bekræftelse på modtagelse af fortrydelsen. Den samme procedure gælder, hvis Brugeren har bestilt fysiske produkter og ønsker at fortryde købet.</p>' +
        '<p>17.4.\tHvis Brugeren fortryder Abonnementet efter, at Brugeren har godkendt, at Panasonic påbegyndte levering af Tjenesten fra tidspunktet for Panasonics accept af ordren, skal Brugeren betale for Panasonics levering af Tjenesten i perioden fra datoen for indgåelse af aftalen indtil datoen for Brugerens meddelelse til Panasonic om at gøre fortrydelsesretten gældende. \n' +
        '\n' +
        '<p><strong>17.5.\tHvis Brugeren gør brug af fortrydelsesretten inden for fjorten (14) dage efter aftaleindgåelsen under samme forudsætninger som punkt 17.4, og Panasonic har udført reparations- og vedligeholdelsesydelser og/eller bestilt eller leveret reservedele af en samlet værdi, der overstiger abonnementsgebyret i serviceperioden, kan Panasonic kræve betaling for den del af værdien af de udførte ydelser og/eller bestilte eller leverede reservedele, der overstiger abonnementsgebyret i serviceperioden. Brugeren kan også returnere leverede reservedele til Panasonic i overensstemmelse med punkt 17.7.  \n </strong></p>' +
        '\n' +
        '17.6.\tPanasonic refunderer beløb, som Panasonic har modtaget fra Brugeren med fradrag af Panasonics tilgodehavender efter punkt 17.4 og 17.5. Panasonic anvender den samme betalingsmetode, som Brugeren benyttede til den oprindelige transaktion, medmindre Panasonic udtrykkeligt har aftalt andet med Brugeren. Denne refusion udløser ikke omkostninger for Brugeren.\n</p>' +
        '<p>17.7.\tI tilfælde af, at Brugeren udnytter sin fortrydelsesret, efter at Panasonic har leveret varer til Brugeren, kan Panasonic tilbageholde købesummen, indtil varerne er blevet returneret til Panasonic, eller Brugeren har bevist, at varerne er blevet returneret, hvad end der måtte komme først. Brugeren skal sende eller overgive varerne til Panasonic hurtigst muligt og under alle omstændigheder senest fjorten (14) dage efter at have meddelt Panasonic, at Brugeren fortryder købet. Brugeren afholder omkostningerne til returnering af varer. \n' +
        '\n' +
        '</p><p>17.8.\tBrugeren hæfter for en eventuel forringelse af varens værdi, som skyldes anden håndtering af varen, end hvad der er nødvendigt for at fastslå varens art, egenskaber og den måde, den fungerer på.\n</p>' +
        '<h3>18.\tÆndring af vilkårene</h3>' +
        '<p>18.1.\tAftalen mellem Panasonic og Brugeren omfatter de abonnementsvilkår, der var gældende på tidspunktet for Brugerens bestilling af et Abonnement som eventuelt ændret efter bestemmelserne i dette afsnit. Panasonic anbefaler, at Brugeren gemmer eller udskriver en kopi af Vilkårene ved bestilling af ethvert Aquarea Service+-produkt. \n' +
        '\n' +
        '</p><p>18.2.\tPanasonic kan fra tid til anden foretage tilføjelser til eller ændringer af Vilkårene med en eller flere af de begrundelser, der er anført i punkt 18.4, under forudsætning af at den følgende proceduren i punkt 18.3 overholdes. Panasonic kan dog foretage mindre ændringer af Vilkårene eller ændringer, der alene er til Brugerens fordel, uden iagttagelse af punkt 18.3-18.4. Disse ændringer vil blive meddelt på Hjemmesiden, når de træder i kraft. \n</p>' +
        '<p>18.3.\t<strong>Panasonic varsler Brugeren via e-mail om tilføjelser eller ændringer til vilkårene. Medmindre Brugeren inden for 60 dage efter afsendelse af denne e-mail meddeler Panasonic, at Brugeren ikke accepterer de ændrede vilkår, vil de ændrede vilkår være gældende fra den dato, der er anført i Panasonics e-mail.</strong> Hvis Brugeren ikke accepterer vilkårsændringerne, vil Abonnementet ophøre dagen før, ændringerne skulle være trådt i kraft ifølge Panasonics meddelelse. \n</p>' +
        '<p>18.4.\tTilføjelser eller ændringer til vilkårene efter punkt 18.2-18.3 kan foretages af følgende grunde:</p>' +
        '<p>(a)\tHvis Panasonic har rimelig grund til at foretage ændringen af juridiske, regulatoriske eller administrative grunde.\n </p>' +
        '<p>(b)\tFor at afspejle ændringer, som Panasonic foretager til de systemer og den infrastruktur, som bruges i forbindelse med levering af Tjenesten.\n</p>' +
        '<p>(c)\tFor at forbedre Tjenesten, tilbyde Brugeren nye servicefunktioner eller fjerne en eller flere servicefunktioner under forudsætning af en rimelig reduktion af abonnementsgebyret eller en anden kompensation, hvis det er relevant.\n</p>' +
        '<p>(d)\tFor at øge sikkerheden i Tjenesten, hvilket kan kræve, at Brugeren følger nye eller ændrede procedurer.\n</p>' +
        '<p>(e)\tFor at afspejle organisatoriske ændringer hos Panasonic eller Panasonics koncernselskaber.\n</p>' +
        '<p>(f)\tFor at justere priser eller ydelser i et rimeligt omfang.\n</p>'
};
