module.exports = {
    headline: "Elección de la actualización",
    currentProtectionPlan: "Plan de servicios actual",
    protectionPlan: "plan de mantenimiento",
    validUntil: "El contrato vence el:",
    costs: "Costes",
    period: "Plazo de pago",
    monthly:"mensualmente",
    quarterly:"por trimestre",
    annually:"anualmente",
    notUpgradableText: "No puedes actualizar tu contrato actual.",
    backButtonText: "Volver a mi cuenta"
};