import React, {useContext, useEffect, useState} from "react";
import i18 from "../../lib/i18n";
import {Link} from "react-router-dom";
import PaymentMethodsSmallCart from "../paymentMethodsSmallCard";
import {CartContext} from "../../lib/contexts/cartContext";
import {ProductContext} from "../../lib/contexts/productContext";
import {
  getPaymentPeriodForSku,
  getPriceForSku,
  getProductByVariant,
} from "../../lib/helper/productHelper";

const t = i18.namespace("PlanCard");
const l = i18.namespace("ChoiceList");

const ChoiceList = () => {
    const {productContext, setProductContext} = useContext(ProductContext);
    const {cartContext} = useContext(CartContext);
    const [installationFeeLineItem, setInstallationFeeLineItem] = useState(null);
    const [protectionPlan, setProtectionPlan] = useState(null);
    const [price, setPrice] = useState("");
    const [period, setPeriod] = useState("");

    useEffect(() => {
        //console.log('choiceList CartContext-change', cartContext);

        if (cartContext.lineItems && cartContext.lineItems.length > 0) {
            setInstallationFeeLineItem(cartContext.lineItems?.find(lineItem => lineItem.sku === 'internet-connection-service-package'));
            if (!productContext.selectedPlan) {
                const selectedProduct = getProductByVariant(productContext, cartContext.lineItems?.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku);
                setProductContext(prevState => {
                    return {...prevState, selectedPlan: selectedProduct?.sku}
                });
            }

            if(productContext.selectedPlan && productContext.selectedVariant){
                setPrice(getPriceForSku(productContext.selectedVariant, productContext));
                setPeriod(getPaymentPeriodForSku(productContext.selectedVariant, productContext));
            } else {
                setPrice(getPriceForSku(productContext.selectedPlan, productContext));
                setPeriod(getPaymentPeriodForSku(productContext.selectedPlan, productContext));
            }
            setProtectionPlan(t("list").find(x => x.sku === productContext.selectedPlan));

        }
        //console.log(cartContext, installationFeeLineItem)
    }, [cartContext, productContext]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!protectionPlan) {
        return null;
    }

    function drawDisclaimer() {
        if(productContext.selectedPlan && productContext.selectedVariant) {
            return " ";
        } else {
            return l("disclaimer");
        }
    }

    //console.log(installationFeeLineItem)
    return (
        <section className="choiceList checkout_sidebar">
            <div className="choiceList_wrapper checkout_sidebar_teaser">
                <p className="choiceList_headline">{l("headline")}</p>
                <div
                    className={"choiceList_plan choiceList_plan--" + protectionPlan.color}>
                    <img src={protectionPlan.headline} alt=""/>
                </div>
                <p className="choiceList_subheadline">{l("subheadline")}</p>
                <img className="choiceList_label" src={protectionPlan.choiceList_image}
                     alt=""/>
                <p className="choiceList_price">{price} {l(period)}</p>
                <p className="choiceList_disclaimer">{drawDisclaimer()}</p>
                <Link className="choiceList_edit"
                      to={l("edit_link")}>
                    {l("edit_label")}
                </Link>
            </div>

            {/*{cartContext.lineItems?.findIndex(lineItem => lineItem.sku === 'internet-connection-service-package') > -1 &&*/}
            {installationFeeLineItem && installationFeeLineItem.totalGross &&
            <div className="choiceList_wrapper choiceList_wrapper--additionally">
                <p className="choiceList_headline">{l("additionally")[0].label}</p>
                <div className="choiceList_content">
                    <p className="choiceList_label">{installationFeeLineItem.name}</p>
                    <p className="choiceList_text">
                        {installationFeeLineItem.totalGross.amount + " " + installationFeeLineItem.totalGross.currency}
                    </p>
                </div>
            </div>
            }
            {/* {
                currentLineItemId.length !== 0 ?
                    <div className="choiceList_wrapper choiceList_wrapper--additionally">
                        <p className="choiceList_headline">{l("additionally")[currentLineItemId[0].value].label}</p>
                        <div className="choiceList_content">
                            <p className="choiceList_label">{l("label")}</p>
                            <p className="choiceList_text">{l("additionally")[currentLineItemId[0].value].text}</p>
                            <div className="choiceList_remove-btn">{l("additionally")[currentLineItemId[0].value].href_label}</div>
                        </div>
                    </div> : null
            } */}
            <PaymentMethodsSmallCart/>
        </section>
    )
}

export default ChoiceList;


