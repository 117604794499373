module.exports = {
    upgradeDiscount: {
        name: "Discount",
        inputPlaceholder: "insert discount code",
        applyBtnLable: "apply",
        removeBtnLable: "remove",
        invalidHeadline: "The entered discount code is invalid",
        invalidHint: "Please check your input",
        errors: {
            tooLong: "The input is to long.",
            invalidInput: "The entered discount code is invalid" // upgrade page
        }
    }
};