import React from 'react'
import {Link, withRouter} from "react-router-dom";
import i18 from "../../lib/i18n";

const t = i18.namespace("Menu");

const MenuTop = (props) => {
    return (
        <div className="menu_top">
            <div className="menu_top_wrapper container">
                <Link className="logo" to={t("logo").href} titel={t("logo").title} name={t("logo").name}
                      aria-label={t("logo").aria_label}/>

                <div className="menu_top_language">
                    <img src={t("language").title} alt=""/>
                </div>
            </div>
        </div>
    )
};

export default withRouter(MenuTop);
