module.exports = {
    home: 'Hem',
    termsConditions: "Villkor och bestämmelser",
    privacyPolicy: "Integritetspolicy",
    protectionPlans: 'Tjänstepaketerare',
    service: 'Kundeservice',
    error: 'Oops ett misstag',
    checkout: 'Kassan',
    login: 'Inloggning',
    cancelProtectionPlan: 'Avbryta servicepaketet',
    myAccount: 'Min-konto',
    editMyAccount: 'Redigera personlig information',
    passwordReset: 'Återställning av lösenord',
    upgradeProtectionPlan: 'Uppgradering av servicepaket',
    upgrade: 'Uppgradera',
    updatePayment: "Ändra betalningsmetod",
    registerUnit: "Registrera värmepumpsenhet",
    faq : "Ofta ställda frågor"
}