import React from "react";
import classNames from "classnames";

export const StepFormNav = ({steps, currentStep}) => {
  return (
    <div className="step-form_nav">
      <ul className="step-form_nav_list list-unstyled">
        {
          steps.map((item, i) =>
            <li key={i}
                id={"step-" + i}
                className={classNames("step-form_nav_list_item", {"step-form_nav_list_item--active": i === currentStep})}>
              {item.label}
            </li>
          )
        }
      </ul>
    </div>
  );
}

export default StepFormNav;