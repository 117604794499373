module.exports = {
    protected: "Du er beskyttet af Aquarea Service+",
    heatPumpsCorrectly: "",
    callHotline: "Kontakt Kundeservice",
    sendMail: "Send e-mail",
    gotoCloud: "Gå til Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "smart"
    },
    imageTags:{
        exclusive: "Aquerea premium",
        advance:"Aquerea smart",
        smart: "Aquerea basic",
        comfort: "Aquarea comfort"
    },
    downgradeInfo1: "Din beskyttelsesplan er planlagt til nedgradering til ",
    downgradeInfo2: ". Denne nedgradering anvendes efter den nuværende kontraktperiode er afsluttet.",
    contactmail: "pan_aircon_customer_sup_h1_dk@eu.panasonic.com",
}