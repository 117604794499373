import React, { useState, useContext } from "react";
import {useHistory, useParams} from 'react-router-dom';
import {AuthContext} from "../lib/contexts/authContext";
import StepButtons from "./stepForm/stepButtons";
import { exceptedPaymentMethods } from "./stepForm/steps/types/paymentMethods";
import {
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem
} from "../lib/sessionStorage";
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";
import {submitOneTimePaymentRequest} from "../lib/dataAccess/checkout/orderRestEndpoint";
import {Page, PageBreadcrumb, PageHeader, PageHeadline} from './layout/page';
import i18n from '../lib/i18n';
import {FormSection} from "./layout/form";
import { getCountryForCountryCode, getLanguageForCountryCode } from "../lib/helper/localizationHelper";

const t = i18n.namespace("SetRepairPaymentMethod");

function SetRepairPaymentMethod({
    subheadline,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const { authContext, setAuthContext } = useContext(AuthContext);
    const [chosenPaymentMethodState, setChosenPaymentMethodState] = useState(null);
    const history = useHistory();
    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
    let { orderId } = useParams();


    async function handleNextStepButtonClick(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
            let res = await submitOneTimePaymentRequest(orderId, chosenPaymentMethodState, getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME));
            const windowOrigin = encodeURIComponent(window.location.origin);
            const paymentRedirectUrl = res.data.redirectUrl + '&'
                + 'country='+ getCountryForCountryCode(countryCode) + '&'
                + 'language=' + getLanguageForCountryCode(countryCode) + '&'
                + 'successURL=' + windowOrigin + '/' + countryCode + '/repair_paysuccess/' + orderId + '&'
                + 'cancelURL=' + windowOrigin + '/repair_paycancel&'
                + 'failureURL=' + windowOrigin + '/' + countryCode + '/repair_payfailure/' + orderId + '&'
                + 'errorURL=' + windowOrigin + '/' + countryCode + '/repair_payerror/' + orderId + '&'
                + 'expiryURL=' + windowOrigin + '/repair_payexpiry&'
                + 'pendingURL=' + windowOrigin + '/repair_paypending';
            window.location.href = paymentRedirectUrl;
        } catch (e) {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        }
    }

    function handleChosenPayment(event, chosenPaymentMethodId) {
        setChosenPaymentMethodState(chosenPaymentMethodId);
    }

    return (
        <Page>
            <div className="container">
                <PageHeader>
                    <PageBreadcrumb/>
                    <PageHeadline className="my-account_headline" headline={t("headline")} />
                </PageHeader>

                <FormSection>
                    <p>{t("selectPayment")}</p>

                    <div className="form-group">
                        {exceptedPaymentMethods(countryCode).map((paymentMethod, index) =>
                            <div className="form-check form-check-inline" key={index}>
                                <input className="form-check-input" id={paymentMethod.id}
                                       key={paymentMethod.id}
                                       onChange={(event) => handleChosenPayment(event, paymentMethod.id)}
                                       type="checkbox"
                                       checked={chosenPaymentMethodState === paymentMethod.id}
                                       value={paymentMethod.id}
                                />
                                <label className="form-check-label" htmlFor={paymentMethod.id}><img src={paymentMethod.image} alt={paymentMethod.label} /></label>
                            </div>
                        )}
                    </div>

                    <div className={"change-payment-step-buttons"}>
                        <StepButtons
                            showBackButton={true}
                            goBackToProtectionPlan={false}
                            backButtonHref={null}
                            backButtonOnClick={history.goBack}
                            backButtonLabel={t("btn.back")}
                            nextButtonEnabled={!!chosenPaymentMethodState}
                            nextButtonOnClick={handleNextStepButtonClick}
                            nextButtonText={t("btn.next")}
                            isLoading={isLoading}
                        />
                    </div>
                </FormSection>
            </div>
        </Page>
    )
}

export default SetRepairPaymentMethod;
