import React, {useState} from "react";
import {withRouter} from "react-router-dom";

import Menu from "../components/menu";
import {Page, PageBreadcrumb, PageFooter, PageHeader, PageHeadline} from "../components/layout/page";

import i18n from "../lib/i18n";
import {BtnGroup, LoadingSpinnerButton} from "../components/layout/buttons";
import {emailValidation} from "../lib/validation";
import { forgotPassword } from "../lib/dataAccess/customer/customerRestEndpoint";
import { getSessionStorageItem, COUNTRY_CODE_NAME } from "../lib/sessionStorage";
import { getLanguageForCountryCode } from "../lib/helper/localizationHelper";

const messages = i18n.namespace("Validation");
const t = i18n.namespace("ForgotPassword");

const PasswordForgot = (props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const sessionCountryCode = getSessionStorageItem(COUNTRY_CODE_NAME);
    const language = getLanguageForCountryCode(sessionCountryCode);

    const submit = () => {
        setIsLoading(true);
        setSuccess(false);
        forgotPassword({email: email,language: language})
            .then(res => {
                //console.log(res);
                setIsLoading(false);
                setSuccess(true);
            })
            .catch(e => {
                console.error(e);
                setIsLoading(false);
                setSuccess(false);
                props.history.push("/404");
            })
    }

    const _onBlur = (value) => {
        setError(false);
        if (emailValidation(value)) {
            setErrorMessage(messages("validationMethods")["email"].message);
            setError(true);
        }
    }

    const enableSubmit = () => {
        return !error && !emailValidation(email);
    }

    return (
        <Page>
            <Menu/>
            <PageHeader>
                <PageBreadcrumb/>
                <PageHeadline headline={t("headline")}/>
            </PageHeader>
            <div className="password-forgot">
                <div className="password-forgot_wrapper container">
                    <div className="password-forgot_content">
                        <h3 className="password-forgot_subheadline">{t("subheadline")}</h3>
                        <div className="password-forgot_inputs">
                            <p className="password-forgot_inputs_label">{t("inputs").label}</p>
                            <input
                                className="password-forgot_inputs_input"
                                type={t("inputs").type}
                                name={t("inputs").name}
                                id={t("inputs").id}
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                onBlur={(event) => _onBlur(event.target.value)}
                            />
                            <p className="error_message error_message--red">
                                {error && errorMessage}
                            </p>
                        </div>

                        <BtnGroup className="justify-content-center">
                            <LoadingSpinnerButton
                                onClick={submit}
                                classNames="btn-primary loading-spinner-button"
                                isEnabled={enableSubmit()}
                                text={t("submit")}
                                isLoading={isLoading}
                            />
                        </BtnGroup>
                        {success && <p className="error_message">{t("success")}</p>}
                    </div>
                </div>
            </div>
            <PageFooter/>
        </Page>
    )
}

export default withRouter(PasswordForgot);