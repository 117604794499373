module.exports = {
    image: "",
    text: "Just like my car, any heating system requires some regular maintenance in order to keep first day performance and extend its lifetime. With my Aquarea Service + plan I just relax while someone is taking care of this",
    author: "Cecilie M. Thomson",
    quotes:{
        image: "/image/home/quotes/logo.png",
        alt: "aquarea",
        headline: "Discover our wide range of Aquarea heat pump equipment",
        link: "More about aquarea heat pumps",
        link_href: ""
    }
};
