import classNames from "classnames";
import React from "react";
import i18n from "../../lib/i18n";

const t = i18n.namespace("Validation");

export const FormSection = ({children, className}) => {
  return (
    <div className={classNames("form-section", className)}>
      {children}
    </div>
  );
}


export const FormContainer = ({children, className}) => {
  return (
    <div className={classNames("form-container", className)}>
      {children}
    </div>
  );
}

export const FormFieldset = ({children, className}) => {
  return (
    <fieldset className={classNames("form-fieldset", className)}>
      {children}
    </fieldset>
  );
}


export const FormHeadline = ({children, className, headline}) => {
  return (
    <h2 className={classNames("form-headline", className)}>
      {headline}{children}
    </h2>
  );
}

export const FormRow = ({children}) => {
  return (
    <div className="form-row">
      {children}
    </div>
  );
}

export const FormGroup = ({children, htmlFor, label, className, isOptional}) => {
  const labelText = isOptional ? label + ' (' + t("optionalValue") + ')' : label;
  return (
    <div className={classNames("form-group", className)}>
      {label && (htmlFor ? <label className="form-label" htmlFor={htmlFor} dangerouslySetInnerHTML={{ __html: labelText }}/> : <legend className="form-label">{labelText}</legend>)}
      {children}
    </div>
  );
}

export const InputGroup = ({children, prependIcon, appendText}) => {
  return (
    <div className="input-group mb-3">
      {prependIcon &&
       <div className="input-group-prepend">
        <span className="input-group-text input-group-text--icon">{prependIcon}</span>
       </div>
      }
      {children}
      {appendText &&
       <div className="input-group-append">
         <span className="input-group-text">{appendText}</span>
       </div>
      }
    </div>
  );
}

export const ValidationMessage = ({children, message}) => {
  return (
    <div className="invalid-feedback">
      {message}{children}
    </div>
  );
}

export const FormText = ({children, className}) => {
  return (
    <p className={classNames("form-text", className)}>
      {children}
    </p>
  );
}

export const FormInfoBox = ({children, className}) => {
  return (
    <div className={classNames("form-info-box", className)}>
      {children}
    </div>
  );
}

export const FormInfoBoxHeadline = ({children, className, headline}) => {
  return (
    <div className={classNames("form-info-box_headline", className)}>
      {headline}{children}
    </div>
  );
}
