import React, {useEffect, useState} from 'react';

import Menu from "../../components/menu";
import {Page, PageFooter} from "../../components/layout/page";
import Breadcrumbs from "../../lib/breadcrumbs";
import {scrollToPos} from "../../lib/scrollToPos";
import {getDocument} from "../../lib/dataAccess/cms/cmsNodesRestEndpoint";
import {
    getSessionStorageItem,
    COUNTRY_CODE_NAME,
  } from "../../lib/sessionStorage";
  import Loader from "react-loader-spinner";
  import {getFormattedCountryCode} from "../../lib/helper/localizationHelper";
  import {useParams} from "react-router-dom";

const FaqPage = () => {
    const { countryCode } = useParams();
    const cmsCountryCode = getSessionStorageItem(COUNTRY_CODE_NAME)|| getFormattedCountryCode(countryCode);
    const documentPath = cmsCountryCode + "/home/faq";
    const [cmsContent, setCmsContent] = useState();

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    useEffect(() => {
         if(!cmsContent){
            getDocument(encodeURIComponent(documentPath))
            .then(res => {
                setCmsContent(res.data[0].markup);
            })
            .catch(e => {
                setCmsContent("")
            })
        }
    }, [documentPath, cmsContent])

    if(!cmsContent){
        return <div>
            <>
            <div className="homepage">
            <Menu/>
            <div className="homepage_body">
            <span className="loading-spinner-button-loading-spinner">
            <Loader
                type="TailSpin"
                color="#000000"
                height={20}
                width={20}
            />
            </span>
            <PageFooter/>
            </div>
        </div>
        </>
       </div>;
    }
    return <Page>
        <Menu/>
        <div className="page_header container">
            <section className="breadcrumbs">
                <Breadcrumbs/>
            </section>
        </div>
        <div dangerouslySetInnerHTML={{__html: cmsContent}}/>
        <PageFooter/>
    </Page>
}

export default FaqPage;
