module.exports = {
    inputs: {
        label: "Email",
        id: "email",
        name: "email",
        placeholder: "Your Email",
        type: "email"
    },
    success: "Password reset requested, please look into your email inbox!",
    subheadline:"Password forgot",
    headline:"Password forgot",
    submit: "Submit"
}