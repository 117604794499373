module.exports = {
    headline: 'Servizio Aquarea + Un momento di tranquillità',
    text: 'Ci prenderemo cura della vostra pompa di calore in modo che possiate rilassarvi e godervi una casa accogliente e calda.',
    btn: {
        href: '/it_IT/home/service_package',
        text: 'VEDI I NOSTRI PACCHETTI DI SERVIZI'
    },
    campaign: {
        img: {
            url: '/image/home/hero/PaSo-GW.svg',
            alt: 'hero-img-alt-text',
        },
        text: 'Ordina un abbonamento Aquarea Service+ nelle settimane verdi tra l\'8 e il 30 novembre 2021 e ricevi gratuitamente il pacchetto di servizi di installazione, del valore di 1.495 euro. Per ogni abbonamento venduto durante questo periodo, pianteremo anche un albero con Treedom in una zona bisognosa.'
    }
};
