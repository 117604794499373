module.exports = {
    protected: "Sei protetto da Aquarea Service+",
    heatPumpsCorrectly: "",
    callHotline: "Contatta il servizio clienti",
    sendMail: "Contattaci via e-mail",
    gotoCloud: "Vai a Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "comfort",
        comfort: "Aquarea comfort"
    },
    imageTags:{
        exclusive: "Premio Aquerea",
        advance:"Aquerea intelligente",
        smart: "Aquerea di base"
    },
    downgradeInfo1: "Il tuo piano di protezione è programmato per il downgrade a ",
    downgradeInfo2: ". Questo downgrade viene applicato dopo il completamento del periodo contrattuale in corso.",
    contactmail: "panasonic.aircon.it@h1.se",
}