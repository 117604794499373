import React from 'react'
import i18 from "../lib/i18n";

const t = i18.namespace("Newsletter");

 


const Newsletter = () => {
        return (
            <section className="newsletter">
                <div className="newsletter_wrapper container">
                    <div className="newsletter_left">
                        <h2 className="newsletter_headline">
                            {
                                t("headline")
                            }
                        </h2>
                        <p>
                            {
                                t("text")
                            }
                        </p>
                    </div>
                    <div className="newsletter_right">
                        <input type="text" placeholder={t("input_placeholder")}/>
                        {/*<button>{t("btn_labl")}</button>*/}
                        <a href={t("newsletterlink")} rel='noreferrer' target="_blank">{t("btn_labl")}</a>
                    </div>
                </div>
            </section>
        )
}

export default Newsletter;
