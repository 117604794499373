module.exports = {
    headline: "Välkommen tillbaka",
    subheadline: "Välkommen tillbaka",
    inputs: [
        {
            label: "E-post",
            id: "email",
            name: "email",
            placeholder: "Email",
            type: "email"
        },
        {
            label: "Lösenord",
            id: "password",
            name: "password",
            password_placeholder: "Lösenord",
            type: "password"
        }
    ],
    btn: "Inloggning",
    link: "Glömt lösenord",
    link_href: "/sv_SE/home/password_forgot",
    newProtectionPlan: {
        label: "Behöver du ett servicepaket?",
        link: "Beställ ett servicepaket",
        link_href: "/sv_SE/home/service_package",
    }
}