module.exports = {
    upgradeDiscount: {
        name: "PROMOZIONE",
        inputPlaceholder: "Inserire codice promozione",
        applyBtnLable: "applicare",
        removeBtnLable: "rimuovere",
        invalidHeadline: "Il codice inserito non è valido",
        invalidHint: "Per favore, controlla il tuo input",
        errors: {
            tooLong: "Il codice di sconto inserito è troppo lungo.",
            invalidInput: "Il codice inserito non è valido" // upgrade page
        }
    }
};