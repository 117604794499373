import React, { useContext, useEffect, useState } from "react";
import { PageBreadcrumb, PageHeader, PageHeadline } from "../layout/page";
import { FormContainer, FormSection } from "../layout/form";
import i18 from "../../lib/i18n";
import UpgradeCheckoutConfirmationStep from "./upgradeCheckoutConfirmationStep";
import { UpgradeContractContext } from "../../lib/contexts/upgradeContractContext";
import { scrollToPos } from "../../lib/scrollToPos";
import { CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME,
    getSessionStorageItem,
    ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME,
    CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    removeSessionStorageByName } from "../../lib/sessionStorage";
import { postContractUpgradeForOrder, postContractUpgradeForOrderWithCart } from "../../lib/dataAccess/checkout/orderRestEndpoint";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AuthContext } from "../../lib/contexts/authContext";
import {getCartFromServer} from '../../lib/dataAccess/checkout/cartRestEndpoint';
import { ErrorResponseHandling } from "../../lib/dataAccess/errorResponseHandling";
import { CartContext } from "../../lib/contexts/cartContext";
import { ProductContext } from "../../lib/contexts/productContext";
import {getProductByVariant} from "../../lib/helper/productHelper";

const t = i18.namespace("UpgradeCheckoutForm");
// const p = i18.namespace("PlanCard");

function UpgradeCheckoutForm() {
    const [isLoading, setIsLoading] = useState(false);
    const { upgradeContractContext, setUpgradeContractContext } = useContext(UpgradeContractContext);
    const { cartContext, setCartContext } = useContext(CartContext);
    const { authContext, setAuthContext } = useContext(AuthContext);
    const { productContext } = useContext(ProductContext);

    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

    const history = useHistory();

    useEffect(() => {
        if(checkIfAuthContextIsLoaded()){
            getCart();
        }
        scrollToPos(0, 0);
    }, [authContext]) // eslint-disable-line react-hooks/exhaustive-deps

    try {
        useEffect(() => {
            if (cartContext?.lineItems?.find(lineItem => lineItem.sku.startsWith("contract-"))) {
                let baseUnit = authContext.accountData.installedBaseUnits.find(x => x.id === cartContext.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.installedBaseUnitId);
                let connectionFee = cartContext.lineItems?.find(lineItem => lineItem.sku === 'internet-connection-service-package');
                setUpgradeContractContext({
                    orderId: "",
                    cartId: cartContext.id,
                    upgradeSku: getProductByVariant(productContext, cartContext.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku),
                    upgradeVariantSku: cartContext.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku,
                    baseUnit: baseUnit,
                    feeRequired: connectionFee !== undefined
                });
            }
        }, [cartContext, authContext?.accountData?.installedBaseUnits, productContext, setUpgradeContractContext])
    } catch (e) {
        ErrorResponseHandling(e, authContext, setAuthContext, history);
    }

    const checkIfAuthContextIsLoaded = () => {
        if (!authContext?.accountData) {
            return false;
        };
        return true;
    }

    async function getCart(){
        await getCartFromServer(null, authContext.authToken).then((result) => {
            if(result.data.type === "UPGRADE" && result.data.lineItems.length > 0){
                setCartContext(result.data);
            } else {
                setUpgradeContractContextFromSession()
            }
        }).catch(e => {
            setUpgradeContractContextFromSession()
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        });
    };

    const setUpgradeContractContextFromSession = () =>{
        const orderId = getSessionStorageItem(ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME);
        const baseUnit = authContext.accountData.installedBaseUnits
        .find(unit => unit?.orderData?.orderId === orderId);
        setUpgradeContractContext({
            orderId: orderId,
            upgradeSku: getSessionStorageItem(CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME),
            upgradeVariantSku: getSessionStorageItem(CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME),
            baseUnit:baseUnit,
            cartId:"",
            feeRequired: !baseUnit.internetConnected && baseUnit.orderData?.lineItems?.length < 2
        });
    }

    const handleConfirmButtonClick = async () => {
        setIsLoading(true);

        try {
            if (upgradeContractContext.cartId) {
                await postContractUpgradeForOrderWithCart(upgradeContractContext.cartId, authContext.authToken)
                    .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    });
            } else {
                await postContractUpgradeForOrder(upgradeContractContext.orderId,
                    upgradeContractContext.upgradeSku, authContext.authToken)
                    .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    });
            }
        } catch (e) {
            ErrorResponseHandling(e, authContext, setAuthContext, history);
        }
        removeSessionStorageByName(ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME);
        removeSessionStorageByName(CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME);
        removeSessionStorageByName(CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME);

        history.push('/'+ countryCode +'/home/upgrade_success');
    }

    if(!upgradeContractContext?.baseUnit){
        return null;
    }
    return (
        <div className="registration checkout step-form">
            <PageHeader>
                <PageBreadcrumb />
                <PageHeadline headline={t("headline")} />
            </PageHeader>

            <div className="container checkout_page">
                <FormSection className="checkout_page_content">
                    <FormContainer>
                        <UpgradeCheckoutConfirmationStep
                            selectedPlanSku={upgradeContractContext?.upgradeVariantSku}
                            onConfirmButtonClick={handleConfirmButtonClick}
                            isLoading={isLoading}
                            baseUnit={upgradeContractContext?.baseUnit}
                            feeRequired={upgradeContractContext?.feeRequired}
                        />
                    </FormContainer>
                </FormSection>
            </div>

        </div>
    )
}

export default UpgradeCheckoutForm;
