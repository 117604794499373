import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../lib/contexts/authContext";
import StepButtons from "../stepForm/stepButtons";
import CheckoutSummary from "./checkoutSummary";
import i18n from "../../lib/i18n";
import {AUTH_TOKEN_SESSION_STORAGE_NAME, getSessionStorageItem} from "../../lib/sessionStorage";
import {updateDiscountCodeForUpgrade} from "../../lib/dataAccess/checkout/cartRestEndpoint";
import {ErrorResponseHandling} from "../../lib/dataAccess/errorResponseHandling";

import {useHistory} from "react-router-dom/cjs/react-router-dom.min";


const t = i18n.namespace("SummaryList");
const d = i18n.namespace("UpgradeDiscountCheckoutSummary")



function UpgradeCheckoutConfirmationStep({ paymentMethodId,
                                             selectedPlanSku,
                                             onConfirmButtonClick,
                                             isLoading, baseUnit,
                                             feeRequired }) {
    const [summaryData, setSummaryData] = useState(null);

    const [discountCode, setDiscountCode] = useState("");
    const [appliedDiscountCode, setAppliedDiscountCode] = useState("");
    const [isInvalidDiscountCode, setIsInvalidDiscountCode] = useState(false);
    const [discountError, setDiscountError] = useState(null);
    const history = useHistory();

    const { authContext, setAuthContext } = useContext(AuthContext);

    useEffect(() => {
        if(authContext?.accountData) {
            setSummaryData(generateSummaryData());
        }
    }, [authContext]) // eslint-disable-line react-hooks/exhaustive-deps

    const generateSummaryData = () => {
        return {
            connectionCheck: {
                internetConnected: baseUnit.internetConnected,
                internetReady: baseUnit.internetReady,
                feeRequired: feeRequired
            },
            installedBase: {
                model: baseUnit.deviceType,
                serialNumber: baseUnit.serialNumber,
                gatewayId: baseUnit.gatewayId,
                installationDate: baseUnit.installationDate,
            },
            account: {
                title: authContext.accountData.title,
                firstName: authContext.accountData.firstName,
                lastName: authContext.accountData.lastName,
                email: authContext.accountData.email
            },
            address: {
                street: authContext.accountData.addresses[0].street,
                zipCode: authContext.accountData.addresses[0].zipCode,
                city: authContext.accountData.addresses[0].city,
                phoneNumber: authContext.accountData.addresses[0].phoneNumber
            },
            payment: {}
        };
    }

    async function validateDiscountCode(overwriteDiscountCode) {
        const dCode = overwriteDiscountCode === "overwrite" ? overwriteDiscountCode : discountCode.trim();

        if (dCode.length > 30) {
            setDiscountError(d("upgradeDiscount.errors.tooLong"));
            return discountError
        }

        if (dCode.length === 0) {
            setDiscountError(d("upgradeDiscount.errors.invalidInput"));
            return discountError;
        }

        if (dCode.length > 0 && dCode.length < 31) {
            const authToken = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);
            updateDiscountCodeForUpgrade(dCode,  authToken).catch(e => {
                ErrorResponseHandling(e, authContext, setAuthContext, history)
            }).then((response) => {
                console.log("setDiscount result", response.data)
                if(response.data.success){
                    setAppliedDiscountCode(discountCode)
                    setIsInvalidDiscountCode(false)
                }
                else if(!response.data.success){
                    setAppliedDiscountCode("")
                    if(overwriteDiscountCode !== "overwrite"){
                        setIsInvalidDiscountCode(true)
                    } else {
                        setIsInvalidDiscountCode(false)
                    }
                }

            });
            return null;
        }
        throw Error("unexpected state - we should not reach this");
    }

    return (
        <div className="registration_step_content">
            {summaryData &&
                <CheckoutSummary
                    summaryData={generateSummaryData()}
                    paymentMethodId={paymentMethodId}
                    selectedPlanSku={selectedPlanSku}
                />
            }

            <div className={"form-group discount"}>
                <div className="form-check form-check-inline">
                    <h3 className={"name"}>{d("upgradeDiscount.name")}</h3>
                    {!appliedDiscountCode && <>
                        <input className={"form-control"} type="text" id={"discount"}
                               name={"discount"} key={"discount-input"}
                               placeholder={ d("upgradeDiscount.inputPlaceholder")}
                               onChange={(event) => {
                                   if (!!event.target.value) {
                                       setDiscountCode(event.target.value)
                                   }
                               }}/>
                        <button className="btn-primary"
                                key={"discount-button"}
                                onClick={validateDiscountCode}>
                            {d("upgradeDiscount.applyBtnLable")}
                        </button>
                    </>
                    }
                    {appliedDiscountCode && <>
                        <span className={"form-control discount"}>{discountCode}</span>
                        <button className="btn-primary"
                                key={"discount-button"}
                                onClick={() => {
                                    validateDiscountCode("overwrite")
                                }}>
                            {d("upgradeDiscount.removeBtnLable")}
                        </button>
                    </>
                    }
                </div>
                {isInvalidDiscountCode &&
                    <div className="form-info-box">
                        <div className="form-info-box_headline">{d("upgradeDiscount.invalidHeadline")}
                            <p>{d("upgradeDiscount.invalidHint")}</p>
                        </div>
                    </div>}
                <div className={"message"}>{discountError === null ? "" : discountError}</div>
            </div>

            <StepButtons
                showBackButton={false}
                nextButtonEnabled={!!summaryData}
                nextButtonOnClick={onConfirmButtonClick}
                nextButtonText={t("confirm")}
                btnAlignment="justify-content-center"
                isLoading={isLoading}
            />
        </div>
    )
}

export default UpgradeCheckoutConfirmationStep;