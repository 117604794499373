import axios from "axios";
import {getHeaderForApiRequest} from "../dataAccessHelper";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_BASE_URL;

export function postRegistrationRequest(registrationData) {
    return axios.post(BASE_URL + '/customer/registration', registrationData , {
        headers: getHeaderForApiRequest()
    });
}

export function postEmailVerificationRequest(mailVerificationToken) {
    const mailVerificationData = {
        token: mailVerificationToken
    };

    return axios.post(BASE_URL + '/customer/verification', mailVerificationData, {
        headers: getHeaderForApiRequest()
    });
}

export function logInUser(loginRequestData) {
    return axios.post(BASE_URL + '/customer/login', loginRequestData, {
        headers: getHeaderForApiRequest()
    });
}

export async function registerUser(registrationData) {
    const registrationResponse = await postRegistrationRequest(registrationData);
    return await postEmailVerificationRequest(registrationResponse.data.token);
}

export function updateUser(){
    return axios.post(BASE_URL + '/customer/update',  {
        headers: getHeaderForApiRequest()
    });
}

// TODO: im body token + neues password
export async function changePassword(body) {
    return axios.put(BASE_URL + '/customer/password-change', body, {
        headers: getHeaderForApiRequest()
    })
}

export async function forgotPassword(body) {
    return axios.post(BASE_URL + '/customer/password-forgotten', body, {
        headers: getHeaderForApiRequest()
    })
}