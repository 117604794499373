module.exports = {
  success: {
    title: "Grazie!",
    headline: "Il pagamento è andato a buon fine!",
    subheadline: "Grazie mille, ",
    btn: "VAI AL MIO ACCOUNT",
  },
  failure: "Il pagamento è stato rifiutato dal nostro sistema di pagamento.",
  cancel: "Il pagamento è stato annullato.",
  error: "Si è verificato un errore durante il pagamento presso il nostro sistema partner.",
  expired: "La sessione di pagamento è scaduta presso il nostro sistema di partner di pagamento.",
  pending: "Il pagamento è ancora in corso presso il nostro sistema di pagamento. Questo può richiedere fino a 10 minuti.",
  link: "VAI AL MIO ACCOUNT",
  link_href: "/it_IT/home/my_account",
};