import React, {useEffect} from 'react';
import Menu from "../components/menu";
import {Page, PageFooter} from "../components/layout/page";
import UgradeChoices from '../components/upgradeContract/upgradeChoices';
import {scrollToPos} from "../lib/scrollToPos";

function UpgradeContract() {

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    return (
        <Page>
            <Menu/>
            <UgradeChoices/>
            <PageFooter/>
        </Page>
    )
}

export default UpgradeContract;
