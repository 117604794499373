/* lib/validation.js
* return true if validation is invalid
* return false if the validation was valid
*/

export const requiredInput = value => {
    return value.trim().length <= 0;
}

export const passwordValidation = value => {
    return !/^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$/.test(value);
}

export const emailValidation = email => {
    if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
        return false;
    }
    if (email.trim() === '') {
        return true;
    }
    return true;
};

export const sameValueValidation = (first, last) => {
    return first !== last;
};