module.exports = {
    title: "Opsigelse or Downgrade",
    headline: "Vi er kede af, at du vil opsige dit abonnement.",
    btnReturnToProfile: "Tilbage til profil",
    cancelSectionTitle: "Annuller abonnement",
    cancelText: "Ønsker du virkelig at opsige Aquarea Service+ abonnementspakken?",
    btnCancelSubscription: "Ja, opsig mit abonnement",
    downgradeSectionTitle: "Nedjustere dit abonnement",
    downgradeText: "Følgende muligheder for nedgradering er tilgængelige:",
    btnDowngradeOrder: "Nedgradere mit abonnement",
    downgradeOptions: {
        silver: "Nedgradering til Aquarea Service+ SMART-pakken",
        bronze: "Nedgradering til Aquarea Service+ BASIC-pakken"
    }
}
