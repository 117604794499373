import React from "react";
import i18 from "../../lib/i18n";
import {SectionHeadline} from "../layout/section";
import {
  getSessionStorageItem,
  COUNTRY_CODE_NAME,
  AUTH_TOKEN_SESSION_STORAGE_NAME,
} from "../../lib/sessionStorage";
import {getDowngradeInfo} from "../../lib/dataAccess/accountService";
import {OptionalValue} from "../layout/detailEntry";

let countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
if (countryCode === 'en_ES') {
  countryCode = 'es_ES'
}
const externalLinkCountryCode = countryCode.substring(3,5) + "_" + countryCode.substring(0,2);

const t = i18.namespace("MyAccountProtectionPlan");

const HOTLINE_SITE = "https://www.aircon.panasonic.eu/"+ externalLinkCountryCode +"/contact/";
const CLOUD_SITE = "https://aquarea-smart.panasonic.com/";


export default class YourProtectionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      isLoaded: false,
      bgClass: "",
      planImage: "",
      baseUnit: props.baseUnit,
      downgradeInfo: ""
    };
  }

  componentDidMount() {
    this.fetchDowngradeInfo();
    this.renderCardImage();
  }

  openHotline() {
    window.location.href = HOTLINE_SITE;
  }

  sendMail() {
    window.location = "mailto:" + t("contactmail");
  }

  openCloud() {
    window.location.href = CLOUD_SITE;
  }

  fetchDowngradeInfo = async () => {
    // TODO bei Daniel nachfragen!!!! Das ist wohl nicht korrekt
    if(this.state.downgradeInfo === "") {
      await getDowngradeInfo(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME), this.state.baseUnit?.orderData?.orderId)
          .then((result) => {
              this.setState({
                downgradeInfo: result.data
              });
          })
          .catch((error) => {
            console.log("Something went wrong: ", error);
          })
    }
  }

  renderCardImage = () => {
    let contractType = this.state.baseUnit?.orderData?.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku.split('-')[2];
    switch (contractType) {
      case "gold":
        this.setState({ bgClass: "yellow", planImage: t("imageName.yellow")});
        return;
      case "silver":
        this.setState({ bgClass: "green", planImage: t("imageName.green") });
        return;
      case "bronze":
        this.setState({ bgClass: "brown", planImage: t("imageName.brown") });
        return;
      default:
        return null;
    }
  };

  render() {
    return (
      <>
        <div className="your-protection-plan">
          <div className="d-flex your-protection-plan_content flex-column flex-md-row">
            <div
              className={`your-protection-plan_tile plan-tile plan-tile--${this.state.bgClass}`}
            >
              <img
                className="plan-tile_icon"
                src={
                  this.state.planImage
                    ? `/image/plan_cards/aquarea_service+_${this.state.planImage}.svg`
                    : ""
                }
                alt={t(`imageTags.${this.state.planImage}`)}
              />
            </div>
            <SectionHeadline>
              {t("protected")}
            </SectionHeadline>
          </div>
          <div className="d-flex your-protection-plan_btn-group flex-column flex-xl-row mb-3">
            <div className="d-flex">
              {/*<button*/}
              {/*  className="btn-primary mr-2 mb-xl-0 mb-2 flex-fill"*/}
              {/*  onClick={() => this.openHotline()}*/}
              {/*>*/}
              {/*  {t("callHotline")}*/}
              {/*</button>*/}
              <a href={"https://www.aircon.panasonic.eu/"+ externalLinkCountryCode +"/contact/"} target="_blank" rel="noopener noreferrer" className="btn-primary mr-2 mb-xl-0 mb-2 flex-fill">
                {t("callHotline")}
              </a>
              <button
                className="btn-primary mr-xl-2 mb-xl-0 mb-2 flex-fill"
                onClick={() => this.sendMail()}
              >
                {t("sendMail")}
              </button>
            </div>

            <button
              className="btn-secondary btn-secondary--link ml-xl-auto"
              onClick={() => this.openCloud()}
            >
              {t("gotoCloud")}
            </button>
          </div>
        </div>
        <OptionalValue condition={this.state.downgradeInfo?.displayName} emptyValue="">
          <div className="dowgradeinfo_box infoText_narrow">
            {t("downgradeInfo1")}<em className="dowgradeinfo_em">{this.state.downgradeInfo.displayName}</em>{t("downgradeInfo2")}
          </div>
        </OptionalValue>
      </>
    );
  }
}
