import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {CartContext} from "../../../lib/contexts/cartContext";
import { ProductContext } from "../../../lib/contexts/productContext";
import {AuthContext} from "../../../lib/contexts/authContext";
import {updatePaymentMethod,
    updateCampaign,
    updateDiscountCode,
    changeCartContract,
    getIsCampaign } from "../../../lib/dataAccess/checkout/cartRestEndpoint";
import { ErrorResponseHandling } from "../../../lib/dataAccess/errorResponseHandling";
import {formatMoney} from "../../../lib/helper/productHelper";
import { formatMoneyByCountryCode } from "../../../lib/helper/moneyHelper";
import {
    COUNTRY_CODE_NAME,
    AUTH_TOKEN_SESSION_STORAGE_NAME,
    getSessionStorageItem
} from "../../../lib/sessionStorage";
import StepButtons from "../stepButtons";
import {exceptedPaymentMethods} from "./types/paymentMethods";
import {scrollToPos} from "../../../lib/scrollToPos";
import {OptionalValue} from "../../layout/detailEntry";
import { sendGTMDataLayer } from "../../../lib/tracking/initGTM";

function PaymentDetailsStep(props) {
    const { setCartContext} = useContext(CartContext);
	const { productContext, setProductContext } = useContext(ProductContext);
    const { authContext, setAuthContext } = useContext(AuthContext);
    const [chosenPaymentMethodState, setChosenPaymentMethodState] = useState(null);
    const [chosenPaymentPeriodState, setChosenPaymentPeriodState] = useState(productContext.selectedVariant);
    const [isLoading, setIsLoading] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [policy, setPolicy] = useState(false);
    const [isCampaign, setIsCampaign] = useState(false);
    const [campaign, setCampaign] = useState(false);
    const [discountCode, setDiscountCode] = useState("");
    const [appliedDiscountCode, setAppliedDiscountCode] = useState("");
    const [isInvalidDiscountCode, setIsInvalidDiscountCode] = useState(false);
    const [discountError, setDiscountError] = useState(null);
    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
    
    //TODO remove workaround to override payment
    const overridePaymentMethod = window.location.href.indexOf("devHiddenSePayPal=yes") > 0 ? "paypal" : "";

    const history = useHistory();

    useEffect(() => {
        getIsCampaign(CartContext.authToken).then(response => {
            setIsCampaign(response.data.isCampaign)
        })
    });

    useEffect(() => {
        scrollToPos(100, 100);
        sendGTMDataLayer({
          event: "pageview",
          category: "Checkout-4Payment",
          eventModel: {
            event_category: "Checkout-4Payment",
          },
        });
    }, []);

    async function handleNextStepButtonClick(event) {
        if (event == null) {
            return;
        }

        event.preventDefault();
        setIsLoading(true);

        if ((!chosenPaymentMethodState || !chosenPaymentPeriodState) && agreement) {
            setIsLoading(false);
            return;
        }

        sendGTMDataLayer({
          event: "selected payment " + chosenPaymentMethodState,
          category: "Checkout-4Payment-NextStep",
          eventModel: {
            event_category: "Checkout-4Payment-NextStep",
          },
        });
            
        const authToken = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);

        try {
            //TODO remove workaround to override payment
            let overriddenPaymentMethod = overridePaymentMethod || chosenPaymentMethodState;
            await updatePaymentMethod(overriddenPaymentMethod, authToken);
            await updateCampaign(campaign, authToken);
            const changeCartContractResponse =
                await changeCartContract(chosenPaymentPeriodState, true, '', authToken)
                    .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    })
            setCartContext(changeCartContractResponse.data);
        } catch (error) {
            props.navigation.setStep("error");
        }

        props.navigation.goNext();
    }

    function handleChosenPayment(event, chosenPaymentMethodId) {
        setChosenPaymentMethodState(chosenPaymentMethodId);
    }

    function handleChosenPaymentPeriod(event, chosenPaymentPeriodId) {
        setChosenPaymentPeriodState(chosenPaymentPeriodId);
        setProductContext(prevState => {
            return {...prevState, selectedVariant: chosenPaymentPeriodId}
        });
    }

    function handleAgreement(event) {
        setAgreement(event.target.checked);
    }

    function handlePolicy(event) {
        setPolicy(event.target.checked);
    }

    function handleCampaign(event) {
        setCampaign(event.target.checked);
    }

    function getSelectableVariants(){
        const selectedProduct = productContext.plans.find(value => value.sku === productContext.selectedPlan);
        if(!selectedProduct){
            return [];
        }
        const variants = productContext.plans.find(value => value.sku === productContext.selectedPlan).variants;
        let sortedVariants = [];
        for (var i in variants) {
            sortedVariants.push(variants[i]);
        }

        sortedVariants.sort(function(a, b) {
            return b.price.amount - a.price.amount;
        });

        return sortedVariants;
    }

    function drawMonthlyPrice(variant) {
        if (variant.paymentPeriod === "monthly") {
            return " "
        } else {

            return `${formatMoney(variant.pricePerMonth.amount, variant.pricePerMonth.currency)} ${props.currentStepValues.per_month}`
        }
    }

    function renderCampaign() {
        if (isCampaign) {
            return <div className="form-group">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" id="campaign"
                           onChange={(event) => handleCampaign(event)}
                           type="checkbox"
                           checked={campaign}
                    />
                    <label className="form-check-label form-check-agreement-period-label"
                           htmlFor="campaign" dangerouslySetInnerHTML={{__html: props.currentStepValues.campaign}}/>
                </div>
            </div>
        }
    }

    async function validateDiscountCode(overwriteDiscountCode) {
        const dCode = overwriteDiscountCode === "overwrite" ? overwriteDiscountCode : discountCode.trim();

        if (dCode.length > 30) {
            setDiscountError(props.currentStepValues.discount.errors.tooLong);
            return discountError
        }

        if (dCode.length === 0) {
            setDiscountError("no discount entered");
            return discountError;
        }

        if (dCode.length > 0 && dCode.length < 31) {
            const authToken = getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);
            updateDiscountCode(dCode,  authToken).catch(e => {
                ErrorResponseHandling(e, authContext, setAuthContext, history)
            }).then((response) => {
                console.log("setDiscount result", response.data)
                if(response.data.success){
                    setAppliedDiscountCode(discountCode)
                    setIsInvalidDiscountCode(false)
                }
                else if(!response.data.success){
                    setAppliedDiscountCode("")
                    if(overwriteDiscountCode !== "overwrite"){
                        setIsInvalidDiscountCode(true)
                    } else {
                        setIsInvalidDiscountCode(false)
                    }
                }

            });
            return null;
        }
        throw Error("unexpected state - we should not reach this");
    }

    return (
        <div className="registration_step_content">
            <div className="mb-5">
            <h3>{props.currentStepValues.subheadline}</h3>
                <div className="form-group form-group-price-large" id="payment-model">
                    {getSelectableVariants().map((variant, index) =>
                        <div className="form-check form-check-box" key={variant.sku}>
                            <input id={variant.sku}
                               key={variant.sku}
                               onChange={(event) => handleChosenPaymentPeriod(event, variant.sku)}
                               type="radio"
                               checked={chosenPaymentPeriodState === variant.sku}
                               value={variant.sku}
                               />
                            <label className="form-check-label form-check-payment-period-label" htmlFor={variant.sku}>
                                <b>{props.currentStepValues.pay} {formatMoneyByCountryCode(variant.price.amount, countryCode)} {props.currentStepValues[variant.paymentPeriod]}</b><br/>
                            </label>
                            <span className={"form-check-span"}>{drawMonthlyPrice(variant)}</span>
                    </div>)}
                </div>

                <div className={"form-group discount"}>
                    <div className="form-discount-content">
                        <h3 className={"name"}>{props.currentStepValues.discount.name}</h3>
                        <div className="form-discount-input-group">
                        {!appliedDiscountCode && <>
                            <input className={"form-control"} type="text" id={"discount"}
                                name={"discount"} key={"discount-input"}
                                placeholder={props.currentStepValues.discount.placeholder}
                                onChange={(event) => {
                                    if (!!event.target.value) {
                                        setDiscountCode(event.target.value)
                                    }
                                }}/>
                            <button className="btn-primary"
                                    key={"discount-button"}
                                    onClick={validateDiscountCode}>
                                {props.currentStepValues.discount.button}
                            </button>
                        </>
                        }
                        {appliedDiscountCode && <>
                            <span className={"form-control discount"}>{discountCode}</span>
                            <button className="btn-primary"
                                    key={"discount-button"}
                                    onClick={() => {
                                        validateDiscountCode("overwrite")
                                    }}>
                                {props.currentStepValues.discount.remove}
                            </button>
                        </>
                        }
                        </div>
                    </div>
                    {isInvalidDiscountCode &&
                            <div className="form-info-box">
                                <div className="form-info-box_headline">{props.currentStepValues.discount.invalidHeadline}
                                <p>{props.currentStepValues.discount.invalidHint}</p>
                                </div>
                            </div>}
                    <div className={"message"}>{discountError === null ? "" : discountError}</div>
                </div>

                <OptionalValue condition={appliedDiscountCode} emptyValue="">
                 <div className="form-check form-check-inline">
                    <img className="payment_methods_check" styles="margin-left: 10px;margin-bottom: 30px;margin-right: 20px;" src="/image/icons/checkout/check.png" alt="check"></img>
                    <p className="payment_methods_bold">{props.currentStepValues.discount.success}</p>
                    </div>
                </OptionalValue>

                <OptionalValue condition={productContext.selectedPlan ==='contract-heatpump-gold'} emptyValue="">
                    <p className="planCard_list_text">{props.currentStepValues.disclaimer}</p>
                </OptionalValue>
                <h3>{props.currentStepValues.text}</h3>
                 <div className="form-group">
                    {exceptedPaymentMethods(countryCode).map((paymentMethod, index) =>
                        <div className="form-check form-check-inline" key={index}>

                            <input className="form-check-input" id={paymentMethod.id}
                                   key={paymentMethod.id}
                                   onChange={(event) => handleChosenPayment(event, paymentMethod.id)}
                                   type="checkbox"
                                   checked={chosenPaymentMethodState === paymentMethod.id}
                                   value={paymentMethod.id}
                            />
                            <label className="form-check-label" htmlFor={paymentMethod.id}><img
                                src={paymentMethod.image} alt={paymentMethod.label}/></label>
                        </div>
                    )}
                </div>

                <div className="row payment_methods_text">
                    <small>{props.currentStepValues.credit_secure}</small>
                </div>
                <div className="row payment_methods_text">
                    <img className="payment_methods_img_main_card" src={props.currentStepValues.visa_secure} alt={props.currentStepValues.visa_secure_alt}/>
                    <img className="payment_methods_bigimg_main_card" src={props.currentStepValues.mastercard_id_check} alt={props.currentStepValues.mastercard_id_check_alt}/>
                </div>



                <div className="form-group">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="agreement"
                               onChange={(event) => handleAgreement(event)}
                               type="checkbox"
                               checked={agreement}
                        />
                        <label className="form-check-label form-check-agreement-period-label"
                               htmlFor="agreement"
                               dangerouslySetInnerHTML={{__html: props.currentStepValues.agreement}}/>
                    </div>
                </div>

                <div className="form-group">
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" id="policy"
                               onChange={(event) => handlePolicy(event)}
                               type="checkbox"
                               checked={policy}
                        />
                        <label className="form-check-label form-check-agreement-period-label"
                               htmlFor="policy" dangerouslySetInnerHTML={{__html: props.currentStepValues.policy}}/>
                    </div>
                </div>

                {renderCampaign()}
            </div>

            <StepButtons
                showBackButton={true}
                goBackToProtectionPlan={false}
                backButtonHref={null}
                backButtonOnClick={props.navigation.goPrev}
                backButtonLabel={props.currentStepValues.href_label}
                nextButtonEnabled={!!chosenPaymentPeriodState &&!!chosenPaymentMethodState && agreement && policy && !isLoading}
                nextButtonOnClick={handleNextStepButtonClick}
                nextButtonText={props.currentStepValues.next_btn}
                isLoading={isLoading}
            />
        </div>
    )
}

export default PaymentDetailsStep;
