/**
 * Observable based scrollIntoView that completes after scrolling has been done
 * @param {string} selector element it should scroll to
 * @param {ScrollIntoViewOptions} options ScrollIntoViewOptions adjust the scrolling behaviour
 * the block option decides where the element will be vertically aligned inside the visible area of its scrollable ancestor:
 * Using {block: "start"}, the element is aligned at the top of its ancestor.
 * Using {block: "center"}, the element is aligned at the middle of its ancestor.
 * Using {block: "end"}, the element is aligned at the bottom of its ancestor.
 * Using {block: "nearest"}, the element:
 * aligned at the top of its ancestor if you're currently below it.
 * is aligned at the bottom of its ancestor if you're currently above it.
 * stays if it's already in view.
 * Using {inline: "start"}, the element is aligned at the left of its ancestor.
 * Using {inline: "center"}, the element is aligned at the centre of its ancestor.
 * Using {inline: "end"}, the element is aligned at the right of its ancestor.
 * Using {inline: "nearest"}, the element:
 * is aligned at the left of its ancestor if you're currently on its right.
 * is aligned at the right of its ancestor if you're currently on its left.
 * stays if it's already in view.
 *
 */
export function scrollToElement(selector, options= {}) {
  const el = document.querySelector(selector);

  if (el) {
    document.querySelector(selector).scrollIntoView({behavior: "smooth", block: "center", ...options});
  }
}
