module.exports = {
    headline: "Opgrader valg",
    currentProtectionPlan: "Nuværende servicepakke",
    protectionPlan: "Servicepakke",
    validUntil: "Kontrakt forfalder den:",
    costs: "Omkostninger",
    period: "Betalingsperiode",
    monthly:"om måneden",
    quarterly:"pr. kvartal",
    annually:"årligt",
    notUpgradableText: "Du kan ikke opgradere fra din nuværende kontrakt.",
    backButtonText: "Tilbage til min konto"
};