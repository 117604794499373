module.exports = {
    reports: "Relator",
    headline: "Aquarea Service+ Driftsrapport",
    table: {
        headers: {
            date: "dato",
            report: "informe operativo",
        },
        showAll: "tornillos para informar",
        hideOlder: "ocultar informes antiguos",
    },
    download: "Descargue el informe sobre ella"
}
