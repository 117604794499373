module.exports = {
    yourProtectionPlan: "DITT SERVICEPAKET",
    contract: "Aquarea Service+ kontrakt",
    invoiceHistory: "Betalningshistorik",
    accountInformation: "Kontoinformation",
    reports: "Rapportera",
    welcome: "Välkommen, ",
    noServicePackage: "Inget servicepaket ännu",
    editAccount: "Rediger konto",
    finishCheckout: "Ofullständigt köp",
    gotoCheckout: "Avsluta ett ofullständigt köp",
    finishUpgrade: "Slutförande av orderuppfyllelse",
}
