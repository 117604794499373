import React, {useEffect, useState} from 'react';

import i18 from "../lib/i18n";
import MenuTop from "../components/landingPage/menuTop";
import Footer from "../components/footer";
import {getGeoLocalization} from "../lib/geolocalization";

const t = i18.namespace("Menu");

const LandingPage = (props) => {

    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        getGeoLocalization()
            .then((res) => {
                onGeoLocalizationSuccess(res)
                setLoading(false)
            })
            .catch((err) => {
                console.log("geo location error: " + err.message);
                setLoading(false)
            });

        function onGeoLocalizationSuccess(response) {
            const countrylinks = [
                ["dk", "da_DK"],
                ["es", "es_ES"],
                ["ie", "en_IE"],
                ["it", "it_IT"],
                ["se", "sv_SE"],
            ];

            var visitorcountryCode =  response.data.country_code;;
            var visitorcountryCodetoLower = visitorcountryCode.toLowerCase();
            var redirectLocale = "";

            for (const line of countrylinks) {
                if (visitorcountryCodetoLower === line[0].toString()) {
                    redirectLocale = line[1];
                    break;
                }
            }

            if(redirectLocale !== "") {
                goToCountry(redirectLocale)
            } else {
                console.error("No valid country code received. Landing page now rendered for manually decision.");
            }
        }

    });

    function goToCountry(link) {
        props.history.push('/'+ link +'/home')
        window.location.reload();
    }

    return <>
        {!isLoading && 
        <div className="landingpage">
                <MenuTop/>
                <div className="homepage_body light-gray">
                    <div className={"center-box"}>
                        <h1>{"Please choose your country"}</h1>
                        <div className="localization-wrapper">
                            <ul className={"localization-picker"}>
                                {t("languages").map((country, i) => {
                                    return <li key={i}
                                               className={"localization-picker-element"}
                                               onClick={() => goToCountry(country.link)}>
                                        <img className={"flag"} src={country.flag} alt={country.name + "_img"} />
                                        <p className={"country-name"}>{country.name}</p>
                                    </li>
                                })}
                            </ul>
                            <div className="hint">The services and products offered in this web shop are only available in these shown countries</div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        }
        {isLoading && <div></div>}
    </>
}


export default LandingPage;
