module.exports = {
    editBtn: "Redigera",
    confirmBtn: "Köp servicepaket",
    connectionCheck: "Kontrollera anslutningen",
    accountInformation: "Information om konton",
    paymentDetails: "Betalningsmetod",
    installedBase: "Information om enheten",
    monthly:"månadsvis",
    quarterly:"per kvartal",
    annually:"årligen",
    yes: "Ja",
    no: "Ingen",
    details: {
        monthlyPrice:"Månatliga kostnader",
        connectionFeePrice:"Kostnader för installationstjänster av engångskaraktär",
        internetConnected: "Har du installerat en molnadapter?",
        internetReady: "Är enheten redo att anslutas?",
        modelName: "Modell",
        serialNumber: "Serienummer",
        installationDate: "Installationsdato",
        gatewayId: "Gateway-ID",
        title: "titel",
        firstName: "Förnamn",
        lastName: "Efternamn",
        name: "Namn",
        email: "E-post",
        address : "Adress",
        zipCode : "Postnummer",
        city: "Ort",
        phoneNumber: "Telefonnummer",
        phone : "Telefonnummer",
        paymentMethod: "Betalningsmetod"
    },
    confirm: "bekräfta"
}
