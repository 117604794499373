module.exports = {
  headline: "Grandi vantaggi per il servizio e l'assistenza",
  text: "I pacchetti Aquarea Service+ includono molti vantaggi grazie alle opzioni personalizzate per ogni esigenzo. Alcuni dei vantaggi sono:",
  list: [
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "Monitoraggio IoT 24/7",
      text: "La tua pompa di calore Aquarea è monitorata a distanza 24 ore su 24 e sarà possibile ricevere assistenza da remoto"
    },
    {
      iconUrl: "/image/icons/services/alert_notification.png",
      iconAlt: "",
      headline: "Report Smart Aquarea",
      text: "Ricevi rapporti personalizzati sul tuo sistema di comfort domestico (riscaldamento, raffrescamento e produzione d'acqua calda) inclusi consigli e suggerimenti su come migliorare le prestazioni operative e l'efficienza"
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Interventi di manutenzione",
      text: "Tutti i pacchetti includono una visita annuale di manutenzione da parte dei tecnici Panasonic Service+"
    },
    {
      iconUrl: "/image/icons/services/247_hotline.png",
      iconAlt: "",
      headline: "Hotline per segnalazione guasti 24/7 ",
      text: "In caso di richieste di intervento o domande sul vostro sistema potete chiamarci dal Lunedì al Venerdì dalle ore 09:00 alle 20:00 e il Sabato dalle ore 09:00 alle 12:00 e parlare con un operatore. Per gli orari non coperti, è disponibile un servizio automatico di segnalazione grazie al quale verrete richiamati non appena l'operatore tornerà ad essere nuovamente disponibile."
    },
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "Ottimizzazione dei tempi e dei costi",
      text: "La tecnologia di cui dispone la vostra pompa di calore permette di ottimizzare tempi e costi legati a malfunzionamenti o settaggi che, altrimenti, richiederebbero la presenza in loco da parte vostra e del tecnico Panasonic."
    },
    {
      iconUrl: "/image/icons/services/repair_discount.png",
      iconAlt: "",
      headline: "Tempo di risposta veloce",
      text: "Ottieni supporto per il tuo dispositivo entro 24 ore sia tramite monitoraggio remoto che tramite una visita di assistenza fisica"
    }
  ]
};
