module.exports = {
    headline: "Range of services",
    desc_label: "Description",
    terms_label: "Terms & Conditions",
    annually: 'per year',
    quarterly: 'per quarter',
    monthly: 'per month',
    list: [
        {
            headline: '/image/plan_cards/aquarea_service+_exclusive.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_exclusive.svg",
            subheadline: 'per month',
            sku: "contract-heatpump-gold",
            color: "yellow",
            image: "/image/plan_cards/1.jpg",
            imageiconlist: "/image/test/screen9.png",
            text: "With your Aquarea Service + Premium subscription you have one less worry. Your heat pump is permanently monitored remotely, and if an error occurs we are the first to know about it. We will contact you immediately and help to solve the problem remotely, or if this is not possible, we will coordinate a service appointment within 24 hours, including weekends, whilst covering the labour and spare part cost!",
            disclaimer: "When purchasing an \"Aquarea+ Premium\" servie package, you will receive \"Aquarea+ Smart\" until a Panasonic technician checks the installation and approves that the key technical requirements are fulfilled. In the case of not achieveing these technical requirements, you will remain an \"Aquarea+ Smart\" customer and can enjoy the benefits of this package. You will be charged the \"Aquarea+ Smart\" price until the time your installation has been approved for \"Aquarea+ Premium\". \n",
            info: "You don't have to do anything other than enjoy the comfort of your home.",
            good_to_know_title: "Good to know",
            good_to_know_list: [
                {
                    label: "Your contract can be cancelled at the end of the year"
                },
            ],
            link: "Download complete terms",
            linkHref: "/en_IE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Premium Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Annual \nmaintenance \nvisit",
                },
                {
                    icon: "/image/icons/services/remotely_diagnose.svg",
                    alt: "",
                    label: "24/7 \nresponse time",
                },
                {
                    icon: "/image/icons/services/maintenance_report.svg",
                    alt: "",
                    label: "Smart \nAquarea Reports",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "24/7 IoT \nmonitoring",
                },
                {
                    icon: "/image/icons/services/service_technicians.svg",
                    alt: "",
                    label: "Spare parts \nand labour \nincluded",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_advanced.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_advanced.svg",
            subheadline: 'per month',
            color: "green",
            sku: "contract-heatpump-silver",
            image: "/image/plan_cards/3.jpg",
            imageiconlist: "/image/test/screen8.png",
            text: "With your Aquarea Service+ Smart subscription you have one worry less. Your heat pump is permanently monitored remotely, and if an error occurs, we are the first to know. We will contact you immediately and help to solve the problem remotely, or if this is not possible, coordinate a service appointment within 24 hours including weekends.",
            info: "You don't have to do anything else than enjoy the comfort of your home.",
            good_to_know_title: "Good to know",
            good_to_know_list: [
                {
                    label: "Your contract can be cancelled at the end of the year"
                },
            ],
            link: "Download complete terms",
            linkHref: "/en_IE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Smart Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Annual \nmaintenance \nvisit",
                },
                {
                    icon: "/image/icons/services/247_call.svg",
                    alt: "",
                    label: "24/7 Priority \nsupport hotline",
                },
                {
                    icon: "/image/icons/services/permanent_monitoring.svg",
                    alt: "",
                    label: "24/7 IoT \nmonitoring",
                },
                {
                    icon: "/image/icons/services/repair_discount.svg",
                    alt: "",
                    label: "20% Spare parts \ndiscount",
                }
            ]
        },
        {
            headline: '/image/plan_cards/aquarea_service+_comfort.svg',
            choiceList_image: "/image/plan_cards/aquarea_service+_comfort.svg",
            subheadline: 'per month',
            color: "brown",
            sku: "contract-heatpump-bronze",
            image: "/image/plan_cards/2.jpg",
            imageiconlist: "/image/test/screen7.png",
            text: "With your Aquarea Service+ Comfort subscription you have a convenient package which includes an annual heat pump maintenance visit and access to a priority hotline.",
            info: "You don't have to do anything other than just enjoy the comfort of your home.",
            good_to_know_title: "Good to know",
            good_to_know_list: [
                {
                    label: "Your contract can be cancelled at the end of the year"
                },
            ],
            link: "Download complete terms",
            linkHref: "/en_IE/home/terms_and_conditions",
            btn_goTo: {
                href: "",
                label: "Go to Comparison Table"
            },
            btn_book: {
                href: "",
                label: "Purchase Comfort Cover"
            },
            iconList: [
                {
                    icon: "/image/icons/services/maintenance.svg",
                    alt: "",
                    label: "Annual \nmaintenance \nvisit",
                },
                {
                    icon: "/image/icons/services/call.svg",
                    alt: "",
                    label: "Priority hotline",
                }
            ]
        },

    ],
};
