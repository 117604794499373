module.exports = {
    reports: "Rapportera",
    headline: "Aquarea Service+ Driftsrapport",
    table: {
        headers: {
            date: "",
            report: "driftsrapport",
        },
        showAll: "skruvar att rapportera",
        hideOlder: "dölja gamla rapporter",
    },
    download: "Ladda ner rapporten hit"
}
