module.exports = {
    headline: "Proposte aggiornamenti",
    currentProtectionPlan: "Pacchetto di servizi attuali",
    protectionPlan: "pacchetto di servizi",
    validUntil: "Contratto in scadenza il:",
    costs: "importo (IVA inclusa)",
    period: "termini di pagamento",
    monthly:"mensile (IVA inclusa)",
    quarterly:"per trimestre (IVA inclusa)",
    annually:"annualmente (IVA inclusa)",
    notUpgradableText: "Non puoi fare l'upgrade dal tuo attuale contratto.",
    backButtonText: "Torna al mio account"
};