module.exports = {
    headline: 'Aquarea Service + Et roligt øjeblik',
    text: 'Vi tager os af din varmepumpe, så du kan slappe af og nyde et hyggeligt og varmt hjem.',
    btn: {
        href: '/da_DK/home/service_package',
        text: 'SE VORES SERVICEPAKKER'
    },
    campaign: {
        img: {
            url: '/image/home/hero/PaSo-GW.svg',
            alt: 'hero-img-alt-text',
        },
        text: 'Bestil et Aquarea Service+ abonnement i Green Weeks mellem d. 8.–30. november 2021, og få installationsservicepakken med gratis til en værdi af 1.495 DKK. For hvert solgt abonnement i denne periode planter vi også et træ sammen med Treedom i et område, der har brug for det.'
    }
};
