module.exports = {
  headline: "Aquarea Service+ kontrakt",
  monthly: "månad",
  quarterly: "kvartal",
  annually: "år",
  period: "period",
  table: {
    headers: {
      date: "DATO",
      price: "BETALNING",
      status: "STATUS",
    },
  },
  PENDING: "PÅGÅENDE",
  PAID: "BETALDA",
  CANCELLED: "AVBOKAD",
  FAILED: "MISSLYCKAD",
  PLANNED: "PLANERAD",
  RETRY: "FÖRSÖK IGEN",
  REFUNDED: "ÅTERBETALNING",
  OPEN: "ÖPPNA",
  viewAll: "Se alla betalare",
  REDUCED_FEE:"Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  THREE_MONTHS_FREE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_REDUCED_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
  UPGRADE_NO_INSTALLATION_FEE: "Discount \"{DISCOUNT_CODE}\" was applied. Amount was deducted from payment scheudule.",
};
