module.exports = {
    headline: "Perché scegliere Aquarea Service+?",
    text: "Scegli Aquarea Service+ e ottieni il meglio dalla tua pompa di calore Aquarea, senza sorprese inutili. Grazie al monitoraggio a distanza, un tecnico professionista può occuparsi del funzionamento della vostra pompa di calore senza dovervi disturbare nella vostra vita quotidiana.",
    list: [
        {
            icon: "/image/icons/services/monitoring.png",
            text: "I nostri tecnici sono esperti in pompe di calore Aquarea",
        },
        {
            icon: "/image/icons/services/24h_response_time.png",
            text: "Diagnostichiamo qualsiasi guasto esternamente ed evitiamo visite di servizio non necessarie",
        },
        {
            icon: "/image/icons/services/247.png",
            text: "Colleghiamo la tecnologia IoT alla vostra pompa di calore",
        }
    ],
    btn_label: "VEDI I NOSTRI PACCHETTI DI SERVIZI",
    btn_href: "/it_IT/home/service_package"
};