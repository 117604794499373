import React, { useContext, useEffect, useMemo } from "react";
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Breadcrumbs from "../lib/breadcrumbs";
import { Link } from "react-router-dom";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";
import { scrollToPos } from "../lib/scrollToPos";
import { useParams } from "react-router-dom";
import { AuthContext } from "../lib/contexts/authContext";
import { useHistory } from "react-router-dom";
import { reportFailedOneTimePayment } from "../lib/dataAccess/checkout/orderRestEndpoint";
import {
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
} from "../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "en_IE";
const t = i18.namespace("RepairPayment");
const errMap = {
  repair_payfailure: "failure",
  repair_paycancel: "cancel",
  repair_payerror: "error",
  repair_payexpiry: "expired",
  repair_paypending: "pending",
};

const RepairPayError = () => {
  const { authContext } = useContext(AuthContext);
  const { orderId } = useParams();
  const history = useHistory();

  const decodedError = useMemo(() => {
    let errMessage = t(errMap.repair_payerror);
    Object.keys(errMap).forEach((err) => {
      if (history.location.pathname.includes(err)) {
        errMessage = t(errMap[err]);
      }
    });
    return errMessage;
  }, [history.location.pathname]);

  useEffect(() => {
    scrollToPos(0, 0);
    const isFailurePage = history.location.pathname.includes(
      errMap.repair_payfailure
    );
    const isErrorPage = history.location.pathname.includes(
      errMap.repair_payerror
    );
    if (!!orderId && authContext?.authToken && (isFailurePage || isErrorPage)) {
      reportFailedOneTimePayment(orderId, authContext.authToken)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
        if (isFailurePage) {
          history.replace("/" + countryCode + "/repair_payfailure");
        }
        if (isErrorPage) {
          history.replace("/" + countryCode + "/repair_payerror");
        }
      });
    }
  }, [authContext?.authToken, history, orderId]);

  return (
    <>
      <div className="no-match-404">
        <Menu />
        <div className="container">
          <div className="page_header">
            <section className="breadcrumbs">
              <Breadcrumbs />
            </section>
          </div>

          <div className="no-match-404_wrapper">
            <div className="no-match-404_content">
              <div
                className={`icon ${
                  history.location.pathname.includes(errMap.repair_paypending)
                    ? "icon_paypending"
                    : "icon_x-circle"
                }`}
              />
              <p className="no-match-404_text">{decodedError}</p>
              <Link className="btn btn_line" to={t("link_href")}>
                {t("link")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  );
};

export default RepairPayError;
