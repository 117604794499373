import React from "react";
import { useHistory } from "react-router-dom";
import i18n from "../../lib/i18n";
import {
    getSessionStorageItem,
    COUNTRY_CODE_NAME,
} from "./../../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

const t = i18n.namespace("MyAccountNoServicePackage");

function NoServicePackage (props) {

    const history = useHistory();

    const onClick = () => {
        history.push('/'+ countryCode +'/home/service_package');
    }

    return (
        <section>
            <p>{t("text")}</p>
            <br />
            <button className="btn-secondary" onClick={() => onClick()}>{t("btn.goto")}</button>
        </section>
    );
}
export default NoServicePackage;
