module.exports = {
    reports: "Reports",
    headline: "Aquarea Service+ Operations Report",
    table: {
        headers: {
            date: "date",
            report: "operations report",
        },
        showAll: "show all reports",
        hideOlder: "hide old reports"
    },
    download: "Download the report here"
}
