import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import i18 from "../../lib/i18n";
import { SmallSectionHeadline } from "../layout/section";
import { LoadingSpinnerButton, StandardButton } from "../layout/buttons";
import {
  getSessionStorageItem,
  COUNTRY_CODE_NAME,
  AUTH_TOKEN_SESSION_STORAGE_NAME,
} from "./../../lib/sessionStorage";
import Modal from "../modal";
import FormItem from "../registration/formItem";
import { updateTaxCode } from "../../lib/dataAccess/customer/accountRestEndpoint";
import { ErrorResponseHandling } from "../../lib/dataAccess/errorResponseHandling";
import { AuthContext } from "../../lib/contexts/authContext";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "da_DK";

const t = i18.namespace("RepairOrder");

const PAID = "paid";
const PENDING = "pending";
const CANCELLED = "cancelled";

const ZERO_INVOICE_AMOUNT = 0;

const countrySpecificExactTaxCodeLength = {
  en_es: 9,
  es_es: 9,
  it_it: {
    "Partita IVA": 11,
    "Codice Fiscale": 16,
  },
};

const initialFormState = {
  taxCodeType: { value: "", invalid: true },
  taxCode: { value: "", invalid: true },
};

const RepairOrder = ({ index, account, order, taxCodeTypes }) => {
  const { orderId, orderNumber, totalGross } = order;
  const paymentStatus = order?.paymentStatus.toLowerCase();
  const orderStatus = order?.orderStatus.toLowerCase();
  const invoiceAmount = totalGross?.amount + " " + totalGross?.currency;
  const repairPaymentMethodPath =
    "/" + countryCode + "/home/repair_payment_method/" + orderId;

  const { authContext, setAuthContext } = useContext(AuthContext);
  const [askTaxCode, setAskTaxCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFormInvalid, setFormInvalid] = useState(true);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [formState, setFormState] = useState(initialFormState);

  const history = useHistory();

  const getCountrySpecificExactTaxCodeLength = (taxCodeType) => {
    const countryTaxCodeLength =
      countrySpecificExactTaxCodeLength[countryCode.toLowerCase()];
    if (countryCode.toLowerCase() === "it_it") {
      return countryTaxCodeLength[taxCodeType] || 16;
    }
    return countryTaxCodeLength || "";
  };

  const formItems = [
    {
      type: "select",
      name: "taxCodeType",
      width: "full",
      options: taxCodeTypes || [],
      validators: ["REQUIRED"],
      emptyOption: t("modal.emptyOption"),
    },
    {
      type: "text",
      name: "taxCode",
      width: "full",
      placeholder: t("modal.inputPlaceholder"),
      validators: ["REQUIRED", "EXACT_TAX_CODE_LENGTH"],
      maxLength: getCountrySpecificExactTaxCodeLength(
        formState.taxCodeType.value
      ),
      constraints: {
        exactlyLength: getCountrySpecificExactTaxCodeLength(
          formState.taxCodeType.value
        ),
      },
    },
  ];

  const setValueAndValidity = ({ target: { name, value } }, invalid) => {
    setFormState((formState) => {
      const newFormState = {
        ...formState,
        [name]: { value, invalid },
      };

      if (name === "taxCodeType") {
        newFormState.taxCode = { value: "", invalid: true };
      }

      if (formState[name].value !== value && showErrorMessage === true) {
        setShowErrorMessage(false);
      }

      validateForm(newFormState);
      return newFormState;
    });
  };

  const validateForm = (newFormState) => {
    setFormInvalid(
      Object.keys(newFormState).some((key) => newFormState[key].invalid)
    );
  };

  const handleUpdateTaxCode = async (event) => {
    event.preventDefault();
    setLoading(true);
    setShowErrorMessage(false);
    try {
      let res = await updateTaxCode(
        {
          taxCode: formState.taxCode.value,
          taxCodeType: formState.taxCodeType.value,
        },
        getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)
      );
      setLoading(false);
      if (res.data === true) {
        setAskTaxCode(false);
        history.push(repairPaymentMethodPath);
      }
      if (res.data === false) {
        setShowErrorMessage(true);
      }
    } catch (e) {
      setLoading(false);
      ErrorResponseHandling(e, authContext, setAuthContext, history);
    }
  };

  const handlePayNow = () => {
    if (
      ["it_it", "es_es", "en_es"].includes(countryCode.toLowerCase()) &&
      !account?.taxCode
    ) {
      setAskTaxCode(true);
    } else {
      history.push(repairPaymentMethodPath);
    }
  };

  const handleClose = () => {
    setAskTaxCode(false);
    setShowErrorMessage(false);
    setFormState(initialFormState);
  };

  const modalContent = {
    title: t("modal.modalTitle"),
    body: t("modal.modalBody"),
    component: (
      <>
        {formItems.map((item, index) => (
          <FormItem
            key={index}
            item={item}
            currentValue={formState[item.name].value}
            runInitialValidation
            saveValueByStep={setValueAndValidity}
          />
        ))}
        {showErrorMessage && (
          <span className="modal-error">{t("modal.errorMessage")}</span>
        )}
      </>
    ),
  };

  const renderModal = () => {
    return (
      <Modal
        id={orderId}
        content={modalContent}
        isOpen={askTaxCode}
        handleClose={handleClose}
        primaryAction={{
          component: (
            <LoadingSpinnerButton
              isLoading={loading}
              text={t("modal.modalPrimaryButton")}
              onClick={handleUpdateTaxCode}
              isEnabled={!isFormInvalid}
              classNames={
                "btn-primary order-1 order-md-2 loading-spinner-button ml-auto"
              }
            />
          ),
        }}
      />
    );
  };

  return (
    <>
      {renderModal()}
      <div className="repair-order" id={"contract-repair-order-" + index}>
        <SmallSectionHeadline>{t("headline")}</SmallSectionHeadline>
        <p>{orderNumber}</p>
        <div className="repair-order-content">
          <div className="text-content">
            <span>{t("thanks")}</span>
            {paymentStatus !== PAID && <span>{t("text")}</span>}
            {paymentStatus === PAID &&
              totalGross.amount !== ZERO_INVOICE_AMOUNT && (
                <span>{t("isPaidText")}</span>
              )}
            {paymentStatus === PAID &&
              totalGross.amount === ZERO_INVOICE_AMOUNT && (
                <span>{t("noInvoiceText")}</span>
              )}
          </div>
          <strong>{t("invoiceAmountText") + invoiceAmount}</strong>
          <div className="btn-container">
            {paymentStatus !== PAID && orderStatus !== CANCELLED && (
              <StandardButton
                text={
                  paymentStatus === PENDING
                    ? t("pendingButtonText")
                    : t("payButton")
                }
                onClick={handlePayNow}
                isEnabled={paymentStatus !== PENDING}
                classNames="btn-quarternary"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RepairOrder;
