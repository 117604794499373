import React, { useEffect } from 'react'
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Breadcrumbs from "../lib/breadcrumbs";
import { Link } from "react-router-dom";
import Newsletter from "../components/newsletter";
import Footer from "../components/footer";
import { scrollToPos } from '../lib/scrollToPos';

const t = i18.namespace("Payexpiry");

const Payexpiry = () => {

  useEffect(() => {
    scrollToPos(0, 0);
  }, []);

  return (
    <>
      <div className="no-match-404">
        <Menu />
        <div className="container">
          <div className="page_header">
            <section className="breadcrumbs">
              <Breadcrumbs />
            </section>
          </div>

          <div className="no-match-404_wrapper">
            <div className="no-match-404_content">
              <div className="icon icon_x-circle" />
              <p className="no-match-404_text">{t("text")}</p>
              <Link className="btn btn_line" to={t("link_href")}>{t("link")}</Link>
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
      <Footer />
    </>
  )
}

export default Payexpiry;
