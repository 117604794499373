module.exports = {
    points:[
        {
            label: "#1",
            text: "Heat Pump Provider"
        },
        {
            label: "60 Years",
            text: "of heat pump experience"
        },
        {
            label: "24/7",
            text: "Monitoring via IoT (Internet of Things)"
        },
        {
            label: ">100K",
            text: "smart connected heat pumps "
        },
    ]
};
