import React from 'react';

// {
//     "result": [
//     {
//         "sku": "contract-heatpump-silver",
//         "name": "Silver",
//         "price": {
//             "amount": 259.00,
//             "currency": "DKK"
//         },
//         "categoryId": "3c22f266-fa82-45d2-87f3-03db77a6ff6e",
//         "contractType": "silver"
//     },
//     {
//         "sku": "contract-heatpump-bronze",
//         "name": "Bronze",
//         "price": {
//             "amount": 189.00,
//             "currency": "DKK"
//         },
//         "categoryId": "3c22f266-fa82-45d2-87f3-03db77a6ff6e",
//         "contractType": "bronze"
//     },
//     {
//         "sku": "contract-heatpump-gold",
//         "name": "Gold",
//         "price": {
//             "amount": 359.00,
//             "currency": "DKK"
//         },
//         "categoryId": "3c22f266-fa82-45d2-87f3-03db77a6ff6e",
//         "contractType": "gold"
//     }
// ],
//     "offset": 0,
//     "total": 3,
//     "totalPages": 1,
//     "count": 3
// }
export const ProductContext = React.createContext({
    productContext: {plans: null, selectedPlan: "contract-heatpump-gold", selectedVariant: null},
    setProductContext: () => {}
});
