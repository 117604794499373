import React from 'react';
import classNames from "classnames";

export const DetailHeadline = ({children, headline}) => {
  return (
    <h3 className="detail_headline">
      {headline}
      <span className="detail_headline_action">{children}</span>
    </h3>
  );
}

export const DetailEntry = ({children, label, className}) => {
  return (
    <div className={classNames("detail_entry", className)}>
      <strong className="detail_entry_label">{label}</strong>
      <div className="detail_entry_value">{children}</div>
    </div>
  );
}

export const DetailContainer = ({children, className}) => {
  return (
    <div className={classNames("detail_container", className)}>
      {children}
    </div>
  );
}


export const DetailList = ({children, className}) => {
  return (
    <div className={classNames("detail_list", className)}>
      {children}
    </div>
  );
}


export const DetailListRow = ({children}) => {
  return (
    <div className="detail_list_row">
      {children}
    </div>
  );
}


export const OptionalValue = ({children, emptyValue, condition}) => {
  return (
    <>
      {condition ? children : emptyValue ?? (<>&mdash;</>)}
    </>
  );
}
