module.exports = {
    headline: 'Aquarea Service + A window to tranquility.',

    text: 'Let us take care of your heat pump whilst you just relax and enjoy a cozy, warm home.',
    btn: {
        href: '/en_IE/home/service_package',
        text: 'SEE OUR SERVICE PACKAGES'
    },
    campaign: {
        img: {
            url: '/image/home/hero/PaSo-GW.svg',
            alt: 'hero-img-alt-text',
        },
        text: 'Order an Aquarea Service+ subscription in Green Weeks between 8-30 November 2021 and get the installation service package for free, worth €1,495. For every subscription sold during this period, we will also plant a tree with Treedom in an area in need.'
    }
};
