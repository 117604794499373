module.exports = {
    headline: "Aquarea Service+ Contract",
    baseUnit: "Installed Base Unit",
    contract: "Contract",
    month:"month",
    monthly:"month",
    quarterly:"quartal",
    annually:"year",
    disclaimerHeadline :"Aquarea + Premium Service: Awaiting technical health and operational checks",
    disclaimer:"When purchasing an \"Aquarea+ Premium\" servie package, you will receive \"Aquarea+ Smart\" until a Panasonic technician checks the installation and approves that the key technical requirements are fulfilled. In the case of not achieveing these technical requirements, you will remain an \"Aquarea+ Smart\" customer and can enjoy the benefits of this package. You will be charged the \"Aquarea+ Smart\" price until the time your installation has been approved for \"Aquarea+ Premium\".",
    contract_complete: "This contract is already completed.",
    contract_cancelled: "This contract has been cancelled.",
    waitingForDateUpdateStatus:"Waiting for response from the payment service system",
    updateExpiryDate:'Expiry Date needs to be renewed',
    updateFailed:'Expiry Date Update Failed - Please try again later',
    updateSuccess:'Exp.Date updated',
    details: {
        model: "model",
        orderNumber: "order number",
        installationDate: "installation date",
        protectionPlan: "service package",
        location: "address",
        serialNumber: "serial number",
        contractValidTill: "contract valid till",
        costs: "costs",
        creditCardNumber:"credit card number",
        paymentMethod:"payment method",
        expiryDate:'Expiry Date',
        expiryValue:'VALID THRU'
    },
    btn:{
        upgrade: "upgrade service package",
        cancel: "cancel service package",
        payment_update: "change payment method",
        registerHeatpump: "register heatpump",
        registerYourDevice: "register your device",
        expiry_update:'UPDATE EXPIRY DATE',
        updateAllContracts:'YES UPDATE ALL',
        cancelUpdate:'NO',
    },
    campaign: {
        img: "/image/home/hero/PaSo-GW@2x.png",
        alt: "alt green week img text"
    },
    modal:{
        updateContractsTitle:"Update All Contracts",
        updateContractsBody:"There are multiple Contracts with the same Expiry Date - Do you want to update all ?",
    },
    accountPage:'/en_ES/home/my_account'
}
