module.exports = {
    home: 'Inicio',
    termsConditions: "Términos y condiciones",
    privacyPolicy: "Política de privacidad",
    protectionPlans: 'Planes de Mantenimiento',
    service: 'Servicio de atención al cliente',
    error: 'Oops un error',
    checkout: 'Comprobación',
    login: 'Inicio de sesión',
    cancelProtectionPlan: 'Cancelar plan de mantenimiento',
    myAccount: 'Mi cuenta',
    editMyAccount: 'Editar la información personal',
    passwordReset: 'Restablecimiento de la contraseña',
    upgradeProtectionPlan: 'Actualización del paquete de servicios',
    upgrade: 'Paquete de servicios de actualización',
    updatePayment: "Cambiar la forma de pago",
    registerUnit: "Registro Unidad de Bomba de Calor",
    faq : "Preguntas frecuentes"
}