module.exports = {
  headline: "Fordele ved Aquarea Service+",
  text: "Aquarea Service+ pakker inkluderer masser af fordele, der opfylder forskellige behov. Nogle af fordelene er:",
  list: [
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "24/7 IoT-overvågning",
      text: "Din Aquarea-varmepumpe fjernovervåges døgnet rundt, og det vil være muligt at modtage fjernsupport"
    },
    {
      iconUrl: "/image/icons/services/alert_notification.png",
      iconAlt: "",
      headline: "Smart Aquarea-rapport",
      text: "Du modtager skræddersyet rapporter om dit varmesystem, der indeholder råd og tips til, hvordan du forbedrer driftsevne og -effektivitet"
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Vedligeholdelsesbesøg",
      text: "Alle pakker inkluderer et årligt vedligeholdelsesbesøg af Panasonic Service+ teknikere"
    },
    {
      iconUrl: "/image/icons/services/247_hotline.png",
      iconAlt: "",
      headline: "Daglig kontroltjek",
      text: "Panasonic Service+ Team monitorerer dagligt for unormale  forhold i dit varmesystem"
    },
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "Eksklusiv 24/7 support",
      text: "Ring til os når som helst, hvis du har spørgsmål angående din varmepumpe"
    },
    {
      iconUrl: "/image/icons/services/repair_discount.png",
      iconAlt: "",
      headline: "Hurtig svartid",
      text: "Du får support til din  enhed inden for 24 timer enten ved hjælp af fjernovervågning eller et fysisk servicebesøg"
    }
  ]
};
