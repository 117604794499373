module.exports = {
    headline: "Bienvenido de nuevo",
    subheadline: "Bienvenido de nuevo",
    inputs: [
        {
            label: "Email",
            id: "email",
            name: "email",
            placeholder: "Email",
            type: "email"
        },
        {
            label: "Contraseña",
            id: "password",
            name: "password",
            password_placeholder: "Contraseña",
            type: "password"
        }
    ],
    btn: "Inicio de sesión",
    link: "Olvidé mi contraseña",
    link_href: "/es_ES/home/password_forgot",
    newProtectionPlan: {
        label: "¿Necesita un paquete de servicios?",
        link: "Pedir un paquete de servicios",
        link_href: "/es_ES/home/service_package",
    }
}