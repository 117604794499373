import React from 'react';
import i18 from "../lib/i18n";

const t = i18.namespace("Menu");

export const Logout = (props) => {
    return (
        <a className="menu_top_logout" href={"#root"} type={"link"} onClick={props.handleClick}>({t("logout")})</a>
    )
};

export default Logout;
