module.exports = {
    protected: "Estás protegido por Aquarea Service+",
    heatPumpsCorrectly: "",
    callHotline: "Contactar con el servicio de atención al cliente",
    sendMail: "Enviar correo electrónico",
    gotoCloud: "Ir a Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "comfort"
    },
    imageTags:{
        exclusive: "Prima Aquerea",
        advance:"Aquerea inteligente",
        smart: "Aquerea básica",
        comfort: "Aquarea confort"
    },
    downgradeInfo1: "Su plan de protección está programado para ser degradado a ",
    downgradeInfo2: ". Esta reducción se aplica después de que se complete el período del contrato actual.",
    contactmail: "serviciosymantenimiento@eu.panasonic.com",
}