    module.exports = {
    headline: "Reparationsorder",
    thanks: "Tack för att du kontaktade Aquarea+ Service!",
    text: "Din reparationsorder har slutförts framgångsrikt. Efter att betalningen har slutförts hittar du din faktura i din e-postinkorg.",
    invoiceAmountText: "Fakturans belopp: ",
    isPaidText: "Din reparationsorder har slutförts framgångsrikt och din faktura har behandlats framgångsrikt.",
    noInvoiceText: "Din reparationsorder har slutförts och din faktura har behandlats framgångsrikt.",
    payButton: "Betala nu",
    pendingButtonText: "pågår",
};
