import React, { useContext, useEffect, useState } from "react";

import i18 from "../../lib/i18n";
import { getPaymentSchedule } from "../../lib/dataAccess/accountService";
import { getProduct } from "../../lib/dataAccess/product/productRestEndpoint";
import { getDiscount } from "../../lib/dataAccess/checkout/orderRestEndpoint";
import { AuthContext } from "../../lib/contexts/authContext";

const t = i18.namespace("MyAccountInvoiceHistory");

const InvoiceHistory = ({orderData, orderId}) => {
  const {authContext} = useContext(AuthContext);
  const [period, setPeriod] = useState("");
  const [state, setState] = useState({
    entries: [],
    isLoading: true,
    showAll: true,
  });
  const [discountCode, setDiscountCode] = useState("");
  const [discountType, setDiscountType] = useState("");
  const NO_DISCOUNT = "NONE"

  useEffect(() => {
    if (orderId) {
      getPaymentSchedule(orderId, authContext.authToken).then(res => setState({...state, entries: res.data.entries, isLoading: false}));
      getDiscount(orderId, authContext.authToken).then(result =>{
        setDiscountType(result.data?.discountType ? result.data?.discountType : NO_DISCOUNT)
        setDiscountCode(result.data?.discountCode)
      });
      getProduct()
        .then(res => {
          const sku = orderData?.lineItems.find(lineItem => lineItem.sku.startsWith("contract-"))?.sku;
          const products = res.data.result;

          products.forEach(product => {
            product.variants.forEach(variant => {
              if (sku === variant.sku) {
                setPeriod(variant.paymentPeriod);
              }
            })
          })
          return res;
        })
    }
  }, [orderId]) // eslint-disable-line react-hooks/exhaustive-deps

function showDiscountMessage(discount) {
      // "NONE" is not set discount or invalid discount
      // See checkout-service: de.panasonic.checkoutservice.custom.model.DiscountType
      return discount !== NO_DISCOUNT;
}

function parsePaymentDate(dateString){
  var dateParts = dateString.substr(0,10).split(".");
  return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
}

  const getPaymentEntries = () => {
    state.entries.sort((a, b) => {
       return parsePaymentDate(a.paymentDate) > parsePaymentDate(b.paymentDate) ? 1: -1;}
      );
    return (
      state.entries.map((entry, index) =>
        <tr key={index}>
          <td>#{index + 1}</td>
          <td>{entry.paymentDate.split(" ")[0]}</td>
          <td>{entry.amount.amount} {entry.amount.currency}</td>
          <td>{t(entry.state)}</td>
        </tr>
      )
    )
  };

    return (
      <>
        <h2 className="section_headline">{t("headline")}</h2>
        {showDiscountMessage(discountType) && <div className="hint"> {discountCode && t(discountType).replace("{DISCOUNT_CODE}", discountCode)}</div>}
        <div className="table-responsive">
          <table className="table table--striped">
            <thead>
              <tr>
                <th className="table_col-highlighted">
                    {t(period)}
                </th>
                <th>{t("table.headers.date")}</th>
                <th>{t("table.headers.price")}</th>
                <th>{t("table.headers.status")}</th>
              </tr>
            </thead>
            <tbody>{!state.isLoading && getPaymentEntries()}</tbody>
          </table>
        </div>
      </>
    );
}

export default InvoiceHistory;
