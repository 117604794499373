import Breadcrumbs from "../../lib/breadcrumbs";
import React from "react";
import Newsletter from "../newsletter";
import Footer from "../footer";
import classNames from "classnames";

export const Page = ({children, className}) => {
  return (
    <div className={classNames("page", className)}>
      {children}
    </div>
  );
}

export const PageBreadcrumb = () => {
  return (
    <section className="breadcrumbs">
      <Breadcrumbs/>
    </section>
  );
}

export const PageHeader = ({children}) => {
  return (
    <div className="page_header container">
      {children}
    </div>
  );
}

export const PageHeadline = ({children, className, headline}) => {
  return (
    <h1 className={classNames("page_headline", className)}>
      {headline} {children}
    </h1>
  );
}

export const PageBody = ({children}) => {
  return (
    <div className="page_body">
      {children}
    </div>
  );
}

export const PageFooter = () => {
  return (
    <>
      <Newsletter/>
      <Footer/>
    </>
  )
}

