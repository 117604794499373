module.exports = {
    inputs: {
        label: "Email",
        id: "e-mail",
        name: "e-mail",
        placeholder: "La tua e-mail",
        type: "e-mail"
    },
    success: "È stata richiesta la reimpostazione della password, controlla la tua casella di posta elettronica!",
    subheadline:"Password dimenticata",
    headline:"Password dimenticata",
    submit: "Invia"
}