module.exports = {
    headline: "Service Package",
    icon: '/image/icons/cart/cart.png',
    iconCount: '/image/icons/cart/cart2.png',
    href: '/cart',
    titel: "shoppingcart",
    overlayLabel:"Your choice:",
    btn: "purchase protection plan",
    btnPath: "/da_DK/home/service_package",
    edit: "edit",
    editPath: "/da_DK/home/service_package"
};
