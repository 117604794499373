import React from 'react';

// {
//     authToken: string
//     accountData: {
//         email: string;
//         firstName: string;
//         lastName: string;
//         title: string;
//         installedBaseUnits: InstalledBaseUnitData[]; // exakt ein Element
//         addresses: AddressData[]; // im MVP bekommen wir exakt eine zurück
//     }
// }
export const AuthContext = React.createContext({
    authContext: null,
    setAuthContext: () => {},
});
