module.exports = {
    headline: "YOUR CHOICE:",
    subheadline: "Service Package",
    edit_link: "/en_ES/home/service_package",
    edit_label: "EDIT",
    label: "Installation Service",
    additionally: [
        {
            label: "Additionally booked:",
            text: "1,495.00DKK one time fee",
            href_label: "DELETE",
        }
    ],
    annually: 'annual payment',
    quarterly: 'quarterly payment',
    monthly: 'monthly payment',
    per_month: 'per month',
    disclaimer: 'Select monthly, quarterly or yearly payment on Payment Step before ordering.'
};
