module.exports = {
    common: {
        REQUIRED: "Questo campo è obbligatorio",
        MAX_LENGTH: "Caratteri {{maxLength}} massimi",
        COMPARE: "Le voci devono essere identiche",
        DATE: "Data non valida",
        MIN_LENGTH: "Min {{minLength}} cifre",
        EXACTLY_LENGTH: "Caratteri {{exactlyLength}} esatti",
        EMAIL: "E-mail non valida",
        BACKEND_VALIDATION_ERROR: "Intestazione del server",
        ZIPCODE: "{{length}} and only numbers",
        SWEDISH_ZIPCODE: "{{length}} and only numbers",
        PASSWORD: "Min. 8 caratteri, numeri e lettere",
        EXACT_TAX_CODE_LENGTH: "Si prega di inserire un codice fiscale di {{exactlyLength}} caratteri"
    },
    USER_ALREADY_REGISTERED: "L'indirizzo e-mail è già registrato",
    REGISTRATION_FAILED: "Si è verificato un errore tecnico durante la registrazione. Si prega di riprovare più tardi.",
    validationMethods: {
        "required": {
            message: "Questo campo è obbligatorio",
        },
        "email": {
            message: "E-mail non valida",
        },
        "matching_email_values": {
            message_mail: "E-mail non valida",
            message_not_same: "Gli indirizzi e-mail devono essere gli stessi"
        },
        "password": {
            message: "Password sbagliata",
            message_password: "Password sbagliata",
            message_password_lengths: "Min. 8 caratteri, numeri e lettere",
            message_not_same: "Le password devono essere le stesse"
        },
        "loginError": {
            message: "Il nome utente o la password non sono corretti"
        },
        "missingEmail": {
            message: "E-mail mancante"
        },
    },
    optionalValue: "Opzionale"
}
