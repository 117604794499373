module.exports = {
    headline: "Dit valg:",
    subheadline: "Service Package",
    edit_link: "/da_DK/home/service_package",
    edit_label: "EDIT",
    label: "Installation Service",
    additionally: [
        {
            label: "Derudover bestilt:",
            text: "1,495.00DKK one time fee",
            href_label: "DELETE",
        }
    ],
    annually: 'årlig betaling',
    quarterly: 'kvartalsvis betaling',
    monthly: 'månedlig betaling',
    per_month: 'pr. måned',
    disclaimer: 'Vælg månedlig, kvartalsvis eller årlig betaling under Betalingsoplysninger, inden du bestiller.'
};
