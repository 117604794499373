module.exports = {
    headline: 'Aquarea Service + Un momento de tranquilidad',
    text: 'Nos ocuparemos de su bomba de calor para que pueda relajarse y disfrutar de un hogar acogedor y cálido.',
    btn: {
        href: '/es_ES/home/service_package',
        text: 'VER NUESTROS PAQUETES DE SERVICIOS'
    },
    campaign: {
        img: {
            url: '/image/home/hero/PaSo-GW.svg',
            alt: 'hero-img-alt-text',
        },
        text: 'Pida una suscripción a Aquarea Service+ en las Semanas Verdes entre el 8 y el 30 de noviembre de 2021 y obtenga el paquete de servicio de instalación gratis, por valor de 1.495 euros. Por cada abono vendido durante este periodo, también plantaremos un árbol con Treedom en una zona necesitada.'
    }
};
