module.exports = {
    home: 'Home',
    termsConditions: "Termini e condizioni",
    rightOfWithdrawal: "Diritto di recesso",
    termsOfUse: "Condizioni di utilizzo del sito web",
    privacyPolicy: "Politica sulla privacy",
    protectionPlans: 'pacchetti di servizi',
    service: 'Servizio clienti',
    error: 'Ops, un errore',
    checkout: 'Cassa',
    login: 'Accesso',
    cancelProtectionPlan: 'Cancellare il pacchetto di servizi',
    myAccount: 'Il Mio Account',
    editMyAccount: 'Modificare le informazioni personali',
    passwordReset: 'Ripristino della password',
    upgradeProtectionPlan: 'Aggiornamento del pacchetto dei servizi',
    upgrade: 'Upgrading',
    updatePayment: "Cambiare il metodo di pagamento",
    registerUnit: "Registrazione Unità a pompa di calore",
    faq : "Domande frequenti"
}