module.exports = {
    upgradeDiscount: {
        name: "Rabatt",
        inputPlaceholder: "infoga rabattkod",
        applyBtnLable: "tillämpa",
        removeBtnLable: "ta bort",
        invalidHeadline: "Den angivna koden är ogiltig",
        invalidHint: "Kontrollera dina uppgifter",
        errors: {
            tooLong: "Den angivna rabattkoden är för lång.",
            invalidInput: "Den angivna koden är ogiltig" // upgrade page
        }
    }
};