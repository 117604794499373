module.exports = {
    inputs: [
        {
            label: "Nueva contraseña",
            id: "nuevaContraseña",
            name: "nuevaContraseña",
            placeholder: "Nueva contraseña",
            type: "password"
        },
        {
            label: "Confirmar contraseña",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Confirmar contraseña",
            type: "password"
        }
    ],
    success: "Se ha solicitado el restablecimiento de la contraseña, ¡mira tu bandeja de entrada!",
    subheadline: "Restablecer contraseña",
    headline: "Restablecer contraseña",
    resetBtn: "Restablecer contraseña"
}