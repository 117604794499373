module.exports = {
    points:[
        {
            label: "#1",
            text: "Varmepumpeleverandør"
        },
        {
            label: "60 års",
            text: "erfaring med varmepumper"
        },
        {
            label: "24/7",
            text: "overvågning via IoT (Internet of Things)"
        },
        {
            label: ">100K",
            text: "tilsluttede varmepumper"
        },
    ]
};
