module.exports = {
    failure: 'Opdateringen er blevet afvist af betalingsservicesystemet.',
    cancel: 'Opdateringen er blevet aflyst',
    error: 'Der opstod en fejl under opdateringen af vores betalingssystem - Prøv venligst igen.',
    success:'Your Update is successfull',
    expired:'URL er udløbet - prøv venligst igen',
    pending:'Afventer svar fra betalingssystem',
    link: "GÅ TIL MIN KONTO",
    link_href: "/da_DK/home/my_account"
};

