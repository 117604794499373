import axios from "axios";
import { getHeaderForApiRequest } from "../dataAccessHelper";

const CHECKOUT_BASE_URL = process.env.REACT_APP_API_CHECKOUT_ENDPOINT_BASE_URL;

export function getCartFromServer(anonymousId, authToken) {
    if (authToken) {
        return axios.get(CHECKOUT_BASE_URL + '/cart', {
            headers: getHeaderForApiRequest(authToken)
        });
    } else {
        return axios.get(CHECKOUT_BASE_URL + '/cart', { params:{ anonymousId } });
    }
}

export function putCart(cartData, authToken) {
    return axios.put(CHECKOUT_BASE_URL + '/cart', cartData, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function changeCartContract(sku, isCheckout, anonymousId, authToken) {
    const requestBody = authToken ? { 'sku': sku, 'isCheckout': isCheckout } : { sku, anonymousId };
    return axios.put(CHECKOUT_BASE_URL + '/cart/change-contract', requestBody, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function updateDiscountCode(code, authToken) {
    const requestBody = { 'discountCode': code, 'isUpgrade': false }
    return axios.put(CHECKOUT_BASE_URL + '/cart/add-discount', requestBody, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function updateDiscountCodeForUpgrade(code, authToken) {
    const requestBody = { 'discountCode': code, 'isUpgrade': true }
    return axios.put(CHECKOUT_BASE_URL + '/cart/add-discount', requestBody, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function updatePaymentMethod(method, authToken) {
    return axios.put(CHECKOUT_BASE_URL + '/cart/payment-method', { method }, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function updateCampaign(campaign, authToken) {
    return axios.put(CHECKOUT_BASE_URL + '/cart/campaign', { campaign }, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function updateInstalledBaseUnits(installedBaseUnitData, authToken) {
    return axios.post(CHECKOUT_BASE_URL + '/cart/update-base-unit', { installedBaseUnitData }, {
        headers: getHeaderForApiRequest(authToken)
    });
}

export function getIsCampaign(authToken) {
    return axios.get(CHECKOUT_BASE_URL + '/cart/is-campaign', {
        headers: getHeaderForApiRequest(authToken)
    });
}