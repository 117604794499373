module.exports = {
    inputs: {
        label: "E-post",
        id: "e-post",
        name: "e-post",
        placeholder: "Din e-postadress",
        type: "e-post"
    },
    success: "Du har begärt att få återställa lösenordet, kontrollera din e-postinkorg!",
    subheadline:"Glömt lösenord?",
    headline:"Glömt lösenord",
    submit: "Skicka"
}