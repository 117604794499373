module.exports = {
    protected: "Du skyddas av Aquarea Service+",
    heatPumpsCorrectly: "",
    callHotline: "Kontakta kundtjänst",
    sendMail: "Skicka e-post",
    gotoCloud: "Gå till Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "smart",
        comfort: "Aquarea comfort"
    },
    imageTags:{
        exclusive: "Akverea-bidrag",
        advance:"Aquerea smart",
        smart: "Akverea basic"
    },
    downgradeInfo1: "Din skyddsplan är planerad att nedgraderas till ",
    downgradeInfo2: ". Denna nedgradering tillämpas efter att den aktuella avtalsperioden har avslutats.",
    contactmail: "pan_aircon_customer_sup_h1_se@eu.panasonic.com",
}