module.exports = {
    title: "Cancelación",
    headline: "Sentimos que quieras cancelar tu suscripción.",
    btnReturnToProfile: "Volver a mi cuenta",
    cancelSectionTitle: "Cancelar suscripción",
    cancelText:"¿Estás seguro de que quieres cancelar tu suscripción y, por tanto, tu plan de mantenimiento Aquarea Service+ ?",
    btnCancelSubscription: "Sí, cancelar mi suscripción",
    downgradeSectionTitle: "Disminuye tu suscripción",
    downgradeText: "Las siguientes opciones de disminución están disponibles:",
    btnDowngradeOrder: "Disminuir mi suscripción",
    downgradeOptions: {
        silver: "Cambio a plan de mantenimineto Smart Aqueare Service +",
        bronze: "Cambio a plan de mantenimineto Comfort Aqueare Service +"
    }
}
