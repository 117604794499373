module.exports = {
    headline: "Val av uppgradering",
    currentProtectionPlan: "Nuvarande tjänstepaket",
    protectionPlan: "Servicepakke",
    validUntil: "Kontrakt förfaller den:",
    costs: "Kostnader",
    period: "Betalningsperiod",
    monthly:"månadsvis",
    quarterly:"per kvartal",
    annually:"årligen",
    notUpgradableText: "Du kan inte uppgradera från ditt nuvarande avtal.",
    backButtonText: "Tillbaka till mitt konto"
};