import React from 'react';

// Anonym:
// {
//     "anonymousCartId": "978d3a66-e041-4fba-ad21-f59193348b84",
//     "anonymousId": "b6de97e0-8453-45fc-9d00-c6a0ddf9a6ae",
//     "lineItems": [],
//     "total": {
//     "amount": 0.00,
//     "currency": "DKK"
// }
// 
// Logged In:
// {
//     "id": "ad03309c-d335-4f3a-935b-fdf684509c8a",
//     "lineItems": [],
//     "total": {
//     "amount": 0.00,
//     "currency": "EUR"
// }
export const CartContext = React.createContext({
    cartContext: null,
    setCartContext: () => {}
});
