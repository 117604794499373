module.exports = {
  headline: "Bra service och supportförmåner",
  text: "Aquarea Service+-paketen innehåller många fördelar, så det finns alternativ som passar alla behov. Några av fördelarna är:",
  list: [
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "Fjärrövervakning dygnet runt",
      text: "Driftparametrar för ditt Aquarea-system övervakas och är tillgängliga för att möjliggöra hjälpsam fjärrsupport."
    },
    {
      iconUrl: "/image/icons/services/alert_notification.png",
      iconAlt: "",
      headline: "Smart Aquarea-rapport",
      text: "Du får anpassade rapporter om ditt värmesystem, med råd och tips för att förbättra prestanda och effektivitet."
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Underhållsbesök",
      text: "Alla paket inkluderar ett årligt underhållsbesök på plats av Panasonic Service+-tekniker."
    },
    {
      iconUrl: "/image/icons/services/247_hotline.png",
      iconAlt: "",
      headline: "Daglig diagnoskontroll",
      text: "Panasonic Service+ Team gör dagliga kontroller för att övervaka eventuella onormala parametrar i ditt system."
    },
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "Prioriterad supportlinje dygnet runt",
      text: "Ring oss när som helst, vilken dag som helst om du har frågor om din värmepump."
    },
    {
      iconUrl: "/image/icons/services/repair_discount.png",
      iconAlt: "",
      headline: "Svarstid inom 24 timmar",
      text: "Enheten får support inom 24 timmar alla vardagar, med hjälp av fjärrstyrning eller assistans på plats."
    }
  ]
};
