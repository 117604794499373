module.exports = {
    points:[
        {
            label: "#1",
            text: "Leverantör av värmepumpar"
        },
        {
            label: "60 år",
            text: "erfarenhet av värmepumpar"
        },
        {
            label: "24/7",
            text: "övervakning via IoT (Internet of Things)"
        },
        {
            label: ">100K",
            text: "anslutna värmepumpar"
        },
    ]
};
