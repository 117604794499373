    module.exports = {
    headline: "Ordine di riparazione",
    thanks: "Grazie per aver contattato Aquarea+ Service!",
    text: "Il suo ordine di riparazione è stato completato con successo. Dopo aver completato il pagamento, troverà la fattura nella sua casella di posta elettronica.",
    invoiceAmountText: "Importo della fattura: ",
    isPaidText: "L'ordine di riparazione è stato completato con successo e la fattura è stata elaborata con successo.",
    noInvoiceText: "L'ordine di riparazione è stato completato con successo e la fattura è stata elaborata con successo.",
    payButton: "Paga adesso",
    pendingButtonText: "In Corso",
    modal: {
        modalTitle: "Convalida del codice fiscale",
        modalBody: "È obbligatorio inserire il codice fiscale per effettuare un pagamento. Inserire e convalidare il codice fiscale per continuare.",
        emptyOption: "Selezionare il tipo di codice fiscale",
        inputPlaceholder: "Inserire il codice fiscale",
        modalPrimaryButton: "Convalidare il codice fiscale",
        errorMessage: "Il codice fiscale inserito non è valido. Si prega di inserire un codice fiscale valido.",
    },
};
