import React from "react";
import { NavLink } from "react-router-dom";
import { withBreadcrumbs } from "./withBreadcrumbs";
import i18 from "./i18n";
import {
  getSessionStorageItem,
  COUNTRY_CODE_NAME,
} from "./sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("Breadcrumbs"); 

const routes = [
  { path: "/"+ countryCode +"/home", breadcrumb: "home" },
  { path: "/"+ countryCode +"/home/service_package", breadcrumb: "protectionPlans" },
  { path: "/"+ countryCode +"/service", breadcrumb: "service" },
  { path: "/"+ countryCode +"/404", breadcrumb: "error" },
  { path: "/"+ countryCode +"/home/checkout", breadcrumb: "checkout" },
  { path: "/"+ countryCode +"/home/login", breadcrumb: "login" },
  { path: "/"+ countryCode +"/home/my_account", breadcrumb: "myAccount" },
  {
    path: "/"+ countryCode +"/home/my_account/cancel_protection_plans",
    breadcrumb: "cancelProtectionPlan",
  },
  { path: "/"+ countryCode +"/home/my_account/edit", breadcrumb: "editMyAccount" },
  { path: "/"+ countryCode +"/home/password_reset", breadcrumb: "passwordReset" },
  { path: "/"+ countryCode +"/home/my_account/upgrade", breadcrumb: "upgradeProtectionPlan" },
  { path: "/"+ countryCode +"/home/upgrade", breadcrumb: "upgradeProtectionPlan" },
  { path: "/"+ countryCode +"/home/my_account/upgrade/checkout", breadcrumb: "upgrade" },
  { path: "/"+ countryCode +"/home/upgrade/checkout", breadcrumb: "upgrade" },
  { path: "/"+ countryCode +"/home/terms_and_conditions", breadcrumb: "termsConditions" },
  { path: "/"+ countryCode +"/home/right_of_withdrawal", breadcrumb: "rightOfWithdrawal" },
  { path: "/"+ countryCode +"/home/terms_of_use_of_the_website", breadcrumb: "termsOfUse" },
  { path: "/"+ countryCode +"/home/privacy_policy", breadcrumb: "privacyPolicy" },
  { path: "/"+ countryCode +"/home/cookie-policy", breadcrumb: "cookiePolicy" },
  { path: "/"+ countryCode +"/home/updatepayment", breadcrumb: "updatePayment" },
  { path: "/"+ countryCode +"/home/register_unit", breadcrumb: "registerUnit" },
  { path: "/"+ countryCode +"/home/faq", breadcrumb: "faq" },
];

const Breadcrumbs = ({ breadcrumbs }) => {
  const breadcrumbsWithLink = breadcrumbs.slice(0, breadcrumbs.length - 1);
  const breadcrumbsWithoutLink = [breadcrumbs[breadcrumbs.length - 1]];
  return (
    breadcrumbs.length > 1 && (
      <div>
        {breadcrumbsWithLink.map(({ breadcrumb, path, match }, i) => (
          <span key={path}>
            <NavLink to={match.url}>{t(breadcrumb)}</NavLink>
            {breadcrumbs.length === i + 1 ? <></> : <span> > </span>}
          </span>
        ))}
        {breadcrumbsWithoutLink.map(({ breadcrumb, path, match }, i) => (
          <span key={path}>
            <span>{t(breadcrumb)}</span>
            {breadcrumbsWithoutLink.length === i + 1 ? <></> : <span> > </span>}
          </span>
        ))}
      </div>
    )
  );
};

export default withBreadcrumbs(routes)(Breadcrumbs);
