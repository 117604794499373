import {formatMoneyByCountryCode} from "./moneyHelper";

export function getPriceForSku(sku, productContext) {
    if (sku && productContext && productContext.plans) {
        const product = getProductByVariant(productContext, sku);
        if(product){
            const price = product.variants.find(x => x.sku === sku).price;
            return formatMoney(price.amount, price.currency);
        }
    }
    return "";
}

export function getPricePerMonthForSku(sku, productContext) {
    if (sku && productContext && productContext.plans) {
        const product = getProductByVariant(productContext, sku);
        if(product){
            const price = product.variants.find(x => x.sku === sku).pricePerMonth;
            return formatMoney(price.amount, price.currency);
        }
    }
    return "";
}

export function getPaymentPeriodForSku(sku, productContext) {
    if (sku && productContext && productContext.plans) {
        const product = getProductByVariant(productContext, sku);
        if(product){
            return product.variants.find(x => x.sku === sku).paymentPeriod;
        }
    }
    return "";
}

export function getVariantSkuForPeriod(productSku, paymentPeriod, productContext){
    const product = getProductByVariant(productContext, productSku);
    return product.variants.find(x => x.paymentPeriod === paymentPeriod).sku;
}

export function getPriceForContractByPaymentPeriod(sku, paymentPeriod, productContext){
    const product = getProductByVariant(productContext, sku);
    const variant = product.variants.find(x => x.paymentPeriod === paymentPeriod);
    const price = variant.price;
    return formatMoney(price.amount, price.currency);
}

export function getProductByVariant(productContext, variantSku){
    return productContext?.plans?.find(x => containsSku(x.variants,variantSku));
}

export function getMinMonthlyPriceForSku(sku, productContext, countryCode) {
    if (sku && productContext && productContext.plans) {
        const monthlyVariant = productContext.plans.find(plan => plan.sku === sku)
        if(!!monthlyVariant)
            return formatMoneyByCountryCode(monthlyVariant.price.amount, countryCode)
    }
    return "";
}

export function getMinMonthlyPricePaymentPeriodForSku(sku, productContext, countryCode) {
    if (sku && productContext && productContext.plans) {
        const sortedVariants = getVariantsSortedByMonthlyPrice(sku, productContext);
        //show price for cheapest variant
        if(sortedVariants.length > 0)
            return sortedVariants[0].paymentPeriod;
    }
    return "";
}

function containsSku(variants, sku) {
    for(var i in variants){
        if(variants[i].sku === sku)
            return true;
    }
    return false;
}

function getVariantsSortedByMonthlyPrice(sku, productContext){
    const selectedProduct = productContext.plans.find(x => x.sku === sku);
    if(!selectedProduct){
        return [];
    }
    const variants = selectedProduct.variants;
    let sortedVariants = [];
    for (var i in variants) {
        sortedVariants.push(variants[i]);
    }
    return sortedVariants.sort(function(a, b) {
        return b.price.amount - a.price.amount;
    });
}

export function formatMoney(money, currency ="DKK") {
    if (currency === "EUR") {
        currency = "€"
    }
    return `${parseFloat(money).toFixed(2).toString().replace(".", ",")} ${currency}`;
}