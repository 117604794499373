module.exports = {
    headline: 'Mantente al día',
    icons: [
        {
            icon: "facebook",
            link: "https://www.facebook.com/PanasonicHeatingCoolingEU/"
        },
        {
            icon: "youtube",
            link: "https://www.youtube.com/c/PanasonicHeatingCoolingSolutionsEurope"
        },
        {
            icon: "linkedin",
            link: "https://www.linkedin.com/company/panasonic-heating-and-cooling-solutions-europe/"
        },
    ],
    links: [
        {
            href: "/es_ES/home/terms_and_conditions",
            label: "Lucha contra la corrupción"
        },
        {
            href: "/es_ES/home/cookies/",
            target: "_blank",
            label: "Política de cookies"
        },
        {
            href: "/es_ES/home/privacy_policy",
            label: "Política de privacidad"
        },
        {
            href: "/es_ES/home/terms_and_conditions",
            label: "Términos y condiciones"
        },
    ],
    copyright: "Copyright 2021 Panasonic Marketing Europe GmbH, todos los derechos reservados",
};
