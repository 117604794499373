module.exports = {
  success: {
    title: "Muchas gracias!",
    headline: "Su pago se ha realizado correctamente!",
    subheadline: "¡Muchas gracias, ",
    btn: "IR A MI CUENTA",
  },
  failure: "Su pago ha sido rechazado en nuestro sistema de pago asociado.",
  cancel: "Su pago ha sido cancelado.",
  error: "Se ha producido un error durante el pago en nuestro sistema de pago asociado.",
  expired: "Su sesión de pago ha caducado en nuestro sistema de pago asociado.",
  pending: "Su pago aún está en curso en nuestro sistema de pago asociado. Esto puede tardar hasta 10 minutos.",
  link: "IR A MI CUENTA",
  link_href: "/es_ES/home/my_account",
};