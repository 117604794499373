module.exports = {
    editBtn: "modifica",
    confirmBtn: "conferma ordine e paga",
    connectionCheck: "Controllare la connessione",
    accountInformation: "Informazioni Account",
    paymentDetails: "Metodo di pagamento",
    installedBase: "Informazioni sul dispositivo",
    monthly:"mensile (IVA inclusa)",
    quarterly:"per trimestre (IVA inclusa)",
    annually:"annualmente (IVA inclusa)",
    yes: "Sì",
    no: "No",
    details: {
        monthlyPrice:"Costi mensili",
        connectionFeePrice:"Costi del servizio di installazione una tantum",
        internetConnected: "Hai un adattatore Cloud installato?",
        internetReady: "Il tuo dispositivo è pronto per la connessione?",
        modelName: "Modello",
        serialNumber: "Numero di serie",
        installationDate: "Data di installazione",
        gatewayId: "ID del gateway",
        title: "titolo",
        firstName: "Nome",
        lastName: "COGNOME",
        name: "Nome",
        email: "Email",
        address : "Indirizzo",
        zipCode : "C.A.P.",
        city: "Città (Provincia)",
        phoneNumber: "Numero di telefono",
        phone : "recapito telefonico",
        paymentMethod: "Metodo di pagamento"
    },
    confirm: "conferma"
}
