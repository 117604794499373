module.exports = {
    yourProtectionPlan: "Your Service Package",
    contract: "Contract",
    invoiceHistory: "Payments history",
    accountInformation: "Account Information",
    welcome: "Welcome, ",
    noServicePackage: "No Service Package yet",
    editAccount: "Edit Account Information",
    finishCheckout: "Incomplete checkout",
    gotoCheckout: "Finish incomplete checkout",
    finishUpgrade: "Finish order upgrade",
}
