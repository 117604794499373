import axios from "axios";
import { getHeaderForApiRequest } from "./dataAccessHelper";

const BASE_CUSTOMER_URL = process.env.REACT_APP_API_ENDPOINT_BASE_URL;
const BASE_CHECKOUT_URL = process.env.REACT_APP_API_CHECKOUT_ENDPOINT_BASE_URL;

export const getAccount = async (token) => {
  return await axios.get(
    `${BASE_CUSTOMER_URL}/account`,
    {
      headers: getHeaderForApiRequest(token),
    }
  );
};

export const getDowngradeInfo = async (token, orderId) => {
    return await axios.get(
        `${BASE_CHECKOUT_URL}/fe-order/` + orderId  + "/downgradehint",
        {
            headers: getHeaderForApiRequest(token),
        }
    )
};

export const getPaymentSchedule = async (orderId, token) => {
  return await axios.get(
    `${BASE_CUSTOMER_URL}/account/payment-schedule/${orderId}`,
    {
      headers: getHeaderForApiRequest(token),
    }
  );
};

export const editAccount = async (token, account) => {
  return await axios.put(
    `${BASE_CUSTOMER_URL}/account`,
    account,
    {
      headers: getHeaderForApiRequest(token),
    }
  );
};
