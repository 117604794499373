module.exports = {
    headline: "Comprobación",
    list: [
        {
            label: "Comprobación de la conexión",
            href: "/es_ES/home/service_package",
            href_label: "VOLVER A LOS PLANES DE PROTECCIÓN",
            next_btn: "SIGUIENTE PASO",
            add_installation_items: {
                add_installation_btn: "Añade el servicio de instalación por 1.495,00DKK",
                add_installation_label: "Información importante:",
                add_installation_text: 'Para poder ofrecerle el servicio completo de nuestros Planes de Protección, es necesario que su dispositivo esté conectado a Internet. <br><br> Para asegurar que su dispositivo está conectado a Internet, le recomendamos que reserve nuestro paquete de servicio de instalación de Internet. Este servicio le costará una tarifa única de 49€. <br><br>Añadiremos automáticamente nuestro servicio de instalación al proceso de compra y le llamaremos para concertar una hora para instalarlo.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "SIGUIENTE PASO",
                change_plan_btn: "Cambiar mi selección al Plan Aquarea*",
                change_plan_link: "Más información sobre el Plan Aquare *",
                change_plan_label: "Información importante:",
                change_plan_text: 'No podemos ofrecerte todos nuestros Planes de Protección en este momento porque tu dispositivo no puede estar conectado a Internet.Para ofrecerte el mejor servicio posible, cambiaremos tu Plan de Protección seleccionado a Aquarea Bronze en este proceso de compra. <br><br>Su Plan de Protección seleccionado: Plan Aquarea *** <br><br>Cambiará a: Plan Aquarea* por 25€ al mes.',
                change_plan_dropdowntext: 'Con nuestro contrato de mantenimiento Aquarea Guard tiene una preocupación menos. Su bomba de calor está permanentemente monitorizada a distancia. Y si se produce un error, somos los primeros en saberlo. Nos pondremos en contacto con usted inmediatamente e intentaremos solucionar el problema o coordinar una cita con el servicio técnico.<br><br>No tiene que hacer nada más que tomar una taza de té mientras tanto.',
                change_plan_dropdowntext_close_btn: true,
                change_plan_dropdowntext_close_btn_label: "INFORMACIÓN DE PROXIMIDAD",
            },
            inputList: [
                {
                    text: "Para proporcionarle un servicio óptimo, necesitamos asegurarnos de que sus dispositivos están conectados a Internet.",
                    label: "¿Está su unidad conectada a Internet?",
                    width: "completo",
                    name: "internetConexión",
                    startValue: true,
                    hint: {
                        label: "¿no está seguro?",
                        text: "prueba",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sí",
                                key: "InternetConectado",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "InternetConectado",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "¿Está su unidad preparada para Internet?",
                    width: "completo",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "¿no está seguro?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sí",
                                key: "InternetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "InternetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
            ],
        },
        {
            label: "Base instalada",
            href: "/service_package",
            href_label: "VOLVER A LOS PLANES DE PROTECCIÓN",
            next_btn: "SIGUIENTE PASO",
            inputList: [
                {
                    headline: "¿Qué modelo está instalado?",
                    inputs: [
                        {
                            label: "NOMBRE DEL MODELO",
                            name: "model",
                            placeholder: "KIT-ADC05JEB5",
                            type: "seleccione",
                            width: "completo",
                            options: [
                                "KIT-ADC05JEB5",
                                "KIT-WQC09H3E8",
                                "KIT-WQC12H9E8",
                                "WH-MXC09H3E5",
                                "WH-MXC12H6E5",
                                "WH-MXC09H3E8",
                            ]
                        },
                        {
                            label: "NÚMERO DE SERIE",
                            name: "número de serie",
                            placeholder: "X03YWWXXX",
                            type: "text",
                            width: "completo"
                        }
                    ]
                },
                {
                    headline: "Si ya tiene el adaptador de red, introduzca el ID del dispositivo",
                    inputs: [

                        {
                            label: "DEVICE ID",
                            name: "gatewayid",
                            placeholder: "54321-12345",
                            type: "text",
                            width: "completo"
                        },
                    ]
                },
                {
                    headline: "¿Cuándo se instaló la unidad?",
                    inputs: [
                        {
                            label: "FECHA DE INSTALACIÓN",
                            name: "installationDate",
                            placeholder: "12/22/2020",
                            type: "fecha",
                            width: "completo",
                        },
                    ]
                },
            ],
        },
        {
            label: "Información de la cuenta",
            href: "/service_package",
            href_label: "VOLVER A LOS PLANES DE PROTECCIÓN",
            next_btn: "SIGUIENTE PASO",
            inputList: [
                {
                    headline: "Crear una nueva cuenta",
                    login: "INSCRIBIRSE",
                    login_path: "",
                    login_label: "¿Ya es usuario?",
                    inputs: [
                        {
                            label_first: "EMAIL",
                            label_last: "REPETIR EL CORREO ELECTRÓNICO",
                            name: "correo electrónico",
                            placeholder: "Envíe un correo electrónico a",
                            type: "matching_email_values",
                            width: "completo",
                            checkId: "correo electrónico",
                            inputs:{
                                type: "correo electrónico",
                                validation: "matching_email"
                            }
                        },
                        {
                            label_first: "CONTRASEÑA",
                            label_last: "REPETIR CONTRASEÑA",
                            name: "contraseña",
                            placeholder: "Contraseña",
                            type: "contraseña",
                            width: "completo",
                            checkId: "contraseña",
                            inputs:{
                                type: "contraseña",
                                validation: "matching_password"
                            }
                        },
                    ]
                },
                {
                    headline: "Dirección de la cuenta",
                    inputs: [
                        {
                            label: "TÍTULO",
                            name: "título",
                            placeholder: "Sra.",
                            type: "seleccione",
                            width: "pequeño",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            type: "white_space",
                            width: "pequeño",
                        },
                        {
                            label: "NOMBRE",
                            name: "firstName",
                            placeholder: "Nombre",
                            type: "text",
                            width: "pequeño",
                            validation: "requerido"
                        },
                        {
                            label: "Apellido",
                            name: "lastName",
                            placeholder: "Apellido",
                            type: "text",
                            width: "pequeño",
                            validation: "requerido"
                        },
                        {
                            label: "CALLEJA",
                            name: "callejero",
                            placeholder: "callejero",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "CÓDIGO POSTAL",
                            name: "código postal",
                            placeholder: "1820",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "CIUDAD",
                            name: "ciudad",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "NÚMERO DE TELÉFONO",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "completo"
                        }
                    ]
                },
                {
                    headline: "¿Dónde se encuentra la unidad?",
                    inputs: [
                        {
                            labels: [
                                {
                                    id: "same_address",
                                    label: 'Same as account</br>address',
                                    name: "same_acc",
                                    value: "",
                                    width: "pequeño"
                                },
                                {
                                    id: "diff_address",
                                    label: 'Enter different</br>address',
                                    name: "diff_address",
                                    value: "",
                                    width: "pequeño"
                                }
                            ],
                            type: "radio",
                            width: "completo",
                        },
                    ]
                }
            ],
        },
        {
            label: "Datos de pago",
            href: "/service_package",
            href_label: "VOLVER",
            text: "Por favor, seleccione su método de pago El pago se completará después de la compra",
            next_btn: "SIGUIENTE PASO",
            inputList: [
                {
                    headline: "Introduzca los datos de pago",
                    inputs: [
                        {
                            label: "Forma de pago",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Forma de pago",
                                    key: "paymentOpt",
                                    value: "crédito",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Forma de pago",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "completo"
                        },
                        {
                            label: "NÚMERO DE TARJETA",
                            name: "card_number",
                            placeholder: "Número de tarjeta",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "FECHA DE CADUCIDAD",
                            name: "expiration_date",
                            placeholder: "Fecha de caducidad",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "CVV",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            labels: [
                                {
                                    id: "derechos de datos",
                                    label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                                    name: "derechos de datos",
                                    value: "",
                                    width: "completo"
                                },
                            ],
                            type: "radio",
                            width: "completo",
                        }
                    ]
                },
            ],
        },
        {
            label: "Confirmación",
            href: "/service_package",
            href_label: "VOLVER A LOS PLANES DE PROTECCIÓN",
            next_btn: "SIGUIENTE PASO",
            inputList: [
                {
                    headline: "Crear una nueva cuenta",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "Envíe un correo electrónico a",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "CONTRASEÑA",
                            placeholder: "Contraseña",
                            type: "text",
                            width: "pequeño"
                        }
                    ]
                },
                {
                    headline: "Dirección de la cuenta",
                    inputs: [
                        {
                            label: "TIPO DE CLIENTE",
                            name: "cliente",
                            placeholder: "Privado",
                            type: "seleccione",
                            width: "pequeño",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "TÍTULO",
                            name: "título",
                            placeholder: "Sra.",
                            type: "seleccione",
                            width: "pequeño",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "NOMBRE",
                            name: "first_name",
                            placeholder: "Nombre",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "Apellido",
                            name: "last_name",
                            placeholder: "Apellido",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "CALLEJA",
                            name: "callejero",
                            placeholder: "dirección",
                            type: "text",
                            width: "completo"
                        },
                        {
                            label: "CÓDIGO POSTAL",
                            name: "código postal",
                            placeholder: "1820",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "CIUDAD",
                            name: "ciudad",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "pequeño"
                        },
                        {
                            label: "NÚMERO DE TELÉFONO",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "completo"
                        }
                    ]
                }
            ],
        }
    ],
    summary: {
        conformation: {
            headline: "CONFIRMACIÓN",
            subheadline: "Plan seleccionado"
        },
    },
};
