import React, {useEffect, useState} from 'react';
//add helper from lib
import {FormGroup, InputGroup, ValidationMessage} from "../layout/form";
import classNames from "classnames";
import {getValidationMessage, getValidators, isOptional, showValidation} from "./validators";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormItem = (props) => {
    const item = props.item;
    let checkBoxValue;
    if (item.type === "checkbox") {
        checkBoxValue = props.checked;
    }
    const {setCompareEmailValues, compareEmailValues} = props
    const {setComparePasswordValues, comparePasswordValues} = props
    const validators = getValidators(item.validators);
    const isOptionalFormItem = isOptional(item.validators);
    const type = item.type;
    const constraints = item.constraints ?? {};
    const showSomeMessages = props.showSomeMessages;
    const validationMessages = item.validationMessages;
    const country = props.country;
    const [state, setState] = useState({payments: [], error: false, message: ""});
    const [controlValue, setControlValue] = useState(props.currentValue ?? '');
    const [touched, setTouched] = useState(false);
    const [disabled, setDisabled] = useState(props.disabled);
    const [visible, setVisible] = useState(true);

        const countries = {
            "IE": "Ireland",
            "SE": "Sverige",
            "DK": "Danmark",
            "IT": "Italia",
            "ES": "España"
    };

    useEffect(() => {
        setControlValue(props.currentValue);
        setDisabled(props.disabled);
        if (props.currentValue) {
            setVisible(true);
            setTouched(props.runInitialValidation || false)
            onControlChange({
                target: {
                    name: item.name,
                    title: item.name,
                    value: props.currentValue,
                    checked: props.currentValue === "true" || props.currentValue === true ? true : false
                }
            });
        } else {
            setVisible(false);
            onControlChange({
                target: {
                    name: item.name,
                    title: item.name,
                    value: controlValue,
                    checked: controlValue
                }
            });
        }


    }, [props.currentValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (showSomeMessages) {
            setTouched(true);
        }
    }, [showSomeMessages])

    useEffect(() => {
        if(item.name === 'email' || item.name === 'repeat-email'){
            onControlChange({target: {
                value:controlValue,
            }})
        }
    },[compareEmailValues?.email]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if(item.name === 'password' || item.name === 'repeat-password'){
            onControlChange({target: {
                value:controlValue,
            }})
        }
    },[comparePasswordValues?.password]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        if (item.type === "select" && item.options.length === 1) {
          setControlValue(item.options[0]);
          onControlChange({
            target: {
              name: item.name,
              value: item.options[0],
            },
          });
        }
    }, [item.options]); // eslint-disable-line react-hooks/exhaustive-deps

    const shouldShowValidation = () => showValidation(state.error, touched, !!props.backendValidationError);
    if(item.name === "phoneNumber"){
        item.value = controlValue.slice(item.offset).replace(/\s/g,'')
    }
    const validate = (value) => {
        const validationState = {
            invalid: false,
            errorKey: ''
        }
        if (validators.length === 0) {
            return validationState;
        }

        if(item.name === "phoneNumber"){
            value = !!item.value? value : item.value
            value = value?.replace('+', '')
        }
        validationState.invalid = validators.some(validator => {
            const error = validator(value, constraints, props.currentStepValues);

            if (error) {
                validationState.errorKey = error;
            }

            return !!error;
        })

        return validationState;
    }

    const onControlChange = (event) => {
        let value = event.target.value;

        if (event.target.name === "sendInvoice") {
            value = !!event.target.checked
        }

        /*
        if (!value) {
            value = props.currentValue;
        }
        */
        const {invalid, errorKey} = validate(value);

        setControlValue(value);
        const e = {
            target: {
                name: item.name,
                value: value,
            }
        }
        if(item.name === 'email' || item.name === 'repeat-email'){
            let name = item.name === 'repeat-email' ? 'repeatMail': 'email'
            setCompareEmailValues(value,name )
        }
        if(item.name === 'password' || item.name === 'repeat-password'){
            let name = item.name === 'repeat-password' ? 'repeatPassword': 'password'
            setComparePasswordValues(value,name )
        }
        //console.debug(`Control: ${event.target.name} is invalid: ${invalid} and has Error: ${errorKey}`, event.target.value);
        setState({...state, error: invalid, message: errorKey});
        props.saveValueByStep(e, invalid);
    }

    const onControlBlur = event => {
        setTouched(true);
        onControlChange(event);
    }

    const onPhoneChange = (val, data, event, formattedValue) => {
        const {invalid, errorKey} = validate(!!item.value? formattedValue : item.value);
        setControlValue(formattedValue);
        setState({...state, error: invalid, message: errorKey});
        props.saveValueByStep(event, invalid);
    }
    const renderInputsByType = (type) => {
        switch (type) {
            case "text":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}
                               isOptional={isOptionalFormItem}>
                        <input
                            className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                            type={type}
                            placeholder={item.placeholder}
                            id={item.name}
                            name={item.name}
                            disabled={disabled}
                            value={controlValue}
                            onBlur={event => onControlBlur(event)}
                            onChange={event => onControlChange(event)}
                            maxLength={item.maxLength}
                        />
                        <ValidationMessage
                            message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                    </FormGroup>
                );
            case "email":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <input
                            className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                            type={type}
                            placeholder={item.placeholder}
                            id={item.name}
                            name={item.name}
                            disabled={disabled}
                            value={controlValue}
                            onBlur={event => onControlBlur(event)}
                            onChange={event => onControlChange(event)}
                        />
                        <ValidationMessage
                            message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                    </FormGroup>
                );
            case "select":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <select className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                                name={item.name}
                                id={item.name}
                                disabled={disabled || item.options.length === 0}
                                value={controlValue}
                                onBlur={event => onControlBlur(event)}
                                onChange={event => onControlChange(event)}>
                            {item.emptyOption && item.options.length > 1 && <option value="">{item.emptyOption}</option>}
                            {
                                item.options.map((option, i) =>
                                    <option key={i} value={option}>{option}</option>
                                )
                            }
                        </select>
                        <ValidationMessage
                            message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                    </FormGroup>
                );
            case "password":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <input
                            className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                            type={type}
                            placeholder={item.placeholder}
                            id={item.name}
                            name={item.name}
                            disabled={disabled}
                            value={controlValue}
                            onBlur={event => onControlBlur(event)}
                            onChange={event => onControlChange(event)}
                        />
                        <ValidationMessage
                            message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                    </FormGroup>
                );
            case "date":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <InputGroup prependIcon={<img src="/image/icons/datepicker.svg" alt=""/>}>
                            <input
                                id={item.name}
                                className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                                type={type}
                                placeholder={item.placeholder}
                                min="01.01.2000"
                                max={new Date().toJSON().split('T')[0]}
                                name={item.name}
                                disabled={disabled}
                                value={controlValue}
                                // onFocus={(event => _onFocus(event))}
                                onBlur={e => onControlBlur(e)}
                                onChange={event => onControlChange(event)}
                            />
                            <ValidationMessage
                                message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                        </InputGroup>
                    </FormGroup>
                )
            case "checkbox":
                return (
                    <FormGroup htmlFor={item.name} className={item.width}>

                        <InputGroup>
                                <input
                                    id={item.name}
                                    type={type}
                                    name={item.name}
                                    value={controlValue}
                                    checked={checkBoxValue}
                                    onChange={event => onControlChange(event)}
                                />
                                <label htmlFor={item.name}>{item.label}</label>
                        </InputGroup>
                    </FormGroup>

                )
            case "country":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <input
                            className={classNames("form-control", {"is-invalid": shouldShowValidation()})}
                            type={type}
                            placeholder={countries[country]}
                            id={item.name}
                            name={item.name}
                            disabled={true}
                            value={countries[country]}
                        />
                    </FormGroup>
                );
            case "phone":
                return (
                    <FormGroup label={item.label} htmlFor={item.name} className={item.width}>
                        <PhoneInput
                            className={classNames({"is-invalid": shouldShowValidation()})}
                            inputStyle={{color: "steelblue", height: "55px", width: "420px"}}
                            containerStyle={{height: "55px"}}
                            inputProps={{
                                name: 'phoneNumber',
                                required: true,
                            }}
                            country={country.toLowerCase()}
                            onlyCountries={["dk", "es", "ie", "it", "se"]}
                            value={controlValue}
                            onBlur={e => onControlBlur(e)}
                            onChange={onPhoneChange}
                            enableLongNumbers={18}
                            autoFormat={false}
                            placeholder=''
                        />
                        <ValidationMessage
                                message={getValidationMessage(state.message, constraints, validationMessages, props.backendValidationError)}/>
                    </FormGroup>
                );
            default:
                return <div>{type}</div>
        }
    }

    if (props.isLogin && visible === false) {
        if(item.name !== 'sendInvoice') // update visibility feature cause checkbox to disapper in
        // checkout process after login. Skipping with condition untill the visibilty logic is verified
        return null;
    }
    return (
        <>{renderInputsByType(type)}</>
    )
};
export default FormItem;
