import React, { useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom';
const RouteChangeTracker = () => {
    const history = useHistory();

    useEffect(()=>{
        history.listen((location, action) => {
        });
    }, [history]);
    return <div></div>;
};

export default withRouter(RouteChangeTracker);