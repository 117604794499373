module.exports = {
    headline: 'Keep up to date',
    icons: [
        {
            icon: "facebook",
            link: "https://www.facebook.com/PanasonicHeatingCoolingEU/"
        },
        {
            icon: "youtube",
            link: "https://www.youtube.com/c/PanasonicHeatingCoolingSolutionsEurope"
        },
        {
            icon: "insta",
            link: "https://www.linkedin.com/company/panasonic-heating-and-cooling-solutions-europe/"
        },
    ],
    links: [
        {
            href: "/en_ES/home/terms_and_conditions",
            label: "Anti Bribery Statement"
        },
        {
            href: "/en_ES/home/cookies/",
            label: "Cookie Policy"
        },
        {
            href: "/en_ES/home/privacy_policy/",
            label: "Privacy Policy"
        },
        {
            href: "/en_ES/home/terms_and_conditions",
            label: "Terms & Conditions"
        }
    ],
    copyright: "Copyright 2021 Panasonic Marketing Europe GmbH All Rights Reserved",
};
