import axios from "axios";
import { getHeaderForApiRequest } from "../dataAccessHelper";

const CMS_URL = process.env.REACT_APP_API_NODES_ENDPOINT_CMS_URL;
const CMS_VERSION = process.env.REACT_APP_CMS_VERSION; //latest for preview or published for prod

export function getDocument(documentPath) {
    return axios.get(CMS_URL + '/openapi/nodes/' + documentPath, {
        headers: getHeaderForApiRequest(),
        params: {version: CMS_VERSION}
    });
}