module.exports = {
    image: "",
    text: "Et varmesystem kræver regelmæssig vedligeholdelse for at bevare en god driftsevne og forlænge dets levetid mest muligt - fuldstændig ligesom med min bil. Med en Aquarea Service+ plan kan jeg slappe af, mens Panasonic tager sig af vedligeholdelsen.",
    author: "Cecilie M. Thomson",
    quotes:{
        image: "/image/home/quotes/logo.png",
        alt: "aquarea",
        headline: "Udforsk vores brede udvalg af udstyr til vores Aquarea-varmepumper",
        link: "Læs mere om Aquarea-varmepumper her",
        link_href: "https://www.aircon.panasonic.eu/DK_da/"
    }
};
