import React, {useEffect} from 'react';
import Menu from "../components/menu";
import {Page, PageFooter} from "../components/layout/page";
import UpgradeCheckoutForm from '../components/upgradeContract/upgradeCheckoutForm';
import {scrollToPos} from "../lib/scrollToPos";

function UpgradeCheckout() {

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    return (
        <Page>
            <Menu/>
            <UpgradeCheckoutForm/>
            <PageFooter/>
        </Page>
    )
}

export default UpgradeCheckout;
