module.exports = {
    slides:[
        {
            label: "#1",
            alt: "",
            image: "",
            text: ""
        },
    ]
};
