module.exports = {
    headline: 'Privacy Policy',
    link: "Go back to homepage",
    link_href: "/home",
    text: '<p class="label">Udkast privatlivspolitik 30. marts 2021</p>' +
        '<h3>Panasonics privatlivspolitik for vedligeholdelses-cloudservicer</h3>' +
        '<p>Hensigten med denne privatlivspolitik ("<strong>privatlivspolitik</strong>") er at fortælle dig, hvordan vi, Panasonic Marketing Europe GmbH, Tyskland, bruger de oplysninger, vi indsamler om dig (betegnet "personoplysninger", som det forklares herunder), når du besøger hjemmesiden for Aquarea Service+ ("<strong>hjemmesiden</strong>"), og når du bruger Aquarea Service+ (”<strong>Tjenesten</strong>”). Vi har prøvet at forklare, hvad vi gør, med detaljer nok til at give dig den information, du har brug for, på en overskuelig måde. Du kan følge linket, hvis du gerne vil vide mere. \n</p>' +
        '<p>Privatlivspolitikken gælder for Tjenesten, og når du tilgår Tjenesten via en computer, en mobiltelefon, en tablet, et tv eller en anden enhed.\n </p>' +
        '<p>Klik på linkene herunder for at få mere information om, hvordan vi indsamler og behandler personoplysninger:\n</p>' +
        '<h3>1.\tHvem er vi?</h3>' +
        '<p>I.\tPanasonic Marketing Europe GmbH, Hagenauer Straße 43, 65203 Wiesbaden, Tyskland, ("Panasonic", "vi", "os" eller "vores").\n </p>' +
        '\n' +
        '<p>II.\tVi er en del af Panasonic Corporation-koncernen, som har hjemsted i Kadoma, Osaka Prefecture, Japan.\n</p>' +
        '\n' +
        '<p>III.\tVi har en række koncernselskaber, som kan findes på <a href="http://www.panasonic.com/global/corporate/profile/group-companies.html#renketsu">http://www.panasonic.com/global/corporate/profile/group-companies.html#renketsu</a>.\n</p>' +
        '<h3>2.\tHvad er personoplysninger?</h3>' +
        '<p>I denne privatlivspolitik er "<strong>personoplysninger</strong>" alle oplysninger, som vedrører en identificeret eller identificerbar enkeltperson (såsom dig). Oplysninger, der identificerer dig, kan for eksempel være dit navn, din adresse, dit telefonnummer, fotos, betalingsoplysninger, identifikationsnummeret på en bestemt Panasonic luft-til-vand-enhed, som du har købt, lokalitetsdata, eller en onlineidentifikator (fx cookies og din IP-adresse). Oplysninger, der ikke i sig selv identificerer dig, men som vi sammenkæder med dig, betegnes også personoplysninger. </p>' +
        '<h3>3.\tHvilke personoplysninger indsamler Panasonic om dig?</h3>' +
        '<p><strong>A.\tPersonoplysninger, du giver os direkte om dig selv</strong></p>' +
        '<p>Tjenesten, vi tilbyder, muliggør eller kræver, at du giver os en række personoplysninger.</p>' +
        '<p><strong>I.\tNår du abonnerer på Tjenesten ved at afgive en ordre på et bestemt serviceprodukt på hjemmesiden og oprette en servicekonto.</strong></p>' +
        '<p>Når du opretter en konto for Aquarea Service+ på hjemmesiden, beder vi dig om at give os dit fornavn, dit efternavn, din e-mailadresse, din faktureringsadresse, placeringen af luft-til-vand-enheden og yderligere information om den, såsom modelbetegnelse, serienummer, gateway-id og monteringsdato, for at behandle din ordre. Vi vil bruge din e-mailadresse og din adgangskode til at oprette et login til din konto. Du bliver også bedt om at give os dine betalingsoplysninger. Vi ser og gemmer dog ikke dine betalingskortoplysninger. En tredjepart, Worldpay (UK) Ltd., Worldpay Limited, Worldpay AP Ltd. og/eller Worldpay BV, vil behandle dine betalingskortoplysninger, når du bruger et betalingskort til at abonnere på Tjenesten på hjemmesiden. Vi anbefaler, at du læser Worldpays privatlivspolitik på <a href="http://www.worldpay.com/uk/privacy-policy">http://www.worldpay.com/uk/privacy-policy</a> for at forstå, hvordan de bruger dine personoplysninger.</p>' +
        '<p><strong>II.\tNår du køber en installeringsservice for at forbinde din luft-til-vand-enhed til Aquarea Cloud  </strong></p>' +
        '<p>Nogle funktioner i Tjenesten i visse versioner kræver, at brugerens luft-til-vand-enhed har internetforbindelse til Aquarea Cloud. Du kan købe installeringsservice, når du opretter din konto til Tjenesten på hjemmesiden. Se vilkårene og betingelserne, privatlivspolitikken og cookiepolitikken for Aquarea Cloud på denne tjenestes hjemmeside for at få mere information.</p>' +
        '<h3>III.\tNår du kommunikerer med Panasonic</h3>' +
        '<p>Når du kontakter os eller kommunikerer med vores kundeservicepersonale ("<strong>Aquarea Service+-kundeservice</strong>") via e-mail, live chat eller telefon eller personligt, kan vi indsamle personoplysninger som dit navn, din postadresse, dit telefonnummer, din e-mailadresse og kontaktpræferencer, og vi kan også indsamle oplysninger om de Panasonic-luft-til-vand-enheder, du ejer, fx deres serienumre og købsdatoer. Vi kan også oprette logger/rapporter, der er nyttige ved diagnosticering af luft-til-vand-enheder eller problemer med applikationers funktion, og indsamle oplysninger relateret til problemet. Vi bruger disse oplysninger til at yde kundeservice og produktsupport til dig. Vi kan tilgå din servicekonto for at yde dig den nødvendige assistance eller support, du har brug for eller har bedt om. Vi kan optage og gennemgå dine samtaler med vores kundeservicepersonale (fx når du ringer til os) med henblik på uddannelse og behandling af klager og for at gennemgå eventuelle oplysninger, du har givet os som svar på en frivillig kundeundersøgelse. Vi optager og/eller gennemgår kun dine samtaler med os, når gældende lovgivning tillader det, og vi fortæller dig senest ved samtalens begyndelse, at vi gør det.</p>' +
        '<h3>IV.\tAbonnementer og nyhedsbreve</h3>' +
        '<p>Når du tilmelder dig vores kampagnemeddelelser og nyhedsbreve, indsamler vi personoplysninger fra dig, herunder din e-mailadresse, dit fornavn, dit efternavn og dit køn. Vi yder kun disse abonnementstjenester til dig i overensstemmelse med gældende lovgivning, og når du udtrykkeligt har bedt os om det. Vi kan sende dig information om vores produkter, der svarer til dem, du har bestilt på vores hjemmeside, en gang imellem.</p>' +
        '<h3>V.\tNår du uploader personoplysninger til hjemmesiden eller onlineapplikationer</h3>' +
        '<p>Tillige med ovenstående kan vi indsamle andre personoplysninger, som du giver os ved at uploade indhold (fx videoer og fotos) til hjemmesiden eller onlineapplikationer. Vi kan i så fald indsamle følgende personoplysninger  for at kunne vise indholdet på hjemmesiden eller i onlineapplikationer: Dit navn, din e-mailadresse og alle personoplysninger i det indhold, du vælger at uploade. Når vi indsamler den slags indhold, gør vi det kun i overensstemmelse med gældende lovgivning.</p>' +
        '<h3>B.\tOplysninger om din brug af Tjenesten</h3>' +
        '<p>Vi kan indsamle oplysninger om din brug af Tjenesten og oplysninger om din brug af vores onlineapplikationer via softwaren i din Panasonic luft-til-vand-enhed og på anden måde. For eksempel kan vi indsamle:</p>' +
        '<p>I.\t<strong>Luft-til-vand-enhedsinformation</strong> – såsom din hardwaremodel, MAC-adressen på luft-til-vand-enhedens grænseflade til det trådløse netværk, det mobilnummer, luft-til-vand-enheden bruger, hvis du kontakter vedligeholdelses-cloudservice-kundesupporten, mobilnetværksinformation, dit mobile operativsystem, hvilken slags mobilbrowser du bruger, tidszoneindstillinger, andre unikke luft-til-vand-enhedsidentifikatorer, IP-adresse, operativsystemoplysninger og indstillingerne på den luft-til-vand-enhed, du bruger til at tilgå servicerne.</p>' +
        '<p>II.\t<strong>Logoplysninger</strong> – såsom tidspunktet for og varigheden af din brug af hjemmesiden, søgeord, du indtaster via servicerne, og alle oplysninger lagret i cookies, som vi har sat på din luft-til-vand-enhed (såsom din pc, mobiltelefon eller tablet) eller vores applikation.</p>' +
        '<p>III.\t<strong>Placeringsoplysninger</strong> – såsom din luft-til-vand-enheds GPS-signal eller information om Wi-Fi-adgangspunkter i nærheden og mobilmaster, som kan overføres til os, når du bruger servicen. </p>' +
        '<p>IV.\t<strong>Stemmeinformation</strong> – såsom optagelser af din stemme, som vi foretager og eventuelt lagrer på vores servere, når du bruger stemmekommandoer.</p>' +
        '<p>V.\t <strong>Andre Oplysninger</strong> – om din brug af vedligeholdelses-cloudservicen, onlineapplikationer, du bruger, og hvordan du interagerer med det indhold, der tilbydes gennem servicen.</p>' +
        '<p><strong>C.\tPersonoplysninger fra tredjepartskilder</strong></p>' +
        '<p>I.\tVi kan modtage personoplysninger fra tredjeparter (dvs. andre virksomheder) forudsat, at dette er i overensstemmelse med lovgivningen. Vi kan også modtage personoplysninger om dig og oplysninger om din luft-til-vand-enhed fra din Aquarea Cloud-konto. \n' +
        '  \n</p>' +
        '<p><strong>4.\tPå hvilket retsgrundlag behandler vi personoplysninger? </strong></p>' +
        '<p>Vi har ikke lov til at behandle personoplysninger, hvis vi ikke kan basere det på et gyldigt retsgrundlag. Derfor behandler vi kun dine personoplysninger i følgende tilfælde:</p>' +
        '<p>I.\tVi har indhentet dit forudgående utvetydige samtykke.\n</p>' +
        '<p>II.\tBehandlingen er nødvendig for at opfylde vores kontraktlige forpligtelser over for dig eller af hensyn til gennemførelse af foranstaltninger, der træffes på din anmodning forud for indgåelse af en kontrakt;\n</p>' +
        '\n' +
        '<p>III.\tBehandlingen er nødvendig for at overholde en retlig forpligtelse, som påhviler os;\n</p>' +
        '\n' +
        '<p>IV.\tBehandlingen er nødvendig for at beskytte dine eller en anden fysisk persons vitale interesser.\n</p>' +
        '\n' +
        '<p>V.\tBehandlingen er nødvendig for vores legitime interesser, medmindre dine interesser eller fundamentale rettigheder og frihedsrettigheder har forrang. Eksempler på disse "legitime interesser" omfatter:\n</p>' +
        '<div class="margin-40"><p>I.\tAt drage fordel af omkostningseffektive tjenester (vi kan fx vælge at bruge bestemte platforme udbudt af leverandører)\n</p>' +
        '<p>II.\tAt beskytte sikkerheden af vores IT-systemer, -arkitektur og -netværk\n</p>' +
        '<p>III.\tAt opfylde vores målsætninger for virksomheden og samfundsmæssigt ansvar.\n</p></div>' +
        '<h3>5.\tHvordan bruger vi dine personoplysninger?</h3>' +
        '<p>Vi kan bruge de personoplysninger, vi indsamler, til følgende formål:</p>' +
        '<br class="margin-40">' +
        '<p>I.\tOprettelse af en servicekonto på vores hjemmeside – for at gøre det muligt for dig at oprette en konto på hjemmesiden eller i andre applikationer og for at gøre det muligt for dig at logge ind på servicekontoen og -applikationerne.\n</p>' +
        '<p>II.\tRegistrering af luft-til-vand-enheder – for at registrere dig eller din luft-til-vand-enhed i Tjenesten.\n</p>' +
        '<p>III.\tOnlinekøb – for at muliggøre onlinekøb af Tjenesten gennem hjemmesiden eller en anden applikation og give den hjælp ved kassen, du måtte bede om, for eksempel for at gennemføre dit køb, hvis du havde tekniske problemer.\n</p>' +
        '<p>IV.\tYdelse af Tjenesten – for at levere de tjenester, du abonnerer på, og herunder at gøre det muligt for os at opfylde vores forpligtelser i henhold til vilkårene og betingelserne for Tjenesten.\n</p>' +
        '<p>V.\tUndersøgelser – for at bede dig om dine meninger eller kommentarer om Tjenesten og gennemføre andre undersøgelser.\n</p>' +
        '<p>VI.\tIndividuelt tilpasset og målrettet indhold – til at levere individuelt tilpasset indhold og fremsætte anbefalinger ud fra dine tidligere aktiviteter i Tjenesten. Vi kan bruge personoplysninger til mere effektivt at målrette Tjenesten, indhold, anbefalinger, annoncer og kommunikation (mere information om dette kan findes i vores cookiepolitik på vores hjemmeside).\n</p>' +
        '<p>VII.\tMarkedsføringsaktiviteter – for at sende dig nyhedsbreve og anden kommunikation med posten eller via e-mail, under forudsætning af at du har givet dit samtykke, eller at vi på anden måde har lov til at gøre dette i henhold til gældende lovgivning.\n</p>' +
        '<p>VIII.\tProfilering – for at lave forudsigelser om dine interesser og sende dig direkte markedsføring på det grundlag.\n</p>' +
        '<p>IX.\tAnnoncer – til annoncering, såsom levering af tilpassede annoncer og sponsoreret indhold og afsendelse af kampagnekommunikation til dig.\n</p>' +
        '<p>X.\tAnalyseformål – til vurdering og analyse af vores marked, kunder, luft-til-vand-enheder og tjenester (herunder at bede dig om dine meninger om vores produkter og tjenester og gennemføre kundeundersøgelser).\n</p>' +
        '<p>XI.\tStatistik – for at udarbejde anonyme, sammenfattede statistikker om brugen af Tjenesten. Disse anonymiserede data kan deles med tredjeparter.\n</p>' +
        '<p>XII.\tForbedring af tjenester – til at udvikle og forbedre nye og eksisterende Panasonic-produkter og -tjenester, anbefalinger, annoncer og anden kommunikation og finde ud af mere om dine præferencer. \n</p>' +
        '<p>XIII.\tKundeservicetjenester – for at yde kundeservicetjenester til din luft-til-vand-enhed eller din service eller for at behandle andre supportforespørgsler, du måtte have (såsom behandling af klager).\n</p>' +
        '<p>XIV.\tVirksomhedsformål – til virksomhedsstyring og intern registrering.\n</p>' +
        '<p>XV.\tOffentliggørelse af dine kommentarer – når du uploader anmeldelser af Tjenesten, kommentarer eller indhold til hjemmesiden eller applikationer, eller når du har besvaret en frivillig undersøgelse, kan vi linke til, offentliggøre eller udgive dine kommentarer, også i vores egne annoncer.\n</p>' +
        '<p>XVI.\tSikkerhed – for at beskytte vores kunder (fx for at undgå spam eller forsøg på at bedrage brugere af vores tjenester), for at drive og vedligeholde sikkerheden i Tjenesten (fx for at undgå eller standse et angreb på vores systemer eller netværk) eller for at beskytte Panasonics rettigheder eller ejendom, herunder håndhævelse af vilkår eller aftaler, der regulerer brugen af Tjenesten.</p>' +
        '<p>XVII.\tJuridiske forpligtelser – for at overholde vores juridiske forpligtelser i henhold til europæiske medlemsstaters eller Den Europæiske Unions lovgivning.\n</p>' +
        '<p>XVIII.\tKonkurrencer og kampagner – for at gennemføre aktiviteter og registrere din deltagelse i dem, som tilladt af lovgivningen, samt give dine personoplysninger til vores partnere og sponsorer af disse aktiviteter. \n</p>' +
        '<p>XIX.\tUddannelse – til personaleuddannelses- og kvalitetssikringsformål, navnlig i forbindelse med vores kundeservicepersonale. \n</p>' +
        '<p>XX.\tOpdateringer – for at kommunikere med dig, herunder at kommunikere med dig om din konto, dine profiler eller dine transaktioner med os og give dig vigtige oplysninger om Tjenesten, luft-til-vand-enheder og applikationer samt sende dig beskeder om væsentlige ændringer af vores privatlivspolitik. \n</p>' +
        '<p>XXI.\tKombinering af personoplysninger – for at kombinere personoplysninger, vi indsamler fra dig, for eksempel for at gøre det muligt for os at forbedre Tjenesten og give mere personligt tilpasset indhold. \n</p>' +
        '<p>XXII.\tVirksomhedstransaktion – for at gennemføre eller styre transaktioner, der involverer vores virksomhed, herunder ved omstruktureringer, sammenlægning, ejerskifte, salg, etablering eller ændring af joint venture, hel eller delvis overdragelse eller afhændelse af en del af Panasonics forretning, aktiver eller lagerbeholdning, herunder også, uden begrænsning, i forbindelse med konkurs eller lignende omstændigheder.\n</p>' +
        '<p><strong>6.\tTil hvem og hvor videregiver vi dine personoplysninger?</strong></p>' +
        '<p>Vi videregiver ikke dine personoplysninger til tredjeparter til deres egen uafhængige markedsførings- eller forretningsformål uden dit samtykke. Vi kan dog videregive dine personoplysninger til følgende virksomheder:</p>' +
        '<p><strong>A.\tTredjepartsmodtagere af personoplysninger</strong></p>' +
        '<div class="margin-40">' +
        '<p><strong>I.\tPanasonic-koncernselskaber</strong></br>Vi kan få brug for at overføre dine personoplysninger til andre Panasonic-koncernselskaber for at yde de tjenester, du har brug for, eller andre tjenester eller anden assistance, du anmoder om. Alle Panasonic-koncernselskaber har en lignende privatlivspolitik.</p>' +
        '<p><strong>II.\tUdvalgte partnere</strong>Vi kan dele dine personoplysninger med vores omhyggeligt udvalgte forretningspartnere, herunder operatører af trådløs kommunikation. Disse partnere kan bruge dine personoplysninger til at yde dig tjenester, du anmoder om, lave forudsigelser om dine interesser og sende dig kampagnematerialer, annoncer og andre materialer.</p>' +
        '<p><strong>III.\tTjenesteleverandører</strong>Vi bruger tredjepartstjenesteleverandører til at hjælpe os med at administrere visse aktiviteter og tjenester på vores vegne, såsom levering af Tjenesten,  administration af aktiviteter, behandling af kreditkortbetalinger, afsendelse af post og e-mails, afsendelse af sms\'er, levering af annoncer, analyse af brugen af vores service, overvågning af effektiviteten af vores markedsføringskampagner, muliggørelse af, at kunder kan logge ind på deres sociale netværk, levering af tjenester til forebyggelse af svindel og til kundeservice. Vi kan dele personoplysninger om dig med disse tredjepartsleverandører alene med det formål at gøre det muligt for dem at udføre tjenester på vores vegne, og de arbejder kun i overensstemmelse med vores instruktioner. Her er eksempler på tredjepartstjenesteleverandører, vi bruger:</p>' +
        '<div class="margin-40">' +
        '<p>\n' +
        'a.\tAnalysetjenesteleverandører – analyseleverandører bruges til at hjælpe os med at forstå brugen af vores produkter og tjenester for at gøre det muligt for os at forbedre vores tjenester. Se vores cookiepolitik for at få yderligere oplysninger.\n</p>' +
        '<p>b.\tMontører og andre tredjeparter, der udfører opgaver under Tjenesten på vores anmodning eller på vores vegne.\n</p>' +
        '</div>' +
        '</div>' +
        '<p><strong>IV.\tTredjeparter, når det kræves af lovgivningen, eller for at beskytte vores tjenester</strong></br>' +
        'Vi videregiver dine personoplysninger for at overholde gældende lovgivning eller reagere på legitime retslige procedurer, herunder fra retshåndhævende eller andre offentlige myndigheder, for at beskytte vores kunder (fx for at undgå spam eller forsøg på at bedrage brugere af vores tjenester), for at drive og vedligeholde sikkerheden i vores tjenester (fx for at undgå eller standse et angreb på vores systemer eller netværk) eller for at beskytte Panasonics rettigheder eller ejendom, herunder håndhævelse af vilkår eller aftaler, der regulerer brugen af vores tjenester.</p>' +
        '<p><strong>V.\tAnonyme statistikker</strong></br>' +
        'Vi udarbejder og udvikler anonyme, sammenfattede eller generiske data og statistikker af forskellige grunde (som anført i nærværende privatlivspolitik). Eftersom disse data er anonyme (dvs. du kan ikke identificeres ud fra dem), anser vi ikke denne information for at være personoplysninger. Af den grund kan vi dele dem med tredjeparter (vores koncernselskaber, partnere, annoncører eller andre).</p>' +
        '<p><strong>VI.\tAndre parter med dit samtykke eller efter dit ønske</strong></br>' +
        'Vi kan dele personoplysninger om dig med tredjeparter, når du giver samtykke til eller anmoder om denne deling.</p>' +
        '<p><strong>B.\t International dataoverførsel</strong></p>' +
        '<p>De personoplysninger, vi indsamler fra dig, kan lagres og behandles i Det Europæiske Økonomiske Samarbejdsområde ("<strong>EØS</strong>") eller overføres, lagres eller på anden måde behandles uden for <strong>EØS</strong>, herunder, men ikke begrænset til, i Storbritannien, Canada og Japan eller i et hvilket som helst andet land, der giver et passende niveau af databeskyttelse som anerkendt af Europa-Kommissionen, og hvor Panasonic eller Panasonics koncernselskaber, datterselskaber eller tjenesteleverandører har afdelinger.\n' +
        '\n' +
        'Når vi overfører dine personoplysninger uden for EØS, sikrer vi, at de fornødne sikkerhedsforanstaltninger, såsom behørige overførselsaftaler og mekanismer (såsom EU\'s modelklausuler) er på plads for at hjælpe med at sikre, at vores tredjepartstjenesteleverandører sørger for det fornødne beskyttelsesniveau for dine personoplysninger. Du kan bede om yderligere information i den forbindelse og få en kopi af de relevante sikkerhedsforanstaltninger på forespørgsel ved at sende en anmodning til den kontakt, der står i afsnittet Kontakt os/yderligere information nedenfor i nærværende privatlivspolitik.\n</p>' +
        '<p><strong>7.\tHvad gør vi for at holde dine personoplysninger sikre?</strong></p>' +
        '<p><strong>A.\tGenerelt</strong></p>' +
        '<p>Panasonic har iværksat de fornødne fysiske og tekniske foranstaltninger for at beskytte de personoplysninger, vi indsamler i forbindelse med Tjenesten. Disse foranstaltninger tager følgende i betragtning:</p>' +
        '<p>I.\tTeknologiens udviklingsniveau\n</p>' +
        '<p>II.\tOmkostningerne ved gennemførelse\n</p>' +
        '<p>III.\tBeskaffenheden af oplysningerne\n</p>' +
        '<p>IV.\tRisikoen ved behandlingen.\n</p>' +
        '<p>Formålet med dette er at beskytte mod hændelig eller ulovlig tilintetgørelse eller ændring, hændeligt tab, uautoriseret videregivelse eller adgang og andre ulovlige former for behandling. \n</p>' +
        '\n' +
        '<p>Det skal dog bemærkes, at selvom vi tager de fornødne foranstaltninger for at beskytte dine personoplysninger, er hjemmesiden, Aquarea Cloud, luft-til-vand-enheder, onlineapplikationer, dataoverførsel, computersystemer og trådløse forbindelser ikke fuldstændigt sikre, og derfor kan vi ikke garantere sikkerheden af dine personoplysninger.\n</p>' +
        '<p><strong>B.  Kryptering</strong></p>' +
        '<p>Alle personoplysninger, du giver os, lagres på vores sikre servere. Alle betalingstransaktioner udført af os eller vores valgte tredjepartsleverandør af betalingsbehandlingstjenester, WorldPay Ltd, krypteres med Secured Sockets Layer-teknologi. Du er ansvarlig for at holde adgangskoden til din servicekonto fortrolig, og vi beder dig om ikke at dele din adgangskode med nogen.</p>' +
        '<p><strong>C.  Andre sikkerhedsforanstaltninger</strong></p>' +
        '<p>Informationssikkerhed, herunder beskyttelse af personoplysninger, er organiseret i et globalt Panasonic-program kaldet "informationssikkerhedsstyring". Det relaterede formål og de relaterede standarder og implementeringsforanstaltninger er organiseret i en politik, standarder og retningslinjer, der gælder i hele verden. Alle centrale europæiske it-systemer vurderes eksternt og falder under de regler for administration af informationssikkerhed, der gælder i henhold til ISO 27001-certificering. Alle dele af programmet følger en streng årlig PDCA-metode (Plan-Do-Check-Act, planlæg, gør, tjek, handl) til at sikre fortroligheden, integriteten og tilgængeligheden af alle data (inklusive dine personoplysninger) i hele informationslivscyklussen fra indsamling til tilintetgørelse af disse oplysninger. </p>' +
        '<p><strong>8.\tHvad er dine rettigheder, og hvordan kan du gøre dem gældende?</strong></p>' +
        '<p><strong>A.\tHvad er dine rettigheder?</strong></p>' +
        '<p>Panasonic indsamler, lagrer og behandler dine personoplysninger i overensstemmelse med dine rettigheder i henhold til alle gældende databeskyttelseslove. \n</p>' +
        '<p>Dine rettigheder i henhold til GDPR omfatter retten til adgang til en kopi af dine personoplysninger, retten til at anmode om berigtigelse eller opdatering af alle urigtige personoplysninger og retten til at gøre indsigelse mod behandling af dine personoplysninger i henhold til betingelserne fastlagt af gældende lovgivning. \n</p>' +
        '<p>Du har også ret til at anmode om sletning af dine personoplysninger, og når du har givet samtykke til behandling af dine personoplysninger, har du ret til at tilbagetrække dette samtykke når som helst (uden at denne tilbagetrækning påvirker lovligheden af behandlingen før denne tilbagetrækning). \n</p>' +
        '<p>Endvidere har du også ret til dataportabilitet i visse tilfælde. Dette er retten til at anmode om returnering af de personoplysninger, du har givet os, i et struktureret, almindeligt brugt og maskinlæsbart format og til at anmode om overførsel af disse personoplysninger til en tredjepart uden hindring fra vores side og underlagt dine egne fortrolighedsforpligtelser.\n</p>' +
        '<p><strong>B.\tSådan gør du dine rettigheder gældende</strong></p>' +
        '<p>Hvis du ønsker at gøre nogen af dine ovenstående rettigheder gældende, bedes du kontakte os ved hjælp af en af de kommunikationsmetoder, der står nedenfor i afsnittet Kontakt os/yderligere information i nærværende privatlivspolitik. </p>' +
        '<p><strong>C.\tKlager</strong></p>' +
        '<p>Hvis du ikke er tilfreds med, hvordan vi behandler dine personoplysninger, kan du kontakte os ved hjælp af en af de kommunikationsmetoder, der står nedenfor i afsnittet Kontakt os/yderligere information i nærværende privatlivspolitik eller alternativt indgive en klage til den kompetente tilsynsmyndighed i tillæg til dine ovenfor nævnte rettigheder. Den relevante myndighed i Danmark er: Datatilsynet, Carl Jacobsens Vej 35, 2500 Valby, (www.datatilsynet.dk).</p>' +
        '<p><strong>Opbevaring af oplysninger – hvor længe vi opbevarer/beholder dine personoplysninger</strong></p>' +
        '<p>Vi vil opbevare dine personoplysninger i den periode, det er nødvendigt for at opfylde de formål, dine personoplysninger er blevet indsamlet til, som beskrevet i nærværende privatlivspolitik, medmindre lovgivningen kræver en længere opbevaringsperiode. Når der ikke længere er behov for dine personoplysninger til det formål, de blev indsamlet til, eller som gældende lovgivning kræver det, slettes de, og/eller returneres de til dig i overensstemmelse med gældende lovgivning.</p>' +
        '<p><strong>10.\tTredjepartslinks </strong></p>' +
        '<p>Vores hjemmeside og applikationer kan indeholde links til andre tredjepartswebsteder, som ikke drives af Panasonic, og vores hjemmeside eller dine luft-til-vand-enheder kan indeholde applikationer, som du kan downloade fra tredjeparter. Disse linkede websteder og applikationer er ikke under Panasonics kontrol, og af den grund er vi ikke ansvarlige for, hvordan nogen linkede websteder og onlineapplikationer behandler personoplysninger. Hvis du vælger at bruge tredjepartswebsteder eller -applikationer, vil alle personoplysninger indsamlet af tredjepartens websted eller applikation være underlagt denne tredjeparts eventuelt eksisterende privatlivspolitik. Vi anbefaler stærkt, at du tager dig tid til at gennemgå privatlivspolitikkerne for alle tredjeparter, som du giver personoplysninger til.</p>' +
        '<p><strong>11.\tCookies</strong></p>' +
        '<p><strong>A.\t Hvad er cookies?</strong></p>' +
        '<p>Panasonic samt visse andre tredjeparter, der leverer indhold, annoncer eller anden funktionalitet til vores tjenester, kan bruge cookies og andre teknologier på visse områder af vores tjenester. Cookies er små sporingskoder, som kan læses af en webserver på det domæne, der anbragte cookien på din harddisk. Vi kan bruge cookies til at lagre dine præferencer og indstillinger, hjælpe dig med at logge ind, levere målrettede annoncer og analysere hjemmesidedrift.\n' +
        'Bemærk, at du kan ændre din browser, så den meddeler dig, når der bliver sendt cookies til den. Hvis du ikke vil modtage cookies, kan du også afvise cookies fuldstændigt ved at aktivere de relevante indstillinger i din browser. Bemærk, at hvis du vælger at afvise alle cookies, kan det ske, at visse funktioner i servicerne ikke er tilgængelige for dig eller ikke virker korrekt. Endelig kan du også slette cookies, der allerede er blevet sendt.\n</p>' +
        '<p><strong>B.\t Yderligere information om cookies</strong></p>' +
        '<p>For at få yderligere information om, hvad cookies er, og hvordan Panasonic og andre tredjeparter bruger dem, og nærmere oplysninger om, hvordan cookies kan deaktiveres, henviser vi til vores cookiepolitik.</p>' +
        '<p><strong>12.\t Dine valgmuligheder (fx markedsføringsrelaterede e-mails)</strong></p>' +
        '<p>Vi og andre Panasonic-koncernselskaber kan bruge dine personoplysninger (såsom dine kontaktoplysninger, herunder eksempelvis fornavn, efternavn, køn, adresse og e-mailadresse) til at sende dig markedsføringsrelateret korrespondance med posten eller via e-mail relateret til Panasonic-produkter, luft-til-vand-enheder og andre tjenester i overensstemmelse med dine e-mail- og kontaktpræferencer i din servicekonto, eller hvad du ellers giver samtykke til.\n</p>' +
        '<p>Når du køber Aquarea Service+, kontakter vores kundeservicepersonale eller opretter en servicekonto, har du måske mulighed for at tilvælge (eller fravælge, når det tillades af gældende lovgivning) modtagelse af denne markedsføringsrelaterede korrespondance.\n</p>' +
        '<p>Vi deler ikke personoplysninger med tredjeparter til tredjeparternes markedsføringsformål.\n</p>' +
        '<p>Vi kan også bruge dine personoplysninger til individuelt at tilpasse og mere effektivt målrette vores markedsføringskommunikation for at sikre, at al markedsføringsrelateret korrespondance i videst muligt omfang er relevant for dig.\n</p>' +
        '<p>For at fravælge modtagelse af markedsføringsrelateret korrespondance fra Panasonic bedes du opdatere dine e-mail- og kontaktpræferencer i din servicekonto eller klikke på "Frameld" i en markedsførings- eller kampagne-e-mail, du har modtaget fra os.\n</p>' +
        '<p><strong>14.  Ændringer til nærværende privatlivspolitik</strong></p>' +
        '<p>Panasonic kan ændre nærværende privatlivspolitik fra tid til anden. "Dato sidst ajourført" nederst på denne side angiver, hvornår politikken sidst blev ajourført, og alle ændringer træder i kraft, når vi slår den reviderede privatlivspolitik op. \n</p>' +
        '<p>Vi vil give dig varsel, hvis disse ændringer er væsentlige, og vi indhenter dit samtykke, når det kræves efter gældende lovgivning. \n</p>' +
        '<p><strong>15.\t Kontakt os/yderligere information</strong></p>' +
        '<p>Hvis du har nogen spørgsmål vedrørende indsamling, behandling eller brug af dine personoplysninger, eller hvis du ønsker at rette, spærre, slette eller berigtige dine personoplysninger, gøre indsigelse mod indsamlingen eller behandlingen af dine personoplysninger, at få adgang til dine personoplysninger, eller at tilbagetrække dit samtykke til nogen del af nærværende privatlivspolitik, bedes du kontakte os:</p>' +
        '<p>I.\tVia post:\n</br>' +
        'Werner Stagel\n</br>' +
        'Panasonic Marketing Europe GmbH\n</br>' +
        'Databeskyttelsesrådgiver\n</br>' +
        'Hagenauer Straße 43\n</br>' +
        '65203 Wiesbaden\n</br>' +
        'Tyskland\n</br>' +
        '<p></p>' +
        'II.\tVia e-mail:\n</<br>>' +
        'Datenschutz@eu.panasonic.com\n</br>' +
        '\n' +
        'III\tVia telefon: \n</br>' +
        '+49 611-235-0 \n</br>' +
        '\n' +
        '<p>mandag til fredag 9-17.30\n</p>' +
        '<p>Dato sidst ajourført: marts 2021</p>' +
        '</div>',
};
