module.exports = {
    points:[
        {
            label: "#1",
            text: "Proveedor de bombas de calor"
        },
        {
            label: "60 años",
            text: "experiencia con bombas de calor"
        },
        {
            label: "24/7",
            text: "supervisión a través de IoT (Internet de las cosas)"
        },
        {
            label: ">100K",
            text: "bombas de calor conectadas"
        },
    ]
};
