module.exports = {
    headline: "¿Por qué elegir Aquarea Service+?",
    text: "Elija Aquarea Service+ y obtenga lo mejor de su bomba de calor Aquarea, sin sorpresas innecesarias. Gracias a la supervisión a distancia, un técnico profesional puede ocuparse del funcionamiento de su bomba de calor sin tener que molestarle en su vida cotidiana.",
    list: [
        {
            icon: "/image/icons/services/monitoring.png",
            text: "Nuestros técnicos de servicio son expertos en bombas de calor Aquarea",
        },
        {
            icon: "/image/icons/services/24h_response_time.png",
            text: "Diagnosticamos las averías de forma externa y evitamos las visitas innecesarias del servicio técnico",
        },
        {
            icon: "/image/icons/services/247.png",
            text: "Conectamos la tecnología IoT a su bomba de calor",
        }
    ],
    btn_label: "VER NUESTROS PAQUETES DE SERVICIOS",
    btn_href: "/ES_es/home/service_package"
};