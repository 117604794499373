module.exports = {
    headline: "Velkommen tilbage",
    subheadline: "Velkommen tilbage",
    inputs: [
        {
            label: "Email",
            id: "email",
            name: "email",
            placeholder: "Email",
            type: "email"
        },
        {
            label: "Adgangskode",
            id: "password",
            name: "password",
            password_placeholder: "Password",
            type: "password"
        }
    ],
    btn: "Login",
    link: "Glemt kodeord",
    link_href: "/da_DK/home/password_forgot",
    newProtectionPlan: {
        label: "Har du brug for en servicepakke?",
        link: "Bestil en servicepakke",
        link_href: "/da_DK/home/service_package",
    }
}