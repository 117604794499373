module.exports = {
    yourProtectionPlan: "Mi plan de mantenimiento",
    contract: "Contrato Aquarea Service+",
    invoiceHistory: "Historial de pagos",
    accountInformation: "Información de la cuenta",
    reports: "Relator",
    welcome: "Bienvenido, ",
    noServicePackage: "Todavía no hay paquete de servicios",
    editAccount: "Editar cuenta",
    finishCheckout: "Compra incompleta",
    gotoCheckout: "Terminar la compra incompleta",
    finishUpgrade: "Finalización de la ejecución del pedido",
}
