// sessionStorage.js

export const ANONYMOUS_ID_SESSION_STORAGE_NAME = 'pana-fe-anonymousId';
export const TOKENS_SESSION_STORAGE_NAME = 'pana-fe-tokens';
export const CART_ID_SESSION_STORAGE_NAME = 'pana-fe-cart-id';
export const AUTH_TOKEN_SESSION_STORAGE_NAME = 'pana-fe-authorization-token';
export const ORDER_ID_TO_UPGRADE_SESSION_STORAGE_NAME = 'pana-fe-upgrade-order-id';
export const CHOSEN_UPGRADE_SKU_SESSION_STORAGE_NAME = 'pana-fe-chosen-upgrade-sku';
export const CHOSEN_UPGRADE_VARIANT_SKU_SESSION_STORAGE_NAME = 'pana-fe-chosen-upgrade-variant-sku';
export const COUNTRY_CODE_NAME = 'country-code';

/*
* write value to datafield.
*
* @name: string
* @value: string
* */
export const setSessionStorageItem = (name, value) => {
    window.sessionStorage.setItem(name,value)
}

/*
* get value by key form datafield
*
* @name: string
* @return string | null
* */
export const getSessionStorageItem = (name) => {
    return window.sessionStorage.getItem(name)
}

/*
* remove value by key
*
* @name: string
* */
export const removeSessionStorageByName = (name) => {
    window.sessionStorage.removeItem(name)
}

/*
* clear all values
* */
export const clearSessionStorage = () => {
    window.sessionStorage.clear()
}


export const removeSessionStorage = () => {
    removeSessionStorageByName(AUTH_TOKEN_SESSION_STORAGE_NAME);
    removeSessionStorageByName(CART_ID_SESSION_STORAGE_NAME);
    removeSessionStorageByName(ANONYMOUS_ID_SESSION_STORAGE_NAME);
}