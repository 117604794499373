module.exports = {
    editBtn: "Edit",
    confirmBtn: "Purchase Service Package",
    connectionCheck: "Connection Check",
    accountInformation: "Account Information",
    paymentDetails: "Payment Details",
    installedBase: "Installed Base",
    monthly:"monthly",
    quarterly:"quarterly",
    annually:"annually",
    yes: "Yes",
    no: "No",
    details: {
        monthlyPrice:"Monthly cost",
        connectionFeePrice:"One-off installation service costs",
        internetConnected: "internet connected",
        internetReady: "internet ready",
        modelName: "Model",
        serialNumber: "Serial Number",
        installationDate: "Installation Date",
        gatewayId: "Gateway ID",
        title: "title",
        firstName: "first Name",
        lastName: "last Name",
        name: "name",
        email: "email",
        address : "address",
        zipCode : "zip code",
        city: "city",
        phoneNumber: "phone number",
        phone : "Phone",
        paymentMethod: "Payment Method"
    },
    confirm: "confirm"
}
