module.exports = {
  success: {
    title: "Tak skal du have!",
    headline: "Din betaling er blevet gennemført!",
    subheadline: "Mange tak, ",
    btn: "GÅ TIL MIN KONTO",
  },
  failure: "Din betaling blev afvist af vores betalingspartner.",
  cancel: "Din betaling er blevet annulleret.",
  error: "Der opstod en fejl under betalingen hos vores betalingspartner.",
  expired: "Din betalingssession er udløbet hos vores betalingspartner.",
  pending: "Din betaling er stadig i gang hos vores betalingspartner. Det kan tage op til 10 minutter.",
  link: "GÅ TIL MIN KONTO",
  link_href: "/da_DK/home/my_account",
};