module.exports = {
    headline: 'Håll dig uppdaterad',
    icons: [
        {
            icon: "facebook",
            link: "https://www.facebook.com/PanasonicHeatingCoolingEU/"
        },
        {
            icon: "youtube",
            link: "https://www.youtube.com/c/PanasonicHeatingCoolingSolutionsEurope"
        },
        {
            icon: "linkedin",
            link: "https://www.linkedin.com/company/panasonic-heating-and-cooling-solutions-europe/"
        },
    ],
    links: [
        {
            href: "/sv_SE/home/terms_and_conditions",
            label: "Kampen mot korruption"
        },
        {
            href: "/sv_SE/home/cookies/",
            target: "_blank",
            label: "Cookie-policy"
        },
        {
            href: "/sv_SE/home/privacy_policy",
            label: "Integritetspolicy"
        },
        {
            href: "/sv_SE/home/terms_and_conditions",
            label: "Villkor och bestämmelser"
        },
    ],
    copyright: "Copyright 2021 Panasonic Marketing Europe GmbH, alla rättigheter förbehållna",
};
