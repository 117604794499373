module.exports = {
    inputs: [
        {
            label: "Nytt lösenord",
            id: "newPassword",
            name: "newPassword",
            placeholder: "Nytt lösenord",
            type: "password"
        },
        {
            label: "Bekräfta lösenordet",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Bekräfta lösenordet",
            type: "password"
        }
    ],
    success: "Du har begärt att få återställa lösenordet, titta i din e-postinkorg!",
    subheadline: "Återställ lösenordet",
    headline: "Återställ lösenordet",
    resetBtn: "Återställ lösenordet"
}