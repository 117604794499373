module.exports = {
    headline: "Paquete de servicios",
    icon: '/image/icons/cart/cart.png',
    iconCount: '/image/icons/cart/cart2.png',
    href: '/cart',
    titel: "carro de la compra",
    overlayLabel:"Tú eliges:",
    btn: "plan de protección de compras",
    btnPath: "/es_ES/home/service_package",
    edit: "editar",
    editPath: "/es_ES/home/service_package"
};
