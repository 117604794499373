import React, {useEffect, useState} from 'react';

import Menu from "../../components/menu";
import {PageFooter} from "../../components/layout/page";
import {scrollToPos} from "../../lib/scrollToPos";
import {getDocument} from "../../lib/dataAccess/cms/cmsNodesRestEndpoint";
import {
    getSessionStorageItem,
    COUNTRY_CODE_NAME,
  } from "../../lib/sessionStorage";
 import Loader from "react-loader-spinner";
import {getFormattedCountryCode} from "../../lib/helper/localizationHelper";
import {useParams} from "react-router-dom";
const HomePage = () => {
    const { countryCode } = useParams();
    const cmsCountryCode = getSessionStorageItem(COUNTRY_CODE_NAME)|| getFormattedCountryCode(countryCode);
    const documentPath = cmsCountryCode + "/home";
    const [cmsContent, setCmsContent] = useState();

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])


    useEffect(() => {
        if(!cmsContent){
            getDocument(encodeURIComponent(documentPath))
            .then(res => {
                let content = "";
                res.data.forEach(element => {
                    content += element.markup;
                });
                setCmsContent(content);
            })
            .catch(e => {
                setCmsContent("")
            })
        }

    }, [documentPath, cmsContent])

    if(!cmsContent){
        return <div>
             <>
             <div className="homepage">
                <Menu/>
                <div className="homepage_body">
                <span className="loading-spinner-button-loading-spinner">
                <Loader
                    type="TailSpin"
                    color="#000000"
                    height={20}
                    width={20}
                />
                </span>
                <PageFooter/>
                </div>
            </div>
            </>
        </div>;
    }

    return (
            <div className="homepage">
                <Menu/>
                <div className="homepage_body">
                    <div dangerouslySetInnerHTML={{__html: cmsContent}}/>
                    <PageFooter/>
                </div>
            </div>
        )
}


export default HomePage;
