module.exports = {
    title: "Cancellation",
    headline: "We very sorry that you would like to cancel your subscription.",
    btnReturnToProfile: "Return to my account",
    cancelSectionTitle: "Cancel subscription",
    cancelText: "Do you really want to cancel Aquarea Service+ subscription package?",
    btnCancelSubscription: "Yes, cancel my subscription",
    downgradeSectionTitle: "Downgrade your subscription",
    downgradeText: "The following downgrade options are available:",
    btnDowngradeOrder: "Downgrade my subscription",
    downgradeOptions: {
        silver: "Downgrade to Aquarea Service+ SMART package",
        bronze: "Downgrade to Aquarea Service+ COMFORT package"
    }
}
