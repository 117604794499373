module.exports = {
    home: 'Home',
    termsConditions: "Vilkår og betingelser",
    privacyPolicy: "Fortrolighedspolitik",
    cookiePolicy: "Cookiepolitik",
    protectionPlans: 'Servicepakker',
    service: 'Kundeservice',
    error: 'Ups en fejl',
    checkout: 'Checkout',
    login: 'Login',
    cancelProtectionPlan: 'Annullere servicepakke',
    myAccount: 'Min konto',
    editMyAccount: 'Rediger personlige oplysninger',
    passwordReset: 'Nulstilling af password',
    upgradeProtectionPlan: 'Opgradering af servicepakke',
    upgrade: 'Opgradering',
    updatePayment: "Ændre betalingsmetode",
    registerUnit: "Registrer varmepumpeenhed",
    faq : "Ofte stillede spørgsmål"
}