module.exports = {
    headline: "TU ELECCIÓN:",
    subheadline: "Plan de mantenimiento",
    edit_link: "/es_ES/home/service_package",
    edit_label: "EDITAR",
    label: "Servicio de instalación",
    additionally: [
        {
            label: "Además, se ha pedido:",
            text: "1.495,00DKK tasa única",
            href_label: "DELETE",
        }
    ],
    annually: 'pago anual',
    quarterly: 'pago trimestral',
    monthly: 'pago mensual',
    per_month: 'por mes',
    disclaimer: 'Seleccionar el pago mensual, trimestral o anual en Detalles de pago antes de realizar el pedido.'
};
