module.exports = {
    protected: "You are protected by Aquarea Service+",
    heatPumpsCorrectly: "",
    callHotline: "Call hotline",
    sendMail: "Send email",
    gotoCloud: "Go to Aquarea Smart Cloud",
    imageName: {
        yellow: "exclusive",
        green: "advanced",
        brown: "comfort",
        comfort: "Aquarea comfort"
    },
    imageTags:{
        exclusive: "Aquerea exclusive",
        advance:"Aquerea advance",
        smart: "Aquerea smart"
    },
    downgradeInfo1: "Your protection plan is scheduled for downgrade to ",
    downgradeInfo2: ". This Downgrade is applied after the current contract period completed.",
    contactmail: "ire-hvac-service@eu.panasonic.com",
}
