module.exports = {
    headline: "Vilket servicepaket är rätt för dig?",
    subheadline: "Välj mellan tre olika Aquarea Service+-paket och hitta det som bäst passar dina behov.",
    imageLeft: {
        image: "/image/home/chooseplan/left.png",
        alt: "",
        text: "Prova vårt interaktiva test och hitta det perfekta paketet för dina behov.",
        btn_label: "Börja testa",
        btn_href: "/sv_SE/home/service_package"
    },
    imageRight: {
        image: "/image/home/chooseplan/right.png",
        alt: "",
        text: "Vill du ha personlig rådgivning om vilket paket som är bäst för dig?",
        btn_label: "Kontakt os",
        btn_href: "https://www.aircon.panasonic.eu/SE_sv/my-project/"
    }
};
