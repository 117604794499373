import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { useHistory } from 'react-router-dom'

const GTM_IDS = {
  'es_ES': 'GTM-54QM6JZR',
  'en_ES': 'GTM-54QM6JZR',
  'da_DK': 'GTM-KMPRMRLS',
  'en_IE': 'GTM-TTG5L5HC',
  'it_IT': 'GTM-TTL7N4XM',
  'sv_SE': 'GTM-TG2MCD4L',
  'landing_page':'GTM-TJTR5PS4'
}

let loadScript = true

export const useGTM = (countryCode) => {
  const {location} = useHistory();

  useEffect(() => {
    const initGTM = (countryCode) => {
      // console.log(
      //   "%c gtm loaded",
      //   "display:block;width:100%;background-color: #332B00;color:#F2AB26;"
      // );
      
      loadScript = false
      let countryCodeSelector = countryCode || window.location.pathname.split('/')[1]
      const tagManagerArgs = {
        gtmId: GTM_IDS[countryCodeSelector]
      }
      TagManager.initialize(tagManagerArgs)
    }
    if(loadScript) {
      initGTM()
    }
  }, [countryCode, location])
}

export const sendGTMDataLayer = (dataLayer) => {
  TagManager.dataLayer({dataLayer: dataLayer})
 }
