module.exports = {
    yourProtectionPlan: "DIN SERVICEPAKKE",
    contract: "Aquarea Service+ kontrakt",
    invoiceHistory: "Betalingshistorik",
    accountInformation: "Kontooplysninger",
    reports: "Rapporter",
    welcome: "Velkommen, ",
    noServicePackage: "Ingen servicepakke endnu",
    editAccount: "Rediger konto",
    finishCheckout: "Ufuldstændigt køb",
    gotoCheckout: "Afslut ufuldstændigt køb",
    finishUpgrade: "Afslutt af ordreopfyldelse",
}
