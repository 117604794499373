import axios from "axios";
import {getHeaderForApiRequest} from "../dataAccessHelper";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_BASE_URL;

// Reports handling
export function getReports(authToken){
    return axios.get(BASE_URL + '/report', {
        headers: getHeaderForApiRequest(authToken)
    })
}

export function downloadReport(report, authToken){
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
        'Accept-Language': '*',
        'Authorization': 'Bearer ' + authToken
    };
    return axios.get(BASE_URL + '/report/download?reportName=' + report, {
        headers: headers,
        responseType: 'blob'
    })
}