import React, {useContext, useState} from 'react';

import {AuthContext} from "../../../lib/contexts/authContext";
import {TokenContext} from "../../../lib/contexts/tokenContext";

import {emailValidation, requiredInput} from "../../../lib/validation";
import {logInUser} from "../../../lib/dataAccess/customer/customerRestEndpoint";
import {ANONYMOUS_ID_SESSION_STORAGE_NAME, AUTH_TOKEN_SESSION_STORAGE_NAME, getSessionStorageItem, setSessionStorageItem} from "../../../lib/sessionStorage";
import {getAccount} from "../../../lib/dataAccess/accountService";
import {LoadingSpinnerButton} from "../../layout/buttons";
import {withRouter} from "react-router-dom";

import i18 from "../../../lib/i18n";
import { CartContext } from '../../../lib/contexts/cartContext';

const t = i18.namespace("LoginPage");
const messages = i18.namespace("Validation");

const SignIn = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const {authContext, setAuthContext} = useContext(AuthContext);
    const {cartContext} = useContext(CartContext);
    const {setTokenContext} = useContext(TokenContext);
    const [state, setState] = useState({
        email: {error: false, value: ""},
        password: {error: false, value: ""},
        login: {error: false, value: ""},
        missingEmail: {error: false, value: ""},
    });

    const _onBlur = (event, type) => {
        let value = event.target.value;
        let error = state[type].error;

        if (type === "password") {
            error = requiredInput(value);
            updateState(type, value, error);
        }
        if (type === "email") {
            error = emailValidation(value);
            updateState(type, value, error);
        }
    };

    const updateState = (type, value, error) => {
        let temp = state[type];
        temp.error = error;
        temp.value = value;

        setState((prevState) => {
            return {...prevState, [type]: temp};
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);

        if (!state.password.error && !state.email.error) {
            try {
                const loginRequestData = {
                    password: state.password.value,
                    email: state.email.value,
                    checkoutLogin: true
                };
                const anonymousId = cartContext?.anonymousId
                    || getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME);

                if (anonymousId) {
                    loginRequestData.anonymousId = anonymousId;
                }
                const loginResponse = await logInUser(loginRequestData);

                setSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME, loginResponse.data.token);
                setTokenContext(loginResponse.data);

                const getAccountResponse = await getAccount(loginResponse.data.token);
                setAuthContext({
                    ...authContext,
                    authToken: loginResponse.data.token,
                    accountData: getAccountResponse.data
                });



                //props.goNext();
                props.toggleSwitch(false);
            } catch (error) {
                if (error.response.data.message === "[EMAIL_MISSING, PASSWORD_MISSING]" ||
                    error.response.data.message === "Invalid Username and/or Password" ||
                    error.response.data.message === "Unauthorized: not logged in"
                ) {
                    updateState("login", true, true);
                    setIsLoading(false);
                } else if (error.response.data.message === "[EMAIL_MISSING]") {
                    updateState("missingEmail", true, true);
                    setIsLoading(false);
                } else if (error.response.data.message === "[PASSWORD_MISSING]") {
                    updateState("password", true, true);
                    setIsLoading(false);
                } else {
                    props.history.push("/404");
                }
            }
        }

        setIsLoading(false);
    };

    const showErrorMessage = (type) => {
        return (
            <p className="error_message error_message--red">
                {messages("validationMethods")[type].message}
            </p>
        );
    };

    return (
        <div className="signIn">
            <div className="login-page_wrapper container">
                <div className="login-page_wrapper">
                    <div className="login-page_content">
                        <h3 className="login-page_subheadline">{t("subheadline")}</h3>
                        {t("inputs").map((item, i) => (
                            <div className="login-page_inputs" key={i}>
                                <p className="login-page_inputs_label">{item.label}</p>
                                <input
                                    className={
                                        state[item.type].error
                                            ? "login-page_inputs_input error"
                                            : "login-page_inputs_input"
                                    }
                                    type={item.type}
                                    name={item.name}
                                    id={item.id}
                                    onBlur={(event) => _onBlur(event, item.type)}
                                />
                                {state[item.type].error && showErrorMessage(item.type)}
                            </div>
                        ))}
                        {state.login.error && showErrorMessage("loginError")}
                        {state.missingEmail.error && showErrorMessage("missingEmail")}
                        <LoadingSpinnerButton
                            onClick={handleSubmit}
                            text={t("btn")}
                            classNames="btn-primary order-1 order-md-2 loading-spinner-button btn"
                            isEnabled={true}
                            isLoading={isLoading}
                        />
                        <a className="btn btn_line" href={t("link_href")} target="_blank" rel="noopener noreferrer">
                            {t("link")}
                        </a>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SignIn);