import React, {useState} from "react";

const Dropdown = ({label, text, closeBtn, closeBtnLabel}) => {

    const [open, setOpen] = useState(false);

    if (closeBtn) {
        return(
            <div className="dropdown" onClick={() => setOpen(!open)}>
                <p className={open ? "dropdown_label dropdown_label--close": "dropdown_label"}>{label}</p>
                <div className={open ? "dropdown_content dropdown_content--show" : "dropdown_content"}>
                    <p className="dropdown_text" dangerouslySetInnerHTML={{__html: text}}/>
                    <div className="dropdown_closebtn" onClick={() => setOpen(!open)}>{closeBtnLabel}</div>
                </div>

            </div>
        )
    } else {
        return(
            <div className="dropdown" onClick={() => setOpen(!open)}>
                <p className="dropdown_label">{label}</p>
                <div className={open ? "dropdown_content dropdown_content--show" : "dropdown_content"}>
                    <p className="dropdown_text">{text}</p>
                </div>
            </div>
        )
    }
}


export default Dropdown;