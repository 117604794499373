import React from "react";
import i18n from "../lib/i18n";

const t = i18n.namespace("Hotline");

const hotline = () => {
    return (
        <div className="hotline">
            <div className="hotline-wrapper">
                <img className={"hotline"} src={t("icon.path")} alt={t("icon.alt")} />
                <div className="hotline-text-content">
                    <h4 className={"phone-number"}>{t("phoneNumber")}</h4>
                    <p className={"text"}>{t("text")}<span>{t("time")}</span></p>
                </div>
            </div>
        </div>
    )
}
export default hotline;