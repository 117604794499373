module.exports = {
    headline: "Register Heatpump Unit",
    date: {
        title: "Installation date of your heatpump",
        name: "installation date"
    },
    model: {
        title: "Which model is installed?",
        name: "MODEL NAME",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/heatpump.png",
        },
        emptyOption: "-- Choose Model --",
        options: [
            "I don't know",
            "WH-ADC0309K3E5",
            "WH-ADC0309K3E5AN",
            "WH-ADC0309K3E5B",
            "WH-ADC0309K6E5",
            "WH-ADC0309K6E5AN",
            "WH-ADC0509L3E5",
            "WH-ADC0509L3E5B",
            "WH-ADC0509L3E5AN",
            "WH-ADC0509L6E5",
            "WH-ADC0509L6E5AN",
            "WH-ADC0912K6E5",
            "WH-ADC0912K6E5AN",
            "WH-MDC05H3E5",
            "WH-MDC05J3E5",
            "WH-MDC07H3E5",
            "WH-MDC07J3E5",
            "WH-MDC09H3E5",
            "WH-MDC09J3E5",
            "WH-MXC09G3E8",
            "WH-MXC09H3E8",
            "WH-MXC09J3E8",
            "WH-MXC12G9E8",
            "WH-MXC12H9E8",
            "WH-MXC12J9E8",
            "WH-MXC16G9E8",
            "WH-MXC16H9E8",
            "WH-MXC16J9E8",
            "WH-SDC0309K3E5",
            "WH-SDC0309K6E5",
            "WH-SDC0509L3E5",
            "WH-SDC0509L6E5",
            "WH-SXC09K3E5",
            "WH-SXC09K6E5",
            "WH-SXC12K6E5",
            "WH-UD03HE5-1",
            "WH-UD03JE5",
            "WH-UD05HE5-1",
            "WH-UD05JE5",
            "WH-UD07HE5-1",
            "WH-UD07JE5",
            "WH-UD09FE5",
            "WH-UD09HE8",
            "WH-UD09JE5-1",
            "WH-UD12HE5",
            "WH-UD16HE5",
            "WH-UDZ03KE5",
            "WH-UDZ03KE5E",
            "WH-UDZ03KE5HE",
            "WH-UDZ05KE5",
            "WH-UDZ05KE5E",
            "WH-UDZ05KE5HE",
            "WH-UDZ07KE5",
            "WH-UDZ07KE5E",
            "WH-UDZ07KE5HE",
            "WH-UDZ09KE5",
            "WH-UDZ09KE5E",
            "WH-UDZ09KE5HE",
            "WH-UQ09HE8",
            "WH-UQ12HE8",
            "WH-UQ16HE8",
            "WH-UX09HE8",
            "WH-UX12HE8",
            "WH-UX16HE8",
            "WH-UXZ09KE5",
            "WH-UXZ09KE5E",
            "WH-UXZ09KE5HE",
            "WH-UXZ12KE5E",
            "WH-UXZ12KE5HE",
            "WH-WDG05LE5",
            "WH-WDG05LE5E",
            "WH-WDG05LE5HE",
            "WH-WDG07LE5",
            "WH-WDG07LE5E",
            "WH-WDG07LE5HE",
            "WH-WDG09LE5",
            "WH-WDG09LE5E",
            "WH-WDG09LE5HE"
        ]
    },
    serialNumber: {
        name: "SERIAL NUMBER",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/heatpump.png",
        },
    },
    gatewayId: {
        title: "If you have already the Network Adaptor, please enter the Device ID",
        name: "DEVICE ID (OPTIONAL)",
        img: {
            linkText: "WHERE TO FIND?",
            path: "/image/checkout/gatewayID.png"
        }
    },
    shippingAddress: {
        title: "Installation Address",
        street: {
            label: "Address",
            name: "street",
        },
        zipcode: {
            label: "Postcode",
            name: "zipcode",
        },
        city: {
            label: "City",
            name: "city",
        },
    },


    btn: {
        back: "BACK TO MY ACCOUNT",
        next: "SAVE"
    },
    errors: {
        futureDate: "the installation date is in the future",
        dateTooFarInPast: "installation date is too early",
        onlyDigits: "only digits allowed",
        lengthIs11: "length of device id must be 11",
        zipCodeMin: "Min 7 digits",
        zipCodeMax: "Max 8 digits",
        serialMin: "Min 8 digits",
        serialMax: "Max 20 digits",
        requiredField: "This field is required",
        addressTooLong: "Maximum 30 characters",
        cityTooLong: "Maximum 35 characters"
    }
};
