import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import i18 from "../lib/i18n";
import Menu from "../components/menu";
import Footer from "../components/footer";
import Newsletter from "../components/newsletter";
import { Link } from "react-router-dom";
import { AuthContext } from "../lib/contexts/authContext";
import { scrollToPos } from "../lib/scrollToPos";
import {
  AUTH_TOKEN_SESSION_STORAGE_NAME,
  COUNTRY_CODE_NAME,
  getSessionStorageItem,
} from "../lib/sessionStorage";
import { getAccount } from "../lib/dataAccess/customer/accountRestEndpoint";
import Loader from "react-loader-spinner";
import { ErrorResponseHandling } from "../lib/dataAccess/errorResponseHandling";
import { useInterval } from "../lib/hooks/useInterval";
import { getRepairOrders } from "../lib/dataAccess/checkout/orderRestEndpoint";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || "da_DK";
const t = i18.namespace("RepairPayment");

const PAID = "paid";
const PENDING = "pending";
const FAILED = "failed";

const RepairPaySuccess = () => {
  const POLLING_TIMEOUT = 1000;
  const { orderId } = useParams();
  const { authContext, setAuthContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [pollingTimeout, setPollingTimeout] = useState(POLLING_TIMEOUT);
  const history = useHistory();

  useEffect(() => {
    scrollToPos(0, 0);
    const authToken =
      authContext?.authToken ||
      getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME);

    if (authToken) {
      getAccount(authToken)
        .then((res) =>
          setAuthContext({
            ...authContext,
            accountData: res.data,
          })
        )
        .catch((error) => {
          ErrorResponseHandling(error, authContext, setAuthContext, history);
        })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getName() {
    if (!authContext.accountData) {
      return "!";
    }
    return `${authContext.accountData.firstName} ${authContext.accountData.lastName}!`;
  }

  useInterval(async() =>{
    getRepairOrders(getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME)).then((result) => {
      const repairOrderPaymentStatus = result?.data?.find((order) => order?.orderId === orderId)?.paymentStatus?.toLowerCase();
      
        if(repairOrderPaymentStatus === PENDING || repairOrderPaymentStatus === PAID || repairOrderPaymentStatus === FAILED) {
            setIsLoading(false);
        }
        setPollingTimeout(pollingTimeout + POLLING_TIMEOUT);
    })
    .catch(e => {
        ErrorResponseHandling(e, authContext, setAuthContext, history);
    });
}, isLoading ? pollingTimeout : null);

  return (
    <>
      {isLoading && (
        <span className="success_loading_spinner">
          <Loader
            type="TailSpin"
            color="#7099AD"
            height={200}
            width={200}
            className="success_loading_spinner_loader"
          />
        </span>
      )}
      <div>
        <Menu />
        <div className="homepage_body">
          <div className="success">
            <div className="container">
              <p className="success_title">{t("success.title")}</p>
            </div>
            <div className="success_wrapper container">
              <div className="success_icon"></div>
              <div className="success_container">
                <p className="success_headline">{t("success.headline")}</p>
                <p className="success_subheadline">
                  {t("success.subheadline")}
                  {getName()}
                </p>
              </div>
              <Link
                className="success_btn btn"
                to={"/" + countryCode + "/home/my_account"}
              >
                {t("success.btn")}
              </Link>
            </div>
          </div>
        </div>
        <Newsletter />
        <Footer />
      </div>
    </>
  );
};

export default RepairPaySuccess;
