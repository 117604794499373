import {useState} from "react";

const getInitialValues = () => {
    // TODO: restore form localStorage
    return {
        values: []
    }
}

const getInitialCart = () => {
    return {
        cart: {}
    }
}

const getInitialLineItem = () => {
    return {
        lineItemId: []
    }
}

const add = (arr, value) => {
    const found = arr.some(el => el.key === value.key);
    if (!found) {
        arr.push(value);
    } else {
        // TODO ohne return wird alles auf undefined gemapped
        arr = arr.map(el => {
            if (el.key === value.key) {
                return value;
            }
            return el;
        })
    }
    return arr;
}

const useCheckoutSession = () => {

    const [state, setState] = useState(getInitialValues());
    const [lineItemIdState] = useState(getInitialLineItem());
    const [cartState, setCartState] = useState(getInitialCart());

    const updateValuesByStep = (event, step) => {
        const temp = {...state};

        const value = {key: event.target.name, value: event.target.value, label: event.target.title};
        if (!temp.values[step]) {
            temp.values[step] = [value];
        } else {
            temp.values[step] = add(state.values[step], value);
        }

        setState(prevState => {
            return {...prevState, values: temp.values}
        });
    }

    /*
    *
    * {key: event.target.name, value: event.target.value, label: event.target.title}
    * */
    const updateValuesFromAccount = (value, step) => {
        const temp = {...state};
        if (!temp.values[step]) {
            temp.values[step] = [value];
        } else {
            temp.values[step] = add(state.values[step], value);
        }

        setState(prevState => {
            return {...prevState, values: temp.values}
        });
    }

    const updateValuesForStep = (values, step) => {
        const temp = {...state};
        if(!temp.values[step]) {
            temp.values[step] = values;
        } else{
            for(let i = 0; i < values.length; i++){
                temp.values[step] = add(state.values[step], values[i]);
            }
        }

        setState(prevState => {
            return {...prevState, values: temp.values}
        });
    }

    const updateValuesForConnection = (key, itemValue, label, step) => {
        const temp = state;
        const value = {key: key, value: itemValue, label: label};


        if (!temp.values[step]) {
            temp.values[step] = [value];
        } else {
            temp.values[step] = add(state.values[step], value,);
        }

        setState(prevState => {
            return {...prevState, values: temp.values}
        });
    }

    const setValues = (values) => {
        setState({values: values});
    }

    const setCart = (cart) => {
        setCartState(cart);
    }

    const getCart = () => {
        return cartState.cart;
    }

    const setValueAndValidity = (event, isInvalid, step) => {
        const temp = {...state};
        const value = {
            key: event.target.name,
            value: event.target.value,
            label: event.target.title,
            invalid: isInvalid
        };


        if (!temp.values[step]) {
            temp.values[step] = [value];
        } else {
            temp.values[step] = add(temp.values[step], value);
        }

        setState(prevState => {
            return {...prevState, values: temp.values}
        });
    }

    const getSomeValues = () => {
        return state.values;
        // return [
        //     [
        //         {
        //             "key": "internetConnected",
        //             "value": true,
        //             "label": "Internet connect: "
        //         },
        //         {
        //             "key": "internetReady",
        //             "value": null,
        //             "label": "Internet ready: "
        //         }
        //     ],
        //     [
        //         {
        //             "key": "model",
        //             "value": "WH-MXC09H3E5",
        //             "label": "MODEL NAME"
        //         },
        //         {
        //             "key": "serialnumber",
        //             "value": "1234567890",
        //             "label": "SERIAL NUMBER"
        //         },
        //         {
        //             "key": "installationDate",
        //             "value": "2021-03-25",
        //             "label": "INSTALLATION DATE"
        //         }
        //     ],
        //     [
        //         {
        //             "key": "password",
        //             "value": "test123!",
        //             "label": ""
        //         },
        //         {
        //             "key": "email",
        //             "value": "sdfredfre@sfresde.de",
        //             "label": ""
        //         },
        //         {
        //             "key": "title",
        //             "value": "Mrs",
        //             "label": "TITEL"
        //         },
        //         {
        //             "key": "firstName",
        //             "value": "test",
        //             "label": "FIRST NAME"
        //         },
        //         {
        //             "key": "lastName",
        //             "value": "test",
        //             "label": "Last NAME"
        //         },
        //         {
        //             "key": "address",
        //             "value": "sdasdasdasdasdasd",
        //             "label": "ADDRESS"
        //         },
        //         {
        //             "key": "addressNumber",
        //             "value": "123",
        //             "label": "NUMBER"
        //         },
        //         {
        //             "key": "zipCode",
        //             "value": "12345",
        //             "label": "ZIP CODE"
        //         },
        //         {
        //             "key": "city",
        //             "value": "asdfgfds",
        //             "label": "CITY"
        //         },
        //         {
        //             "key": "phoneNumber",
        //             "value": "12345678987",
        //             "label": "PHONE NUMBER"
        //         }
        //     ]
        // ];
    }

    const getLineItemId = () => {
        return lineItemIdState.lineItemId;
    }

    function getValuesByStep(step) {
        return state.values[step] ? state.values[step] : null;
    }

    return {
        updateValuesByStep,
        updateValuesForStep,
        getSomeValues,
        getValuesByStep,
        getLineItemId,
        updateValuesForConnection,
        setCart,
        getCart,
        setValues,
        setValueAndValidity,
        updateValuesFromAccount
    };
}

export default useCheckoutSession;
