import React from "react";
import {Link} from "react-router-dom";
import {BtnGroup, LoadingSpinnerButton} from "../layout/buttons";

function StepButtons({
                         showDisabled,
                         showBackButton,
                         backButtonHref,
                         backButtonLabel,
                         backButtonOnClick,
                         goBackToProtectionPlan,
                         nextButtonEnabled,
                         nextButtonOnClick,
                         nextButtonText,
                         btnAlignment,
                         isLoading,
                         additionalClassNames,
                     }) {
    additionalClassNames = additionalClassNames ? ' ' + additionalClassNames : '';
    return (
        <BtnGroup className={btnAlignment ?? "justify-content-between"}>
            {goBackToProtectionPlan &&
            <Link className="btn-tertiary btn-tertiary--align-left order-2 order-md-1 align-self-start"
               to={backButtonHref}>
                {backButtonLabel}
            </Link>
            }
            {
                showBackButton &&
                <div className="btn-tertiary btn-tertiary--align-left order-2 order-md-1 align-self-start"
                     onClick={backButtonOnClick}>
                    {backButtonLabel}
                </div>
            }
            <LoadingSpinnerButton
                isEnabled={nextButtonEnabled}
                onClick={nextButtonOnClick}
                isLoading={isLoading}
                text={nextButtonText}
                classNames={showDisabled === false
                    ? "btn-primary disabled order-1 order-md-2 loading-spinner-button" + additionalClassNames
                    : "btn-primary order-1 order-md-2 loading-spinner-button" + additionalClassNames}
            />
        </BtnGroup>
    );
}

export default StepButtons;
