import {useState} from "react";


function getInitialValues() {
    return {
        step: 0,
        steps: [],
        initialStep: 0
    }
}

const useNavigation = (steps, initialStep) => {

    const [state, setState] = useState(getInitialValues());


    const currentStep = () => {
        return state.step;
    }

    const setStep = (id) => {
        setState({
            step: id
        })
    }

    const goNext = () => {
        setState({
            step: state.step + 1
        })
    }

    const goPrev = () => {
        setState({
            step: state.step - 1
        })
    }

    return {currentStep, setStep, goNext, goPrev}
}

export default useNavigation;
