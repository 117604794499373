import {COUNTRY_CODE_NAME, getSessionStorageItem, removeSessionStorage} from "../sessionStorage";

export function ErrorResponseHandling(error, authContext, setAuthContext, history) {
    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

    console.error(error)

    if (!!error.response && error.response.status.toString() === "401") {
        removeSessionStorage();
        if (!!authContext.authToken) {
            setAuthContext({ authToken: null, accountData: null });
        }
        history.push('/'+ countryCode +'/home/login');
    } else {
        history.push('/'+ countryCode +'/404');
    }

    window.scrollTo(0, 0);
}