import React from 'react';
import i18 from "../lib/i18n";
const t = i18.namespace("ToggleSwitch");

const ToggleSwitch = ({checked, onClick}) => {
    return (
        <div className="toggle_switch">
            <button className={checked ? "btn-primary btn-primary--white" : "btn-primary"}
                    onClick={() => onClick(false)}>
                {t("createNewAccount")}
            </button>
            <button className={checked ? "btn-primary" : "btn-primary btn-primary--white"}
                    onClick={() => onClick(true)}>
                {t("login")}
            </button>
        </div>
    )
}

export default ToggleSwitch;