module.exports = {
  headline: "Ventajas de Aquarea Service+",
  text: "Los planes Aquarea Service+ incluyen multitud de ventajas para satisfacer diferentes necesidades. Algunos de los beneficios son:",
  list: [
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "Monitorización remota a través de Aquarea Service Cloud",
      text: "Tu bomba de calor Aquarea se puede monitorizar a distancia por el equipo Panasonic y premitirá resolver incidencias remotamente."
    },
    {
      iconUrl: "/image/icons/services/maintenance_report_bl.svg",
      iconAlt: "",
      headline: "Soporte Smart Aquarea",
      text: "Recibirás informes personalizados sobre tu instalación de calefacción y refrigeración, con consejos y sugerencias para mejorar su funcionamiento y eficiencia."
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Visitas de mantenimiento",
      text: "Todos los planes incluyen al menos una visita anual de mantenimiento por parte de los técnicos de Panasonic."
    },
    {
      iconUrl: "/image/icons/services/2_extension_warranty_bl.svg",
      iconAlt: "",
      headline: "Años de garantía adicional",
      text: "Los planes de mantenimiento ofrecen hasta dos años adicionales de garantía, puediendo llegar a ser un total de 5 años de garantía en total."
    },
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "Mano de obra y desplazamiento incluído",
      text: "Algunos de los planes incluyen la mano de obra y desplazamientos"
    },
    {
      iconUrl: "/image/icons/services/phone_bl.svg",
      iconAlt: "",
      headline: "Asistencia 6 días a la semana con tiempo de respuesta rápido",
      text: "Soporte técnico telefónico en 900 82 87 87 de lunes a viernes de 09:00h a 20:00h y sábados de 09:00 a 12:00h. Además asistencia técnica presencial para tu equipo en un plazo de 24/48h. laborales."
    }
  ]
};
