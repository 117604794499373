module.exports = {
  headline: "Great service and support benefits",
  text: "Aquarea Service+ packages include many benefits, so there are options to suit all needs. Some of the benefits include:",
  list: [
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "24/7 IoT monitoring",
      text: "Parameters of your Aquarea system will be monitored and available to enable helpful remote support."
    },
    {
      iconUrl: "/image/icons/services/alert_notification.png",
      iconAlt: "",
      headline: "Smart Aquarea Reports",
      text: "Receive customised reports about your heating system, which contain advice and tips to improve performance and effiency."
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Annual maintenance visit",
      text: "All the packages include an annual maintenance visit on your Aquarea heat pump by a Panasonic Service+ technician."
    },
    {
      iconUrl: "/image/icons/services/247_hotline.png",
      iconAlt: "",
      headline: "Daily diagnosis check",
      text: "Panasonic Service+ team reviews your system daily, to monitor for any abnormal parameters in your system."
    },
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "24/7 Priority support hotline",
      text: "Call us at anytime, any day if you have questions about your heat pump."
    },
    {
      iconUrl: "/image/icons/services/repair_discount.png",
      iconAlt: "",
      headline: "24/7 Response time",
      text: "Your unit will be supported within 24h either remotely or via home visit."
    }
  ]
};
