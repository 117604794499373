import React, {useEffect} from 'react';
import Menu from "../components/menu";
import {Page, PageFooter} from "../components/layout/page";
import SetRepairPaymentMethod from '../components/setRepairPaymentMethod';
import {scrollToPos} from "../lib/scrollToPos";

function RepairPaymentMethod() {

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    return (
        <Page>
            <Menu/>
            <SetRepairPaymentMethod subheadline="Update Payment Method"/>
            <PageFooter/>
        </Page>
    )
}

export default RepairPaymentMethod;
