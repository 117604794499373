import { useEffect,memo } from "react";

function Modal({content, primaryAction, secondaryAction, handleClose, isOpen}) {

  useEffect(() => {
		const closeOnEscapeKey = (e) => (e.key === "Escape" ? handleClose() : null);
		document.body.addEventListener("keydown", closeOnEscapeKey);
		return () => {
			document.body.removeEventListener("keydown", closeOnEscapeKey);
		};
	}, [handleClose]);

  return (
    !isOpen ? <></>:
      <div className="modal" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{content.title}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>{content.body}</p>
              {content.component}
            </div>
            <div className="modal-footer">
              {primaryAction && (primaryAction.component ?? 
                <button type="button" className="btn btn-primary" onClick={primaryAction.handler}>{primaryAction.text}</button>)
              }
              {secondaryAction && (secondaryAction.component ??
                <button type="button" className="btn btn-secondary" onClick={secondaryAction.handler} data-dismiss="modal">{secondaryAction.text}</button>)
              }

            </div>
          </div>
        </div>
      </div>
  );
}

export default memo(Modal);