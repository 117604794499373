import React from "react";

export const FeatureIconList = ({children}) => {
  return (
    <ul className="feature-icon-list list-unstyled">
      {children}
    </ul>
  );
}

export const FeatureIconListItem = ({icon, label, alt}) => {
  return (
    <li className="feature-icon-list_item">
      <img className="feature-icon-list_item_icon" src={icon} alt={alt} />
      <span className="feature-icon-list_item_label">{label}</span>
    </li>
  );
}
