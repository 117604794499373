module.exports = {
  headline: "Great service and support benefits",
  text: "Aquarea Service+ packages include many benefits, so there are options to suit all needs. Some of the benefits include:",
  list: [
    {
      iconUrl: "/image/icons/services/24h_response_time.png",
      iconAlt: "",
      headline: "Remote monitoring with Aquarea Service Cloud",
      text: "Your Aquarea heat pump can be monitored remotely by Panasonic equipment and will allow incidents to be resolved remotely."
    },
    {
      iconUrl: "/image/icons/services/maintenance_report_bl.svg",
      iconAlt: "",
      headline: "Smart Aquarea Support",
      text: "You will receive personalized reports about your heating and cooling installation, with advice and suggestions to improve its operation and efficiency."
    },
    {
      iconUrl: "/image/icons/services/maintenance_visits.png",
      iconAlt: "",
      headline: "Maintenance visits",
      text: "All plans include at least one annual maintenance visit by Panasonic technicians."
    },
    {
      iconUrl: "/image/icons/services/2_extension_warranty_bl.svg",
      iconAlt: "",
      headline: "Additional Years of Warranty",
      text: "The maintenance plans offer up to two additional years of warranty, which can reach a total of 5 years of warranty in total."
    },
    {
      iconUrl: "/image/icons/services/monitoring.png",
      iconAlt: "",
      headline: "Spare parts and labor included",
      text: "Some of the plans include labor and spare parts"
    },
    {
      iconUrl: "/image/icons/services/phone_bl.svg",
      iconAlt: "",
      headline: "Support 6 days a week with fast response time",
      text: "Telephone technical support at 900 82 87 87 from Monday to Friday from 9:00 a.m. to 8:00 p.m. and Saturdays from 9:00 a.m. to 12:00 p.m. In addition, in-person technical assistance for your team within 24/48 hours. labor."
    }
  ]
};
