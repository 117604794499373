module.exports = {
    common: {
        REQUIRED: "Detta fält är obligatoriskt",
        MAX_LENGTH: "Max {{maxLength}} tecken",
        COMPARE: "Uppgifterna måste vara identiska.",
        DATE: "Felaktigt datum",
        MIN_LENGTH: "Min {{minLength}} siffror",
        EXACTLY_LENGTH: "Exakta {{exactlyLength}} tecken",
        EMAIL: "Ogiltig e-post",
        BACKEND_VALIDATION_ERROR: "Serverhuvud",
        ZIPCODE: "{{length}} and only numbers",
        SWEDISH_ZIPCODE: "3 siffror tomt 2 siffror",
        PASSWORD: "Min. 8 tecken, siffror och bokstäver"
    },
    USER_ALREADY_REGISTERED: "E-postadressen är redan registrerad",
    REGISTRATION_FAILED: "Ett tekniskt fel uppstod under registreringen. Vänligen försök igen senare.",
    validationMethods: {
        "required": {
            message: "Detta fält är obligatoriskt",
        },
        "email": {
            message: "Ogiltig e-post",
        },
        "matching_email_values": {
            message_mail: "Ogiltig e-post",
            message_not_same: "E-postadresserna måste vara desamma"
        },
        "password": {
            message: "Felaktigt lösenord",
            message_password: "Felaktigt lösenord",
            message_password_lengths: "Min. 8 tecken, siffror och bokstäver",
            message_not_same: "Lösenorden måste vara desamma"
        },
        "loginError": {
            message: "Användarnamnet eller lösenordet är felaktigt"
        },
        "missingEmail": {
            message: "Saknad e-post"
        },
    },
    optionalValue: "Valfritt"
}
