import React from 'react';

export const Section = ({children, id}) => {
  return (
    <section className="section" id={id ?? null}>
      {children}
    </section>
  );
}

export const SectionSubheadline = ({children}) => {
  return (
    <div className="section_subheadline">
      {children}
    </div>
  );
}

export const SectionHeadline = ({children}) => {
  return (
    <h2 className="section_headline">
      {children}
    </h2>
  );
}

export const SmallSectionHeadline = ({children}) => {
  return (
      <h2 className="section_headline_small">
        {children}
      </h2>
  );
}