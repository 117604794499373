import React from "react";
import i18n from "../lib/i18n";

const t = i18n.namespace("PaymentMethods");

const PaymentMethodsSmallCart = () => {

    return(
        <div className="payment_methods">
            <div className="payment_methods_wrapper">
                <p className="payment_methods_label">{t("payments").label}</p>
                <img className="payment_methods_img" src={t("payments").visa} alt={t("payments").visa_alt}/>
                <img className="payment_methods_img" src={t("payments").mastercard} alt={t("payments").mastercard_alt}/>
                <img className="payment_methods_img" src={t("payments").paypal} alt={t("payments").paypal_alt}/>
            </div>
        </div>
    )
}

export default PaymentMethodsSmallCart;