module.exports = {
    failure: 'Uppdateringen har avvisats av betaltjänstsystemet',
    cancel: 'Uppdateringen har ställts in',
    error: 'Ett fel uppstod under uppdateringen av vårt betalningssystem - Vänligen försök igen',
    success:'Your Update is successfull',
    expired:'URL har gått ut - Vänligen försök igen',
    pending:'Utestående svar från betaltjänstsystemet',
    link: "GÅ TILL MITT KONTO",
    link_href: "/sv_SE/home/my_account"
};

