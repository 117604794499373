module.exports = {
    editBtn: "Editar",
    confirmBtn: "Confirmar compra",
    connectionCheck: "Comprobar la conexión",
    accountInformation: "Información de la cuenta",
    paymentDetails: "Forma de pago",
    installedBase: "Información sobre el dispositivo",
    monthly:"mensualmente",
    quarterly:"por trimestre",
    annually:"anualmente",
    yes: "Sí",
    no: "No",
    details: {
        monthlyPrice:"Costes mensuales",
        connectionFeePrice:"Costes del servicio de instalación único",
        internetConnected: "¿Tiene un adaptador de la nube instalado?",
        internetReady: "¿Tienes conexión a internet?",
        modelName: "Modelo",
        serialNumber: "Número de serie",
        installationDate: "Fecha de instalación",
        gatewayId: "ID de la puerta de enlace",
        title: "título",
        firstName: "Nombre",
        lastName: "Apellido",
        name: "Nombre",
        email: "Correo electrónico",
        address : "Dirección",
        zipCode : "Código postal",
        city: "Ciudad",
        phoneNumber: "Número de teléfono",
        phone : "Número de teléfono",
        paymentMethod: "Forma de pago"
    },
    confirm: "confirmar"
}
