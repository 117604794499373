module.exports = {
    headline: "Hvilken servicepakke er den rette for dig?",
    subheadline: "Vælg mellem tre forskellige Aquarea Service+  servicepakker og find den, der passer bedst til dine behov.",
    imageLeft: {
        image: "/image/home/chooseplan/left.png",
        alt: "",
        text: "Se de forskellige fordele med vores tre servicepakker og find den rette til dine behov.",
        btn_label: "Se servicepakkerne",
        btn_href: "/da_DK/home/service_package"
    },
    imageRight: {
        image: "/image/home/chooseplan/right.png",
        alt: "",
        text: "Vil du have personlig rådgivning om, hvilken pakke der passer bedst til dig?",
        btn_label: "Kontakt os",
        btn_href: "https://www.aircon.panasonic.eu/DK_da/my-project/"
    }
};
