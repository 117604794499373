module.exports = {
    yourProtectionPlan: "Pacchetto di servizi",
    contract: "Contratto Aquarea Service+",
    invoiceHistory: "Storico dei pagamenti",
    accountInformation: "Informazioni sul conto",
    reports: "Rapporter",
    welcome: "Benvenuto, ",
    noServicePackage: "Nessun pacchetto di servizi ancora",
    editAccount: "Modifica account",
    finishCheckout: "Acquisto incompleto",
    gotoCheckout: "Finisci l'acquisto incompleto",
    finishUpgrade: "Completamento dell'esecuzione dell'ordine",
}
