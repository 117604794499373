import React from 'react';
import Menu from "../components/menu";
import StepForm from "../components/stepForm/stepform";
import { Page, PageFooter } from "../components/layout/page";

const Checkout = (props) => {
  return (
    <Page>
      <Menu/>
      <StepForm {...props}/>
      <PageFooter/>
    </Page>
  )
}

export default Checkout;
