module.exports = {
    common: {
        REQUIRED: "Dette felt er obligatorisk",
        MAX_LENGTH: "Max {{maxLength}} tegn",
        COMPARE: "Indtastningerne skal være ens",
        DATE: "Ugyldig dato",
        MIN_LENGTH: "Min {{minLength}} cifre",
        EXACTLY_LENGTH: "Præcise {{exactlyLength}} tegn",
        EMAIL: "Ugyldig e-mail",
        BACKEND_VALIDATION_ERROR: "Serverfejl",
        ZIPCODE: "Præcis {{length}} cifre",
        SWEDISH_ZIPCODE: "Præcis {{length}} cifre",
        PASSWORD: "Min. 8 tegn, tal og bogstaver"
    },
    USER_ALREADY_REGISTERED: "E-mailadressen er allerede registreret",
    REGISTRATION_FAILED: "Der opstod en teknisk fejl under registreringen. Prøv venligst igen senere.",
    validationMethods: {
        "required": {
            message: "Dette felt er obligatorisk",
        },
        "email": {
            message: "Ugyldig e-mail",
        },
        "matching_email_values": {
            message_mail: "Ugyldig e-mail",
            message_not_same: "E-mailadresserne skal være ens"
        },
        "password": {
            message: "Forkert adgangskode",
            message_password: "Forkert adgangskode",
            message_password_lengths: "Min. 8 tegn, tal og bogstaver",
            message_not_same: "Adgangskoderne skal være ens"
        },
        "loginError": {
            message: "Brugernavn eller adgangskode er forkert"
        },
        "missingEmail": {
            message: "Manglende e-mail"
        },
    },
    optionalValue: "Valgfrit"
}
