module.exports = {
    headline: "Comparativa de planes de mantenimiento",
    activePlanHint: "plan de mantenimiento activo",
    buttonTextUpgrade: "mejorar a este plan",
    buttonTextActive: "plan activo",
    startingAt: " ",
    plusFee: "más intalación del dispositivo de conexión a Smart Cloud para",
    premiumText: "Al adquirir el plan de mantenimiento \"Aquarea Service+ Premium\", recibirás el plan \"Aquarea Service+ Smart\" hasta que el técnico de Panasonic valide el cumplimiento de los requisitos técnicos de tu solicitud. Si no se cumplen estos requisitos técnicos, disfrutarás de las ventajas del plan \"Aquarea Service+  Smart\". Se cobrará el precio de \"Aquarea Service+ Smart\" hasta que tu instalación sea aprobada para el plan \"Aquarea Service+ Premium\".",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_comfort.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Avanzado"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exclusivo"
            }
        ]
    },
    feature: {
        list: [{
            label: "Visita anual de mantenimiento",
            included: [true, true, "doubletick"]
            },
            {
                label: "Asistencia telefónica exclusiva 6 días de la semana",
                included: [true, true, true]
            },
            {
                label: "Priorización asistencia técnica",
                included: [false, true, true]
            },
            {
                label: "Años de garantía adicional",
                included: [false, true, "doubletick"]
            },
            {
                label: "Mano de obra y desplazamiento incluidos",
                included: [false, " ", true]
            },
            {
                label: "Actuación remota a través de Aquarea Service Cloud",
                included: [false, true, true]
            },
            {
                label: "Monitorización y entrega de informe periódicamente",
                included: [false, true, true]
            },
            {
                label: "Tiempo de respuesta en 24h. laborables*",
                included: [false, " ", true]
            },
            {
                label: "Descuento repuestos después del periodo de garantía",
                included: [false, " ", "25%"]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Incluido en el paquete"
        }
    },
    advantages: " ",
    explanation: {
        text: "*Puedes consultar en la línea telefónica de soporte técnico, 900 82 87 87, la disponibilidad del servicio técnico oficial de tu zona.",
        layout: " explanation"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>al mes (IVA incluido)</strong>",
            quarterly:"<strong>por trimestre (IVA incluido)</strong>",
            annually:"<strong>anual (IVA incluido)</strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>al mes (IVA incluido)</strong>",
            quarterly:"<strong>por trimestre (IVA incluido)</strong>",
            annually:"<strong>anual (IVA incluido)</strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>al mes (IVA incluido)</strong>",
            quarterly:"<strong>por trimestre (IVA incluido)</strong>",
            annually:"<strong>anual (IVA incluido)</strong>"
        }
    ],
    productCode: [],
    annotation: " "
};
