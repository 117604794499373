module.exports = {
    failure: 'The Update has been rejected by the payment service system',
    cancel: 'The Update has been cancelled',
    error: 'An error occured during the update at our payment service system - Please try again',
    success:'Your Update is successfull',
    expired:'URL has expired - Please try again',
    pending:'Response pending from payment service system',
    link: "GO TO MY ACCOUNT",
    link_href: "/en_ES/home/my_account"
};

