module.exports = {
    headline: "Repair Order",
    thanks: "Thank you for contacting Aquarea+ Service!",
    text: "Your repair order has been successfully finished. After the payment is completed, you will find your invoice in your email inbox.",
    invoiceAmountText: "Invoice amount: ",
    isPaidText: "Your repair order has been successfully finished, and your invoice has been successfully processed.",
    noInvoiceText: "Your repair order has been successfully finished, and your invoice has been successfully processed.",
    payButton: "Pay now",
    pendingButtonText: "In Progress",
    modal: {
        modalTitle: "Tax Code Validation",
        modalBody: "It is now mandatory to enter your tax code to make a payment. Please enter and validate your tax code to continue.",
        emptyOption: "Select Tax code type",
        inputPlaceholder: "Enter tax code",
        modalPrimaryButton: "Validate tax code",
        errorMessage: "The tax code you entered is invalid. Please enter a valid tax code.",
    },
};
