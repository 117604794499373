module.exports = {
    upgradeDiscount: {
        name: "Descuento",
        inputPlaceholder: "insertar código de descuento",
        applyBtnLable: "aplicar",
        removeBtnLable: "eliminar",
        invalidHeadline: "El código introducido no es válido",
        invalidHint: "Por favor, compruebe su entrada",
        errors: {
            tooLong: "El código de descuento introducido es demasiado largo.",
            invalidInput: "El código introducido no es válido" // upgrade page
        }
    }
};