export const exceptedPaymentMethods = (countryCode) =>{
    let paymentMethods = [];
    let visaActiveCountries = process.env.REACT_APP_VISA_ACTIVE;
    let masterCardActiveCountries = process.env.REACT_APP_MASTERCARD_ACTIVE;
    let payPalActiveCountries = process.env.REACT_APP_PAYPAL_ACTIVE;
    if(visaActiveCountries.indexOf(countryCode) >= 0){
        paymentMethods.push(visa);
    }
    if(masterCardActiveCountries.indexOf(countryCode) >= 0){
        paymentMethods.push(mastercard);
    }
    if(payPalActiveCountries.indexOf(countryCode) >= 0){
        paymentMethods.push(paypal);
    }

    return paymentMethods;
}

const visa = {
    id: 'visa',
    label: 'Visa',
    image: '/image/icons/payment/visa.svg'
}

const mastercard = {
    id: 'mastercard',
    label: 'Mastercard',
    image: '/image/icons/payment/mastercard.svg'
}

const paypal = {
    id: 'paypal',
    label: 'PayPal',
    image: '/image/icons/payment/paypal.svg'
}