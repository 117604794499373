    module.exports = {
    headline: "Repair Order",
    thanks: "Thank you for contacting Aquarea+ Service!",
    text: "Your repair order has been successfully finished. After the payment is completed, you will find your invoice in your email inbox.",
    invoiceAmountText: "Invoice amount: ",
    isPaidText: "Your repair order has been successfully finished, and your invoice has been successfully processed.",
    noInvoiceText: "Your repair order has been successfully finished, and your invoice has been successfully processed.",
    payButton: "Pay now",
    pendingButtonText: "In Progress",
};
