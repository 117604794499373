module.exports = {
    headline: "Hvorfor tilvælge Aquarea Service+ ?",
    text: "Vælger du Aquarea Service+ får du det bedste ud af din Aquarea-varmepumpe – uden unødige overraskelser. Takket være fjernovervågning kan en proffesionel tekniker tage sig af din varmepumpes drift uden at skulle forstyrre dig i din hverdag.",
    list: [
        {
            icon: "/image/icons/services/monitoring.png",
            text: "Vores serviceteknikere er eksperter i Aquarea-varmepumper",
        },
        {
            icon: "/image/icons/services/24h_response_time.png",
            text: "Vi diagnosticerer eventuelle fejl eksternt og undgår unødvendige servicebesøg",
        },
        {
            icon: "/image/icons/services/247.png",
            text: "Vi kobler IoT-teknologi til din varmepumpe",
        }
    ],
    btn_label: "SE VORES SERVICEPAKKER",
    btn_href: "/da_DK/home/service_package"
};