module.exports = {
    headline: "Jämförelse servicepaket",
    activePlanHint: "aktivt paket",
    buttonTextUpgrade: "grävningsdräkt",
    buttonTextActive: "aktivt paket",
    startingAt: " ",
    plusFee: "plus installationstjänstpaket för",
    premiumText: "Vänligen notera att en Användare som beställer ett Premium-abonnemang kommer att få ett Smart-abonnemang tills dess att Panasonic har verifierat och kontrollerat installationen. När det bekräftats att de huvudsakliga tekniska kraven har uppfyllts kan Panasonic bevilja användarens ansökan om Premium-abonnemang. Om Användaren inte uppfyller de tekniska kraven för Premium-abonnemanget kommer Användaren fortsatt kunna nyttja Smart-abonnemangets fördelar. Användaren kommer initial att debiteras avgiften för Smart-abonnemanget och om Användarens ansökan om Premium-abonnemang beviljas av Panasonic kommer Användaren fortsättningsvis att debiteras avgiften för Premium-abonnemanget.",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_smart.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exklusivt"
            }
        ]
    },
    feature: {
        list: [{
            label: "Årligt underhållsbesök",
            included: [true, true, true]
        },
            {
                label: "Prioriterad telefonsupport",
                included: [true, true, true]
            },
            {
                label: "Fjärrövervakning dygnet runt",
                included: [false, true, true]
            },
            {
                label: "Smart Aquarea-rapport",
                included: [false, true, true]
            },
            {
                label: "Daglig kontroll",
                included: [false, true, true]
            },
            {
                label: "Prioriterad telefonsupport dygnet runt",
                included: [false, true, true]
            },
            {
                label: "Service inom 24 timmar*",
                included: [false, true, true]
            },
            {
                label: "Reservdelar från Panasonic",
                included: [false, "20% rabatt", "Ingår"]
            },
            {
                label: "Arbetstid och resekostnader ingår",
                included: [false, false, true]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Ingår i paketet"
        }
    },
    advantages: "Fördelar med abonnemang",
    explanation: {
        text: "*Service på plats inom 24 timmar från felrapporten om felet inte kan lösas på plats. Inkluderar inte helger och helgdagar.",
        layout: " explanation"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>Pris inkl. moms (per månad)</strong>",
            quarterly:"<strong>pris inkl. moms (per kvartal)</strong>",
            annually:"<strong>Pris inkl. moms (årligen)</strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>Pris inkl. moms (per månad)</strong>",
            quarterly:"<strong>pris inkl. moms (per kvartal)</strong>",
            annually:"<strong>Pris inkl. moms (årligen)</strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>Pris inkl. moms (per månad)</strong>",
            quarterly:"<strong>pris inkl. moms (per kvartal)</strong>",
            annually:"<strong>Pris inkl. moms (årligen)</strong>"
        }
    ],
    productCode: [],
    annotation: " "
};
