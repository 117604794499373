module.exports = {
    failure: 'La actualización ha sido rechazada por el sistema de servicios de pago',
    cancel: 'La actualización ha sido cancelada',
    error: 'Se ha producido un error durante la actualización en nuestro sistema de servicios de pago - Por favor, inténtelo de nuevo',
    success:'Your Update is successfull',
    expired:'La URL ha caducado - Por favor, inténtelo de nuevo',
    pending:'Respuesta pendiente del sistema de servicios de pago',
    link: "IR A MI CUENTA",
    link_href: "/es_ES/home/my_account"
};

