module.exports = {
  success: {
    title: "Tack så mycket!",
    headline: "Din betalning har gått igenom!",
    subheadline: "Tack så mycket, ",
    btn: "GÅ TILL MITT KONTO",
  },
  failure: "Din betalning avvisades i vår betalningspartners system.",
  cancel: "Din betalning har avbrutits.",
  error: "Ett fel inträffade under betalningen hos vår betalningspartner.",
  expired: "Din betalningssession har löpt ut i vår betalningspartners system.",
  pending: "Din betalning är fortfarande under behandling hos vår betalningspartner. Detta kan ta upp till 10 minuter.",
  link: "GÅ TILL MITT KONTO",
  link_href: "/sv_SE/home/my_account",
};

