module.exports = {
    home: 'Home',
    termsConditions: "Terms & Conditions",
    privacyPolicy: "Privacy policy",
    protectionPlans: 'Protection plans',
    service: 'Service contact',
    error: 'Oops an Error',
    checkout: 'Checkout',
    login: 'Login',
    cancelProtectionPlan: 'Cancel Protection Plan',
    myAccount: 'My Account',
    editMyAccount: 'Edit Personal Information',
    passwordReset: 'Password Reset',
    upgradeProtectionPlan: 'Upgrade Protection Plan',
    upgrade: 'Upgrade',
    updatePayment: "Change payment method",
    registerUnit: "Register Heatpump Unit",
    faq : "Frequently Asked Questions"
}