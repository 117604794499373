import {
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
  } from "../../lib/sessionStorage";

function getStoreForCountryCode(countryCode){
    switch (countryCode.toLowerCase()) {
        case 'en_ie':
            return 'a2w_ireland';
        case 'it_it':
            return 'a2w_italy';
        case 'es_es':
        case 'en_es':
            return 'a2w_spain';
        case 'sv_se':
            return 'a2w_sweden';
        default:
            return 'a2w_denmark';
    }
}

export function getHeaderForApiRequest(authToken) {
    const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Accept-Language': '*',
        'X-Store': getStoreForCountryCode(countryCode)
    };

    return authToken ? {...headers, 'Authorization': 'Bearer ' + authToken} : headers;
}