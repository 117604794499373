module.exports = {
    failure: 'L\'aggiornamento è stato rifiutato dal sistema di servizi di pagamento.',
    cancel: 'L\'aggiornamento è stato annullato',
    error: 'Si è verificato un errore durante l\'aggiornamento del nostro sistema di servizi di pagamento - Riprovare.',
    success:'Your Update is successfull',
    expired:'L\'URL è scaduto - Riprovare',
    pending:'Risposta in attesa dal sistema di servizi di pagamento',
    link: "VAI AL MIO ACCOUNT",
    link_href: "/it_IT/home/my_account"
};

