module.exports = {
    headline: "Ditt val:",
    subheadline: "Servicepaket",
    edit_link: "/sv_SE/home/service_package",
    edit_label: "Ändra",
    label: "Installationstjänst",
    additionally: [
        {
            label: "Beställdes dessutom:",
            text: "1,495.00DKK engångsavgift",
            href_label: "DELETE",
        }
    ],
    annually: 'årlig utbetalning',
    quarterly: 'kvartalsvis betaling',
    monthly: 'månadsbetalning',
    per_month: 'per månad',
    disclaimer: 'Välj månads-, kvartals- eller årsbetalning under Betalningsuppgifter innan du beställer.'
};
