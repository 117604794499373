module.exports = {
    headline: "Reparationsordre",
    thanks: "Tak, fordi du kontaktede Aquarea+ Service!",
    text: "Din reparationsordre er blevet gennemført. Når betalingen er gennemført, vil du finde din faktura i din indbakke.",
    invoiceAmountText: "Fakturabeløb: ",
    isPaidText: "Din reparationsordre er afsluttet, og din faktura er blevet behandlet.",
    noInvoiceText: "Din reparationsordre er afsluttet, og din faktura er blevet behandlet.",
    payButton: "Betal nu",
    pendingButtonText: "I gang",
};
