module.exports = {
    title: "Uppsägning",
    headline: "Vi beklagar att du vill säga upp din prenumeration.",
    btnReturnToProfile: "Tillbaka till mitt konto",
    cancelSectionTitle: "Avsluta prenumeration",
    cancelText:"Är du säker på att du vill säga upp ditt abonnemang och därmed ditt Aquarea Service+-paket?",
    btnCancelSubscription: "Ja, avbryt min prenumeration",
    downgradeSectionTitle: "Nedgradera ditt abonnemang",
    downgradeText: "Följande nedgraderingsalternativ är tillgängliga:",
    btnDowngradeOrder: "Nedgradera mitt abonnemang",
    downgradeOptions: {
        silver: "Nedgradera till Aquarea Service+ SMART-paket",
        bronze: "Nedgradera till Aquarea Service+ BASIC-paket"
    }
}
