// TODO: rename the keys to camelCase
module.exports = {
    headline: "Comprobación",
    summary: {
        confirmation: {
            headline: "Konfirmación",
            subheadline: "Seleccione el paquete"
        },
    },
    steps: [
        {
            label: "Comprobar la conexión",
            href: "/es_ES/home/service_package",
            href_label: "VOLVER A LOS PLANES DE MANTENIMIENTO",
            next_btn: "Siguiente",
            continue_btn: "Proceder sin servicio de instalación",
            add_installation_items: {
                add_installation_btn: "Añadir el servicio de instalación ",
                add_installation_label: "Información importante:",
                add_installation_text: "Para obtener todas las ventajas de nuestros planes de mantenimiento, el equipo de aerotermia debe estar conectado a Internet.<br/><br/> Para asegurar la conexión a Internet, recomendamos solicitar nuestro  servicio de instalación. Este servicio tiene un coste único de 195€ e incluye el dispositivo CZ-TAW1 y su instalación.<br/><br/> Si sigues deseando este plan de mantenimiento, el servicio de instalación se agregará automáticamente al proceso de compra. Concertaremos el día y hora de instalación mediante una llamada telefónica.",
                add_installation_text_comfort: "Para obtener todas las ventajas de nuestros planes de mantenimiento, el equipo de aerotermia debe estar conectado a Internet.<br/><br/> Para asegurar la conexión a Internet, recomendamos solicitar nuestro  servicio de instalación. Este servicio tiene un coste único de 195€ e incluye el dispositivo CZ-TAW1 y su instalación.<br/><br/> Si sigues deseando este plan de mantenimiento, el servicio de instalación se agregará automáticamente al proceso de compra. Concertaremos el día y hora de instalación mediante una llamada telefónica.",
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "Siguiente",
                change_plan_btn: "Cambiar mi elección a Comfort",
                change_plan_link: "",
                change_plan_label: "Información importante:",
                change_plan_text: 'Como tu dispositivo no está conectado a Internet, no podemos ofrecerte todos nuestros paquetes de servicios en este momento. Con el fin de ofrecerte el mejor servicio posible, cambiaremos el paquete de servicios elegido por Aquarea Service+ Comfort.',
            },
            text: "Para ofrecerte el mejor servicio, nos aseguraremos de que tus dispositivos estén conectados a Internet.",
            inputList: [
                {
                    label: "¿Tu dispositivo está conectado a Internet?",
                    width: "col",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "no está seguro?",
                        text: "prueba",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/CloudAdapter.jpg", type: "image"}
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sí",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "¿Qué tipo de mando a distancia tienes?",
                    width: "col",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "¿no está seguro?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    images: [
                        {path: "/image/checkout/NewController.jpg", type: "label"},
                        {path: "/image/checkout/OldController.jpg", type: "label"},
                    ],
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sí",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
                {
                    label: "¿Tu aerotermia tiene menos de 1 año?",
                    width: "col",
                    name: "baseUnitAge",
                    startValue: true,
                    inputs: {
                        checkboxes: [
                            {
                                label: "Sí",
                                key: "baseUnitAge",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "baseUnitAge",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                }
            ],
        },
        {
            label: "Información de la cuenta",
            href: "/es_ES/home/service_package",
            href_label: "Volver",
            next_btn: "Siguiente",
            inputList: [
                {
                    headline: "Crear una nueva cuenta",
                    login: "INICIAR SESIÓN",
                    login_path: "",
                    login_label: "¿Existe un usuario?",
                    inputs: [
                        [{
                            label: "E-mail",
                            name: "email",
                            placeholder: "",
                            type: "email",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH", "EMAIL"],
                            constraints: {minLength: 3, maxLength: 80},
                            width: "col-12 col-lg-6",
                            checkId: "email",
                            validation: "matching_email"
                        },
                            {
                                label: "Repetir el correo electrónico",
                                name: "repeat-email",
                                placeholder: "Email",
                                type: "email",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "El correo electrónico debe ser el mismo",
                                },
                                constraints: {compareControl: "email"},
                                width: "col-12 col-lg-6",
                                checkId: "correo electrónico",
                            }],
                        [{
                            label: 'Contraseña <span>Mínimo 8 caracteres, números y letras</span>',
                            name: "password",
                            placeholder: "",
                            type: "password",
                            validators: ["REQUIRED", "MIN_LENGTH","PASSWORD"],
                            constraints: {minLength: 8},
                            width: "col-12 col-lg-6",
                            checkId: "password",
                        },
                            {
                                label: "Repite la contraseña",
                                name: "repetir contraseña",
                                placeholder: "Password",
                                type: "password",
                                validators: ["REQUIRED", "COMPARE"],
                                validationMessages: {
                                    COMPARE: "La contraseña debe ser la misma",
                                },
                                constraints: {compareControl: "password", minLength: 8},
                                width: "col-12 col-lg-6",
                                checkId: "password",
                            }]
                    ]
                },
                {
                    headline: "Dirección",
                    inputs: [
                        [{
                            label: "Nombre",
                            name: "firstName",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 2, maxLength: 19},
                            width: "col-12 col-lg-6",
                            validation: "required"
                        },
                            {
                                label: "Apellido",
                                name: "lastName",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 2, maxLength: 19},
                                type: "text",
                                width: "col-12 col-lg-6",
                                validation: "required"
                            }],
                        [{
                            label: "Dirección",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 1, maxLength: 30},
                            width: "col"
                        }],
                        [{
                            label: "Código postal",
                            name: "zipCode",
                            placeholder: "",
                            validators: ["REQUIRED", "EXACTLY_LENGTH"],
                            constraints: {exactlyLength: 5},
                            type: "text",
                            width: "col-6"
                        },
                            {
                                label: "Ciudad",
                                name: "city",
                                placeholder: "",
                                validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                                constraints: {minLength: 1, maxLength: 35},
                                type: "text",
                                width: "col-6"
                            },
                            {
                            label: "País <img src='/image/menu/locales/spain.jpg' style='width: 1.5em'/> <br> <span> Este paquete de servicios y condiciones se aplica a las unidades instaladas en el país indicado. Si su bomba de calor está instalada en otro país, seleccione el país en la parte superior derecha de la página, donde se muestran los países en los que Aquarea Service+ está disponible.</span>",
                            name: "country",
                            type: "country",
                            width: "col-12"
                        }],
                        [{
                            label: "Número de teléfono",
                            name: "phoneNumber",
                            key: "phoneNumber",
                            placeholder: "",
                            validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
                            constraints: {minLength: 9, maxLength: 17},
                            type: "phone",
                            width: "col",
                            offset:3
                        }]
                    ]
                }, 
                // {
                //     headline: "Factura",
                //     inputs: [
                //         [{
                //             label: "¿Desea recibir la factura impresa?",
                //             name: "sendInvoice",
                //             type: "checkbox",
                //             width: "col-12 col-lg-6"
                //         }]
                //     ]
                // }
            ],
        },
        {
            label: "Información sobre el pago",
            href: "/es_ES/home/service_package",
            href_label: "Volver",
            subheadline: "Método de pago",
            per_month:"por mes",
            pay: "",
            monthly:"mensualmente",
            quarterly:"trimestralmente",
            annually:"anualmente",
            discount: {
                name: "Descuento",
                placeholder: "código de descuento",
                button: "aplicar",
                remove: "eliminar",
                invalidHeadline: "El código introducido no es válido",
                invalidHint: "Por favor, compruebe su entrada",
                success: "El código de descuento ha sido aceptado y se aplicará automáticamente en su compra. Por favor tenga en cuenta que el descuento no será visible en la cantidad total hasta que finalice la gestión el pago, dónde podrá ver el descuento en su calendario de pago.",
                errors: {
                    tooLong: "El código de descuento introducido es demasiado largo."
                }
            },
            text: "Seleccionar forma de pago. El pago se procesará después de la compra.",
            disclaimer: "Al adquirir el Plan de mantenimiento \"Aquarea Service+ Premium\" recibirás el plan \"Aquarea Service+ Smart\" hasta que el técnico de Panasonic verifique la instalación y compruebe que se cumplen los requisitos técnicos necesarios. Si no se cumplen estos requisitos, disfrutarás de las ventajas del plan \"Aquarea Service+ Smart\". Hasta que la instalación sea aprobada para la contratación de \"Aquarea Service+ Premium, se cobrará el precio de \"Aquarea Service+ Smart\".",
            credit_secure: "Tenga en cuenta que nuestra plataforma sólo acepta tarjetas de crédito que hayan sido habilitadas con el protocolo 3D Secure.\n" +
                "3D Secure es un paso adicional de seguridad en la validación del proceso de pago, en el que el titular de la tarjeta tiene que introducir \n" +
                "un código que se le ha enviado a su teléfono móvil.\n" +
                "Para activarlo en su tarjeta de crédito, póngase en contacto directamente con su banco o con el emisor de la tarjeta de crédito. \n" +
                "También le informamos de que puede pagar con PayPal.\n",
            visa_secure: "/image/icons/checkout/visaSecure.png",
            visa_secure_alt: "visa secure",
            mastercard_id_check: "/image/icons/checkout/mastercardIdCheckBlack.png",
            mastercard_id_check_alt: "mastercard id check",
            agreement: "Confirmo que he leído y acepto los <a href=\"/es_ES/home/terms_and_conditions\" target=\"_blank\">Términos y condiciones de Aquarea Service+</a>, y que conozco la <a href=\"/es_ES/home/privacy_policy\" target=\"_blank\">Política de privacidad de Aquarea Service+.</a>",
            policy: "Requiero expresamente y acepto que el plan de mantenimiento empiece antes del periodo de 14 días de desistimiento. Aceptaré los cargos de reparación y mantenimiento incurridos antes de que se cumplan los 14 días de mi derecho de desistimiento.",
            campaign: "Acepto recibir por separado un correo electrónico de Treedom con un código para iniciar la plantación de mi árbol.",
            next_btn: "Siguiente",
            inputList: [
                {
                    headline: "Introduzca los datos de pago",
                    inputs: [
                        {
                            label: "Forma de pago",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Forma de pago",
                                    key: "paymentOpt",
                                    value: "crédito",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Forma de pago",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "col"
                        },
                        {
                            label: "NÚMERO DE TARJETA",
                            name: "card_number",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "FECHA DE CADUCIDAD",
                            name: "expiration_date",
                            placeholder: "Fecha de caducidad",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            labels: [
                                {
                                    id: "derechos de datos",
                                    label: "Por favor, confirme que ha leído y aceptado los términos del Acuerdo de Cliente de Aquarea Service+",
                                    name: "derechos de datos",
                                    value: "",
                                    width: "col"
                                },
                            ],
                            type: "radio",
                            width: "col",
                        }
                    ]
                },
            ],
        },
        {
            label: "Confirmación",
            href: "/protection_plans",
            href_label: "Volver",
            next_btn: "Siguiente",
            inputList: [
                {
                    headline: "Crear una nueva cuenta",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CONTRASEÑA",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        }
                    ]
                },
                {
                    headline: "Kontoadresse",
                    inputs: [
                        {
                            label: "TIPO DE CLIENTE",
                            name: "customer",
                            placeholder: "Privado",
                            type: "select",
                            width: "col col-lg-6",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "NOMBRE",
                            name: "first_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "APELLIDO",
                            name: "last_name",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "DIRECCIÓN",
                            name: "street",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        },
                        {
                            label: "NÚMERO DE PUESTO",
                            name: "zipCode",
                            validators: ["REQUIRED", "ZIPCODE"],
                            constraints: {length: 5},
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "CIUDAD",
                            name: "city",
                            placeholder: "",
                            type: "text",
                            width: "col col-lg-6"
                        },
                        {
                            label: "TELÉFONO",
                            name: "phoneNumber",
                            placeholder: "",
                            type: "text",
                            width: "col"
                        }
                    ]
                }
            ],
        }
    ]
}

