import React from "react";
import {Link} from "react-router-dom";
import i18n from "../lib/i18n";

// components
import PaymentMethods from "./paymentMethods";

const t = i18n.namespace("Footer");

const Footer = () => {
    return (
        <section className="footer">
            <div className="footer_wrapper container">
                <div className="footer_desktop">
                    <p className="footer_headline">{t("headline")}</p>
                    <ul className="list-unstyled footer_icons">
                        {
                            t("icons").map((item, i) => {
                                return (
                                    <li key={i} className={"footer_icons_icon footer_icons_icon--" + item.icon}>
                                        <a href={item.link} target="_blank" rel="noopener noreferrer"><i></i></a>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
                <div className="footer_line"/>
                <ul className="list-unstyled footer_links">
                    {
                        t("links").map((item, i) => {
                            return (
                                <li key={i} className="footer_links_link">
                                    {
                                        item.target ? <a target="_blank" rel="noopener noreferrer" href={item.href}>{item.label}</a> : <Link to={item.href}>{item.label}</Link>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
                <p className="footer_copyright">{t("copyright")}</p>
                <PaymentMethods/>
            </div>
        </section>
    )
}
export default Footer;