module.exports = {
    image: "",
    text: "Un sistema di riscaldamento richiede una manutenzione regolare per farlo funzionare bene e prolungarne la vita il più possibile - proprio come la mia auto. Con un piano Aquarea Service+, posso rilassarmi mentre Panasonic si occupa della manutenzione.",
    author: "Cecilie M. Thomson",
    quotes:{
        image: "/image/home/quotes/logo.png",
        alt: "aquarea",
        headline: "Esplora la nostra vasta gamma di attrezzature per le nostre pompe di calore Aquarea",
        link: "Leggi di più sulle pompe di calore Aquarea qui",
        link_href: "https://www.aircon.panasonic.eu/IT_it/"
    }
};
