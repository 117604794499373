module.exports = {
    headline: "Sammenlign servicepakker",
    activePlanHint: "aktiv pakke",
    buttonTextUpgrade: "opgraderingspakke",
    buttonTextActive: "aktiv pakke",
    startingAt: "fra",
    plusFee: "plus installationsservicepakke til",
    premiumText: "Når du køber servicepakke \"Aquarea + Premium\", får du \"Aquarea + Smart\", indtil Panasonics tekniker kontrollerer installationen og godkender, at vigtige tekniske krav er opfyldt. Hvis du ikke opfylder disse tekniske krav, vil du forblive og nyde fordelene ved \"Aquarea + Smart\"-pakken. Indtil din installation er godkendt til \"Aquarea + Premium\", bliver du opkrævet for \"Aquarea + Smart\"-pris.",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_smart.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Exclusive"
            }
        ]
    },
    feature: {
        list: [{
            label: "Årligt vedligeholdelsesbesøg",
            included: [true, true, true]
        },
            {
                label: "Eksklusiv telefonisk support",
                included: [true, true, true]
            },
            {
                label: "24/7 IoT fjernovervågning",
                included: [false, true, true]
            },
            {
                label: "Smart Aquarea-rapport",
                included: [false, true, true]
            },
            {
                label: "Dagligt kontroltjek",
                included: [false, true, true]
            },
            {
                label: "24/7 Ekslusiv telefonisk support",
                included: [false, true, true]
            },
            {
                label: "Service inden for 24 timer*",
                included: [false, true, true]
            },
            {
                label: "Panasonic reservedele",
                included: [false, "20% rabat", "Gratis"]
            },
            {
                label: "Arbejdstid og rejseomkostninger inkluderet",
                included: [false, false, true]
            },
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Inkluderet i pakken"
        }
    },
    advantages: " ",
    explanation: {
        text: "*Service inden for 24 timer efter rapportering af fejl, dog ikke weekender og helligdage, hvis fejlen ikke kan løses ved fjernstyring.",
        layout: " explanation"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>per måned (Pris inkl. moms)</strong>",
            quarterly:"<strong>per kvartal (Pris inkl. moms)</strong>",
            annually:"<strong>årligt(Pris inkl. moms)</strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>per måned (Pris inkl. moms)</strong>",
            quarterly:"<strong>per kvartal (Pris inkl. moms)</strong>",
            annually:"<strong>årligt(Pris inkl. moms)</strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>per måned (Pris inkl. moms)</strong>",
            quarterly:"<strong>per kvartal (Pris inkl. moms)</strong>",
            annually:"<strong>årligt(Pris inkl. moms)</strong>"
        }
    ],
    productCode: [],
    annotation: " "
};
