module.exports = {
    upgradeDiscount: {
        name: "Rabat",
        inputPlaceholder: "indsæt rabatkode",
        applyBtnLable: "anvende",
        removeBtnLable: "fjerne",
        invalidHeadline: "Den indtastede kode er ugyldig",
        invalidHint: "Kontroller venligst dine input",
        errors: {
            tooLong: "Den indtastede rabatkode er for lang.",
            invalidInput: "Kontroller venligst dine input" // upgrade page
        }
    }
};