module.exports = {
    headline: "Confronta i pacchetti di servizi",
    activePlanHint: "pacchetto attivo",
    buttonTextUpgrade: "aggiorna",
    buttonTextActive: "pacchetto attivo",
    startingAt: "da",
    plusFee: "più il pacchetto di servizi di installazione per",
    premiumText: "Per attivare i pacchetti \"Premium\" e \"Smart\", è necessario una linea internet attiva e il collegamento dell’impianto all'Aquarea Service Cloud. \n" +
        "Per il pacchetto \"Premium\", in fase di sottoscrizione del contratto, viene acquistata inizialmente la versione \"Aquarea+ Smart\" al corrispondente valore di vendita.\n" +
        "Una volta verificata e comunicata la piena idoneità dell’impianto, il pacchetto passerà alla versione \"Premium\" e verrà addebitata la restante quota a copertura del valore di vendita del pacchetto \"Premium\".\n" +
        "In assenza di requisiti idonei al completo utilizzo del pacchetto \"Aquarea+ Premium\", non sarà possibile erogare il servizio, l'abbonamento verrà cancellato e l'importo pagato, restituito.",
    header: {
        plan: [
            {
                path: "/image/plan_cards/aquarea_service+_comfort.svg",
                alt: "Aquarea Service+ Smart"
            },
            {
                path: "/image/plan_cards/aquarea_service+_advanced.svg",
                alt: "Aquarea Service+ Advanced"
            },
            {
                path: "/image/plan_cards/aquarea_service+_exclusive.svg",
                alt: "Aquarea Service+ Esclusivo"
            }
        ]
    },
    feature: {
        list: [
            {
            label: "Manutenzione annuale in loco",
            included: [true, true, true]
            },
            {
                label: "Interventi prioritari",
                included: ["48h", "48h", "24h"]
            },
            {
                label: "Adempimenti di legge e burocratici",
                included: [true, true, true]
            },
            {
                label: "Hotline per segnalazione guasti 24/7 (call back)",
                included: [true, true, true]
            },
            {
                label: "Monitoraggio 24/7 via Aquarea Service Cloud",
                included: [false, true, true]
            },
            {
                label: "Notifica in tempo reale di eventuali anomalie",
                included: [false, true, true]
            },
            {
                label: "Supporto tecnico a distanza",
                included: [false, true, true]
            },
            {
                label: "Scontistica dedicata per l'acquisto dei ricambi per interventi fuori garanzia (manodopera esclusa)",
                included: [false, "5%*", "10%*"]
            },
            {
                label: "Diagnosi predittiva dei malfunzionamenti",
                included: [false, false, true]
            },
            {
                label: "Rapporto annuale sui principali parametri di funzionamento",
                included: [false, false, true]
            },
            {
                label: "Consulenza personalizzata per ridurre gli sprechi ed aumentare il comfort",
                included: [false, false, true]
            }
        ],
        includedIcon: {
            src: "/image/icons/check_circle.svg",
            alt: "Incluso nel pacchetto"
        }
    },
    advantages: "BENEFICI DI UNA SOTTOSCRIZIONE",
    explanation: {
        text: " ",
        layout: " explanation"
    },
    planAbo: [
        {
            sku: "contract-heatpump-bronze",
            price: '25€',
            monthly: "<strong>al mese IVA inclusa</strong>",
            quarterly:"<strong>per trimestre IVA inclusa</strong>",
            annually:"<strong>annuale IVA inclusa</strong>"
        },
        {
            sku: "contract-heatpump-silver",
            price: '35€',
            monthly: "<strong>al mese IVA inclusa</strong>",
            quarterly:"<strong>per trimestre IVA inclusa</strong>",
            annually:"<strong>annuale IVA inclusa</strong>"
        },
        {
            sku: "contract-heatpump-gold",
            price: '48€',
            monthly: "<strong>al mese IVA inclusa</strong>",
            quarterly:"<strong>per trimestre IVA inclusa</strong>",
            annually:"<strong>annuale IVA inclusa</strong>"
        }
    ],
    productCode: [],
    annotation: "*rispetto al listino Panasonic in vigore"
};
