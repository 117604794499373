import React, {useContext, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Menu from "../components/menu";
import Footer from "../components/footer";
import i18 from "../lib/i18n";

import Dropdown from "../components/dropdown";
import Breadcrumbs from "../lib/breadcrumbs";
import PlanComparisonTable from "../components/planComparisonTable";
import PlanServicePoints from "../components/planServicePoints";
import {
    ANONYMOUS_ID_SESSION_STORAGE_NAME,
    CART_ID_SESSION_STORAGE_NAME,
    COUNTRY_CODE_NAME,
    getSessionStorageItem,
    setSessionStorageItem
} from '../lib/sessionStorage';
import {changeCartContract, getCartFromServer, putCart} from '../lib/dataAccess/checkout/cartRestEndpoint';
import {CartContext} from '../lib/contexts/cartContext';
import {ProtectionPlanContext} from "../lib/contexts/protectionPlanContext";
import {FeatureIconList, FeatureIconListItem} from "../components/layout/featureIconList";
import {AuthContext} from "../lib/contexts/authContext";
import {ProductContext} from "../lib/contexts/productContext";
import {getProduct} from "../lib/dataAccess/product/productRestEndpoint";
import {getPriceForSku} from '../lib/helper/productHelper';
import {ErrorResponseHandling} from "../lib/dataAccess/errorResponseHandling";
import {OptionalValue} from "../components/layout/detailEntry";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';
const t = i18.namespace("PlanCard");

function ProtectionPlans(props) {
    const {setProtectionPlanContext} = useContext(ProtectionPlanContext);
    const {setProductContext} = useContext(ProductContext);
    const {cartContext, setCartContext} = useContext(CartContext);
    const {authContext, setAuthContext} = useContext(AuthContext);
    const {productContext} = useContext(ProductContext);
    const [products, setProduct] = useState([]);

    const history = useHistory();

    useEffect(() => {
        getProduct()
            .then(response => {
                if (response.status === 200) {
                    setProduct(response.data.result)
                }
            })
    }, [])

    async function handleClick(event, skuName, id) {
        event.preventDefault();
        setProtectionPlanContext(id);
        setProductContext(prevState => {
            return {...prevState, selectedPlan: skuName, selectedVariant: null}
        });
        const anonymousId = getSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME) || '';
        let serverResponse;

        try {
            let cartFromServerForLoggedInUser;

            if (authContext.authToken) {
                cartFromServerForLoggedInUser = await getCartFromServer(null, authContext.authToken)
                    .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    })
            }
            if (cartContext.lineItems?.length > 0 || cartFromServerForLoggedInUser?.data.lineItems?.length > 0) {
                serverResponse = await changeCartContract(skuName, false, anonymousId, authContext?.authToken)
                    .catch(e => {
                        ErrorResponseHandling(e, authContext, setAuthContext, history);
                    });
            } else {
                serverResponse = await putCart({
                    sku: skuName,
                    installedBaseUnitReference: '',
                    anonymousId
                }, authContext?.authToken);
            }

            setCartContext(serverResponse.data);
            setSessionStorageItem(ANONYMOUS_ID_SESSION_STORAGE_NAME, serverResponse.data.anonymousId);
            setSessionStorageItem(CART_ID_SESSION_STORAGE_NAME, serverResponse.data.id);

            history.push('/'+ countryCode +'/home/checkout');
        } catch (error) {
            ErrorResponseHandling(error, authContext, setAuthContext, history);
        }
    }

    function drawPrices(item) {
        let productVariantSkus = [];

        products.forEach(product => {
            if (item.sku === product.sku) {
                productVariantSkus = product.variants
            }
        })

        productVariantSkus = productVariantSkus.reverse();

        if (productVariantSkus[0] !== undefined) {
            return productVariantSkus.map((productVariantSku, index) => {
                let span;
                if (index === 0) {
                    span =
                        <span key={index}>
                            <b>{getPriceForSku(productVariantSku.sku, productContext)}
                                {" " + t(productVariantSku.paymentPeriod)}</b><br/>
                        </span>
                } else {
                    span =
                        <span key={index}>
                            {getPriceForSku(productVariantSku.sku, productContext)}
                            {" " + t(productVariantSku.paymentPeriod)}<br/>
                        </span>
                }
                return span;
            });
        }
    }

    return (
        <div>
            <div className="page">
                <Menu/>
                <div className="page_header container">
                    <section className="breadcrumbs">
                        <Breadcrumbs/>
                    </section>

                    <h1 className="planCard_headline">{t("headline")}</h1>
                </div>
                <div className="page_body">
                    <section className="planCard">
                        <div className="container_for_desktop">


                            <ul className="planCard_list planCard_list_mobil">
                                {
                                    t("list").map((items, i) =>
                                        <li key={i} className={"planCard_list_item planCard_list_item__" + items.color}>
                                            <div className="planCard_list_box">
                                                <img className="planCard_list_headline" src={items.headline} alt=""/>
                                                <div className="planCard_list_image"
                                                     style={{backgroundImage: `url(${items.image})`}}>
                                                </div>
                                                <div className="planCard_list_image_plusicon"/>
                                            </div>
                                            <div className="planCard_list_box">
                                                <p className="planCard_list_subheadline">
                                                    {drawPrices(items)}
                                                </p>

                                                <Dropdown label={t("desc_label")} text={items.text}/>

                                                <Dropdown label={t("terms_label")} text={<Link className="planCard_list_link" to={items.linkHref}>{items.link}</Link>}/>

                                                <FeatureIconList>
                                                    {
                                                        items.iconList.map(({label, alt, icon}, index) =>
                                                            <FeatureIconListItem key={index} label={label} icon={icon}
                                                                                 alt={alt}/>
                                                        )
                                                    }
                                                </FeatureIconList>
                                                <OptionalValue condition={items.sku ==='contract-heatpump-gold'} emptyValue="">
                                                    <p className="planCard_list_text">{items.disclaimer}</p>
                                                </OptionalValue>

                                                <div className="planCard_list_btns">
                                                    <a className="planCard_list_btns_btn planCard_list_btns_btn--secondary"
                                                       href="#plan-comparison-table">
                                                        {items.btn_goTo.label}
                                                    </a>

                                                    <button type="button" className="planCard_list_btns_btn"
                                                            onClick={(event) => handleClick(event, items.sku, i)}>
                                                        {items.btn_book.label}
                                                    </button>

                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>

                            <ul className="planCard_list planCard_list_desktop">
                                {
                                    t("list").map((items, i) =>
                                        <li key={i} className={"planCard_list_item planCard_list_item__" + items.color}>
                                            <div key={i} className="planCard_list_box">
                                                <img className="planCard_list_headline" src={items.headline} alt=""/>
                                                <p className="planCard_list_subheadline">
                                                    {drawPrices(items)}
                                                </p>
                                                <p className="planCard_list_text">
                                                    {items.text}
                                                </p>
                                                <p className="planCard_list_info">
                                                    {items.info}
                                                </p>

                                                <FeatureIconList>
                                                    {
                                                        items.iconList.map(({label, alt, icon}, index) =>
                                                            <FeatureIconListItem key={index} label={label} icon={icon}
                                                                                 alt={alt}/>
                                                        )
                                                    }
                                                </FeatureIconList>

                                                <OptionalValue condition={items.sku ==='contract-heatpump-gold' ||
                                                                          items.sku ==='contract-heatpump-silver'}
                                                               emptyValue="">
                                                    <p className="planCard_list_text">{items.disclaimer}</p>
                                                </OptionalValue>
                                                <div className="planCard_list_listlabel p">
                                                    {items.good_to_know_title}
                                                    <ul className="planCard_list_list list-unstyled">
                                                        {
                                                            items.good_to_know_list.map((items, i) =>
                                                                <li key={i} className="planCard_list_list_item">
                                                                    {items.label}
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                <Link className="planCard_list_link" to={items.linkHref}>{items.link}</Link>
                                                <div className="planCard_list_btns">
                                                    <a className="planCard_list_btns_btn planCard_list_btns_btn--secondary"
                                                       href="#plan-comparison-table">
                                                        {items.btn_goTo.label}
                                                    </a>
                                                    <button
                                                        type="button"
                                                        className="planCard_list_btns_btn"
                                                        onClick={(event) => handleClick(event, items.sku, i)}
                                                    >
                                                        {items.btn_book.label}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="planCard_list_box">
                                                <div className="planCard_list_image"
                                                     style={{backgroundImage: `url(${items.image})`}}>
                                                </div>
                                                <div className="planCard_list_image_plusicon"/>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </section>
                    <PlanServicePoints/>
                    <PlanComparisonTable/>
                </div>
                <Footer/>
            </div>
        </div>
    )
}

export default ProtectionPlans;
