module.exports = {
    headline: "Bentornato",
    subheadline: "Bentornato",
    inputs: [
        {
            label: "Email",
            id: "email",
            name: "email",
            placeholder: "Email",
            type: "email"
        },
        {
            label: "Password",
            id: "password",
            name: "password",
            password_placeholder: "Password",
            type: "password"
        }
    ],
    btn: "Accesso",
    link: "Password dimenticata",
    link_href: "/it_IT/home/password_forgot",
    newProtectionPlan: {
        label: "Avete bisogno di un pacchetto di servizi?",
        link: "Ordina un pacchetto di servizi",
        link_href: "/it_IT/home/service_package",
    }
}