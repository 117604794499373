module.exports = {
    headline: "¿Qué paquete de servicios le conviene?",
    subheadline: "Elija entre tres paquetes diferentes de Aquarea Service+ y encuentre el que mejor se adapte a sus necesidades.",
    imageLeft: {
        image: "/image/home/chooseplan/left.png",
        alt: "",
        text: "Pruebe nuestro test interactivo y encuentre el paquete perfecto para sus necesidades",
        btn_label: "Empezar a probar",
        btn_href: "/es_ES/home/service_package"
    },
    imageRight: {
        image: "/image/home/chooseplan/right.png",
        alt: "",
        text: "¿Desea asesoramiento personal sobre qué paquete es el mejor para usted?",
        btn_label: "Contacto con nosotros",
        btn_href: "https://www.aircon.panasonic.eu/ES_es/my-project/"
    }
};
