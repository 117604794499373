module.exports = {
    inputs: [
        {
            label: "Nuova password",
            id: "newPassword",
            name: "newPassword",
            placeholder: "Nuova password",
            type: "password"
        },
        {
            label: "Conferma la password",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Conferma la password",
            type: "password"
        }
    ],
    success: "Richiesta di reimpostazione della password, controlla la tua casella di posta elettronica!",
    subheadline: "Ripristina la password",
    headline: "Ripristino password",
    resetBtn: "Ripristina la password"
}