module.exports = {
    headline: "LA TUA SCELTA:",
    subheadline: "Pacchetto di servizi",
    edit_link: "/it_IT/home/service_package",
    edit_label: "EDIT",
    label: "Servizio di installazione",
    additionally: [
        {
            label: "Inoltre è stato ordinato:",
            text: "1.495,00DKK una tantum",
            href_label: "CANCELLARE",
        }
    ],
    annually: 'pagamento annuale IVA inclusa',
    quarterly: 'pagamento trimestrale IVA inclusa',
    monthly: 'pagamento mensile IVA inclusa',
    per_month: 'al mese',
    disclaimer: 'Seleziona il pagamento mensile, trimestrale o annuale sotto Dettagli di pagamento prima di ordinare.'
};
