import React, { useContext, useState } from "react";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {AuthContext} from "../../lib/contexts/authContext";
import { downloadReport } from "../../lib/dataAccess/customer/reportsRestEndpoint";
import { ErrorResponseHandling } from "../../lib/dataAccess/errorResponseHandling";
import i18n from "../../lib/i18n";
import {
  AUTH_TOKEN_SESSION_STORAGE_NAME,
  getSessionStorageItem,
} from "../../lib/sessionStorage";
import {SectionSubheadline} from "../layout/section";
const FileDownload = require('js-file-download');


const t = i18n.namespace("Reports");

function Reports(props) {
  let associatedReports = [];
  props.reports.forEach((report) => {
      let gatewayidFromReportName = report.substr(-14, 10);

      if (gatewayidFromReportName === props.account.installedBaseUnits[props.index].gatewayId) {
          associatedReports.push(report)
      }
  });

  const { authContext, setAuthContext } = useContext(AuthContext);
  const history = useHistory();

  const [showAll, setShowAll] = useState(false);


  function sortInTime() {
      return function (p1, p2) {
          let d1 = new Date(p1);
          let d2 = new Date(p2);

          if (d1 === d2) {
              return 0;
          } else {
              return (d1 < d2) ? 1 : -1;
          }
      };
  }

  function getTableBody() {
    let entries = [];

    let entriesWithTime = [];
      associatedReports.forEach((entry) => {
        let date = Date.UTC(parseInt(entry.substr(37, 4)),
          parseInt(entry.substr(41, 2)));
        entriesWithTime.push([date, entry]);
    });

    const sortedReports = entriesWithTime.sort(sortInTime());
    let displayedEntries = sortedReports;
    if (!showAll) {
      displayedEntries = sortedReports.slice(0, 5);
    }

    displayedEntries.forEach((entry) => {
    let date = getDateFromReport(entry[1]);
    let link = getLinkFromReport(entry[1]);
      entries.push(
        <>
          <tr>
            <td>{date}</td>
            <td>{link}</td>
          </tr>
        </>
      );
    });
    return entries;
  }

  function getDateFromReport(report) {
    let year =  report.substr(37, 4);
    let month = report.substr(41, 2);
    return month + "." + year;
  }

  function getLinkFromReport(report) {
    return <div
      className="btn-secondary btn-secondary--align-left order-2 order-md-1 align-self-start"
      onClick={() =>
        { 
          downloadReport(report, getSessionStorageItem(AUTH_TOKEN_SESSION_STORAGE_NAME))
            .then((response) => 
              {
                FileDownload(response.data, report.substr(37));
              })
              .catch(e => {
                  ErrorResponseHandling(e, authContext, setAuthContext, history);
              });
        }
      }>{t("download")}</div>
  }

    function showAllButton() {
        return (associatedReports.length > 5) ?
            <div className="show_all">
                <button onClick={() => setShowAll(!showAll)}>
                    {showAll ? t("table.hideOlder") : t("table.showAll")}
                </button>
            </div> : ""
    }

    return (
        (associatedReports.length > 0) ?
      <>
        <SectionSubheadline>{t("reports")}</SectionSubheadline>
        <div className={"reports_body"}>
            <h2 className="section_headline">{t("headline")}</h2>
            <div className="table-responsive">
                <table className="table table--striped">
                    <thead>
                    <tr>
                        <th>{t("table.headers.date")}</th>
                        <th className="table_col-greyedout">{t("table.headers.report")}</th>
                    </tr>
                    </thead>
                    <tbody>
                      {getTableBody()}
                      <tr>
                          <td colspan="2">{showAllButton()}</td>
                      </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </> : ""
  );
}

export default Reports;
