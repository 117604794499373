module.exports = {
    inputs: {
        label: "Correo electrónico",
        id: "correo electrónico",
        name: "correo electrónico",
        placeholder: "Su correo electrónico",
        type: "correo electrónico"
    },
    success: "Se ha solicitado el restablecimiento de la contraseña, por favor, compruebe su bandeja de entrada de correo electrónico.",
    subheadline:"Contraseña olvidada",
    headline:"Contraseña olvidada",
    submit: "Enviar"
}