module.exports = {
    reports: "Rapporter",
    headline: "Aquarea Service+ Driftsrapport",
    table: {
        headers: {
            date: "",
            report: "rapporto operativo",
        },
        showAll: "viti da segnalare",
        hideOlder: "nascondere i vecchi rapporti",
    },
    download: "Scaricare i rapporti con lei"
}
