module.exports = {
    headline: "Kassan",
    list: [
        {
            label: "Kontroll av anslutningen",
            href: "/sv_SE/home/service_package",
            href_label: "TILLBAKA TILL SKYDDSPLANER",
            next_btn: "NÄSTA STEG",
            add_installation_items: {
                add_installation_btn: "Lägg till installationstjänst för 1 495,00DKK",
                add_installation_label: "Viktig information:",
                add_installation_text: 'För att vi ska kunna erbjuda dig alla tjänster i våra skyddsplaner är det nödvändigt att din enhet är ansluten till Internet. <br><br>För att säkerställa att din enhet är ansluten till internet rekommenderar vi att du bokar vårt paket med internetinstallationstjänst. Denna tjänst kostar dig en engångsavgift på 49 euro. <br><br>Vi kommer automatiskt att lägga till vår installationstjänst i köpprocessen och ringa dig för att bestämma en tid för installationen.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "NÄSTA STEG",
                change_plan_btn: "Ändra mitt val till Aquarea Plan*",
                change_plan_link: "Läs mer om Aquare Plan *",
                change_plan_label: "Viktig information:",
                change_plan_text: 'Vi kan inte erbjuda dig alla våra skyddsplaner just nu eftersom din enhet inte kan anslutas till Internet.För att ge dig bästa möjliga service kommer vi att ändra din valda skyddsplan till Aquarea Bronze i denna köpprocess. <br><br>Din valda skyddsplan: Aquarea Plan *** <br><br>Vi kommer att ändra till: Aquarea Plan* för 25€ i månaden.',
                change_plan_dropdowntext: 'Med vårt underhållsavtal Aquarea Guard har du ett bekymmer mindre. Din värmepump övervakas permanent på distans. Och om ett fel uppstår är vi de första som får veta det. Vi kontaktar dig omedelbart och försöker lösa problemet eller samordna ett servicebesök.<br><br>Du behöver inte göra något annat än att ta en kopp te under tiden.',
                change_plan_dropdowntext_close_btn: true,
                change_plan_dropdowntext_close_btn_label: "NÄRA INFORMATION",
            },
            inputList: [
                {
                    text: "För att vi ska kunna ge dig en optimal tjänst måste vi se till att dina enheter är anslutna till internet.",
                    label: "Är enheten ansluten till internet?",
                    width: "fullständig",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "är du inte säker?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "Ingen",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Är din enhet redo för internet?",
                    width: "fullständig",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "är du inte säker?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Ja",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "Ingen",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
            ],
        },
        {
            label: "Installerad bas",
            href: "/service_package",
            href_label: "TILLBAKA TILL SKYDDSPLANER",
            next_btn: "NÄSTA STEG",
            inputList: [
                {
                    headline: "Vilken modell är installerad?",
                    inputs: [
                        {
                            label: "MODELLNAMN",
                            name: "model",
                            placeholder: "KIT-ADC05JEB5",
                            type: "välj",
                            width: "fullständig",
                            options: [
                                "KIT-ADC05JEB5",
                                "KIT-WQC09H3E8",
                                "KIT-WQC12H9E8",
                                "WH-MXC09H3E5",
                                "WH-MXC12H6E5",
                                "WH-MXC09H3E8",
                            ]
                        },
                        {
                            label: "SERIENUMMER",
                            name: "serienummer",
                            placeholder: "X03YWWWXXX",
                            type: "text",
                            width: "fullständig"
                        }
                    ]
                },
                {
                    headline: "Om du redan har nätverksadaptern, ange enhetens ID",
                    inputs: [
                        {
                            label: "DEVICE ID",
                            name: "gatewayid",
                            placeholder: "54321-12345",
                            type: "text",
                            width: "fullständig"
                        },
                    ]
                },
                {
                    headline: "När installerades enheten?",
                    inputs: [
                        {
                            label: "DATUM FÖR INSTALLATION",
                            name: "installationDate",
                            placeholder: "12/22/2020",
                            type: "datum",
                            width: "fullständig",
                        },
                    ]
                },
            ],
        },
        {
            label: "Kontoinformation",
            href: "/service_package",
            href_label: "TILLBAKA TILL SKYDDSPLANER",
            next_btn: "NÄSTA STEG",
            inputList: [
                {
                    headline: "Skapa ett nytt konto",
                    login: "LOGGA IN",
                    login_path: "",
                    login_label: "Är du redan användare?",
                    inputs: [
                        {
                            label_first: "EMAIL",
                            label_last: "UPPREPA E-POST",
                            name: "e-post",
                            placeholder: "E-post",
                            type: "matching_email_values",
                            width: "fullständig",
                            checkId: "e-post",
                            inputs:{
                                type: "e-post",
                                validation: "matching_email"
                            }
                        },
                        {
                            label_first: "LÖSENORD",
                            label_last: "UPPREPA LÖSENORDET",
                            name: "lösenord",
                            placeholder: "Lösenord",
                            type: "lösenord",
                            width: "fullständig",
                            checkId: "lösenord",
                            inputs:{
                                type: "lösenord",
                                validation: "matching_password"
                            }
                        },
                    ]
                },
                {
                    headline: "Kontoadress",
                    inputs: [
                        {
                            label: "TITLE",
                            name: "titel",
                            placeholder: "Fru.",
                            type: "välj",
                            width: "liten",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            type: "white_space",
                            width: "liten",
                        },
                        {
                            label: "FÖRNAMN",
                            name: "Förnamn",
                            placeholder: "Förnamn",
                            type: "text",
                            width: "liten",
                            validation: "krävs"
                        },
                        {
                            label: "Efternamn",
                            name: "Efternamn",
                            placeholder: "Efternamn",
                            type: "text",
                            width: "liten",
                            validation: "krävs"
                        },
                        {
                            label: "STREET",
                            name: "gata",
                            placeholder: "gata",
                            type: "text",
                            width: "fullständig"
                        },
                        {
                            label: "POSTNUMMER",
                            name: "postnummer",
                            placeholder: "1820",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "CITY",
                            name: "staden",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "TELEFONNUMMER",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "fullständig"
                        }
                    ]
                },
                {
                    headline: "Var är enheten belägen?",
                    inputs: [
                        {
                            labels: [
                                {
                                    id: "same_address",
                                    label: 'Same as account</br>address',
                                    name: "same_acc",
                                    value: "",
                                    width: "liten"
                                },
                                {
                                    id: "diff_address",
                                    label: 'Enter different</br>address',
                                    name: "diff_address",
                                    value: "",
                                    width: "liten"
                                }
                            ],
                            type: "radio",
                            width: "fullständig",
                        },
                    ]
                }
            ],
        },
        {
            label: "Betalningsuppgifter",
            href: "/service_package",
            href_label: "GÅ TILLBAKA",
            text: "Vänligen välj din betalningsmetod Betalningen kommer att slutföras efter utcheckning.",
            next_btn: "NÄSTA STEG",
            inputList: [
                {
                    headline: "Ange betalningsuppgifter",
                    inputs: [
                        {
                            label: "Betalningsmetod",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Betalningsmetod",
                                    key: "paymentOpt",
                                    value: "kredit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Betalningsmetod",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "fullständig"
                        },
                        {
                            label: "KORTNUMMER",
                            name: "card_number",
                            placeholder: "Kortnummer",
                            type: "text",
                            width: "fullständig"
                        },
                        {
                            label: "UTGÅNGSDATUM.",
                            name: "expiration_date",
                            placeholder: "Utgångsdatum",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "CVV",
                            type: "text",
                            width: "liten"
                        },
                        {
                            labels: [
                                {
                                    id: "datarättigheter",
                                    label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                                    name: "datarättigheter",
                                    value: "",
                                    width: "fullständig"
                                },
                            ],
                            type: "radio",
                            width: "fullständig",
                        }
                    ]
                },
            ],
        },
        {
            label: "Bekräftelse",
            href: "/service_package",
            href_label: "TILLBAKA TILL SKYDDSPLANER",
            next_btn: "NÄSTA STEG",
            inputList: [
                {
                    headline: "Skapa ett nytt konto",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "E-post",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "LÖSENORD",
                            placeholder: "Lösenord",
                            type: "text",
                            width: "liten"
                        }
                    ]
                },
                {
                    headline: "Kontoadress",
                    inputs: [
                        {
                            label: "KUNDTYP",
                            name: "kund",
                            placeholder: "Privat",
                            type: "välj",
                            width: "liten",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "TITLE",
                            name: "titel",
                            placeholder: "Fru.",
                            type: "välj",
                            width: "liten",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FÖRNAMN",
                            name: "first_name",
                            placeholder: "Förnamn",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "Efternamn",
                            name: "last_name",
                            placeholder: "Efternamn",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "STREET",
                            name: "gata",
                            placeholder: "adress",
                            type: "text",
                            width: "fullständig"
                        },
                        {
                            label: "POSTNUMMER",
                            name: "postnummer",
                            placeholder: "1820",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "CITY",
                            name: "staden",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "liten"
                        },
                        {
                            label: "TELEFONNUMMER",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "fullständig"
                        }
                    ]
                }
            ],
        }
    ],
    summary: {
        conformation: {
            headline: "BEKRÄFTELSE",
            subheadline: "Utvald plan"
        },
    },
};
