import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import i18n from '../../lib/i18n';
import {editAccount} from '../../lib/dataAccess/accountService';
import {ErrorResponseHandling} from "../../lib/dataAccess/errorResponseHandling";
import FormItem from '../registration/formItem';
import {AuthContext} from "../../lib/contexts/authContext";
import {FormContainer, FormFieldset, FormRow} from "../layout/form";
import {BtnGroup, LoadingSpinnerButton} from "../layout/buttons";
import {COUNTRY_CODE_NAME, getSessionStorageItem} from "../../lib/sessionStorage";

const countryCode = getSessionStorageItem(COUNTRY_CODE_NAME) || 'da_DK';

const t = i18n.namespace("MyAccountAccountInformation");

function EditAccountInformation({account}) {
  const {authContext, setAuthContext} = useContext(AuthContext);
  const history = useHistory();
  const getFirstAccountAddress = () => {
    return authContext.accountData.addresses[0];
  }

  useEffect(() => {
    if (authContext.accountData) {
      setFormState(getInitialFormState());

    }
  }, [authContext]) // eslint-disable-line react-hooks/exhaustive-deps


  const [formState, setFormState] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [isFormInvalid, setFormInvalid] = useState(true);

  const getInitialFormState = () => {
    const account = authContext.accountData;
    return {
      firstName: {value: account.firstName, invalid: false},
      lastName: {value: account.lastName, invalid: false},
      title: {value: account.title, invalid: false},
      sendInvoice: {value: account.sendInvoice === true || account.sendInvoice === "true", invalid: false},
      city: {value: getFirstAccountAddress().city ?? '', invalid: false},
      zipCode: {value: getFirstAccountAddress().zipCode ?? '', invalid: false},
      street: {value: getFirstAccountAddress().street ?? '', invalid: false},
      phoneNumber: {value: getFirstAccountAddress().phoneNumber ?? '', invalid: false},
    };
  }

  const getControls = () => {
    switch(countryCode) {
      case 'da_DK':
        return formControlsDk;
      case 'es_ES':
      case 'en_ES':
        return formControlsEs;
      case 'en_IE':
        return formControlsIe;
      case 'it_IT':
        return formControlsIt;
      case 'sv_SE':
        return formControlsSe;
      default:
        return formControls;
    }
  }

  const formControls = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 40},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 40},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 60},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED"],
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 40},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH"],
      constraints: {minLength: 5},
      type: "phone",
      width: "col"
    }],
  ];

  const formControlsDk = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 19},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 19},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 30},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED", "EXACTLY_LENGTH"],
      constraints: {exactlyLength: 4},
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 35},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 9, maxLength: 17},
      type: "phone",
      width: "col",
      offset: 3
    }],
  ];

  const formControlsEs = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 19},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 19},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 30},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED", "ZIPCODE"],
      constraints: {length: 5},
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 35},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 9, maxLength: 17},
      type: "phone",
      width: "col",
      offset:3
    }],
    // [{
    //   label: t("details.sendInvoice"),
    //   name: "sendInvoice",
    //   type: "checkbox",
    //   placeholder: "",
    //   checked: false,
    //   width: "col"
    // }],
  ];

  const formControlsIe = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 19},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 19},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 30},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH_DIGIT", "MAX_LENGTH_DIGIT"],
      constraints: {minLength: 7, maxLength: 8},
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 35},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 9, maxLength: 17},
      type: "phone",
      width: "col",
      offset: 4
    }],
  ];

  const formControlsIt = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 19},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 19},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 30},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED", "EXACTLY_LENGTH"],
      constraints: {exactlyLength: 5},
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 35},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 9, maxLength: 17},
      type: "phone",
      width: "col",
      offset:3
    }],
  ];

  const formControlsSe = [
    [{
      label: t("details.firstName"),
      name: "firstName",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 2, maxLength: 19},
      width: "col-12 col-lg-6",
    },
      {
        label: t("details.lastName"),
        name: "lastName",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 2, maxLength: 19},
        type: "text",
        width: "col-12 col-lg-6",
      }],
    [{
      label: t("details.address"),
      name: "street",
      placeholder: "",
      type: "text",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 1, maxLength: 30},
      width: "col"
    }],
    [{
      label: t("details.zipCode"),
      name: "zipCode",
      placeholder: "",
      validators: ["REQUIRED", "SWEDISH_ZIPCODE"],
      constraints: {minLength: 3, maxLength: 6},
      type: "text",
      width: "col-6"
    },
      {
        label: t("details.city"),
        name: "city",
        placeholder: "",
        validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
        constraints: {minLength: 1, maxLength: 35},
        type: "text",
        width: "col-6"
      }],
    [{
      label: t("details.phoneNumber"),
      name: "phoneNumber",
      placeholder: "",
      validators: ["REQUIRED", "MIN_LENGTH", "MAX_LENGTH"],
      constraints: {minLength: 9, maxLength: 17},
      type: "phone",
      width: "col",
      offset:3
    }],
  ];
  const setValueAndValidity = ({target: {name, value}}, invalid) => {
    setFormState((prev) => {
      const newFormState = {...prev, [name]: {value, invalid}};
      validateForm(newFormState);
      return {
        ...newFormState
      }
    });
  };

  const validateForm = (newFormState) => {
    //console.log('validateForm Edit ', newFormState, Object.keys(newFormState).some(key => newFormState[key].invalid));
    setFormInvalid(Object.keys(newFormState).some(key => newFormState[key].invalid));
  }

  const updateAccount = () => {
    setLoading(true);
    const updateData = {
      firstName: formState.firstName.value,
      lastName: formState.lastName.value,
      title: formState.title.value,
      sendInvoice: formState.sendInvoice.value,
      address: {
        id: getFirstAccountAddress().id,
        countryCode: getFirstAccountAddress().countryCode,
        city: formState.city.value,
        street: formState.street.value,
        zipCode: formState.zipCode.value,
        phoneNumber: formState.phoneNumber.value
      }
    };

    editAccount(authContext.authToken, updateData).then((res) => {
      setAuthContext({...authContext, accountData: {...authContext.accountData, ...res.data}});
      back();
    }).catch((e) => {
      setLoading(false);
      ErrorResponseHandling(e, authContext, setAuthContext, history);
    });
  }

  const back = () => {
    history.push("/"+ countryCode +"/home/my_account");
  }


  return (
      formState &&
      <FormContainer>
        <FormFieldset>
          {
            getControls().map((rows, rowIndex) =>
                <FormRow key={rowIndex}>
                  {
                    rows.map((input, inputIndex) =>
                        <FormItem
                            key={inputIndex}
                            item={input}
                            currentValue={formState[input.name]?.value}
                            saveValueByStep={setValueAndValidity}
                            currentStepValues={formState}
                            country={countryCode.substring(3)}
                            runInitialValidation
                            checked={input.name === "sendInvoice" ?
                                formState[input.name].value === true || formState[input.name].value === "true" : undefined}
                        />
                    )
                  }
                </FormRow>
            )
          }
        </FormFieldset>

        <BtnGroup>
          <button className="btn-secondary" onClick={back}>{t("btn.back")}</button>
          <LoadingSpinnerButton
              onClick={updateAccount}
              classNames="btn-primary order-1 order-md-2 loading-spinner-button ml-auto"
              isEnabled={!isFormInvalid}
              isLoading={isLoading}
              text={t("btn.update")}
          />
        </BtnGroup>
      </FormContainer>
  );
}

export default EditAccountInformation;
