import React, {useEffect} from 'react';

import i18 from "../lib/i18n";
import Menu from "../components/menu";
import {Page, PageFooter} from "../components/layout/page";
import Breadcrumbs from "../lib/breadcrumbs";
import {scrollToPos} from "../lib/scrollToPos";

const t = i18.namespace("CookiePolicy");

const CookiePolicy = () => {

    useEffect(() => {
        scrollToPos(0, 0);
    }, [])

    return <Page>
        <Menu/>
        <div className="page_header container">
            <section className="breadcrumbs">
                <Breadcrumbs/>
            </section>
            <h1 className="planCard_headline">{t("headline")}</h1>
        </div>
        <div className="container">
            <div className="cookies_content">
                <button id="ot-sdk-btn" className="ot-sdk-show-settings btn-secondary margin-bottom">Cookie Settings</button>
                <div id="ot-sdk-cookie-policy"></div>
            </div>
        </div>
        <PageFooter/>
    </Page>
}

export default CookiePolicy;
