module.exports = {
    inputs: [
        {
            label: "Adgangskode",
            id: "newPassword",
            name: "newPassword",
            placeholder: "Adgangskode",
            type: "password"
        },
        {
            label: "Bekræft adgangskode",
            id: "confirmPassword",
            name: "confirmPassword",
            password_placeholder: "Bekræft adgangskode",
            type: "password"
        }
    ],
    success: "Du er blevet bedt om at nulstille din adgangskode, tjek din indbakke!",
    subheadline: "Opret adgangskode",
    headline: "Opret adgangskode",
    resetBtn: "Opret adgangskode"
}