module.exports = {
    points:[
        {
            label: "#1",
            text: "Fornitore di pompe di calore"
        },
        {
            label: "60 anni",
            text: "esperienza con le pompe di calore"
        },
        {
            label: "24/7",
            text: "monitoraggio tramite IoT (Internet of Things)"
        },
        {
            label: ">100K",
            text: "pompe di calore collegate"
        },
    ]
};
