module.exports = {
    headline: "Checkout",
    list: [
        {
            label: "Connection Check",
            href: "/en_ES/home/service_package",
            href_label: "BACK TO PROTECTION PLANS",
            next_btn: "NEXT STEP",
            add_installation_items: {
                add_installation_btn: "Add installation service for 1,495.00DKK",
                add_installation_label: "Important information:",
                add_installation_text: 'To be able to offer you the full service of our Protection Plans, it is necessary that your device is connected to the Internet. <br><br>To ensure that your device is connected to the internet, we recommend that you book our internet installation service package. This service will cost you a one-time fee of 49€. <br><br>We will automatically add our installation service to the purchase process and call you to arrange a time to install it.',
            },
            change_plan_items: {
                change_plan_changeid: 2,
                change_plan_btn_sameid: "NEXT STEP",
                change_plan_btn: "Change my selection to Aquarea Plan*",
                change_plan_link: "Learn more about Aquare Plan *",
                change_plan_label: "Important information:",
                change_plan_text: 'We cannot offer you all of our Protection Plans at this time because your device cannot be connected to the Internet.In order to provide you with the best possible service, we will change your selected Protection Plan to Aquarea Bronze in this purchase process. <br><br>Your selected Protection Plan: Aquarea Plan *** <br><br>Will change to: Aquarea  Plan* for 25€ month.',
                change_plan_dropdowntext: 'With our Aquarea Guard maintenance contract you have one worry less. Your heat pump is permanently monitored remotely. And if an error occurs, we are the first to know. We will contact you immediately and try to solve the problem or coordinate a service appointment.<br><br>You dont have to do anything else but have a cup of tea meanwhile.',
                change_plan_dropdowntext_close_btn: true,
                change_plan_dropdowntext_close_btn_label: "CLOSE INFORMATION",
            },
            inputList: [
                {
                    text: "To provide you with the optimal service, we need to make sure that your devices are connected to the internet.",
                    label: "Is your unit connected to the internet?",
                    width: "full",
                    name: "internetConnection",
                    startValue: true,
                    hint: {
                        label: "not sure?",
                        text: "test",
                        img: "https://picsum.photos/400/200",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Yes",
                                key: "internetConnected",
                                defaultValue: true,
                                value: true,
                            },
                            {
                                label: "No",
                                key: "internetConnected",
                                defaultValue: false,
                                value: false,
                            }
                        ]
                    }
                },
                {
                    label: "Is your unit internet ready?",
                    width: "full",
                    name: "unitForInternet",
                    startValue: true,
                    hint: {
                        label: "not sure?",
                        text: "",
                        img: "",
                        img_alt: ""
                    },
                    inputs: {
                        checkboxes: [
                            {
                                label: "Yes",
                                key: "internetReady",
                                defaultValue: false,
                                value: true
                            },
                            {
                                label: "No",
                                key: "internetReady",
                                defaultValue: false,
                                value: false
                            }
                        ]
                    }
                },
            ],
        },
        {
            label: "Installed base",
            href: "/service_package",
            href_label: "BACK TO PROTECTION PLANS",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Which model is installed?",
                    inputs: [
                        {
                            label: "MODEL NAME",
                            name: "model",
                            placeholder: "KIT-ADC05JEB5",
                            type: "select",
                            width: "full",
                            options: [
                                "KIT-ADC05JEB5",
                                "KIT-WQC09H3E8",
                                "KIT-WQC12H9E8",
                                "WH-MXC09H3E5",
                                "WH-MXC12H6E5",
                                "WH-MXC09H3E8",
                            ]
                        },
                        {
                            label: "SERIAL NUMBER",
                            name: "serialnumber",
                            placeholder: "X03YWWXXX",
                            type: "text",
                            width: "full"
                        }
                    ]
                },
                {
                    headline: "If you have already the Network Adaptor, please enter the Device ID",
                    inputs: [
                        {
                            label: "DEVICE ID",
                            name: "gatewayid",
                            placeholder: "54321-12345",
                            type: "text",
                            width: "full"
                        },
                    ]
                },
                {
                    headline: "When was the unit installed?",
                    inputs: [
                        {
                            label: "INSTALLATION DATE",
                            name: "installationDate",
                            placeholder: "12/22/2020",
                            type: "date",
                            width: "full",
                        },
                    ]
                },
            ],
        },
        {
            label: "Account Information",
            href: "/service_package",
            href_label: "BACK TO PROTECTION PLANS",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Create new account",
                    login: "SIGN IN",
                    login_path: "",
                    login_label: "Already a user? ",
                    inputs: [
                        {
                            label_first: "EMAIL",
                            label_last: "REPEAT EMAIL",
                            name: "email",
                            placeholder: "Email",
                            type: "matching_email_values",
                            width: "full",
                            checkId: "email",
                            inputs:{
                                type: "email",
                                validation: "matching_email"
                            }
                        },
                        {
                            label_first: "PASSWORD",
                            label_last: "REPEAT PASSWORD",
                            name: "password",
                            placeholder: "Password",
                            type: "password",
                            width: "full",
                            checkId: "password",
                            inputs:{
                                type: "password",
                                validation: "matching_password"
                            }
                        },
                    ]
                },
                {
                    headline: "Account address",
                    inputs: [
                        {
                            label: "TITEL",
                            name: "title",
                            placeholder: "Mrs.",
                            type: "select",
                            width: "small",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            type: "white_space",
                            width: "small",
                        },
                        {
                            label: "FIRST NAME",
                            name: "firstName",
                            placeholder: "First name",
                            type: "text",
                            width: "small",
                            validation: "required"
                        },
                        {
                            label: "Last NAME",
                            name: "lastName",
                            placeholder: "Last name",
                            type: "text",
                            width: "small",
                            validation: "required"
                        },
                        {
                            label: "STREET",
                            name: "street",
                            placeholder: "street",
                            type: "text",
                            width: "full"
                        },
                        {
                            label: "ZIP CODE",
                            name: "zipCode",
                            placeholder: "1820",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "CITY",
                            name: "city",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "PHONE NUMBER",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "full"
                        }
                    ]
                },
                {
                    headline: "Where is the unit located?",
                    inputs: [
                        {
                            labels: [
                                {
                                    id: "same_address",
                                    label: 'Same as account</br>address',
                                    name: "same_acc",
                                    value: "",
                                    width: "small"
                                },
                                {
                                    id: "diff_address",
                                    label: 'Enter different</br>address',
                                    name: "diff_address",
                                    value: "",
                                    width: "small"
                                }
                            ],
                            type: "radio",
                            width: "full",
                        },
                    ]
                }
            ],
        },
        {
            label: "Payment Details",
            href: "/service_package",
            href_label: "GO BACK",
            text: "Please select your payment method Payment will be completed after checkout",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Enter payment details",
                    inputs: [
                        {
                            label: "Payment method",
                            name: "payment_opt",
                            type: "payment_opt",
                            payments: [
                                {
                                    id: "0",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "credit",
                                    image: "/image/icons/checkout/credit.png",
                                    isChecked: false
                                },
                                {
                                    id: "1",
                                    label: "Payment method",
                                    key: "paymentOpt",
                                    value: "paypal",
                                    image: "/image/icons/checkout/paypal.png",
                                    isChecked: false
                                },
                            ],
                            width: "full"
                        },
                        {
                            label: "CARD NUMBER",
                            name: "card_number",
                            placeholder: "Card number",
                            type: "text",
                            width: "full"
                        },
                        {
                            label: "EXPIRATION DATE",
                            name: "expiration_date",
                            placeholder: "Expiration date",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "CVV",
                            name: "cvv",
                            placeholder: "CVV",
                            type: "text",
                            width: "small"
                        },
                        {
                            labels: [
                                {
                                    id: "datarights",
                                    label: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
                                    name: "datarights",
                                    value: "",
                                    width: "full"
                                },
                            ],
                            type: "radio",
                            width: "full",
                        }
                    ]
                },
            ],
        },
        {
            label: "Confirmation",
            href: "/service_package",
            href_label: "BACK TO PROTECTION PLANS",
            next_btn: "NEXT STEP",
            inputList: [
                {
                    headline: "Create new account",
                    inputs: [
                        {
                            label: "EMAIL",
                            placeholder: "Email",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "PASSWORD",
                            placeholder: "Password",
                            type: "text",
                            width: "small"
                        }
                    ]
                },
                {
                    headline: "Account address",
                    inputs: [
                        {
                            label: "CUSTOMER TYPE",
                            name: "customer",
                            placeholder: "Private",
                            type: "select",
                            width: "small",
                            options: [
                                "Private",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "TITEL",
                            name: "title",
                            placeholder: "Mrs.",
                            type: "select",
                            width: "small",
                            options: [
                                "Mrs.",
                                "test1",
                                "test2",
                                "test3",
                                "test4",
                                "test5",
                                "test6"
                            ]
                        },
                        {
                            label: "FIRST NAME",
                            name: "first_name",
                            placeholder: "First name",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "Last NAME",
                            name: "last_name",
                            placeholder: "Last name",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "STREET",
                            name: "street",
                            placeholder: "adress",
                            type: "text",
                            width: "full"
                        },
                        {
                            label: "ZIP CODE",
                            name: "zipCode",
                            placeholder: "1820",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "CITY",
                            name: "city",
                            placeholder: "FREDERIKSBERG",
                            type: "text",
                            width: "small"
                        },
                        {
                            label: "PHONE NUMBER",
                            name: "phoneNumber",
                            placeholder: "+49 60-80-19-79",
                            type: "text",
                            width: "full"
                        }
                    ]
                }
            ],
        }
    ],
    summary: {
        conformation: {
            headline: "CONFIRMATION",
            subheadline: "Selected plan"
        },
    },
};
