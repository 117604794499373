module.exports = {
    headline: "Pacchetto di servizi",
    icon: '/image/icons/cart/cart.png',
    iconCount: '/image/icons/cart/cart2.png',
    href: '/cart',
    titel: "shoppingcart",
    overlayLabel:"La vostra scelta:",
    btn: "piano di protezione dell'acquisto",
    btnPath: "/it_IT/home/service_package",
    edit: "edit",
    editPath: "/it_IT/home/service_package"
};
